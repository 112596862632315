import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import FeedCreate from './FeedCreate';
import FeedEdit from './FeedEdit';
import FeedList from './FeedList';

const feed = {
    create: FeedCreate,
    edit: FeedEdit,
    list: FeedList,
    icon: InvoiceIcon,
};

export default feed;
