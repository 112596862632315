import React from "react";
import {Box, Button, Modal} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Button as PANWDSButton }  from "@panwds/react-ui";

interface ITimeoutModalProps {
  showModal: boolean,
  togglePopup: () => void,
  onStayLoggedIn: () => Promise<void>,
  delay: number,
  onTimeExpire: () => void;
}

const BoxModal = withStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '32px',
  }
}))(Box);

const TimeoutModal: React.FC<ITimeoutModalProps> = ({ showModal, togglePopup, onStayLoggedIn, delay, onTimeExpire }) => {
  const [counter, setCounter] = React.useState(0);

  const incrementCounter = React.useCallback(() => {
    if (counter === delay) {
      onTimeExpire();
    }
    else {
      setCounter(counter + 1);
    }
  }, [counter, setCounter, delay, onTimeExpire]);

  React.useEffect(() => {
    let timer: any = null;
    if (showModal) {
      timer = setTimeout(() => {
        incrementCounter();
      }, 1000);
    }
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearTimeout(timer);
    }
  }, [counter, showModal]);

  const handleOnStayLoggedIn = React.useCallback(async () => {
    setCounter(0);
    await onStayLoggedIn();
  }, [setCounter]);

  React.useEffect(() => {
    setCounter(0);
  }, [showModal]);

  const remainingTime = delay - counter;

  return (
    <Modal
      open={showModal}
      onClose={() => togglePopup()}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      data-test-id="timeout-modal"
    >
      <BoxModal>
        <h2 id="parent-modal-title" data-test-id="timeout-title">Session Timeout!</h2>
        <p id="parent-modal-description" data-test-id="timeout-description">
          Your session is about to expire in {remainingTime} seconds due to inactivity.
          You will be redirected to the login page.
        </p>
        <PANWDSButton appearance="primary" onClick={handleOnStayLoggedIn} data-test-id="timeout-button">
          Stay Logged In
        </PANWDSButton>
      </BoxModal>
    </Modal>
  );
}

export default TimeoutModal;
