import { CircleCheckIcon, ExclamationCircleIcon, SpinnerIcon } from "@panwds/icons";
import { find, isEmpty } from "lodash";

export const FIREWALL_STATE_FILTERS = {
    CREATING: 'Creating',
    UPDATING: 'Updating',
    DELETING: 'Deleting',
    CREATE_COMPLETE: 'Create Complete',
    UPDATE_COMPLETE: 'Update Complete',
    DELETE_COMPLETE: 'Delete Complete',
    CREATE_FAIL: 'Create Fail',
    UPDATE_FAIL: 'Update Fail',
    DELETE_FAIL: 'Delete Fail'
};

export const getPolicyManager = (record: Record<string, any>) => {
    if (record?.Firewall?.LinkId?.includes('Link-SCM')) {
        return `Strata Cloud Manager (${record?.Firewall?.LinkId})`
    } else if (record?.Firewall?.LinkId) {
        return `Panorama (${record?.Firewall?.LinkId})`
    } else if (record?.Firewall?.RuleStackName) {
        return `Local Rulestack (${record?.Firewall?.RuleStackName})`
    } else if (record?.Firewall?.GlobalRuleStackName) {
        return `Global Rulestack (${record?.Firewall?.GlobalRuleStackName})`
    } else {
        return '';
    }
};

export const getFirewallStateDisplay = (state: string) => {
    const lowerCaseState = state.toLowerCase();
    const firewallFilterState = FIREWALL_STATE_FILTERS[state] ?? state;
    if (lowerCaseState?.includes('complete')) {
        return (<><CircleCheckIcon size="xs" className="tw-text-teal-600 dark:tw-text-dark-bg-teal"/> &nbsp; {firewallFilterState}</>);
    } else if (lowerCaseState?.includes('fail')) {
        return (<><ExclamationCircleIcon size="xs" className="tw-text-red-500 dark:tw-text-dark-bg-red"/> &nbsp; {firewallFilterState} </>);
    } else {
        return (<><SpinnerIcon size="xs" className="tw-text-orange-400 dark:tw-text-dark-bg-orange"/> &nbsp; {firewallFilterState}</>)
    }
};

export const getFirewallName = (record: Record<string, any>) => {
    if (isEmpty(record)) {
        return '-';
    }
    const result = find(record?.Firewall?.Tags, (tag) => {
        if (tag.Key === 'FirewallName') {
          return true;
        }
      }) as {
        Key: string;
        Value: string;
      } | undefined;
    return (result) ? result['Value'] : '-';
};

export const getFirewallTags = (tags: Record<string, any>, firewallName: string) => {
    if (isEmpty(tags)) {
        return [];
    }
    const index = tags.findIndex(tag => tag.Key === "FirewallName");
    if (index !== -1) {
        tags[index] = { ...tags[index], Value: firewallName };
    }
    else {
        tags.push({Key: "FirewallName", Value: firewallName})
    }

    return tags;
};

export const prepareData = (records: Record<string, any>[]) => {
    if (isEmpty(records)) {
        return [];
    }
    return records.map((record) => {
        return { ...record, Firewall: { ...record.Firewall, 'PolicyManager': getPolicyManager(record), 'FirewallName': getFirewallName(record)} }
    })
};

export const determinePolicyManaged = (firewallData: Record<string, any>): string => {
    if (firewallData?.Firewall?.LinkId?.includes('Link-SCM')) {
        return "Strata";
    } else if (firewallData?.Firewall?.LinkId) {
        return "Panorama";
    } else if (firewallData?.Firewall?.RuleStackName) {
        return "Rulestack";
    } else {
        return "Rulestack";
    }
};
