import { FilterComponent } from "../../../components/Filters";

export const getVPCsFilters = (accountData: any, monitoredData: any, groupData: any) => {
    const vpcFilters = [
        {
            Component: FilterComponent,
            label: "AWS Account ID",
            name: "AccountId",
            context: {
                enableMultiSelect: true,
                items: accountData,
            },
            required: true,
            columnValue: 'AccountId',
            dataTestId: "AccountId",
            // defaultValues: ["209713031700"]
        },
        {
            Component: FilterComponent,
            label: "Monitoring Status",
            name: "Monitored",
            required: true,
            items: monitoredData,
            columnValue: 'Monitored',
            dataTestId: "Monitored",
        },
        {
            Component: FilterComponent,
            label: "VPC Group",
            name: "VpcGroup",
            required: true,
            items: groupData,
            columnValue: 'VpcGroup',
            dataTestId: "VpcGroup",
        }
    ]
    return vpcFilters;
};