import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'
import * as initialState from "../utils/initialStates";
import {
    resetListDetails,
    deleteResource,
    describe,
    fetchList,
    fetchListDetails,
    updateResource,
    compareList
} from "../actions";
import Resources from '../utils/enums';
import {isEmpty, last} from "lodash";

const ruleStacksSlice = createSlice({
    name: Resources.RULESTACK,
    initialState: initialState.ruleStacksInitialState,
    reducers: {

    },
    extraReducers: (builder) => {
        const resource = Resources.RULESTACK
        builder
            .addCase(fetchListDetails({resource}).pending, (state) => {
                state.listDetails.loading = true;
            })
            .addCase(fetchListDetails({resource}).fulfilled, (state, action) => {
                state.listDetails.loading = false;
                if (action.meta.arg.data?.NextToken) {
                    state.listDetails.data = [...state.listDetails.data, ...action.payload.data];
                    state.listDetails.total += action.payload.total;
                } else {
                    state.listDetails.data = action.payload.data;
                    state.listDetails.total = action.payload.total;
                }
                state.listDetails.nextToken = action.payload.nextToken;
            })
            .addCase(fetchListDetails({resource}).rejected, (state, action) => {
                if (!action.payload?.noToast) {
                    state.listDetails.error = action.payload;
                }
                state.listDetails.loading = false;
            })
            .addCase(fetchList({resource}).pending, (state, action) => {
                state.list.loading = true;
            })
            .addCase(fetchList({resource}).fulfilled, (state, action) => {
                state.list.data = action.payload.data
                state.list.loading = false;
            })
            .addCase(fetchList({resource}).rejected, (state, action) => {
                state.list.loading = false;
                state.list.error = action.error.message;
            })
            .addCase(compareList({resource}).fulfilled, (state, action) => {
                state.list.data = action.payload.list;
                state.listDetails.data = action.payload.listDetails;
                state.listDetails.total = action.payload.listDetails.length;
            })
            .addCase(resetListDetails({resource}).fulfilled, (state, action) => {
                state.listDetails = initialState.listDetails;
                state.list = initialState.list;
            })
            .addCase(describe({resource}).fulfilled, (state, action) => {
                if (action.payload.error) {
                    // When one api is giving error there is no point to set the whole list to initialState
                    //state.listDetails = initialState.listDetails;
                } else {
                    let ruleStackPayload = action.payload.data;
                    ruleStackPayload.RuleStack = ruleStackPayload.RuleStackCandidate;
                    delete ruleStackPayload.RuleStackCandidate;
                    // @ts-ignore
                    const lastRuleStackId = last(state.listDetails.data).id;
                    ruleStackPayload.id = lastRuleStackId + 1;
                    state.listDetails.data = [...state.listDetails.data, ruleStackPayload];
                    state.listDetails.total += 1;
                    if (!isEmpty(state.list.data) && !isEmpty(state.listDetails.data) && state.list.data.length === state.listDetails.total) {
                        const list = state.list.data;
                        const listDetails = state.listDetails.data;
                        const newList: any[] = [];
                        let index: number = Math.floor(100000 + Math.random() * 900000);
                        list.map((l: any) => {
                            const ruleStack = listDetails.find((ld: any) => ld.RuleStackName === l.Name);
                            ruleStack.id = index++;
                            newList.push(ruleStack);
                        })
                        state.listDetails.data = newList;
                    }
                }
            })
            .addCase(describe({resource}).rejected, (state, action) => {
                state.listDetails = initialState.listDetails;
            })
            .addCase(deleteResource({resource}).fulfilled, (state, action) => {
                const deletedItems = action.meta.arg?.deletedItems;
                if (deletedItems && deletedItems.length > 0) {
                    const filteredData = state.listDetails.data.filter((l: { RuleStackName: any; }) => deletedItems.findIndex((di: any) => di.RuleStackName === l.RuleStackName) === -1);
                    state.listDetails.data = filteredData;
                    state.listDetails.total = filteredData.length;
                    state.listDetails.loading = false;
                }
            })
            .addCase(updateResource({resource}).fulfilled, (state, action) => {
                let ruleStackPayload = action.payload.data;
                const ruleStackName = action.meta.arg.RuleStackName;

                const index = state.listDetails.data.findIndex((d: any) => d.RuleStackName === ruleStackName);
                if (index !== -1) {
                    state.listDetails.data[index].RuleStack = ruleStackPayload.RuleStackCandidate;
                    state.listDetails.data[index].RuleStackState = ruleStackPayload.RuleStackState;
                    if (ruleStackPayload.Tags) state.listDetails.data[index].Tags = ruleStackPayload.Tags;
                    if (ruleStackPayload.RuleStackRunning) state.listDetails.data[index].RuleStackRunning = ruleStackPayload.RuleStackRunning;
                }
            })
    }
});

export const {} = ruleStacksSlice.actions;

export default ruleStacksSlice.reducer
