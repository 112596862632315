import { Box } from '@material-ui/core';
import { InfoIcon } from '@panwds/icons';
import { useContext, useEffect, useState} from 'react';
import { makeStyles} from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@panwds/react-ui';
import { PANTitle, toast } from '../../components';
import {PANWDSBreadcrumbs, PanwdsTableLightWithFilters} from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import {GlobalContext} from "../../context/global";
import _ from 'lodash';
import * as DataTypes from "../../api/FwaasDataTypes";
import {useTranslate} from '../../customHooks';

const useStyles = makeStyles((theme) => ({
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
  },
}));

const FileBlocking = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const title = translate(`resources.fileBlocking.name`)
  const globalContext = useContext(GlobalContext);
  // get the id from route params
  const ruleStackName = props.match.params.rulestackname;

  const [gridData, setGridData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showDropdownActions, setShowDropdownActions] = useState(false);

  useEffect(() => {
    async function checkRecord() {
      setLoading(true);
      // @ts-ignore
      if (!_.isEmpty(ruleStackName) || !_.isEmpty(globalContext.state)) {
        // @ts-ignore
        let record = _.get(globalContext.state, `${ruleStackName}`, {});
        if (_.isEmpty(record)) {
          record = await getRecord();
        }
        const fileBlockingProfile = await _.get(record, "RuleStackCandidate.Profiles.FileBlockingProfile", '');
        if (fileBlockingProfile === 'Custom') {
          setShowDropdownActions(true);
          loadData('Custom');
        } else {
          loadData('BestPractice');
        }
      }
    }
    checkRecord();
  }, []);

  const prepareData = (rowData: any) => {
    const value = rowData?.map((data: any) => ({
        Description: data?.Description,
        FileType: data?.FileType,
        FileTypeJSX: (data?.Overridden ?
        (<div className={classes.iconedParagraph}>{data?.FileType}<Tooltip label='Overriden'><InfoIcon  size='xs' /></Tooltip></div>) : data?.FileType),
        DefaultActionJSX: (data?.Overridden ? <span> {data?.Action} </span> : <span> {data?.DefaultAction} </span>),
        DefaultDirectionJSX: (data?.Overridden ? <span> {data?.Direction} </span> : <span> {data?.DefaultDirection} </span>),
    }));
    return value;
  };

  let columns = [
    {
      accessor: 'Description',
      Header: translate(`resources.fileBlocking.fields.Name`),
      columnSize: 4,
    },
    {
      accessor: 'FileType',
      Header: translate(`resources.fileBlocking.fields.FileTypeName`),
      columnSize: 3,
      minWidth: 150,
      Cell: ({ row }: any) => <>{row?.original?.FileTypeJSX}</>,
    },
    {
      accessor: 'DefaultAction',
      Header: translate(`resources.fileBlocking.fields.Action`),
      columnSize: 2.5,
      Cell: ({ row }: any) => <>{row?.original?.DefaultActionJSX}</>,
    },
    {
      accessor: 'DefaultDirection',
      Header: translate(`resources.fileBlocking.fields.Direction`),
      // columnSize: 2.5,
      Cell: ({ row }: any) => <>{row?.original?.DefaultDirectionJSX}</>,
    }
  ];

  const getRecord = () => {
    return dataProvider.describe('ruleStacks', '',
      { RuleStackName: ruleStackName })
      .then((res: DataTypes.IFwaasApiResponse) => {
        if (res.data) return res?.data;
      }).catch((e: any) => {
      return {};
    });
  };

  const loadData = (typeSelected: any) => {
    // setLoading(true);
    dataProvider.getList("fileBlocking", {
      pagination: { page: 1, perPage: 5 },
      sort: { field: 'Description', order: 'ASC' },
      filter: {},
      data: { RuleStackName: ruleStackName, typeSelected }
    }).then(async (response: any) => {
      if (response.data) {
        //@ts-ignore
        let fileBlockingTypes = response.data.FileTypesRunning;
        //@ts-ignore
        setGridData(fileBlockingTypes);
        setLoading(false);
      } else {
        toast.error(response?.error, { toastId: "fileBlocking-getList" });
        setLoading(false);
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "fileBlocking-getList" });
        setLoading(false);
      });
  };

  const updateFileBlockingAction = (action: string, selected: any[]) => {
    setLoading(true);
    setGridData([]);
    let calls: any = [];
    selected.forEach(selectedItem => {
      let fileType: any = gridData.find((fileType: any) => fileType.FileType === selectedItem?.FileType);
      const selectedOverrideAction = fileType?.Overridden ? fileType?.Action : fileType?.DefaultAction;
      //@ts-ignore
      if (selectedOverrideAction === action) {
        toast.success(translate(`resources.fileBlocking.actionOverride`));
      } else {
        //@ts-ignore
        let payload: any = { ...fileType, Action: action, RuleStackName: ruleStackName };
        calls.push(dataProvider.update("fileBlocking", payload));
      }
    })
    Promise.all(calls).finally(() => loadData(showDropdownActions ? 'Custom' : 'BestPractice'));
  }

  const updateFileBlockingDirection = (direction: string, selected: any[]) => {
    setLoading(true);
    setGridData([]);
    let calls: any = [];

    selected.forEach(selectedItem => {
      let fileType: any = gridData.find((fileType: any) => fileType.FileType === selectedItem?.FileType);
      const selectedOverrideDirection = fileType?.Overridden ? fileType?.Direction : fileType?.DefaultDirection
      //@ts-ignore
      if (selectedOverrideDirection === direction) {
        toast.success(translate(`resources.fileBlocking.directionOverride`));
      } else {
        //@ts-ignore
        let payload: any = { ...fileType, Direction: direction, RuleStackName: ruleStackName };
        calls.push(dataProvider.update("fileBlocking", payload));
      }
    })
    Promise.all(calls).then((response: any) => {
      if(response.data){
        return;
      }
      else{
        toast.error(response?.error, { toastId: "fileBlocking-update" });
      }
    }).catch((e: any) =>
      toast.error(e?.error, { toastId: "fileBlocking-update" })
    )
      .finally(
        () => loadData(showDropdownActions ? 'Custom' : 'BestPractice')
      );
  }

  const dropDownActionsArray = [
    {
      title: translate(`resources.fileBlocking.dropdown.setAction`),
      actionsMap: [
        { menuText: translate(`resources.fileBlocking.dropdown.alert`), handleAction: (selected: any) => updateFileBlockingAction("alert", selected), dataMetrics: "cloudngfw-fileblocking-alert-btn" },
        { menuText: translate(`resources.fileBlocking.dropdown.block`), handleAction: (selected: any) => updateFileBlockingAction("block", selected), dataMetrics: "cloudngfw-fileblocking-block-btn" },
        { menuText: translate(`resources.fileBlocking.dropdown.continue`), handleAction: (selected: any) => updateFileBlockingAction("continue", selected), dataMetrics: "cloudngfw-fileblocking-continue-btn" },
      ]
    },
    {
      title: translate(`resources.fileBlocking.dropdown.setDirectionOfTraffic`),
      actionsMap: [
        { menuText: translate(`resources.fileBlocking.dropdown.upload`), handleAction: (selected: any) => updateFileBlockingDirection("upload", selected), dataMetrics: "cloudngfw-fileblocking-upload-btn" },
        { menuText: translate(`resources.fileBlocking.dropdown.download`), handleAction: (selected: any) => updateFileBlockingDirection("download", selected), dataMetrics: "cloudngfw-fileblocking-download-btn" },
        { menuText: translate(`resources.fileBlocking.dropdown.both`), handleAction: (selected: any) => updateFileBlockingDirection("both", selected), dataMetrics: "cloudngfw-fileblocking-both-btn" },
      ]
    }
  ];

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: translate(`resources.ruleStacks.name`),
          [ruleStackName]: ruleStackName,
          [`securityservices`]: translate(`resources.securityservices.name`),
          fileblocking: translate(`resources.fileBlocking.name`)
        }}
      />
      <PANTitle title={title} divider />
      <Box style={{ position: 'relative', height: '100%' }}>
        <PanwdsTableLightWithFilters
          columns={columns}
          gridData={prepareData(gridData)}
          title={title}
          showToolbar={true}
          dropDownActionsArray={showDropdownActions ? dropDownActionsArray : undefined}
          showTileTitle={true}
          loading={loading}
          singleSelect={false}
          multiSelect={showDropdownActions}
          offsetTableHeight={210}
          overflowTable={true}
          dataMetrics="cloudngfw-fileblocking-table"
          dataTestId="cloudngfw-fileblocking-table"
          resizable={true}
          enablePagination={true}
          searchFilterRequired={true}
        />
      </Box>
    </>
  );
};

export default withRouter(FileBlocking);
