import InfoIcon from "@material-ui/icons/Info";
import {PANLabel} from "../DisplayElements";
import EditIcon from "@material-ui/icons/Edit";
import {Button, makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useTranslate} from '../../customHooks';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: "capitalize",
    color: '#006FCC',
    marginTop: "8px",
    '& span': {
      justifyContent: "start",
    }
  },
  infoIcon: {
    fontSize: "14px",
    color: "#707070",
    paddingLeft: "4px"
  },
}));

export const PANLabelWithActions = ({ title, content, target }: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();

  return (
    <div>
      <PANLabel title={title} content={content} icon={<InfoIcon className={classes.infoIcon} />} />
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        className={classes.button}
        onClick={() => {
          history.push(target);
        }}
      >
        {translate("common.edit")}
      </Button>
    </div>
  );
}
