import { useTranslate } from '../../../customHooks';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Sheet, Tooltip } from "@panwds/react-ui";
import { PANWDSForm, PANWDSRadioField } from '../../../components/PANWDSElements';
import { FormControl } from '@material-ui/core';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { isRequired } from '../../../utils/validate';
import { useState } from 'react';
import PanoramaForm from "./PanoramaForm";
import CloudManagerForm from './CloudManagerForm';

const useStyles = makeStyles((theme) => ({
    infoIcon: {
        fontSize: "14px",
    },
    iconedParagraph: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        gap: '10px',
    },
    iconedParagraphTop: {
        display: 'flex',
        alignItems: 'top',
        gap: '5px',
    },
    pendingGrayText: {
        marginTop: "5px",
        color: '#707070 !important',
        fontSize: "12px",
    },
    grayText: {
        color: '#707070',
        fontSize: "14px",
    },
    checkIcon: {
        color: '#006FCC',
        fontSize: '18px',
        marginBottom: "-2px",
    },
    toolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: '16px',
        borderTop: "1px solid #DADBDB",
        marginTop: 16,
    },
    innerToolbar: {
        display: "flex",
        gap: theme.spacing(1),
    },
    rightToolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'flex-end',
        padding: '0 16px',

    },
    sidePanelForm: {
        padding: '20px 16px',
        '& .ra-input ': {
            padding: '7px 0',
        }
    },
    circle: {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
    },
    circleActive: {
        backgroundColor: '#33CCB8',
    },
    circleInActive: {
        backgroundColor: '#707070;',
    },
    circlePending: {
        backgroundColor: '#EF9700',
    },
    iconAndText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    notes: {
        fontSize: 12,
        color: '#333333',
    },
    pending: {
        color: "#B8B8B8",
        "& a, & .iconedParagraph, & div": {
            color: "#B8B8B8",
        },
    },
    enabledColor: {
        color: '#33CCB8',
    },
    disabledColor: {
        color: '#707070',
    },
    labelControl: {
        fontSize: "12px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "10px 0px",
        '& > div': {
            marginBottom: "8px",
        },
        '& > div:first-child': {
            fontWeight: '600',
        },
    }
}));

export const AddEditPolicyPanel = (props: any) => {
    const { close, record } = props;
    const translate = useTranslate();
    const isEditMode = !!record?.Panorama;
    const classes = useStyles();
    const PolicyManagers = [
        { label: "Strata Cloud Manager", value: "CloudManager" },
        { label: "Panorama", value: "Panorama" }
    ]
    const [selectedManager, setSelectedManager] = useState(PolicyManagers[0].value);

    const handleSelectedManager = (selected: any) => {
        setSelectedManager(selected);
    }

    return (
        <Sheet
            onClose={() => close()}
            title={translate(`resources.integrations.panel.${isEditMode ? 'editPolicyManagement' : 'addPolicyManager'}`)}
            isOpen
        >
            <div className={classes.sidePanelForm}>
                <PANWDSForm onSubmit={() => null} toolbar={false}>
                    <FormControl fullWidth>
                        <Field
                            name="PolicyManager"
                            row
                            // @ts-ignore
                            component={PANWDSRadioField}
                            options={PolicyManagers}
                            title={'Manage Type'}
                            validate={isRequired}
                            defaultValue={PolicyManagers[0].value}
                        />
                        <OnChange name="PolicyManager">
                            {(selected: any) => handleSelectedManager(selected)}
                        </OnChange>
                    </FormControl>
                </PANWDSForm>

                {selectedManager == PolicyManagers[0].value && <CloudManagerForm {...props} />}

                {selectedManager == PolicyManagers[1].value && <PanoramaForm {...props} />}

            </div>
        </Sheet>
    );
};
