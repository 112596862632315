import React from "react";
import {Route} from "react-router-dom";
import {RouteUri} from "../routeUri";
import Login from "../layout/Login";
import SignUp from "../layout/SignUp";
import {ApplicationConfigManager} from "../types";
import {authProvider} from "../authProvider";
import SetPassword from "../layout/SetPassword";
import ForgotPassword from "../layout/ForgotPassword";
import ConfirmPassword from "../layout/ConfirmPassword";
import LoginCallback from "../layout/LoginCallback";
import CircularLoader from "../components/CircularLoader/CircularLoader";

export const PublicRoutes = [
    <Route exact path={RouteUri.Register} render={() => <SignUp />} />,
    <Route exact path={RouteUri.Logout} render={() => {
        //ApplicationConfigManager.getInstance().clearSession();
        authProvider.logout(null);
        return <Login />;
    }} />,
    <Route exact path={RouteUri.Login} render={(routeProps) => <Login {...routeProps}/>} />,
    <Route exact path={RouteUri.Welcome} render={() => <SetPassword />} />,
    <Route exact path={RouteUri.SetPassword} render={() => <SetPassword />} />,
    <Route exact path={RouteUri.ForgotPassword} render={() => <ForgotPassword />} />,
    <Route exact path={RouteUri.ConfirmPassword} render={() => <ConfirmPassword />} />,
    <Route exact path={RouteUri.LoginCallback} render={() => (<LoginCallback loadingElement={<CircularLoader loading={true}/>} />)} />,
];
