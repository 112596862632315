import {ListDetailsTypes, ListTypes} from "./types";

export const listDetails: ListDetailsTypes = {
    data: [],
    nextToken: '',
    total: 0,
    loading: false,
    error: '',
    notSaveNextToken: false
};

export const list: ListTypes = {
    data: [],
    loading: false,
    error: ''
}

export const firewallsInitialState = {
    list,
    listDetails,
    activeFirewall: {}
};

export const ruleStacksInitialState = {
    list,
    listDetails,
    activeRuleStack: {}
};

export const userAndRolesInitialState = {
    list,
    listDetails,
};
