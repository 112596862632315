import { FormControl, Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState, Fragment } from "react";
import { usePermissions } from '../../customHooks';
import { Field, FormSpy } from "react-final-form";
import { useHistory, withRouter } from 'react-router-dom';
import { Button as PANWDSButton } from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from "../../components";
import {
  Row
} from "../../components/FormElements";
import { dataProvider } from "../../dataProvider";
import { isAlphaNumeric, isFeedURL, isIpV4, isRequired, maxLength, selectIsRequired, composeValidators } from "../../utils/validate";
import { timeOptions } from "./FeedCreate";
import {PANWDSBreadcrumbs, PANWDSForm, PANWDSInput, PANWDSSelect} from '../../components/PANWDSElements';
import {useTranslate} from '../../customHooks';

export const styles = {
  price: { width: "7em" },
  width: { width: "7em" },
  height: { width: "7em" },
  stock: { width: "7em" },
  widthFormGroup: { display: "inline-block" },
  heightFormGroup: { display: "inline-block", marginLeft: 32 },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

const FeedEdit = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const { permissions } = usePermissions();


  const [certificateProfiles, setCertificateProfiles] = useState<any[]>([]);
  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;
  // get the feed name from route params
  const feedName = props.match.params.feedname;

  const [record, setRecord] = useState<any>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const title = feedName;

  useEffect(() => {
    if (permissions?.DescribeIntelligentFeed) {
      dataProvider.describe('feed', '', { RuleStackName: ruleStackName, Name: feedName }).then((res: any) => {
        if (res.data) {
          setRecord({ ...res.data });
        } else {
          toast.error(res?.error, { toastId: "feed-describe" });
        }
      }).catch((e: any) => {
        toast.error(e?.error, { toastId: "feed-describe" });
      });
    }
  }, [feedName, ruleStackName, permissions]);

  const SaveToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar}>
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          dataTestId="rulestack-create-cancel"
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-feed-edit-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          label="Save"
          redirect={false}
          submitOnEnter={true}
          disabled={!permissions?.UpdateIntelligentFeed}
          className={classes.button}
          loading={submitting}
          dataMetrics="cloudngfw-feed-edit-save-button"
          {...toolbarProps}
        />
      </div>
    );
  };

  useEffect(() => {
    dataProvider.getList("certificate", {
      data: { RuleStackName: ruleStackName }
    }).then(async (response: any) => {
      if (response.data) {
        setCertificateProfiles(response.data.map((certificate: any) => ({ value: certificate.Name })));
      } else {
        toast.error(response?.error, { toastId: "certificate-getList" });
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "certificate-getList" });
      });
  }, [feedName, ruleStackName, record]);

  const save = useCallback(
    async (values) => {
      if (!permissions?.UpdateIntelligentFeed) {
        return;
      }
      let payload = { ...values.FeedCandidate, RuleStackName: values.RuleStackName, Name: values.Name };
      if (payload.Frequency !== "DAILY") {
        delete payload.Time;
      }
      setSubmitting(true);
      try {
          await dataProvider.update("feed", payload);
        //toast.success("Success");
        history.goBack();
      } catch (error: any) {
        toast.error(error?.error, { toastId: "feed-update" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, permissions]
  );

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: translate(`resources.ruleStacks.name`),
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`feeds`]: translate(`resources.feed.name`),
          [feedName]: feedName
        }}
      />
      <PANTitle title={title} divider />
        <PANWDSForm
            toolbar={<SaveToolbar />}
            onSubmit={save}
            initialValues={record}
            style={{ margin: 16 }}
            validate={(values: any) => {
                let errors: any = {FeedCandidate: {}};
                // if (values?.FeedCandidate?.Type === "URL_LIST") {
                //     errors.FeedCandidate.FeedURL = isFeedURL(values?.FeedCandidate?.FeedURL);
                // }
                // if (values?.FeedCandidate?.Type === "IP_LIST") {
                //     errors.FeedCandidate.FeedURL = isIpV4(values?.FeedCandidate?.FeedURL);
                // }
                return errors;
            }}
        >
            <Fragment>
                <Grid container style={{ gap: 16, width: 'auto' }}>
                    <PANTile title={translate(`resources.feed.fields.General`)} subtitle={translate(`resources.feed.fields.CreateSubtitle`)} size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.feed.fields.Name`)}
                                        dataMetrics="cloudngfw-feed-edit-field-name"
                                        required
                                        validate={composeValidators(isAlphaNumeric, maxLength(63), isRequired)}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="FeedCandidate.Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.feed.fields.Description`)}
                                        dataMetrics="cloudngfw-feed-edit-field-description"
                                        validate={maxLength(512)}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                    <PANTile title={translate(`resources.feed.fields.FeedSource`)} size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="FeedCandidate.Type"
                                        // @ts-ignore
                                        component={PANWDSSelect}
                                        items={[
                                            { text: "IP List", value: "IP_LIST" },
                                            { text: "URL List", value: "URL_LIST" },
                                        ]}
                                        title={translate(`resources.feed.fields.Type`)}
                                        dataMetrics="cloudngfw-feed-edit-field-type"
                                        required
                                        validate={selectIsRequired}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="FeedCandidate.FeedURL"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.feed.fields.FeedURL`)}
                                        dataMetrics="cloudngfw-feed-edit-field-feedurl"
                                        required
                                        validate={composeValidators(maxLength(255), isRequired)}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="FeedCandidate.Certificate"
                                        // @ts-ignore
                                        component={PANWDSSelect}
                                        items={certificateProfiles}
                                        title={translate(`resources.feed.fields.CertificateProfile`)}
                                        dataMetrics="cloudngfw-feed-edit-field-certificate"
                                        allowEmpty
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="FeedCandidate.Frequency"
                                        // @ts-ignore
                                        component={PANWDSSelect}
                                        items={[
                                            { text: "Hourly", value: "HOURLY" },
                                            { text: "Daily", value: "DAILY" }
                                        ]}
                                        title={translate(`resources.feed.fields.UpdatesFrequency`)}
                                        dataMetrics="cloudngfw-feed-edit-field-frequency"
                                        required
                                        validate={selectIsRequired}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <FormSpy subscription={{ values: true }}>
                                        {({ values, ...rest }) => {
                                            // wait data to load before get Time field dirty
                                            const loaded = String(values.Name !== undefined);
                                            let initialProp = (values.FeedCandidate?.Frequency ?
                                                { initialValue: ((values.FeedCandidate?.Time !== undefined) ? values.FeedCandidate?.Time : 3) } :
                                                {});
                                            return values?.FeedCandidate?.Frequency === "DAILY" ? (
                                                <Field
                                                    key={loaded}
                                                    title="&nbsp;"
                                                    name="FeedCandidate.Time"
                                                    // @ts-ignore
                                                    component={PANWDSSelect}
                                                    items={timeOptions}
                                                    {...initialProp}
                                                    disabled={values.FeedCandidate?.Frequency !== "DAILY"}
                                                    clearable={false}
                                                    dataMetrics="cloudngfw-feed-edit-field-time"
                                                />) : null
                                        }}
                                    </FormSpy>
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(FeedEdit);
