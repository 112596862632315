import { FormControl, makeStyles } from "@material-ui/core";
import { useTranslate } from "../../../customHooks";
import { FC, useState } from "react";
import { Field } from "react-final-form";
import { toast } from '../../../components';
import { Button as PANWDSButton, LoadingButton }  from "@panwds/react-ui";
import { PANWDSInput } from '../../../components/PANWDSElements';
import {cognitoProvider} from "../../../authProvider";

interface ChangePasswordFormValues {
    oldPassword?: string,
    password?: string,
    reEnterPassword?: string;
}
interface ChangePasswordFormProps {
    validate: any;
    Form: any;
    onOpen: (open: boolean) => void,
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        gap: theme.spacing(1),
        '-webkit-justify-content': 'flex-end',
        padding: '20px',
        backgroundColor: 'rgba(249, 249, 249, 1)',
        borderColor: 'rgb(218 219 219/var(--tw-border-opacity))',
        borderTopStyle: 'solid',
      },
      formLabels: {
        padding: '20px 60px'
      }
}));
export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ validate, Form, onOpen }) => {
    const [changePasswordFailed, setChangePasswordFailed] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();
    const handleSubmit = (auth: ChangePasswordFormValues) => {
      setSubmitting(true);
      if (auth.oldPassword && auth.password && auth.password === auth.reEnterPassword) {
          cognitoProvider.changePassword({oldPassword: auth.oldPassword, password: auth.password})
              .then((result: any) => {
                  setChangePasswordFailed(false);
                  toast.success(result);
                  onOpen(false);
                  setSubmitting(false);
              }).catch((err: any) => {
              setChangePasswordFailed(true);
              setSubmitting(false);
              toast.error(
                  typeof err === 'string'
                      ? err
                      : typeof err === 'undefined' || !err.message
                          ? 'ra.auth.sign_in_error'
                          : err.message,
                  {toastId: "cognito-error"}
              );
          });
        if (!changePasswordFailed)
          onOpen(false);
      }
    };
    return (
      <>
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          render={(changePasswordFormProps: any) => {
            const { handleSubmit } = changePasswordFormProps;
            return (
              <form onSubmit={handleSubmit} title={translate('changePassword.title')} >
                <div className={classes.formLabels}>
                <FormControl fullWidth>
                  <Field name="oldPassword">
                    {(props: any) => (
                      <PANWDSInput
                        meta={props.meta}
                        title={translate('changePassword.oldPassword')}
                        type="password"
                        required
                        autoFocus
                        dataMetrics="cloudngfw-users-edit-oldPassword"
                        input={{
                          onFocus: props.input.onFocus,
                          onChange: props.input.onChange,
                          onBlur: props.input.onBlur,
                          type:"password"
                        }}
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth>
                  <Field name="password">
                    {(props: any) => (
                      <PANWDSInput
                        meta={props.meta}
                        title={translate('changePassword.password')}
                        type="password"
                        required
                        note={translate('validation.passwordCriteria')}
                        dataMetrics="cloudngfw-users-edit-password"
                        input={{
                          onFocus: props.input.onFocus,
                          onChange: props.input.onChange,
                          onBlur: props.input.onBlur,
                          type:"password"
                        }}
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth>
                  <Field name="reEnterPassword">
                    {(props: any) => (
                      <PANWDSInput
                        meta={props.meta}
                        title={translate('changePassword.reEnterPassword')}
                        type="password"
                        required
                        dataMetrics="cloudngfw-users-edit-reEnterPassword"
                        input={{
                          onFocus: props.input.onFocus,
                          onChange: props.input.onChange,
                          onBlur: props.input.onBlur,
                          type:"password"
                        }}
                      />
                    )}
                  </Field>
                </FormControl>
                </div>
                <div className={classes.toolbar}>
                  <PANWDSButton
                    size="md"
                    appearance="secondary"
                    disabled={submitting}
                    onClick={() => {
                      onOpen(false);
                    }}
                    dataMetrics="cloudngfw-users-edit-cancel-modal"
                  >
                    {translate(`resources.users.fields.Cancel`)}
                  </PANWDSButton>
                  <LoadingButton
                    dataResult={{
                      loading: !!submitting,
                      success: true
                    }}
                    size="md"
                    appearance="primary"
                    type="submit"
                    disabled={submitting}
                    dataMetrics="cloudngfw-users-edit-change-modal"
                  >
                    {translate('changePassword.button')}
                  </LoadingButton>
                </div>
              </form>
            );
          }}
        />
      </>
    );
  };
