import { useState, Children, cloneElement } from 'react';
import type { ReactElement } from "react";

type AccordionWrapperProps = {
    children: ReactElement | ReactElement[];
};
const AccordionWrapper = ({ children }: AccordionWrapperProps): ReactElement => {
    const [hoveredItem, setHoveredItem] = useState(-1);

    return (
        <>
        {Children.map(children, (child, index) =>
            cloneElement(child, {
                groupId: index,
                onHover: (groupId: number) => {
                    setHoveredItem(groupId);
                },
                hoveredItem,
            })
        )}
        </>
    );
}

export default AccordionWrapper;
