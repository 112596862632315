import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  CircularProgress
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';
import { PANHelpText, SelectButtonContainer, SelectLabel } from './styles';

const useStyles = makeStyles((theme) => ({
  Container: {
    background: "rgba(0,0,0,0)",
  },
  ButtonContainer: {
    background: "#FFFFFF",
    border: "1px solid #DADBDB",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  DropDownButton: {
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "normal",
    border: "1px solid #DADBDB",
    padding: "16px 8px",
    color: "#707070",
    background: "#FFFFFF",
    alignItems: "center",
    // marginRight: theme.spacing(1),
  },
  Label: {
    fontSize: 16,
    fontWeight: "normal",
    textTransform: 'none',
  },
  errorText: {
    color: "#D13C3C",
  },
  loadingForSelectIcon: {
    width: '15px !important',
    height: '15px !important',
  },
  centerLeftIcons: {
    display: 'flex',
    alignItems: 'center'
  },
}));

interface Props {
  options: any,
  handleChange: any,
  label?: string;
  required?: boolean;
  error?: boolean;
  value?: string;
  disabled?: boolean;
  maxItemsListHeight?: string;
  focusOnRender?: boolean,
  loading?: boolean,
  clearable?: boolean,
  dataTestId?: string ,
  defaultFirst?: boolean,
  inputProps?: Object,
};

export const PANSelect = ({
  options, handleChange, label, required, error, value, disabled, maxItemsListHeight,
  focusOnRender = false, loading,clearable=true, dataTestId, defaultFirst, inputProps={}
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (index === -1){ // clear selection
      setSelectedIndex(0);
      handleChange("")
    }else{
      setSelectedIndex(index);
      handleChange(options[index]?.['value'] ?? options[index]);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (options.length >= 1 && value && value !== options[selectedIndex]?.value) {
      if (value !== undefined && value !== "") {
        let index = _.findIndex(options, function (o: any) { return o?.value === value; });
        setSelectedIndex(index);
        handleChange(value);
      } else {
        if (defaultFirst && firstRender) {
          setSelectedIndex(0);
          handleChange(options[0].value);
          setFirstRender(false);
        } else {
          handleChange("");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  useEffect(() => {
    if (focusOnRender && anchorRef.current) anchorRef.current.focus();
  }, [anchorRef, focusOnRender]);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        {
          label && (<SelectLabel className={error ? classes.errorText : ''}>
            {label} {required && (<strong className={classes.errorText}>*</strong>)}
          </SelectLabel>)
        }
        <SelectButtonContainer ref={anchorRef} aria-label="split button">
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            disabled={disabled}
            className={classes.DropDownButton}
            data-test-id={dataTestId}
            {...inputProps}
          >
            <Typography className={classes.Label}>
              {(value !== undefined && value !== "")? options[selectedIndex]?.['text'] ?? options[selectedIndex] : "Select"}
            </Typography>
            <div className={classes.centerLeftIcons}>
              { loading && <CircularProgress color="inherit" className={classes.loadingForSelectIcon} />}
              <ArrowDropDownIcon />
            </div>
          </Button>
        </SelectButtonContainer>
        <Popper open={open} anchorEl={anchorRef.current || null} role={undefined} transition disablePortal style={{
          zIndex: 100,
          width: '98%',
        }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper elevation={2}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList style={{
                    padding: 0, borderRadius: 4, overflowY: "scroll", maxHeight: maxItemsListHeight ?? '130px'
                  }}
                    id="split-button-menu"
                  >
                    {clearable && (value !== undefined && value !== "") && <MenuItem
                      selected={false}
                      onClick={(event) => handleMenuItemClick(event, -1)}
                    >
                      Select
                    </MenuItem>}
                    {options.map((option: any, index: number) => (
                      <MenuItem
                        key={option?.['value'] ?? index}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        style={{ borderBottom: '1px solid #EAEBEB', padding: '8px 16px', color: '#333333' }}
                        data-test-id={`${dataTestId}-item-${index}`}
                      >
                        {option?.['text'] ?? option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}


export const PANSelectField = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {
  const classes = useStyles();
  const existerror = !!(touched && error);

  const handleOnChange = useCallback((event: any) => {
    inputProps.onChange(event);
    if (props.onChange && props.onChange instanceof Function) {
      props.onChange(event);
    }
  }, [inputProps, props]);

  return (
    <Box className={classes.Container}>
      <PANSelect disabled={props.disabled} value={inputProps.value} options={props?.options || []}
        handleChange={handleOnChange} label={props.label} required={props.required} error={existerror} loading={props?.loading}
        clearable={props?.clearable} dataTestId={props.dataTestId} defaultFirst={props.defaultFirst} inputProps={props.inputProps}
      />
      {
        existerror && (<PANHelpText id={props.label} >{error}</PANHelpText>)
      }
    </Box>
  )
}
