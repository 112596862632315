import {useCallback} from "react";
import { authProvider } from "../authProvider";
import useLogout from "./useLogout";
import {defaultAuthParams} from "./useAuthProvider";
import { useOktaAuth } from '@okta/okta-react';

const useCheckAuth = (): CheckAuth => {
    const logout = useLogout();
    const { authState } = useOktaAuth();

    const checkAuth = useCallback(
        (
            params: any = {},
            logoutOnFailure = true,
            redirectTo = defaultAuthParams.loginUrl,
            disableNotification = false
        ) =>
            authProvider.checkAuth(params).catch(error => {
                if (logoutOnFailure) {
                    logout(
                        {},
                        error && error.redirectTo
                            ? error.redirectTo
                            : redirectTo
                    );
                }
                throw error;
            }),
        [authProvider, logout]
    );

    return authProvider && (authState && !authState.isAuthenticated) ? checkAuth : checkAuthWithoutAuthProvider;
};

const checkAuthWithoutAuthProvider = () => Promise.resolve();

type CheckAuth = (
    params?: any,
    logoutOnFailure?: boolean,
    redirectTo?: string,
    /** @deprecated to disable the notification, authProvider.checkAuth() should return an object with an error property set to true */
    disableNotification?: boolean
) => Promise<any>;

export default useCheckAuth;
