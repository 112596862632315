import SubscriptionCreditUsage from './SubscriptionCreditsUsage';
import SubscriptionEdit from './SubscriptionEdit';

const subscription_mgmt =
{
    edit: SubscriptionEdit,
    creditUsage: SubscriptionCreditUsage,
};

export default subscription_mgmt;
