import { makeStyles } from "@material-ui/core";
import { ChipInput } from "@panwds/react-ui";
import { PANFieldContainer, PANHelpText, PANMutedText } from "../FormElements/styles";
import './PANWDSChipInput.css';
import {PANWDSLabel} from "./PANWDSLabel";

const useStyles = makeStyles((theme) => ({
    error: {
        border: '1px solid #D13C3C',
        borderRadius: 4,
    },
    errorText: {
        color: "#D13C3C",
    }
}));

export const PANWDSChipInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    const classes = useStyles();
    const existerror = !!(touched && error);
    let { items, title, required, enableArrayInput, dataMetrics, loading = false, disabled, formatter = undefined, menuStyle, muted } = props;
    let value = inputProps.value ? inputProps.value : [];
    if (enableArrayInput) {
        items = items?.map((v: string) => ({ value: v }));
        value = value?.map((v: string) => ({ value: v }));
    }

    return <PANFieldContainer>
        {title && <PANWDSLabel disabled={disabled} required={required} title={title}/>}
        <ChipInput
            items={items}
            selectedItems={value}
            onSelectedItemsChange={({ selectedItems = [] }) => {
                if (enableArrayInput) {
                    inputProps.onChange(selectedItems.map(({ value }) => formatter ? formatter(value) : value ))
                } else {
                    inputProps.onChange(selectedItems);
                }
            }}
            dataMetrics={dataMetrics}
            isLoading={loading}
            disabled={disabled}
            menuStyle={menuStyle}
        />
        {existerror && (<PANHelpText>{error}</PANHelpText>)}
        {muted && (<PANMutedText>{muted}</PANMutedText>)}
    </PANFieldContainer>;
};
