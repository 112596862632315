//This file to be converted to scss when the support is added.

import { makeStyles } from "@material-ui/core";

export const firewallStyles = makeStyles((theme) => ({
    fwReadOnly: {
        pointerEvents: "none",
        opacity: 0.6,
        "& input": {
          backgroundColor: "#f0f0f0",
        }
    },
    fwEditHeader: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '20px',
      color: "#333333",
      padding: '8px 0',
  }
}));
