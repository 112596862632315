import { FormControl } from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Button, Card } from "@panwds/react-ui";
import { useEffect, useState } from "react";
import { Field, withTypes } from "react-final-form";
import { cognitoProvider } from "../authProvider";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "../components";
import { PANWDSInput } from "../components/PANWDSElements";
import logo from "../images/panw_logo.png";
import { RouteUri } from "../routeUri";
import { Background, Title } from "./styles";
import { lightTheme } from "./themes";
import { isBlockedDomain, isEmail, isRequired, validatePasswordRegex, composeValidators } from "../utils/validate";
import "./CustomPanwdsCard.css";
import CircularLoader from "../components/CircularLoader/CircularLoader";
import {useTranslate} from '../customHooks';
import {pendoInitialize, pendoUpdate} from "./Pendo";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    padding: "15px",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  formControl: {
    marginTop: "1em",
    width: "100%",
  },
  actions: {
    flexDirection: "row-reverse",
  },
  title: {
    fontWeight: 400,
    fontSize: 24,
    color: "#333333",
    textAlign: "center",
    paddingTop: 20,
  },
  loadingBlur: {
    filter: "blur(1px)",
  },
}));

interface ConfirmPasswordFormValues {
  email?: string;
  verificationCode?: string;
  password?: string;
}

const { Form } = withTypes<ConfirmPasswordFormValues>();

const ConfirmPassword = (props: any) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const [confirmPasswordFailed, setConfirmPasswordFailed] = useState(true);

  const handleSubmit = (auth: ConfirmPasswordFormValues) => {
    setLoading(true);
    cognitoProvider
      .confirmPassword(auth)
      .then((response: any) => {
        pendoUpdate({UserName: auth.email});
        history.push(RouteUri.Login);
        setConfirmPasswordFailed(false);
        setLoading(false);
      })
      .catch((error: Error) => {
        setLoading(false);
        setConfirmPasswordFailed(true);
        let errorMessage: string =
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message;
        toast.error(errorMessage);
      });
  };

  const [userName, setUserName] = useState<any>();

  useEffect(() => {
    setUserName(localStorage.getItem("reset"));
    pendoInitialize({});
  }, []);

  const validate = (values: ConfirmPasswordFormValues) => {
    const errors: ConfirmPasswordFormValues = {};
    if (!values.email) {
      errors.email = translate("validation.required");
    }
    if (!values.password) {
      errors.password = translate("validation.required");
    }
    //@ts-ignore
    if (!validatePasswordRegex(values.password)) {
      errors.password = translate("validation.invalidPassword");
    }
    if (!values.verificationCode) {
      errors.verificationCode = translate("validation.required");
    }
    return errors;
  };

  if (confirmPasswordFailed) {
    return (
      <>
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Background>
                <Card className="customPanwdsCard">
                  <CircularLoader loading={loading} />
                  <div className={loading ? classes.loadingBlur : ""}>
                    <div className={classes.avatar}>
                      <img src={logo} alt="logo" className={classes.logo} />
                    </div>

                    <Title>{translate("confirmPassword.title")}</Title>
                    <div className={classes.form}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="email"
                          // @ts-ignore
                          component={PANWDSInput}
                          autoFocus={true}
                          title={translate("confirmPassword.email")}
                          required
                          disabled={loading}
                          dataMetrics="cloudngfw-confirm-password-email"
                          type="email"
                          initialValue={userName}
                          validate={composeValidators(
                            isRequired,
                            isBlockedDomain,
                            isEmail
                          )}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="verificationCode"
                          // @ts-ignore
                          component={PANWDSInput}
                          autoFocus={true}
                          title={translate("confirmPassword.verificationCode")}
                          required
                          disabled={loading}
                          dataMetrics="cloudngfw-confirm-password-code"
                          type="text"
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <Field name="password">
                          {(props: any) => (
                            <PANWDSInput
                              meta={props.meta}
                              title={translate(`confirmPassword.password`)}
                              type="password"
                              required
                              dataMetrics="cloudngfw-confirm-password-password"
                              note={translate("validation.passwordCriteria")}
                              input={{
                                onFocus: props.input.onFocus,
                                onChange: props.input.onChange,
                                onBlur: props.input.onBlur,
                                type:"password"
                              }}
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>
                    <Card className="actions">
                      <Button
                        appearance="primary"
                        size="md"
                        color="primary"
                        type="submit"
                        disabled={loading}
                        data-metrics="cloudngfw-confirm-password-button"
                      >
                        {translate("confirmPassword.button")}
                      </Button>
                    </Card>
                  </div>
                </Card>
              </Background>
            </form>
          )}
        />
      </>
    );
  } else {
    return (
      <Background>
        <div className="customPanwdsCard">
          <CircularLoader loading={loading} />
          <div className={loading ? classes.loadingBlur : ""}>
          <div className={classes.avatar}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          <Title>{translate("confirmPassword.success")}</Title>
          <Card className="actions">
            <Button
              appearance="primary"
              size="md"
              color="primary"
              data-metrics="cloudngfw-confirm-password-success-button"
              onClick={() => history.push(RouteUri.Login)}
            >
              {translate("login.name")}
            </Button>
          </Card>
          </div>
        </div>
      </Background>
    );
  }
};

ConfirmPassword.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in ConfirmPassword won't get
// the right theme
const ConfirmPasswordWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <ConfirmPassword {...props} />
  </ThemeProvider>
);

export default withRouter(ConfirmPasswordWithTheme);
