import AccountsCreate from './AccountsCreate';
import AccountsList from './AccountsList';
import AccountsEdit from './AccountsEdit';

const accounts =  {
    create: AccountsCreate,
    list: AccountsList,
    edit: AccountsEdit
};

export default accounts;
