import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DrawerProps } from '@material-ui/core/Drawer';
import cx from "classnames";
import { Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FC } from 'react';
import { width } from '@mui/system';

interface ExtendedDrawerProps {
    title: string,
    close: () => void,
};

export interface PanDrawerSize {
    size?: any
}

type PanDrawerProps = DrawerProps & ExtendedDrawerProps & PanDrawerSize;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
        width: '300px',
    },
    sheetBody: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        borderBottom: "1px solid #DADBDB",
        backgroundColor: "#F4F5F5",
        padding: "0 10px",
        color: "333333",
    },
    smallWidthSize: {
        width: "400px",
    },
    mediumWidthSize: {
        width: "500px",
    },
    largeWidthSize: {
        width: "600px",
    }
}));

const PanSidePanel: FC<PanDrawerProps> = props => {
    const {
        open,
        close,
        children,
        title,
        size
    } = props;
    const classes = useStyles();

    let widthStyling = classes.smallWidthSize;
    if(size === "medium") {
        widthStyling = classes.mediumWidthSize;
    } else if(size === "large") {
        widthStyling = classes.largeWidthSize;
    }

    return (
        <Drawer
            classes={{root: classes.root}}
            anchor="right"
            variant="temporary"
            open={open}
            onClose={(ev, reason) => close()}
        >
            <div className={cx(classes.sheetBody, widthStyling)}>
                {title}
                <IconButton onClick={close}><CloseIcon /></IconButton>
            </div>
            {children}
        </Drawer>
    );
};

export default PanSidePanel;
