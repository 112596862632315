import { FormControl } from "@material-ui/core";
import { Button, Card } from "@panwds/react-ui";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Buffer } from "buffer";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { cognitoProvider } from "../authProvider";
import { Field, withTypes } from "react-final-form";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { toast } from '../components';
import { PANWDSInput } from "../components/PANWDSElements";
import { Subtitle } from "../components/Tile/styles";
import logo from "../images/panw_logo.png";
import { RouteUri } from "../routeUri";
import { isBlockedDomain, isEmail, isRequired, validatePasswordRegex, composeValidators } from "../utils/validate";
import { Background, Title } from "./styles";
import { lightTheme } from "./themes";
import "./CustomPanwdsCard.css";
import CircularLoader from "../components/CircularLoader/CircularLoader";
import {useTranslate} from '../customHooks';
import {pendoInitialize, pendoUpdate} from "./Pendo";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    padding: "15px",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  formControl: {
    marginTop: "1em",
    width: "100%",
  },
  title: {
    fontWeight: 400,
    fontSize: 24,
    color: "#333333",
    textAlign: "center",
    paddingTop: 20,
  },
  loadingBlur: {
    filter: "blur(1px)",
  },
}));

interface FormValues {
  email?: string;
  oldPassword?: string;
  password?: string;
  reEnterPassword?: string;
}

const { Form } = withTypes<FormValues>();

const SetPassword = (props: any) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const [SetPasswordFailed, setSetPasswordFailed] = useState(true);
  const location = useLocation();
  const search = useLocation().search;
  const uname: string = new URLSearchParams(search).get("uname") ?? "";
  const ctp: string = new URLSearchParams(search).get("ctp") ?? "";
  const tid: string = new URLSearchParams(search).get("tid") ?? "";

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    cognitoProvider
      .setPassword(auth)
      .then((response: any) => {
        pendoUpdate({UserName: auth.email});
        setSetPasswordFailed(false);
        toast.success(translate("setPassword.success"));
        history.replace(RouteUri.Dashboard);
      })
      .catch((error: Error) => {
        setLoading(false);
        toast.error(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message
        );
      });
  };

  const [userName, setUserName] = useState("");

  useEffect(() => {
    //@ts-ignore
    if (localStorage.getItem("reset")) {
      setUserName(localStorage.getItem("reset") ?? "");
      pendoInitialize({});
    } else if (uname) {
      var duname = Buffer.from(uname, "base64").toString("ascii");
      setUserName(duname);
      pendoInitialize({UserName: duname});
    }
  }, []);

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("validation.required");
    }
    if (!values.oldPassword) {
      errors.oldPassword = translate("validation.required");
    }
    if (!values.password) {
      errors.password = translate("validation.required");
    }
    //@ts-ignore
    if (!validatePasswordRegex(values.password)) {
      errors.password = translate("validation.invalidPassword");
    }
    if (!values.reEnterPassword) {
      errors.reEnterPassword = translate("validation.required");
    }
    if (values.reEnterPassword !== values.password) {
      errors.reEnterPassword = translate("validation.missMatch");
    }
    if (
      values.reEnterPassword === values.password &&
      values.password === values.oldPassword
    ) {
      errors.password = translate("validation.invalidPassword");
    }
    return errors;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Background>
              <Card className="customPanwdsCard">
                <CircularLoader loading={loading} />
                <div className={loading ? classes.loadingBlur : ""}>
                  <div className={classes.avatar}>
                    <img src={logo} alt="logo" className={classes.logo} />
                  </div>

                  <Title>{translate("setPassword.title")}</Title>
                  <Subtitle>{translate("register.successMessage")}</Subtitle>
                  <div className={classes.form}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="email"
                        // @ts-ignore
                        component={PANWDSInput}
                        autoFocus={true}
                        title={translate(`setPassword.email`)}
                        required
                        disabled={true}
                        dataMetrics="cloudngfw-setpassword-email"
                        type="email"
                        initialValue={userName}
                        validate={composeValidators(
                          isRequired,
                          isBlockedDomain,
                          isEmail
                        )}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Field name="oldPassword">
                        {(props: any) => (
                          <PANWDSInput
                            meta={props.meta}
                            title={translate(`setPassword.oldPassword`)}
                            type="password"
                            disabled={loading}
                            required
                            autoFocus
                            dataMetrics="cloudngfw-setpassword-old-password"
                            input={{
                              onFocus: props.input.onFocus,
                              onChange: props.input.onChange,
                              onBlur: props.input.onBlur,
                              type:"password"
                            }}
                            initialValue={ctp}
                          />
                        )}
                      </Field>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Field name="password">
                        {(props: any) => (
                          <PANWDSInput
                            meta={props.meta}
                            title={translate(`setPassword.password`)}
                            type="password"
                            disabled={loading}
                            required
                            note={translate("validation.passwordCriteria")}
                            dataMetrics="cloudngfw-setpassword-password"
                            input={{
                              onFocus: props.input.onFocus,
                              onChange: props.input.onChange,
                              onBlur: props.input.onBlur,
                              type:"password"
                            }}
                          />
                        )}
                      </Field>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Field name="reEnterPassword">
                        {(props: any) => (
                          <PANWDSInput
                            meta={props.meta}
                            title={translate(`setPassword.reEnterPassword`)}
                            type="password"
                            disabled={loading}
                            required
                            dataMetrics="cloudngfw-setpassword-reEnterPassword"
                            input={{
                              onFocus: props.input.onFocus,
                              onChange: props.input.onChange,
                              onBlur: props.input.onBlur,
                              type:"password"
                            }}
                          />
                        )}
                      </Field>
                    </FormControl>
                  </div>
                  <Card className="actions">
                    <Button
                      appearance="primary"
                      size="md"
                      color="primary"
                      type="submit"
                      data-metrics="cloudngfw-set-password-success-button"
                      disabled={loading}
                    >
                      {translate("setPassword.button")}
                    </Button>
                  </Card>
                </div>
              </Card>
            </Background>
          </form>
        )}
      />
    </>
  );
};

SetPassword.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in setPassword won't get
// the right theme
const SetPasswordWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <SetPassword {...props} />
  </ThemeProvider>
);

export default withRouter(SetPasswordWithTheme);
