import { RuleHitCounters } from "../rules/RulesEdit";
import { PANTitle, PANTile } from "../../components";
import { useLocation } from 'react-router-dom';
import { useTranslate } from '../../customHooks';
import {PANWDSBreadcrumbs} from "../../components/PANWDSElements";

const FirewallRuleUsage = (props: any) => {
  const translate = useTranslate();

  const fireWallName = props.match.params.firewallname;
  const rulename = props.match.params.rulename;

  const search = useLocation().search;
  const rulestackname = new URLSearchParams(search).get('rulestackname');
  const priority = new URLSearchParams(search).get('priority');
  const rulelistname = new URLSearchParams(search).get('rulelistname');
  const accountId = new URLSearchParams(search).get('AccountId');

  const breadcrumbMapping = {
    ngfirewalls: translate(`resources.firewalls.name`),
    [fireWallName]: fireWallName,
    rules: translate(`resources.ngfwrules.name`),
    [rulename]: rulename,
  };

  return (
    <>
      <PANWDSBreadcrumbs
        mapping={breadcrumbMapping}
        queryParams={"?AccountId=" + accountId}
      />
      <PANTitle title={props.match.params.rulename} divider />
      <div style={{ margin: '16px' }}>
        <PANTile size={12}>
          <RuleHitCounters RuleStackName={rulestackname} RuleListName={rulelistname} Priority={priority} FirewallName={fireWallName} AccountId={accountId} />
        </PANTile>
      </div>
    </>
  );
}

export default FirewallRuleUsage;
