export default {
    // Management
    USER: "users",
    ACCOUNT: "accounts",
    ROLE: "role",
    SUBSCRIPTION: "subscription",
    TOKEN: "tokens",
    SUPPORT: "support",
    XACCOUNT_ROLES: "xaccountroles",
    PERMISSION_POLICIES: "permissionpolicies",

    // Configuration
    FIREWALL: "firewalls",
    RULESTACK: "ruleStacks",
    RULES: "rules",
    PREFIX: "prefix",
    FQDN: "fqdn", // CHECK correct name of resource fqdn | fqdnlists
    CATEGORY: "category",
    APPID: "appid",
    APPLICATIONS: "applications",
    COUNTRY: "countries",
    FQDNLIST: "fqdnlists",
    PREFIXLIST: "prefixlists",
    FEED: "feed",
    CERTIFICATE: "certificate",
    COUNTRIES: "countries",
    PREDEFINED: "predefinedUrlCategory",
    PREDEFINED_UPDATE: "predefinedUrlCategoryUpdate",
    PREDEFINED_OVERRIDE: "predefinedUrlCategoryOverride",
    LOGPROFILE: "logProfile",
    RULE_COUNTERS: "counters",
    RULE_COUNTERS_RESET: "counters_reset",

    // security services
    FILEBLOCKING: "fileBlocking",
    FILEBLOCKING_CUSTOM: "fileBlockingCustom",

    // tags endpoint for ngfirewall or rulestack
    TAGS: "tags",

    // settings endpoint
    SETTINGS: "settings",

    // inventory endpoint
    VPCS: "vpcs",
    VPCTAGS: "vpcTags",
    VPCTAGIPS: "vpcTagIPs",
    VPCGROUPS: "vpcGroups",
    VPCPREFIXTAGS: "vpcPrefixTags",

    PANORAMA: "panorama",
    REGIONS: "regions",
    DEVICE_GROUPS: "dgs",
}
