import { Box, FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { PANHelpText, PANMutedText } from "./styles";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  singleChecbox: {
    paddingLeft: '0px',
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#006FCC",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  formControl: {
    margin: 0,
  }
});

export const PANCheckbox = (props: any & { label?: string }) => {
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            className={props?.group ? classes.root : clsx(classes.root, classes.singleChecbox) }
            disableRipple
            color="default"
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            inputProps={{ "aria-label": "decorative checkbox" }}
            {...props}
          />
        }
        label={props?.label}
      />
      {props?.muted && (<PANMutedText>{props.muted}</PANMutedText>)}
    </>
  );
};

const PANCheckboxGroup = (props: any) => {
  const [opts, setOpts] = useState([]);

  useEffect(() => {
    let newObj: any = [];
    props?.options.forEach((element: string) => {
      newObj.push({ name: element, checked: (props.initValue) ? props.initValue.includes(element) : false })
    });
    setOpts(newObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let tempOpts = [...opts]

    tempOpts.forEach((value: any) => {
      if (value?.name === event.target.name) {
        //@ts-ignore
        value.checked = event.target.checked;
      }
    });
    setOpts(tempOpts);

    props?.onChange({
      ...tempOpts
    });
  };

  return (
    <>
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup row>
        {opts.map((opt: any, idx: number) => (
          <PANCheckbox
            key={idx}
            onChange={handleChange}
            label={opt.name}
            name={opt.name}
            //@ts-ignore
            checked={opt.checked}
            required={props.required}
            onClick={props.onClick}
            group={'true'}
            disabled={props.disabled || false}
          />
        ))}
        {props?.muted && (<PANMutedText>{props.muted}</PANMutedText>)}
        <PANHelpText>{props.error}</PANHelpText>
      </FormGroup>
    </>
  );
};

export const PANCheckboxField = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {

  if (props.isGroup) {
    return (
      <Box>
        <PANCheckboxGroup
          error={error}
          onChange={inputProps.onChange}
          onClick={inputProps.onClick}
          label={props.label}
          required={props.required}
          options={props?.options}
          {...props}
        />
      </Box>
    );
  }

  return (
    <Box>
      <PANCheckbox
        onChange={inputProps.onChange}
        label={props.label}
        required={props.required}
        checked={!!inputProps.value}
        {...props}
      />
    </Box>
  );
};
