import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import RulesCreate from './RulesCreate';
import RulesEdit from './RulesEdit';
import RulesList from './RulesList';

const rules = {
    create: RulesCreate,
    list: RulesList,
    edit: RulesEdit,
    icon: InvoiceIcon,
};

export default rules;