
import { FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useState, Fragment } from 'react';
import { Field } from "react-final-form";
import { useHistory, withRouter } from 'react-router-dom';
import { Button as PANWDSButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from '../../components';
import { Row } from "../../components/FormElements";
import {PANWDSBreadcrumbs, PANWDSForm, PANWDSInput, PANWDSTextarea} from '../../components/PANWDSElements';
import { isAlphaNumeric, isFqdnList, isRequired, maxLength, composeValidators } from '../../utils/validate';
import {ApplicationConfigManager} from "../../types";
import { useTranslate } from '../../customHooks';
import {dataProvider} from "../../dataProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

const FQDNCreate = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  const save = useCallback(
    async (values) => {
      values = { ...values, RuleStackName: ruleStackName }
      setSubmitting(true);
      try {
          await dataProvider.create("fqdn", { data: values });
        //toast.success("Success");
        history.goBack();
        return;
      } catch (error: any) {
        toast.error(error?.error, { toastId: "fqdn-create" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]
  );

  const FQDNCreateToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          dataTestId="rulestack-create-cancel"
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-fqdn-create-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          submitOnEnter={true}
          className={classes.button}
          loading={submitting}
          dataMetrics="cloudngfw-fqdn-create-save-button"
          {...toolbarProps}
        />
      </div>
    )
  };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: `${translate(`resources.ruleStacks.name`)} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`fqdn`]: translate(`resources.fqdn.name`),
          create: translate(`resources.fqdn.fields.CreateButton`)
        }}
      />
      <PANTitle title={translate(`resources.fqdn.fields.CreateButton`)} divider/>

        <PANWDSForm
            onSubmit={save}
            toolbar={<FQDNCreateToolbar />}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: 'auto' }}>
                    <PANTile title={translate(`resources.fqdn.fields.General`)} subtitle={translate(`resources.fqdn.fields.CreateSubtitle`)} size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        required
                                        title={translate(`resources.fqdn.fields.Name`)}
                                        dataMetrics="cloudngfw-fqdn-create-field-name"
                                        validate={composeValidators(isRequired, isAlphaNumeric, maxLength(128))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.fqdn.fields.Description`)}
                                        dataMetrics="cloudngfw-fqdn-create-field-description"
                                        validate={maxLength(512)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="FqdnList"
                                        // @ts-ignore
                                        component={PANWDSTextarea}
                                        enableArrayValue
                                        note="Enter one value per line"
                                        rowsMin={1}
                                        title={translate(`resources.fqdn.fields.FqdnList`)}
                                        dataMetrics="cloudngfw-fqdn-create-field-list"
                                        validate={composeValidators(isRequired, isFqdnList, maxLength(255))}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(FQDNCreate);
