import { fetchJson } from './utils/fetch';
import { ApplicationConfigManager } from './types';

export const httpClient = async (url: string, options: any = {}) => {
  //console.log('-----------------------Starting Ensure-----------------------');
    await ApplicationConfigManager.getInstance().ensureSession();
  //console.log('-----------------------Ending Ensure-------------------------');
  //@ts-ignore
    const token = localStorage.getItem('idToken');
    const newAppConfig = ApplicationConfigManager.getInstance().getConfig();
    //@ts-ignore
    if (!options?.headers) {
      //@ts-ignore
      options.headers = new Headers({ Authorization: token, "x-api-key": newAppConfig.xApiKey });
    }
    //const reourceUrl = url.replace(".region.", ".us-east-1.");
    try {
      return await fetchJson(url, options);
    }
    catch(error) {
      return error;
    }
  };
