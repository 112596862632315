import { Backdrop, makeStyles } from "@material-ui/core";
import { ConnectedTable, ConnectedTableFilter, ConnectedTableLayout, TableProvider, useSelectedRowData } from "@panwds/react-table";
import { Tooltip, Button } from "@panwds/react-ui";
import { InfoIcon } from '@panwds/icons';
import { ITableToolbar } from "../../types";
import PANWDSButtonDropdown from './PANWDSButtonDropdown';
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PANWDSModal } from "./PANWDSModal";
import useTranslate from "../../customHooks/useTranslate";
import SaveButton from "../Buttons/SaveButton";
import './PANWDSTable.css';
import EmptyListImage from '../../images/empty_firewall.svg';
import _ from "lodash";
import {PANWDSTooltip} from "./index";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#333333",
    fontSize: 16,
  },
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
    color: "#333333",
  },
  ButtonContainer: (props: any) => ({
    display: "flex",
    gap: theme.spacing(1),
    lex: '0 0 50%',
    [theme.breakpoints.down(props.nested ? 900 : 800)]: {
      gap: "2px",
      lex: '0 0 55%',
    },
    [theme.breakpoints.down(800)]: {
      padding: props.nested ? "5px 0" : "0",
      gap: "2px",
    },
  }),
  infiniteScrollTableBox: (props: any) => ({
    padding: 16,
    overflowX: 'auto',
    '& div.overflow-table-container > div div.tw-flex-grow.tw-overflow-auto': {
      height: `${props.offsetTableHeight ? 'calc(100vh - ' + props.offsetTableHeight + 'px)' : '100vh'}`
    }
  }),
  backdrop: {
    position: "absolute",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
}));

const TableToolBar = ({ toolbar, disableActionsBtn }: { toolbar: ITableToolbar[], disableActionsBtn?: boolean | Function },) => {
  const selectedItems = useSelectedRowData();
  const translate = useTranslate();
  const [confirmDelete, setConfirmDelete] = useState<any>({ open: false });
  const showConfirmModal = (type: string, callback: Function) => {
    if (type === 'delete') {
      setConfirmDelete({ open: true, callback });
    }
  };

  const checkDisableActions = (selectedRows: any, tool: ITableToolbar) => {
    if (tool.requireSelectedRows && selectedRows.length === 0) {
      return true;
    }
    return (disableActionsBtn && typeof disableActionsBtn === "function" && disableActionsBtn(selectedRows)) ||
      typeof disableActionsBtn === "boolean" && disableActionsBtn;
  }

  const ConfirmDeleteModal = (
    <PANWDSModal
      title={translate(`panwdsTable.confirmDelete`)}
      onClose={() => setConfirmDelete({ open: false })}
      isOpen={confirmDelete.open}
      size="sm"
      dataTestId="cloudngfw-panwds-delete-confirm-modal"
      dataMetrics="cloudngfw-panwds-delete-confirm-modal"
      modalBody={translate(`panwdsTable.sureDelete`)}
      modalBodyStylingRequired
      modalFooter={{
        footerRequired: true,
        cancelButtonRequired: true,
        featureSpecificButton: <SaveButton
          appearance="primary"
          size="md"
          label={translate(`panwdsTable.confirm`)}
          onClick={() => {
            confirmDelete.callback(selectedItems);
            setConfirmDelete({ open: false });
          }}
          dataMetrics="cloudngfw-panwds-delete-confirm-modal-button"
        />
      }}
    />);

  return (<>
    {confirmDelete.open && ConfirmDeleteModal}
    {toolbar.map((tool, index) => {
      if (tool.type === "button" || tool.type === "button_with_tooltip") {
          const button = (
              <Button
                  key={index}
                  appearance={tool.appearance ?? 'secondary'}
                  size="md"
                  disabled={tool.disabled || checkDisableActions(selectedItems, tool)}
                  data-metrics={tool.dataMetrics || ''}
                  //@ts-ignore
                  onClick={() => tool.action(selectedItems)}
              >
                  {tool.title}
              </Button>
          );
          if (tool.type === "button_with_tooltip") {
              return (
                  <PANWDSTooltip showTooltip={tool?.showTooltip}
                                 label={translate("csp.registerCSP")}>
                      <div>
                          {button}
                      </div>
                  </PANWDSTooltip>
              );
          } else {
              return button;
          }
      }

        if (tool.type === "button_with_tooltip") {
            return (
                <PANWDSTooltip showTooltip={tool?.showTooltip}
                               label={translate("csp.registerCSP")}>
                    <div>
                        <Button
                            key={index}
                            appearance={tool.appearance ?? 'secondary'}
                            size="md"
                            disabled={tool.disabled || checkDisableActions(selectedItems, tool)}
                            data-metrics={tool.dataMetrics || ''}
                            //@ts-ignore
                            onClick={() => tool.action(selectedItems)}
                        >
                            {tool.title}
                        </Button>
                    </div>
                </PANWDSTooltip>
            )
        }

      // dropdown case
      return (
        <PANWDSButtonDropdown
          key={index}
          buttonTitle={tool.title}
          items={
            tool.actions.map(({ title, action, confirmModal, dataMetrics, disabled }, index) => {
              return {
                value: title,
                context: {
                  action,
                  confirmModal,
                  dataMetrics
                },
                disabled
              }
            })
          }
          disabled={tool.disabled || checkDisableActions(selectedItems, tool)}
          appearance={tool.appearance ? tool.appearance : 'secondary'}
          dataMetrics={tool.dataMetrics || ''}
          onChange={(e: any) => {
            const { selectedItem: { context: { confirmModal, action } } } = e;
            if (confirmModal) {
              showConfirmModal(confirmModal, action);
            } else {
              action(selectedItems);
            }
          }}
        />
      )
    })}
  </>)
};

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  let doit: any;
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', () => {
      clearTimeout(doit);
      doit = setTimeout(updateSize, 800);
    });
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

interface PANWDSTableLightProps {
  columns: any[],
  data: any[],
  dataTestId: string,
  title: string,
  subtitle?: string,
  searchFilterRequired?: boolean,
  showToolbar?: boolean,
  showTileTitle?: boolean,
  toolbarActionsArray: ITableToolbar[],
  disableActionsBtn?: boolean | Function,
  isSingleSelect?: boolean,
  nested?: string,
  tableContainerStyle?: string,
  offsetTableHeight?: number,
  initialGroupBy?: string[],
  loading?: boolean,
  enableRowSelect?: boolean,
  backgroundLoading?: boolean,
  lockRows?: boolean,
  emptyTitle?: string,
  emptySubtitle?: string,
  dataMetrics?: string,
};

export const PANWDSTableLight = ({
  columns,
  data,
  title,
  subtitle,
  dataTestId,
  searchFilterRequired,
  showToolbar = true,
  showTileTitle = true,
  loading = false,
  toolbarActionsArray = [],
  initialGroupBy = [],
  disableActionsBtn,
  isSingleSelect = false,
  nested,
  tableContainerStyle,
  offsetTableHeight,
  enableRowSelect = true,
  backgroundLoading = false,
  lockRows = false,
  emptyTitle = undefined,
  emptySubtitle = undefined,
  dataMetrics,
}: PANWDSTableLightProps
) => {
  const [tableKey, setTableKey] = useState(0);
  const tableInstanceRef = useRef<any>();
  const [width, height] = useWindowSize();
  const classes = useStyles({ nested, tableContainerStyle, offsetTableHeight });

  const getSelectedItems = () => {
    if (tableInstanceRef.current) {
      return tableInstanceRef.current.selectedFlatRows.map((row: any) => row.original);
    }
    return [];
  };

  const onColumnWidthChange = (columnWidths: { [key: string]: number }) => {
    // dataTestId is required, will be used as key in the local storage
    if (dataTestId && Object.keys(columnWidths).length) {
      const tableWidth = document?.querySelector<HTMLElement>(`div[data-testid="${dataTestId ? dataTestId : 'connected-table'}"]`)?.offsetWidth || false;
      if (tableWidth) {
        const panwdsColumns = JSON.parse(localStorage.getItem('panwds-columns') || "{}");
        Object.keys(columnWidths).map((column: string) => {
          // we store proportion
          _.set(panwdsColumns, `${dataTestId}.${column}`, columnWidths[column] / tableWidth)
        })
        localStorage.setItem('panwds-columns', JSON.stringify(panwdsColumns));
      }
    }
  };

  const customWidth = (columnName: string, tableWidth: number) => {
    if (dataTestId) {
      try {
        const localTableInfo = JSON.parse(localStorage.getItem('panwds-columns') || "");
        const savedWidth = _.get(localTableInfo, `${dataTestId}.${columnName}`, false);
        if (savedWidth > 1) {
          return false;
        }
        if (savedWidth) {
          return tableWidth * savedWidth;
        }
        return false;
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  const AddWidthToColumns = () => {
    const tableWidth = document?.querySelector<HTMLElement>(`div[data-testid="${dataTestId ? dataTestId : 'connected-table'}"]`)?.offsetWidth || false;
    if (tableWidth) {
      columns.map((col: any) => {
        const localCustomWidth = customWidth(col.accessor, tableWidth);
        if (localCustomWidth) {
          col.width = localCustomWidth;
        } else {
          col.width = col.columnSize ? ((tableWidth * col.columnSize) / 12) : (tableWidth / 12);
        }
        return col;
      })
    }
    return columns;
  }

  useEffect(() => {
    // force rerender
    setTableKey(tableKey + 1);
  }, [width, height]);

  const tableColumns = AddWidthToColumns();

  let classNameContainer = 'overflow-table-container tw-flex tw-flex-col';
  // classNameContainer += isMultiselect || props.singleSelect ? ' row-selection' : ' no-row-selection';
  // classNameContainer = props.singleSelect ? classNameContainer + ' single-selection' : classNameContainer;
  // classNameContainer = props.tableInnerContainerClassName ? classNameContainer + ' ' + props.tableInnerContainerClassName : classNameContainer;
  // classNameContainer += tableDataRowStyle?.length === 0 || classNameForEmpty ? ' noDataStyles' : '';


  return (
    <div className={classes.infiniteScrollTableBox}>
      <div className={classNameContainer}>
        <TableProvider
          columns={tableColumns}
          data={data}
          enableResizeColumns
          tableInstanceRef={tableInstanceRef}
          enableRowSelect={enableRowSelect}
          enableColumnSort={true}
          rowSelectMode={isSingleSelect ? "single" : "multiple"}
          key={tableKey}
          initialState={{
            groupBy: initialGroupBy || [],
          }}
          isLoading={loading}
          isBackgroundFetching={lockRows || backgroundLoading} // use this value to show loading indicator along title
          onColumnWidthChange={onColumnWidthChange}
        >
          <ConnectedTableLayout
            overflow
            enableHeaderTitle
            dataMetrics={dataMetrics}
            dataTestId={dataTestId ? dataTestId : ''}
            controlLeft={
              <>
                {showTileTitle &&
                  <div className={classes.iconedParagraph}>
                    {title ?? ''}
                    {subtitle ? <Tooltip label={subtitle} defaultPlacement="right">
                      <InfoIcon size="xs" />
                    </Tooltip> : ''}
                  </div>
                }
              </>
            }
            controlRight={
              showToolbar ?
                <>
                  {searchFilterRequired && <ConnectedTableFilter />}
                  <section className={classes.ButtonContainer}>
                    <TableToolBar toolbar={toolbarActionsArray} disableActionsBtn={disableActionsBtn} />
                  </section>
                </>
                : null
            }
          >
            <ConnectedTable
              emptyStateProps={{
                heading: emptyTitle,
                description: emptySubtitle,
                graphic: (
                  <img width='50px' height='50px' src={EmptyListImage} />
                ),
                // style: ({
                //  marginTop: '-28%'
                // })
              }}
            />
            <Backdrop className={classes.backdrop} open={lockRows} />
          </ConnectedTableLayout>
        </TableProvider>
      </div>
    </div>)
};
