import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Resources from "../utils/enums";
import {describe} from "../actions";
import {isEmpty} from "lodash";

interface SupportState {
    cspEnable: boolean,
    PubSubMessageStatus: string,
    setCspWorkFlow: boolean,
    ExternalId: string,
    SalesAccountId: string,
    SerialNumber: string,
    Status: string,
    SupportAccountId: string,
    SupportTenantId: string
    SupportType: string,
}

const initialState = {
    cspEnable: true,
    PubSubMessageStatus: '',
    ExternalId: "",
    SalesAccountId: "",
    SerialNumber: "",
    Status: "",
    SupportAccountId: "",
    SupportTenantId: "",
    SupportType: "",
} as SupportState

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        setCspEnable(state, action: PayloadAction<boolean>) {
            state.cspEnable = action.payload;
        },
        setPubSubMessageStatus(state, action: PayloadAction<string>) {
            state.PubSubMessageStatus = action.payload;
        }
    },
    extraReducers: (builder) => {
        const resource = Resources.SUPPORT;
        builder
            .addCase(describe({resource}).fulfilled, (state, action) => {
                const payload = action.payload;
                let supportInfo = {};
                if (payload.data && payload.data.SupportInformations && !isEmpty(payload.data.SupportInformations[0])) {
                    supportInfo = {...payload.data.SupportInformations[0]}
                }
                return {...state, ...supportInfo, ExternalId: payload?.data?.ExternalId}
            })
    }
})

export const { setCspEnable, setPubSubMessageStatus } = supportSlice.actions
export default supportSlice.reducer
