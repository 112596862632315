import { FormControlLabel, makeStyles, RadioGroup } from "@material-ui/core";
import clsx from "clsx";
import { ChangeEvent, useEffect } from "react";
import { PANFieldContainer, PANFormLabel, PANHelpText, RadioLabelContainer, RadioMutedStyle, RadioTitleStyle, StyledRadio } from "./styles";

interface IRadioOptions { label: string; muted?: string; value: string, default?: boolean; }

const useStyles = makeStyles({
  errorText: {
    color: "#D13C3C",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    // "$root.Mui-focusVisible &": {
    //   outline: "2px auto rgba(19,124,189,.6)",
    //   outlineOffset: 2,
    // },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#006FCC",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

export const PANRadioField = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => {
  const classes = useStyles();

  useEffect(() => {
    if (props?.initValue) {
      return inputProps.onChange(inputProps.value || props?.initValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeValue = (event: any) => {
    if(props.onClick){
      props.onClick(event);
      inputProps.onChange(event.target.value)
    }
    else{
    inputProps.onChange(event.target.value)
    }
  }

  const existerror = !!(touched && error);
  

  return (
    <PANFieldContainer>
        {props.title && <PANFormLabel className={existerror ? classes.errorText : ''}>{props.title}</PANFormLabel>}

        <PANRadio name={props.label} onChange={onChangeValue} options={props?.options}
          initValue={inputProps.value || props?.initValue} row={props.row} disabled={props?.disabled} />

      {
        existerror && (<PANHelpText id={props.label} >{error}</PANHelpText>)
      }
    </PANFieldContainer>
  );
};

export const PANRadio = ({ name, onChange, options, initValue = '', row, title, onClick, disabled}: { name?: string; 
  row?: boolean; onChange: ((event: ChangeEvent<HTMLInputElement>, value: string ) => void) | undefined;
  initValue?: string; options: IRadioOptions[]; title?: string; onClick?: any ; disabled?: boolean}) => {
  
  const classes = useStyles();

  return (
    <PANFieldContainer>
        {title && <PANFormLabel>{title}</PANFormLabel>}

    <RadioGroup
        aria-label={name}
        name={name}
        onChange={onChange}
        onClick={onClick}
        row={row}
        value={initValue}
      >
          {options?.map((opt: IRadioOptions, idx: number) => (
            <FormControlLabel
              key={idx}
              value={`${opt.value}`}
              control={
                <StyledRadio
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                />
              }
              disabled={disabled}
              label={<RadioLabelContainer><RadioTitleStyle>{opt.label}</RadioTitleStyle><RadioMutedStyle>{opt.muted}</RadioMutedStyle></RadioLabelContainer>}
            />
          ))}
       
      </RadioGroup>
      </PANFieldContainer>
  )
}