import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { kebabCase } from 'lodash';
import { makeStyles } from "@material-ui/core";
import { toast } from '../../../components';
import { usePermissions } from '../../../customHooks';
import {useTranslate} from '../../../customHooks';
import { dataProvider } from '../../../dataProvider';
import { nameStyleCursor } from '../../../layout/styles';
import { Button, Sheet, SheetBody } from '@panwds/react-ui';
import { PANWDSTable } from '../../../components/PANWDSElements';
import { CaretLeftIcon } from '@panwds/icons';


const useStyles = makeStyles(() => {
  return ({
    appColumnStyles: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100px'
    },
    tagNameHeader: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '20px',
      color: '#333333',
    },
    tagName: {
      background: '#F4F5F5',
      border: '1px solid #DADBDB',
      borderRadius: '4px',
      fontSize: '12px',
      height: '12px',
      padding: '12px 0 12px 8px',
      color: '#333333',
    },
    backButtonHeader: {
        display: 'flex',
        marginBottom: '18px',
        alignItems: 'center'
    },
    backButtonTitle: {
        marginLeft: '12px',
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#000000'
    },
    sheetContainer: {
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100vh",
      display: "flex",
    },
    footer: {
      justifyContent: "flex-end",
      display: "flex",
      padding: '16px 0',
      borderTop: "1px solid #DADBDB",
      "& button": {
        marginRight: "16px",
        padding: "16px",
      },
    },
  });
});

const AssociatedTagsList = ({ isOpenTags, setOpenTags, clickedIPDetails, clickedTagIPDetails, getTagIPsPanel }: any) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const classes = useStyles();
    const nameClass = nameStyleCursor();
    const [loading, setLoading] = useState(true);
    const [tagsData, setTagsData] = useState<never[]>([]);

    const loadAssociatedTagsList = (needRefresh = false, ipPrefix: any, accountId: any, vpcId: any) => {
      if (!tagsData.length || needRefresh) {
        if (!tagsData.length || needRefresh) {
        setTagsData([]);
          setLoading(true);
        }
        dataProvider.getList("vpcPrefixTags", { data: { ipPrefix, accountId, vpcId }})
          .then((response) => {
            if (response?.data) {
              // @ts-ignore
              // setTagsData(response?.data?.IpPrefixTag[ipPrefix]); // older code version
              setTagsData(formatTagsData(response?.data?.Tags));
            } else {
              toast.error(response, { toastId: `tags-ip-list-error` });
            }
          })
          .catch((error: any) => {
            if (error?.noToast) {
              return;
            }
            toast.error(error?.error?.error, { toastId: `tags-ip-list-error` });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
      }
  };

    useEffect(() => {
        loadAssociatedTagsList(false, clickedIPDetails.ipPrefix, clickedIPDetails.account,  clickedIPDetails.vpcid);
    }, [clickedIPDetails]);

    const formatTagsData = (tagsData: any) => {
      let formatTags: any = [];
      tagsData?.forEach((tag: any) => {
        formatTags.push({
          TagName: tag
        })
      })
      return formatTags;
    }


    const getTagIPsColumns = () => [
      {
        accessor: 'TagName',
        Header: translate(`resources.inventory.tags.tagName`),
        columnSize: 12,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-ips-record-${kebabCase(row?.original?.TagName?.toLowerCase())}`}
              className={classes.appColumnStyles}
              data-test-id={`tags-ips-record-${kebabCase(row?.original?.TagName?.toLowerCase())}`}
            >
              {row?.original?.TagName}
            </span>
          )
        }
      }
  ];

  const goToTagIPs = () => {
    setOpenTags(false)
    getTagIPsPanel(clickedTagIPDetails)
  }

  return (
      <>
        <Sheet
          onClose={goToTagIPs}
          title={translate(`resources.inventory.tags.checkAssociatedTags`)}
          isOpen={isOpenTags}
          showMask={true}
          position="md"
        >
          <div className={classes.sheetContainer}>
          <SheetBody isLoading={loading}>
            <div className={classes.backButtonHeader}>
                <Button
                    aria-label="back button"
                    icon={<CaretLeftIcon size="sm" />}
                    appearance="secondary"
                    size="sm"
                    onClick={goToTagIPs}
                />
                <span className={classes.backButtonTitle}>{translate(`resources.inventory.tags.back`)}</span>
            </div>
            <div className={classes.tagNameHeader}>{translate(`resources.inventory.tags.selectedIPAddress`)}</div>
            <div className={classes.tagName}>{clickedIPDetails.ipPrefix}</div>
            <PANWDSTable
              permissions={permissions}
              columns={getTagIPsColumns()}
              gridData={tagsData}
              loading={loading}
              title={`${translate(`resources.inventory.tags.associatedTags`)} (${tagsData?.length})`}
              searchFilterRequired={true}
              showToolbar={true}
              idProperty={"Tags-IPs"}
              showTileTitle
              emptySubtitle={translate(`resources.inventory.tags.noassociatedTags`)}
              singleSelect={false}
              multiSelect={false}
              dataTestId="tags-IPs-table"
              tableInnerContainerClassName="tags-IPs-table"
              infiniteScroll={false}
              enablePagination={true}
              rowSize="standard"
              tableContainerStyle = {{
                display: "flex",
                flexFlow: "column",
                padding: '16px 0 16px 0',
                boxSizing: 'border-box',
                overflowX: 'hidden',
                overflowY: 'hidden'
              }}
            />
          </SheetBody>
          <div className={classes.footer}>
            <Button
              key="tags-IPs-table-button"
              appearance="secondary"
              size="sm"
              buttonPosition="right"
              data-metrics="cngfw-tags-IPs-table-button"
              //@ts-ignore
              onClick={goToTagIPs}
            >
              {translate(`resources.inventory.cancel`)}
            </Button>
          </div>
          </div>
        </Sheet>
      </>
  );
}

export default withRouter(AssociatedTagsList);
