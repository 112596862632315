import { makeStyles } from "@material-ui/core";
import { Tag } from "@panwds/react-ui";
import { useEffect } from "react";
import { PANFieldContainer, PANHelpText } from "../FormElements/styles";
import {PANWDSLabel} from "./PANWDSLabel";

const useStyles = makeStyles((theme) => ({
    error: {
        border: '1px solid #D13C3C',
        borderRadius: 4,
    },
    errorText: {
        color: "#D13C3C",
    },
    headerStyle: {
        marginBottom: '10px'
    },
    appsIncluded:{
        marginBottom: '10px',
        '&>div':{
            marginTop: '5px',
            marginRight: '5px',
        }
    },
    appsRemainingStyles: {
        '&>div':{
            marginTop: '5px',
            backgroundColor: '#CFE8FC'
        }
    }
}));


export const PANWDSTags = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    const classes = useStyles();
    const existerror = !!(touched && error);
    let { items, title, required } = props;
    let slicedAppsArrays = items?.slice(0, 4);
    let appsRemaining = Number(items?.length) - 4;

    useEffect(() => {
        inputProps.onChange(items);
    }, [inputProps]);

    return <PANFieldContainer>
        <div className={classes.headerStyle}>
            {title && <PANWDSLabel required={required} title={title}/>}
        </div>
        <div className={classes.appsIncluded}>
            {slicedAppsArrays?.length > 0 && slicedAppsArrays?.map((item: any) => {
                return  (
                    <Tag key={item} appearance="standard" readOnly >
                        {item}
                    </Tag>
                );
            })}
            {items?.length >= 5 &&
             <span className={classes.appsRemainingStyles}>
                <Tag key={appsRemaining} appearance="standard" readOnly >
                    {`+${appsRemaining}`}
                </Tag>
            </span>
            }
        </div>
        {existerror && (<PANHelpText>{error}</PANHelpText>)}

    </PANFieldContainer>;
};
