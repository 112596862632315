import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import Skeleton from '@mui/material/Skeleton';

interface EgressnatProps {
    firewall: any;
    styles: any;
    isLoading: boolean;
}

const EgressNatOverview: React.FC<EgressnatProps> = ({ firewall, styles, isLoading }) => {
    const translate = useTranslate();
    
     /* TODO: Needs a revisit with response data*/
    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.egressNat`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`generic.enabled`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.EgressNAT?.Enabled}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.publicIPType`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.EgressNAT?.Settings?.IPPoolType}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.publicIPs`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.Description}</span>
                </div>
            </div>
        </div>
    );
};

export default EgressNatOverview;