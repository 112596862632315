import { Box, Paper, Typography, withStyles } from "@material-ui/core";

export const TileContainer = withStyles(() => ({
  root: {
    position: "relative",
  },
}))(Paper);

export const Title = withStyles(() => ({
  root: {
    color: "#333333",
  },
}))(Typography);

export const Subtitle = withStyles(() => ({
  root: {
    fontSize: 12,
    color: "#707070",
  },
}))(Typography);

export const TitleContainer = withStyles(() => ({
  root: {
    marginBottom: 10,
  },
}))(Box);

export const TitleDividerContainer = withStyles(() => ({
  root: {
    borderBottom: '1px solid #DADBDB',
  },
}))(Box);
