import { FormControl, Grid, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState, Fragment } from "react";
import { usePermissions } from '../../customHooks';
import { Field } from "react-final-form";
import { useHistory, withRouter } from "react-router-dom";
import { Button as PANWDSButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from "../../components";
import { Row } from "../../components/FormElements";
import {
    PANWDSInput,
    PANWDSTextarea,
    PANWDSSelect,
    PANWDSForm,
    PANWDSBreadcrumbs
} from '../../components/PANWDSElements';
import { dataProvider } from "../../dataProvider";
import { isCategoryName, isRequired, isUrlList, maxLength, composeValidators } from '../../utils/validate';
import {useTranslate} from '../../customHooks';

export const styles = {
  price: { width: "7em" },
  width: { width: "7em" },
  height: { width: "7em" },
  stock: { width: "7em" },
  widthFormGroup: { display: "inline-block" },
  heightFormGroup: { display: "inline-block", marginLeft: 32 },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

const CategoryEdit = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { permissions } = usePermissions();

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  // get the category name from route params
  const categoryName = props.match.params.categoryname;

  const [record, setRecord] = useState<any>({});
  const title = categoryName;

  useEffect(() => {
    if (permissions?.DescribeURLCategory) {
      dataProvider.describe('category', '',
        { RuleStackName: ruleStackName, Name: categoryName })
        .then((res: any) => {
          if (res.data) {
            setRecord({ ...res.data });
          } else {
            toast.error(res?.error, { toastId: "category-describe" });
          }
        }).catch((e: any) => {
        toast.error(e?.error, { toastId: "category-describe" });
      });
    }
  }, [ruleStackName, categoryName, permissions]);

  const SaveToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar}>
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-category-edit-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          label="Save"
          redirect={false}
          submitOnEnter={true}
          disabled={!permissions?.UpdateURLCategory}
          className={classes.button}
          loading={submitting}
          dataMetrics="cloudngfw-category-edit-save-button"
          {...toolbarProps}
        />
      </div>
    );
  };

  const save = useCallback(
    async (values) => {
      if (!permissions?.UpdateURLCategory) {
        return;
      }
      let payload = { ...values.URLCategoryCandidate, RuleStackName: values.RuleStackName, Name: values.Name };
      setSubmitting(true);
      try {
          await dataProvider.update("category", payload);
        toast.success("Success");
        history.goBack();
      } catch (error: any) {
        toast.error(error?.error, { toastId: "category-update" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, permissions]
  );

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: translate(`resources.ruleStacks.name`),
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`categories`]: translate(`resources.category.name`),
          [categoryName]: categoryName
        }}
      />
      <PANTitle title={title} divider />
        <PANWDSForm
            toolbar={<SaveToolbar />}
            onSubmit={save}
            initialValues={record}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: 'auto' }}>
                    <PANTile title={translate(`resources.category.fields.General`)} subtitle={translate(`resources.category.fields.CreateSubtitle`)} size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        required
                                        title={translate(`resources.category.fields.Name`)}
                                        dataMetrics="cloudngfw-category-edit-field-name"
                                        validate={composeValidators(isRequired, isCategoryName, maxLength(29))}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="URLCategoryCandidate.Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.category.fields.Description`)}
                                        dataMetrics="cloudngfw-category-edit-field-description"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="URLCategoryCandidate.URLTargets"
                                        // @ts-ignore
                                        component={PANWDSTextarea}
                                        enableArrayValue
                                        note="Enter one value per line"
                                        rowsMin={1}
                                        title={translate(`resources.category.fields.URLTargets`)}
                                        dataMetrics="cloudngfw-category-edit-field-urltargets"
                                        validate={composeValidators(isRequired, isUrlList, maxLength(255))}
                                        initialValue={record && record.URLCategoryCandidate ? record.URLCategoryCandidate.URLTargets : ""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="URLCategoryCandidate.Action"
                                        // @ts-ignore
                                        component={PANWDSSelect}
                                        title={translate(`resources.category.fields.Action`)}
                                        dataMetrics="cloudngfw-category-edit-field-action"
                                        items={[
                                            { text: "Alert", value: "alert" },
                                            { text: "Allow", value: "allow" },
                                            { text: "Block", value: "block" },
                                            { text: "None", value: "none" },
                                        ]}
                                    />
                                </FormControl>
                            </Grid>

                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};


export default withRouter(CategoryEdit);
