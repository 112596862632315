import {rules} from "../../../index";

const Index = ({record}: {record: any}) => {
    return (
        <rules.list
            // @ts-ignore
            scope={record?.Firewall?.RuleStackName ? "Local" : record?.Firewall?.GlobalRuleStackName ? "Global" : undefined}
            ruleStackName={record?.Firewall?.RuleStackName ?? record?.Firewall?.GlobalRuleStackName}
            checkboxColumn={false}
            showToolbar={false}
            readOnly
        />
    )
};

export default Index;
