import { useEffect, useState } from "react";
import { Box, Chip, makeStyles, FormControl } from "@material-ui/core";
import { Field, withTypes } from "react-final-form";
import { PANLabelStyle, PANFieldContainer } from "./styles";
import { PANDropDown } from "../Buttons/DropDown";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { IChipInitValue, IChipOptions } from "../../types";
import { PANWDSModal, PANWDSInput } from "../PANWDSElements";
import { Button } from "@panwds/react-ui";
import { isRequired } from '../../utils/validate';
import {useTranslate} from '../../customHooks';
import {composeValidators} from '../../utils/validate';
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  error: {
    border: "1px solid #D13C3C",
    borderRadius: 4,
  },
  errorText: {
    color: "#D13C3C",
    fontSize: "12px",
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    '-webkit-justify-content': 'flex-end',
    padding: '20px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
    borderColor: 'rgb(218 219 219/var(--tw-border-opacity))',
    borderTopStyle: 'solid',
  },
  formLabels: {
    padding: '20px 60px'
  }
}));

interface TagFormValues {
  Key?: string;
  Value?: string;
}

const { Form } = withTypes<TagFormValues>();
const maxKeyLength = 128;
const maxValueLength = 256;


const AddNewTag = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const handleSubmit = (newTag: TagFormValues) => {
    props.addOptions(newTag);
    props.setOpenAddForm(false)
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={formProps => {
        let { handleSubmit, values } = formProps;
       return  <form onSubmit={handleSubmit}>
            <div className={classes.formLabels}>
              <FormControl fullWidth>
                <Field
                    name="Key"
                    type="text"
                    // @ts-ignore
                    component={PANWDSInput}
                    autoFocus={true}
                    required
                    validate={composeValidators(isRequired)}
                    title={translate(`chip.key`)}
                    dataMetrics="cloudngfw-tag-key"
                    maxLength={maxKeyLength}
                  />
              </FormControl>
              <FormControl fullWidth>
                <Field
                    name="Value"
                    type="text"
                    // @ts-ignore
                    component={PANWDSInput}
                    required
                    validate={composeValidators(isRequired)}
                    title={translate(`chip.value`)}
                    dataMetrics="cloudngfw-new-tag-value-input"
                    maxLength={maxValueLength}
                  />
              </FormControl>
            </div>
            <div className={classes.toolbar}>
              <Button
                  size="md"
                  appearance="primary"
                  type="submit"
                  dataMetrics="new-tag-submit-btn"
                  disabled={formProps?.pristine}
                >
                  {translate('chip.add')}
              </Button>
            </div>
          </form>
      }}
    />
  );
};


export const PANChip = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  addNew = false,
  addNewLabel = '',
  disabled = false,
  disableButton = false,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const existerror = !!(touched && error);

  const MAX_TEXT_LENTGH = props.maxTextLength ?? 15;
  const displayText = (text: String) => text?.length > MAX_TEXT_LENTGH ? text?.slice(0, MAX_TEXT_LENTGH) + '...': text;

  const calculateInitialOptions = () => {
    const data = inputProps.value? inputProps.value : [];
    const options = props?.options ? [...props.options] : [];
    return [...data, ...options];
  }

  const [data, setData] = useState<IChipInitValue[]>(inputProps.value ? inputProps.value : [])
  const [options, setOptions] = useState<IChipOptions[]>(props?.options ? [...props.options] : []);
  const [originOpts, setOriginOpts] = useState<IChipOptions[]>(calculateInitialOptions())

  const [openAddForm, setOpenAddForm] = useState(false);

  useEffect(() => {
    inputProps.onChange(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!isEmpty(inputProps?.value) && isEmpty(data)) { // don't update unnecessarily
      setData(inputProps?.value);
    }
  }, [inputProps?.value])

  const addOptions = (newOption: any) => {
    if (props.addDirectly){
      handleChange(newOption);
    } else {
      setOptions([...options, {...newOption}]);
    }
  }

  const handleDelete = (idx: number): void => {
    let findEl: IChipInitValue | undefined;

    // Remove the item form the chips
    setData((prevState) => {
      const newData = [...prevState];

      findEl = newData.splice(idx, 1)[0];

      return newData;
    });

    const findIdx = findEl?.Key && originOpts.map(({ Key }) => Key).indexOf(findEl.Key);
    const originLength = originOpts.length - 1;

    // Revert the item in the dropdown
    setOptions((prevState) => {

      if (typeof findIdx !== 'number' || !findEl?.Key ) return prevState;

      const newOptions = [...prevState];

      findIdx > originLength ? newOptions.push(findEl as IChipInitValue) : newOptions.splice(findIdx, 0, findEl as IChipInitValue);

      return newOptions
    })
  }

  /**
   * handle change custom dropdown
   * @param args - received data
   */
  const handleChange = (args: IChipOptions) => {
    // Remove the element of the dropdown list data
    setOptions((prevState) => {
      const idx = prevState.map(({ Key }) => Key).indexOf(args.Key);
      prevState.splice(idx, 1);
      return [...prevState];
    });

    // add the element in the data chips
    setData((prevState) => {
      return [...prevState, args];
    });
  };

  return (
    <PANFieldContainer>
      <PANLabelStyle
        shrink
        htmlFor={props.label}
        className={existerror ? classes.errorText : ""}
      >
        {props.label} {props.required && "*"}
      </PANLabelStyle>

      <Box style={{ marginTop: 24, display: 'flex', gap: 8, color: '#006FCC', ...props.style }}>
        {data?.map(({ Key, Value }, idx: number) => {
          return props.hideDelete ?
            (<Chip
              color="secondary"
              key={idx}
              label={`${displayText(Key)} | ${displayText(Value)}`}
              data-test-id={`tag-${idx}`}
              clickable={false}
              title={`${Key} | \n${Value}`}
            />) : (<Chip
              color="secondary"
              key={idx}
              label={`${displayText(Key)} | ${displayText(Value)}`}
              deleteIcon={<CloseIcon data-test-id={`tag-close-${idx}`}/>}
              data-test-id={`tag-${idx}`}
              onDelete={() => handleDelete(idx)}
              clickable={false}
              disabled={disabled}
              title={`${Key} | \n${Value}`}
            />);
        })}

        {
          addNew && (<PANDropDown label={<AddIcon />} options={options} dataTestId={props.dataTestId} disabled={disableButton} onChange={handleChange} addNew addNewLabel={addNewLabel} addFunc={() => setOpenAddForm(true)} />)
        }
      </Box>
      <Box>
      {existerror ?
        <span className={classes.errorText}>
          {error}
        </span>
        : null}</Box>
      <PANWDSModal
        title={translate('chip.addNewTag')}
        onClose={(() => setOpenAddForm(false))}
        isOpen={openAddForm}
        size="sm"
        dataTestId="cloudngfw-chip-modal"
        dataMetrics="cloudngfw-chip-modal"
        modalBody={<AddNewTag setOpenAddForm={setOpenAddForm} addOptions={addOptions}/>}
      />
    </PANFieldContainer>
  );
};
