import { FormControl, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { find, get, kebabCase, cloneDeep } from "lodash";
import { useCallback, useEffect, useState, Fragment, useRef } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { Field, FormSpy } from "react-final-form";
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { Button as PANWDSButton } from "@panwds/react-ui";
import * as DataTypes from "../../api/FwaasDataTypes";
import { PANTile, PANTitle, SaveButton, toast } from '../../components';
import { Row } from "../../components/FormElements";
import { PANFormLabel } from "../../components/FormElements/styles";
import { dataProvider } from "../../dataProvider";
import { composeValidators, requiredValidator } from '../../utils/validate';
import {
  isAlphaNumeric, isRequired, maxLength,
  maxValue, minValue, isProtocolAndPortArray,
  multilineUniqueIpV4CIDRS,
  isAppsArray
} from '../../utils/validate';
import { RouteUri } from '../../routeUri';
import {
  PANWDSTextarea, PANWDSChipInput, PANWDSSelectWithSearch, PANWDSSelectsAndInput,
  PANWDSRadioField, PANWDSCheckboxField, PANWDSInput, PANWDSSelect, PANWDSForm, PANWDSBreadcrumbs
} from '../../components/PANWDSElements';
import { decode } from 'html-entities';
import _ from 'lodash';
import { ApplicationConfigManager } from '../../types';
import { AppsModalComponent } from './Components/ModalComponent/AppsModalComponent';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  description: {
    fontSize: 14,
    color: "#707070",
  },
  error: {
    fontSize: 14,
    color: "#D13C3C",
  },
  errorIcon: {
    fontSize: 20,
    margin: '0 5px -4px 0',
  },
  linkText: {
    color: "#006FCC",
    fontWeight: 600,
    textDecoration: "none",
  },
  toolbar: {
    background: "transparent",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    padding: '10px 0',
    alignItems: 'baseline'
  },
  panTableClone: {
    display: 'flex',
    borderRadius: '4px',
    border: '1px solid #DADBDB',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& > div': {
      borderBottom: '1px solid #DADBDB',
      padding: '10px 15px 10px 15px',
      margin: 0,
    },
    '& .MuiGrid-item': {
      padding: '0 10px 0 0',
    },
    width: '550px',
  },
  panTableCloneTitle: {
    fontSize: '0.875rem',
    padding: '15px 15px 0 15px !important',
    display: 'grid',
    gap: 15,
    gridTemplateColumns: '35% 65%',
  }
}));

let countriesMap: any;

const RulesCreate = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();

  const search = useLocation().search;
  const rulelistname = new URLSearchParams(search).get('rulelistname');
  const [protocolAndPort, setProtocolAndPort] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { permissions } = usePermissions();
  const [rulestackData, setRulestackData] = useState<any>({});
  const [gridRef, setGridRef] = useState({
    current: {
      columns: []
    },
  });
  const [addedApplications, setAddedApplications] = useState<never[]>([]);


  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  // get the rulestack name from route params
  const ruleListName = rulelistname ?? props.match.params.rulelistname;

  const title = translate(`resources.rules.fields.Create.${ruleListName}`);
  const subtitle = (
    ruleListName === "LocalRule"
      ? translate(`resources.rules.fields.LocalListSubtitle`)
      : translate(`resources.rules.fields.GlobalCreateSubtitle`)
  );

  const [tableOption, setTableOptions] =
    useState<{
      prefixlists: string[],
      fqdnlists: string[],
      ipFeedlists: string[],
      urlFeedlists: string[],
      countries: string[],
      urlcategories: string[],
      applications: string[],
      signedCertificates: string[],
    }>
      ({
        prefixlists: [],
        fqdnlists: [],
        ipFeedlists: [],
        urlFeedlists: [],
        countries: [],
        urlcategories: [],
        applications: [],
        signedCertificates: [],
      });


  const save = useCallback(
    async (values) => {
      if (!permissions?.CreateSecurityRule) {
        return;
      }
      const payload = { ...cloneDeep(values), RuleStackName: ruleStackName, RuleListName: ruleListName };
      //values = { ...values, RuleStackName: ruleStackName, RuleListName: ruleListName }
      payload['Priority'] = +payload?.Priority;

      if (payload['RuleEntry']['Protocol'] === "select") {
        delete payload['RuleEntry']['Protocol'];
      } else {
        delete payload['RuleEntry']['ProtPortList'];
      }

      // process source
      if (payload.RuleEntry.Source?.Type === 'any') {
        payload['RuleEntry']['Source'] = { Cidrs: ['any'] };
      } else {
        payload.RuleEntry.NegateSource = payload['RuleEntry']['Source']['Type'] === 'exclude';
        delete payload['RuleEntry']['Source']['Type'];
      }

      // process destination
      if (payload.RuleEntry.Destination?.Type === 'any') {
        payload['RuleEntry']['Destination'] = { Cidrs: ['any'] };
      } else {
        payload.RuleEntry.NegateDestination = payload['RuleEntry']['Destination']['Type'] === 'exclude';
        delete payload['RuleEntry']['Destination']['Type'];
      }

      // exclude disabled
      if (payload?.RuleEntry?.Source?.Cidrs?.includes('any')) {
        payload.RuleEntry.NegateSource = false;
      }
      if (payload?.RuleEntry?.Destination?.Cidrs?.includes('any')) {
        payload.RuleEntry.NegateDestination = false;
      }
      if (payload?.RuleEntry?.DecryptionRuleType === "None") {
        delete payload.RuleEntry.DecryptionRuleType;
      }

      // process applications
      payload.RuleEntry.Applications = payload.RuleEntry.Applications.Type === 'any' ?
        ['any'] :
        payload.RuleEntry.Applications.Apps;

      // process category
      if (payload.RuleEntry.Category.Type === 'any') {
        payload.RuleEntry.Category = {};
      } else {
        payload.RuleEntry.Category.Feeds = payload.RuleEntry.Category.Feeds;
        payload.RuleEntry.Category.URLCategoryNames = payload.RuleEntry.Category.URLCategoryNames;
        delete payload.RuleEntry.Category.Type;
      }

      setSubmitting(true);
      try {
        await dataProvider.create("rules", { data: payload });
        //toast.success("Success");
        history.goBack();
        return;
      } catch (error: any) {
        toast.error(error?.error?.error, { toastId: "rules-create" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, permissions]
  );


  const getTableOptions = async () => {
    if (!permissions?.CreateSecurityRule) {
      return;
    }
    const res = await getAllList();
    // dataProvider.getList("applications", {
    //   pagination: { page: 1, perPage: 5 },
    //   sort: { field: 'Name', order: 'ASC' },
    //   filter: {},
    //   data: record
    // }).then(async (response:any) => {
    //   //@ts-ignore
    //   let responseObj = { applications: response?.data[0]?.Applications || [] };

    //   setTableOptions((prevState) => ({ ...prevState, ...responseObj }));
    // })
    //   .catch((e) => {
    //     console.log("Error", e);
    //   });
    setTableOptions((prevState) => ({ ...prevState, ...res }))
  }

  useEffect(() => {
    dataProvider.describe('ruleStacks', '', { RuleStackName: ruleStackName })
      .then((res: DataTypes.IFwaasApiResponse) => {
        if (res.data) {
          setRulestackData({ ...res.data });
        }
      })
      .catch((e: any) => {
        // TODO handle invalid rs name
      });
  }, []);

  useEffect(() => {
    getTableOptions();
  }, [permissions]);

  const getAllList = async (): Promise<{
    prefixlists: string[];
    fqdnlists: string[];
    ipFeedlists: string[];
    urlFeedlists: string[];
    countries: string[];
    urlcategories: string[];
    signedCertificates: string[];
  }> => {
    let data = { RuleStackName: ruleStackName, RuleListName: ruleListName };
    return Promise.all([
      dataProvider.get("prefixlists", { data: data }),
      dataProvider.get("fqdnlists", { data: data }),
      dataProvider.get("feed", { data: { ...data, Type: "IP_LIST" } }),
      dataProvider.get("feed", { data: { ...data, Type: "URL_LIST" } }),
      dataProvider.get("countries", { data: { ...data } }),
      dataProvider.get("category", { data: { ...data } }),
      dataProvider.get("predefinedUrlCategory", { data: data }),
      dataProvider.get("certificate", { data: { ...data, selfsigned: false } })
    ])
      .then(values => {
        let urlCategoriesData = (values[5]?.data) ? values[5].data.map((item: any) => ({text: item, value: item})) : [];
        let predefinedUrlCategoryData = (values[6]?.data) ? values[6].data.map((item: any) => ({text: item, value: item})) : []
        return {
          prefixlists: (values[0]?.data) ? values[0].data.map((item: any) => ({text: item, value: item})) : [],
          fqdnlists: (values[1]?.data) ? values[1].data.map((item: any) => ({text: item, value: item})) : [],
          ipFeedlists: (values[2]?.data) ? values[2].data.map((item: any) => ({text: item, value: item})) : [],
          urlFeedlists: (values[3]?.data) ? values[3].data.map((item: any) => ({text: item, value: item})) : [],
          countries: (values[4]?.data) ? values[4].data.map((item: any) => ({text: item.Description, value: item.Code})) : [],
          urlcategories: urlCategoriesData.concat(predefinedUrlCategoryData),
          signedCertificates: values[7]?.data,
        };

      })
  }

  const RulesCreateToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar}>
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-rules-create-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          submitOnEnter={true}
          loading={submitting}
          disabled={!permissions?.CreateSecurityRule}
          dataMetrics="cloudngfw-rules-create-save-button"
          {...toolbarProps}
        />

      </div>
    )
  };

  const handleProtocolChange = (event: any) => {
    if (event.target.value === "select") {
      setProtocolAndPort(true);
    } else {
      setProtocolAndPort(false);
    }
  };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: `${translate(`resources.ruleStacks.name`)} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
          [ruleStackName]: ruleStackName,
          [`rules`]: translate(`resources.rules.name`),
          create: title
        }}
      />
      <PANTitle title={title} divider />
      <PANWDSForm
        toolbar={<RulesCreateToolbar />}
        onSubmit={save}
        style={{ margin: 16 }}
      >
        <Fragment>
          <Grid container style={{ gap: 16, width: 'auto' }}>
            <PANTile
              title={translate(`resources.rules.fields.General`)}
              subtitle={subtitle}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.RuleName"
                      // @ts-ignore
                      component={PANWDSInput}
                      required
                      validate={composeValidators(requiredValidator(translate("validation.required")), maxLength(60), isAlphaNumeric)}
                      title={translate(`resources.rules.fields.RuleName`)}
                      dataMetrics="cloudngfw-rules-create-field-name"
                    />
                  </FormControl>
                </Grid>
              </Row>
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.Description"
                      // @ts-ignore
                      component={PANWDSInput}
                      title={translate(`resources.rules.fields.Description`)}
                      validate={maxLength(512)}
                      dataMetrics="cloudngfw-rules-create-field-description"
                    />
                  </FormControl>
                </Grid>
              </Row>

              <Row>
                <Grid item sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="Priority"
                      // @ts-ignore
                      component={PANWDSInput}
                      required
                      note="Rules with lower priority are evaluated first. Each rule within a rule group must have a unique priority setting."
                      validate={composeValidators(requiredValidator(translate("validation.required")), minValue(1), maxValue(1000000))}
                      title={translate(`resources.rules.fields.RulePriority`)}
                      type="number"
                      inputProps={{
                        min: "1",
                        onInput: (e: any) => e.target.validity.valid || (e.target.value = ""),
                      }}
                      dataMetrics="cloudngfw-rules-create-field-priority"
                    />
                  </FormControl>
                </Grid>
              </Row>

              <Row>
                <Grid item sm={12} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.Enabled"
                      // @ts-ignore
                      component={PANWDSCheckboxField}
                      label={translate(`resources.rules.fields.Enabled`)}
                      defaultValue={true}
                      muted={translate(`resources.rules.fields.EnabledSubtitle`)}
                      type="checkbox"
                    />
                  </FormControl>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.Source`)}
              subtitle={translate(`resources.rules.fields.SourceSubtitle`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.Source.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" },
                        { label: translate(`resources.rules.fields.excludeNegateMatch`), value: "exclude" },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        if (['match', 'exclude'].includes(values?.RuleEntry?.Source?.Type)) {
                          return <>
                            <Field
                              name="RuleEntry.Source.Cidrs"
                              row
                              // @ts-ignore
                              component={PANWDSTextarea}
                              title={translate(`resources.rules.fields.Cidrs`)}
                              note={translate(`resources.rules.fields.CidrsNote`)}
                              validate={multilineUniqueIpV4CIDRS}
                              enableArrayValue
                              placeholder="10.1.0.0/10&#10;10.1.1.5"
                            />
                            <Field
                              name="RuleEntry.Source.PrefixLists"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.PrefixList`)}
                              items={tableOption.prefixlists}
                              enableArrayInput
                              enableMultiSelect
                            />
                            <Field
                              name="RuleEntry.Source.Countries"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.Countries`)}
                              items={tableOption.countries}
                              enableMultiSelect
                              enableArrayInput
                            />
                            <Field
                              name="RuleEntry.Source.Feeds"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.Feeds`)}
                              items={tableOption.ipFeedlists}
                              enableMultiSelect
                              enableArrayInput
                            />
                          </>;
                        } else return <></>;
                      }}
                    </FormSpy>

                  </FormControl>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.Destination`)}
              subtitle={translate(`resources.rules.fields.DestinationSubtitle`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.Destination.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" },
                        { label: translate(`resources.rules.fields.excludeNegateMatch`), value: "exclude" },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        if (['match', 'exclude'].includes(values?.RuleEntry?.Destination?.Type)) {
                          return <>
                            <Field
                              name="RuleEntry.Destination.Cidrs"
                              row
                              // @ts-ignore
                              component={PANWDSTextarea}
                              title={translate(`resources.rules.fields.Cidrs`)}
                              note={translate(`resources.rules.fields.CidrsNote`)}
                              validate={multilineUniqueIpV4CIDRS}
                              enableArrayValue
                              placeholder="10.1.0.0/10&#10;10.1.1.5"
                            />
                            <Field
                              name="RuleEntry.Destination.PrefixLists"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.PrefixList`)}
                              items={tableOption.prefixlists}
                              enableArrayInput
                              enableMultiSelect
                            />
                            <Field
                              name="RuleEntry.Destination.FqdnLists"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.FQDNList`)}
                              items={tableOption.fqdnlists}
                              enableMultiSelect
                              enableArrayInput
                            />
                            <Field
                              name="RuleEntry.Destination.Countries"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.Countries`)}
                              items={tableOption.countries}
                              enableMultiSelect
                              enableArrayInput
                            />
                            <Field
                              name="RuleEntry.Destination.Feeds"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.Feeds`)}
                              items={tableOption.ipFeedlists}
                              enableMultiSelect
                              enableArrayInput
                            />
                          </>;
                        } else return <></>;
                      }}
                    </FormSpy>

                  </FormControl>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={decode(translate(`resources.rules.fields.Application`))}
              subtitle={translate(`resources.rules.fields.ApplicationSubtitle`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.Applications.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        if (values?.RuleEntry?.Applications?.Type === 'match') {
                          return <>
                            <Field
                              name="RuleEntry.Applications.Apps"
                              row
                              // @ts-ignore
                              component={AppsModalComponent}
                              title={translate(`resources.rules.fields.Applications`)}
                              validate={composeValidators(isAppsArray)}
                            />
                          </>
                        } else return <></>;
                      }}
                    </FormSpy>

                  </FormControl>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.URLFiltering`)}
              subtitle={translate(`resources.rules.fields.URLFilteringSubtitles`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.Category.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" }
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        if (values?.RuleEntry?.Category?.Type === 'match') {
                          return <>
                            <Field
                              name="RuleEntry.Category.URLCategoryNames"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.URLCategory`)}
                              items={tableOption.urlcategories}
                              enableArrayInput
                              enableMultiSelect
                            />
                            <Field
                              name="RuleEntry.Category.Feeds"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.Feeds`)}
                              items={tableOption.urlFeedlists}
                              enableMultiSelect
                              enableArrayInput
                            />
                          </>;
                        } else return <></>;
                      }}
                    </FormSpy>

                  </FormControl>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.ProtocolAndPorts`)}
              subtitle={translate(`resources.rules.fields.ProtocolAndPortsSubtitles`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.Protocol"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.applicationDefault`), value: "application-default" },
                        { label: translate(`resources.rules.fields.select`), value: "select" },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="application-default"
                    />

                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        if (values?.RuleEntry?.Protocol === 'select') {
                          return <Field
                            name="RuleEntry.ProtPortList"
                            // @ts-ignore
                            component={PANWDSSelectsAndInput}
                            options={[
                              { value: "TCP" },
                              { value: "UDP" },
                            ]}
                            selectLabel={translate(`resources.rules.fields.Protocol`)}
                            textLabel={translate(`resources.rules.fields.PortNumber`)}
                            textNote={translate(`resources.rules.fields.ProtocolPortNote`)}
                            required
                            validate={composeValidators(isRequired, isProtocolAndPortArray)}
                            formatter={(value: any[]) => {
                              if (value.forEach) {
                                let protPort: any = {};
                                value.forEach((data: any) => {
                                  if (data.split(":")[0] in protPort) {
                                    protPort[data.split(":")[0]] += `,${data.split(":")[1]}`;
                                  } else {
                                    protPort[data.split(":")[0]] = data.split(":")[1];
                                  }
                                });
                                let protPortItems = [];
                                for (let port in protPort) {
                                  protPortItems.push([port, protPort[port]]);
                                }
                                return protPortItems;
                              }
                              return value;
                            }}
                            changeformatter={(items: any) => {
                              let protPorts = items.map((item: any) => item[1].split(',').map((port: string) => `${item[0]}:${port}`));
                              return protPorts.flat();
                            }}
                          />;
                        } else return <></>;
                      }}
                    </FormSpy>

                  </FormControl>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.Action`)}
              subtitle={translate(`resources.rules.fields.ActionSubtitle`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} className="noLeftPadding">
                  <FormControl>
                    <Field
                      name="RuleEntry.Action"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.allow`), value: "Allow" },
                        { label: translate(`resources.rules.fields.deny`), value: "DenySilent" },
                        { label: translate(`resources.rules.fields.resetServer`), value: "DenyResetServer" },
                        { label: translate(`resources.rules.fields.resetBoth`), value: "DenyResetBoth" },
                      ]}
                      title={translate(`resources.rules.fields.Action`)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntry.DecryptionRuleType"
                      // @ts-ignore
                      component={PANWDSSelect}
                      defaultValue={"None"}
                      title={translate(`resources.rules.fields.DecryptionRuleType`)}
                      items={[
                        { text: translate(`resources.rules.fields.none`), value: "None" },
                        { text: translate(`resources.rules.fields.outbound`), value: "SSLOutboundInspection" },
                        { text: translate(`resources.rules.fields.inbound`), value: "SSLInboundInspection" },
                        // { text: translate(`resources.rules.fields.inboundNoInspect`), value: "SSLInboundNoInspection" },
                        { text: translate(`resources.rules.fields.outboundNoInspect`), value: "SSLOutboundNoInspection" },
                      ]}
                      dataMetrics="cloudngfw-rules-create-field-decryption-rule-type"
                    />
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        if (values?.RuleEntry?.DecryptionRuleType === 'None' || values?.RuleEntry?.DecryptionRuleType === 'SSLOutboundInspection' || values?.RuleEntry?.DecryptionRuleType === 'SSLOutboundNoInspection') {
                          delete values?.RuleEntry?.InboundInspectionCertificate
                        }

                        if (values?.RuleEntry?.DecryptionRuleType === 'SSLInboundInspection') {
                          const validSC = !!tableOption.signedCertificates.length;

                          if (!validSC) {
                            const profileUrl = RouteUri.CertificateList.replace(":rulestackname", ruleStackName);
                            return (<Typography className={classes.error}>
                              <ErrorIcon className={classes.errorIcon} />
                              {translate(`resources.rules.fields.InboundInspectCertError.prefix`)}<Link to={profileUrl} className={classes.linkText}>{translate(`resources.rules.fields.InboundInspectCertError.link`)}</Link>{translate(`resources.rules.fields.InboundInspectCertError.suffix`)}
                            </Typography>
                            );
                          }

                          return <Field
                            name="RuleEntry.InboundInspectionCertificate"
                            // @ts-ignore
                            component={PANWDSSelect}
                            title={translate(`resources.rules.fields.InboundInspectCert`)}
                            items={tableOption.signedCertificates.map((cert: string) => ({ text: cert, value: cert }))}
                            required
                            validate={composeValidators(isRequired)}
                            dataMetrics="cloudngfw-rules-create-field-inbound-inspection-certificate"
                          />;
                        }
                        if (values?.RuleEntry?.DecryptionRuleType === 'SSLOutboundInspection') {
                          const trust = rulestackData.RuleStackCandidate?.Profiles?.OutboundTrustCertificate;
                          const unTrust = rulestackData.RuleStackCandidate?.Profiles?.OutboundUntrustCertificate;
                          if (trust || unTrust) {
                            return (<>
                              <Typography className={classes.description}><b>UnTrust Certificate:</b> {unTrust ?? "N/A"}</Typography>
                              <Typography className={classes.description}><b>Trust Certificate:</b> {trust ?? "N/A"}</Typography>
                            </>);
                          }
                          const profileUrl = RouteUri.SecurityServices.replace(":rulestackname", ruleStackName);
                          return (<Typography className={classes.error}>
                            <ErrorIcon className={classes.errorIcon} />
                            You haven't set up the Outbound Decryption profile yet.
                            Please edit the <Link to={profileUrl} className={classes.linkText}>Outbound Decryption</Link> in security profiles.
                          </Typography>);
                        }
                        return <></>;
                      }}
                    </FormSpy>

                  </FormControl>
                </Grid>

                <Grid item xs={12} className="noLeftPadding">
                  <FormControl>
                    <PANFormLabel>
                      {translate(`resources.rules.fields.Logging`)}
                    </PANFormLabel>
                    <Field
                      name="RuleEntry.Logging"
                      // @ts-ignore
                      component={PANWDSCheckboxField}
                      label={translate(`resources.rules.fields.Enabled`)}
                      type="checkbox"
                    />
                  </FormControl>
                </Grid>
              </Row>
            </PANTile>
          </Grid>
        </Fragment>
      </PANWDSForm >
    </>
  );
};

export default withRouter(RulesCreate);
