import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import PrefixCreate from './PrefixCreate';
import PrefixEdit from './PrefixEdit';
import PrefixList from './PrefixList';

const prefix = {
    create: PrefixCreate,
    edit: PrefixEdit,
    list: PrefixList,
    icon: InvoiceIcon,
};

export default prefix;
