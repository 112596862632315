import React, { useEffect, useState } from 'react';
import { useTranslate } from '../../../../../customHooks';
import { useLazyGetRulesByRuleStackQuery } from '../../../../../redux/services/rules-service';
import { toast } from '../../../../../components';
import Skeleton from '@mui/material/Skeleton';

interface SecurityPoliciesProps {
    firewall: any;
    managedBy: string;
    styles: any;
    isLoading: boolean;
}

const SecurityPoliciesOverview: React.FC<SecurityPoliciesProps> = ({ firewall, managedBy, styles, isLoading }) => {
    const translate = useTranslate();
    const isRuleStack = managedBy === "Local Rulestack";
    const ruleListName = isRuleStack ? "LocalRule" : "";
    const [totalRules, setTotalRules] = useState<number>(0);
    let [trigger, { data, error, isLoading: rulesLengthLoading }] = useLazyGetRulesByRuleStackQuery();

    const fetchAllRules = async (nextToken?: string) => {
        try {
            data = await trigger({ ruleStackName: firewall?.RuleStackName, ruleListName, NextToken: nextToken }).unwrap();
            const newRulesCount = data.ruleEntries.length;
            setTotalRules((prevCount: any) => prevCount + newRulesCount);

            if (data.nextToken) {
                fetchAllRules(data.nextToken);
            }
        } catch (error: any) {
            toast.error(`${error?.code}: ${error?.error}`, {toastId: "rules-list"});
        }
    };

    useEffect(() => {
        if (isRuleStack) {
            fetchAllRules();
        }
    }, [isRuleStack]);
    
     /* TODO: Needs a revisit with response data and rules click redirect url*/
    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.securityPolicies`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.managedBy`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : managedBy}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.localRulestack`)}</strong>
                    <span className={styles.flexRowValues}>
                        {isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : (
                            firewall?.RuleStackName ? (
                                <a href={`/rulestack/${firewall?.FirewallId}`} className="text-blue-500 underline"> {firewall?.RuleStackName}</a>
                            ) : (
                                "N/A"
                            )
                        )}
                    </span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.localRulestackStatus`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : "N/A"}</span>
                </div>
                {isRuleStack && (
                    <div className={styles.flexRow}>
                        <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.numberOfRules`)}</strong>
                        <span className={styles.flexRowValues}>
                            {rulesLengthLoading ? <Skeleton variant="text" width="50px" height="30px" /> : (
                                <a href={`/firewall/${firewall?.FirewallId}/rules`} className="text-blue-500 underline"> {totalRules}</a>
                            )} 
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SecurityPoliciesOverview;