import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import Skeleton from '@mui/material/Skeleton';

interface LogMetricsProps {
    firewall: any;
    styles: any;
    isLoading: boolean;
}

const LogsMetricsOverview: React.FC<LogMetricsProps> = ({ firewall, styles, isLoading }) => {
    const translate = useTranslate();
    
    /* TODO: Needs a revisit with response data*/
    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.logMetrics.title`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logDestinationType`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.UserID?.UserIDConfig}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logType`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.UserID?.UserIDStatus}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.policyType`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.UserID?.EndpointId}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.logMetrics.logDestination`)}</strong>
                    <span className={styles.flexRowValues}>{isLoading ? <Skeleton variant="text" width="50px" height="30px" /> : firewall?.UserID?.EndpointId}</span>
                </div>
            </div>
        </div>
    );
};

export default LogsMetricsOverview;