import {Subtitle, Banner} from "@panwds/react-ui";
import {useTranslate} from "../../../../customHooks";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm, useFormState} from "react-final-form";
import {
    PANWDSCheckboxField,
    PanwdsTableLightWithFilters,
    PANWDSRadioField, PANWDSInput
} from "../../../../components/PANWDSElements";
import {Row} from "../../../../components/FormElements";
import * as React from "react";
import {useMemo, useState} from "react";
import {isEmpty} from "lodash";
import {composeValidators, isRequired, isValidIPAMPoolId} from "../../../../utils/validate";

const Index = () => {
    const formState = useFormState();
    const translate = useTranslate();

    const values = formState.values;

    const egressNatPublicIpColumns = useMemo(() => [
        {
            accessor: 'IPAddress',
            Header: 'Public IP',
            columnSize: 5,
            Cell: ({ row }: any) => <span>{row?.original?.IPAddress}</span>,
        },
        {
            accessor: 'IpStatus',
            Header: 'Used',
            Cell: ({ row }: any) => <span>{row?.original?.IPStatus === 'FREE' ? 'NO' : 'YES'}</span>,
        }
        ], []);

    return (
        <>
            <Row>
                <Grid item sm={12} className="noPadding" style={{paddingBottom: "1rem"}}>
                    <FormControl>
                        <Field
                            name="Firewall.EgressNAT.Enabled"
                            // @ts-ignore
                            component={PANWDSCheckboxField}
                            label={translate(`resources.firewalls.fields.EgressNAT.EnableEgressNat`)}
                            type="checkbox"
                            disabled={!values.Firewall?.EgressNAT || formState?.values?.FirewallUpdating}
                            dataMetrics={"cloudngfw-firewall-edit-egressNatEnabled"}
                            muted={translate(`resources.firewalls.fields.EgressNAT.EnableEgressNatMutedText`)}
                        />
                    </FormControl>
                </Grid>
            </Row>
            {(!isEmpty(values.Firewall?.EgressNAT) && values?.Firewall?.EgressNAT.Enabled === true) &&
                <>
                    <Subtitle as={"div"} appearance={"semibold"} size={"sm"} addClassName="tw-pb-2">Public
                        IPs</Subtitle>
                    <Grid item sm={12}>
                        <FormControl>
                            <Field
                                name="Firewall.EgressNAT.Settings.IPPoolType"
                                row
                                // @ts-ignore
                                component={PANWDSRadioField}
                                options={[
                                    {
                                        label: translate(`resources.firewalls.fields.EgressNAT.AWSService`),
                                        value: "AWSService"
                                    },
                                    {label: translate(`resources.firewalls.fields.EgressNAT.BYOIP`), value: "BYOIP"}
                                ]}
                                required
                                validate={isRequired}
                                initValue={values?.Firewall?.EgressNAT.Enabled ? 'AWSService' : 'BYOIP'}
                                defaultValue={values?.Firewall?.EgressNAT?.Settings?.IPPoolType}
                                dataMetrics={"cloudngfw-firewall-update-egress-nat-public-ips"}
                                disabled={formState?.values?.FirewallUpdating}
                                customStyle={{marginBottom: 0}}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12} className="tw-pt-2 tw-pb-2">
                        <Banner type="inline" appearance="warning" showIcon>
                            {translate(`resources.firewalls.fields.EgressNAT.IPPoolTypeBannerText`)}
                        </Banner>
                    </Grid>
                    {values?.Firewall?.EgressNAT?.Settings?.IPPoolType === 'BYOIP' &&
                        <Grid item xs={12} sm={6} className="tw-pt-2">
                            <FormControl fullWidth>
                                <Field
                                    title={'IPAM Pool Id'}
                                    name="Firewall.EgressNAT.Settings.IPAMPoolId"
                                    placeholder={"Please enter a IPAM pool id (e.g., ipam-pool-0123456789abcdef)"}
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    validate={composeValidators(isRequired, isValidIPAMPoolId)}
                                    readOnly={!isEmpty(formState?.initialValues?.Firewall?.EgressNAT?.Settings?.IPAMPoolId)}
                                    required
                                    dataMetrics="cloudngfw-firewall-create-egress-nat-public-own-ips"
                                />
                            </FormControl>
                        </Grid>
                    }
                    {!isEmpty(values?.Status?.PublicIPs) && (formState?.initialValues?.Firewall?.EgressNAT?.Settings?.IPPoolType === values?.Firewall?.EgressNAT?.Settings?.IPPoolType) &&
                        <PanwdsTableLightWithFilters
                            columns={egressNatPublicIpColumns}
                            gridData={values?.Status?.PublicIPs || []}
                            dataTestId={"cloudngfw-firewall-egress-nat-public-ip-table"}
                            title={''}
                            showToolbar={false}
                            showTileTitle={false}
                            multiSelect={false}
                            searchFilterRequired={false}
                            tableContainerStyle={{padding: '0 1.5rem 0 0'}}
                        />}
                </>
            }
        </>
    );
};

export default Index;
