import { FilterComponent } from "../../../components/Filters";
import { getList } from "../../../components/Filters/utilGenerateMethods";

export const filters = [
  {
    Component: FilterComponent,
    label: "Category",
    name: "category",
    required: true,
    items: getList('Properties.Category'),
    // defaultValues: ["general-internet", "networking", "business-application"],
    columnValue: 'Properties.Category',
    dataTestId: "category",
  },
  {
    Component: FilterComponent,
    label: "SubCategory",
    name: "subCategory",
    required: true,
    items: getList('Properties.Subcategory'),
    columnValue: 'Properties.Subcategory',
    dataTestId: "subCategory",
  },
  {
    Component: FilterComponent,
    label: "Technology",
    name: "technology",
    required: true,
    items: getList('Properties.Technology'),
    columnValue: 'Properties.Technology',
    dataTestId: "technology",
  },
  {
    Component: FilterComponent,
    label: "Risk",
    name: "risk",
    required: true,
    items: getList('Properties.Risk'),
    columnValue: 'Properties.Risk',
    dataTestId: "risk",
  },
  {
    Component: FilterComponent,
    label: "Tags",
    name: "tags",
    required: true,
    items: getList('Tag'),
    columnValue: 'Tag',
    dataTestId: "tags",
  },
  {
    Component: FilterComponent,
    label: "Characteristics",
    name: "characteristics",
    required: true,
    items: getList('Characteristics'),
    columnValue: 'Characteristics',
    dataTestId: "characteristics",
  }
];