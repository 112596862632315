import FirewallCreate from './FirewallCreate';
import FirewallEdit from './FirewallEdit';
import FirewallList from './FirewallList';
import FirewallRuleUsage from './FirewallRuleUsage';

const firewalls = {
    create: FirewallCreate,
    list: FirewallList,
    edit: FirewallEdit,
    ruleUsage: FirewallRuleUsage,
};

export default firewalls;