import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    loadingCenter: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
    }
}));

const CircularLoader = (props: any) => {
    const classes = useStyles();
    const { loading } = props;

    return (
        loading &&
            <div className={classes.loadingCenter}>
              <CircularProgress size={25} thickness={4} />
            </div>
    );
}

export default CircularLoader;
