import { useCallback, useEffect, useState } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from '../../components';
import { dataProvider } from '../../dataProvider';
import { nameStyleCursor } from '../../layout/styles';
import { RouteUri } from '../../routeUri';
import { PANWDSTable } from '../../components/PANWDSElements';

const PrefixList = (props: any) => {
  const { permissions } = usePermissions();
  const nameClass = nameStyleCursor();
  const translate = useTranslate();
  const history = useHistory();
  const title = translate(`resources.prefix.name`)

  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken]: any = useState();
  // @ts-ignore
  const [gridRef, setGridRef] = useState({
    current: {
      columns: []
    },
  });

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  const deleteAction = (selected: any) => {
    if (!permissions?.DeletePrefixList) {
      toast.warning(translate("permissions.cantExecute"));
      return;
    }
    if (selected && Array.isArray(selected)) {
      let calls: any = [];
      setLoading(true);
      selected.map((item: any) => {
        if (item.Name) {
          calls.push(dataProvider.delete("prefix", { id: item.Name, previousData: {...item, RuleStackName: ruleStackName} }));
        }
        return item;
      });
      Promise.all(calls)
        .then((responses) => toast.success(translate(`resources.prefix.delete.success`)))
        .catch((e) => toast.error(e?.error, { toastId: "prefix-delete-error" }))
        .finally(() => {
          setLoading(false);
          loadGridData(true);
        });
    }
  }

  const getcolumns = () =>  [
    {
      accessor: 'Name',
      Header: translate(`resources.prefix.fields.Name`),
      columnSize: 2,
      render: ({ row }: any) => <span
        className={nameClass.blueColor}
        onClick={(evt) => onRowClick(row, evt)}
        >
          {row?.original?.Name}
      </span>,
    },
    {
      accessor: 'PrefixListCandidate.PrefixList',
      Header: translate(`resources.prefix.fields.PrefixList`),
      columnSize: 5,
      render: ({ row }: any) => {
        if (row?.original?.PrefixListCandidate?.PrefixList) {
          const list = row?.original?.PrefixListCandidate?.PrefixList.map((entry: any) => {
            return entry
          }).join(", ");
          return <span>{list}</span>
        }
        return null;
      },
    },
    {
      accessor: 'PrefixListCandidate.Description',
      Header: translate(`resources.prefix.fields.Description`),
      columnSize: 5,
      render: ({ row }: any) => {
        if (row?.original?.PrefixListCandidate?.Description) {
          return <span>{row?.original?.PrefixListCandidate?.Description}</span>
        }
        return null;
      },
    }
  ];

  const loadGridData = (needRefresh=false) => {

    if (!permissions?.ListPrefixList) {
      if (permissions && !permissions?.ListPrefixList) {
        // TODO toast is not showing, but if we add toast container it will show
        // many times until permissions is loaded, need to rethink this logic
        toast.warning(translate("permissions.cantView"));
      }
      setLoading(false);
      return;
    }
    if ((gridData.length && nextToken) || !gridData.length || needRefresh) {
      if (!gridData.length || needRefresh) {
        setLoading(true);
      }
      dataProvider.getList("prefix", {
        data: { RuleStackName: ruleStackName, NextToken: (nextToken) ? nextToken : undefined }
      }).then(async (response: any) => {
        if (response.data) {
          //@ts-ignore
          let responseObj = response.data;
          if (needRefresh){
            setGridData(responseObj);
          } else {
            //@ts-ignore
            setGridData([...gridData, ...responseObj]);
          }
          setNextToken(response?.nextToken);
        } else {
          toast.error(response?.error, { toastId: "prefix-getList" });
        }
      })
      .catch((e:any) => {
        if (e?.noToast) {
          return;
        }
        toast.error(e?.error, { toastId: "prefix-getList" });
      })
      .finally( () => setLoading(false) );
    }
  }

  useEffect(() => {
    return () => {
      dataProvider.abort('prefix');
    }
  }, []);

  const onRowClick = useCallback((rowProps, event) => {
    if (!permissions?.DescribePrefixList) {
      return;
    }
    history.push(
      RouteUri.PrefixListEdit.replace(":rulestackname", ruleStackName)
        .replace(":prefixlistname", rowProps.original.Name));
  }, [history, ruleStackName, permissions]);

  const toolbarActions: any[] = [];
  if(permissions?.DeletePrefixList){
    toolbarActions.push({
      title: translate(`common.actions`),
      dataMetrics: "cloudngfw-prefix-actions-btn",
      actionsMap: [{ menuText: translate(`common.delete`), handleAction: deleteAction, dataMetrics: "cloudngfw-prefix-delete-btn" },]
    });
  }
  if(permissions?.CreatePrefixList){
    toolbarActions.push({
      title: translate(`resources.prefix.fields.CreateButton`),
      actionsMap: [],
      dataMetrics: "cloudngfw-prefix-create-btn",
      handleButtonAction: () => history.push(RouteUri.PrefixListCreate.replace(":rulestackname", ruleStackName)),
      type: 'button',
      appearance: 'primary'
    });
  }

  return (
    <>
      <PANWDSTable
        setHandle={setGridRef}
        loadGridData={loadGridData}
        infiniteScroll={false}
        overflowTable={true}
        permissions={permissions}
        columns={getcolumns()}
        gridData={gridData}
        showSelectGroupBy={false}
        title={title}
        subtitle={translate(`resources.prefix.fields.ListSubtitle`)}
        idProperty={"RuleStackName"}
        showToolbar={true}
        dropDownActionsArray={toolbarActions}
        showTileTitle={true}
        emptyTitle={translate(`resources.prefix.fields.EmptyTitle`)}
        emptySubtitle={translate(`resources.prefix.fields.EmptySubtitle`)}
        loading={loading}
        isBackgroundFetching={nextToken}
        singleSelect={false}
        multiSelect={true}
        useOldToolbarButtons={true}
        offsetTableHeight={305}
        tableMinHeight={550}
        searchFilterRequired={true}
        dataMetrics="cloudngfw-prefix-table"
        dataTestId="cloudngfw-prefix-table"
      />
    </>
  );
};

export default withRouter(PrefixList);
