import { FormControl, Grid, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState, Fragment } from "react";
import { Field } from "react-final-form";
import { useHistory, withRouter } from "react-router-dom";
import { Button as PANWDSButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from "../../components";
import { Row } from "../../components/FormElements";
import {
    PANWDSInput,
    PANWDSTextarea,
    PANWDSSelect,
    PANWDSForm,
    PANWDSBreadcrumbs
} from '../../components/PANWDSElements';
import { dataProvider } from "../../dataProvider";
import { isCategoryName, isRequired, isUrlList, maxLength, composeValidators } from '../../utils/validate';
import {ApplicationConfigManager} from "../../types";
import {useTranslate} from '../../customHooks';

export const styles = {
  price: { width: "7em" },
  width: { width: "7em" },
  height: { width: "7em" },
  stock: { width: "7em" },
  widthFormGroup: { display: "inline-block" },
  heightFormGroup: { display: "inline-block", marginLeft: 32 },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

const CategoryCreate = (props: any) => {
  //const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const [record, setRecord] = useState<any>({});
  const [submitting, setSubmitting] = useState<boolean>(false);

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;
  const [predefinedUrlCategories, setPredefinedUrlCategories] = useState([]);

  const CategoryCreateToolbar = (toolbarProps: any) => {
    const classes = useStyles();

    return (
      <div className={classes.toolbar}>
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-category-create-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          label={translate("ra.action.create")}
          redirect={false}
          submitOnEnter={true}
          className={classes.button}
          loading={submitting}
          dataMetrics="cloudngfw-category-create-save-button"
          {...toolbarProps}
        />
      </div>
    );
  };

  const save = useCallback(
    async (values) => {
      if (record) {
        values = { ...values, RuleStackName: ruleStackName }
      }
      setSubmitting(true);
      try {
          await dataProvider.create("category", { data: values });
        //toast.success("Success");
        history.goBack();
      } catch (error: any) {
        toast.error(error?.error, { toastId: "category-create" });
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  );

  //@ts-ignore
  const notPredefined = (value: string) => {
    //@ts-ignore
    return (predefinedUrlCategories && ! predefinedUrlCategories.includes(value) ? undefined : 'Name cannot be a predefined URL category.')
  };

  useEffect(() => {
    dataProvider.get("predefinedUrlCategory", { data: record }).then((response: any) => {
      if (response?.data) {
          //@ts-ignore
          let rulestacks = response.data;
          if (rulestacks) {
            setPredefinedUrlCategories(rulestacks);
            return;
          }
      } else {
          toast.error("Error", { toastId: "predefinedUrlCategory-get" });
      }
  })
      .catch(() => {
          toast.error("Error", { toastId: "predefinedUrlCategory-get" });
      });
  }, []);

  return (
    <>
    <PANTitle divider={false} />
    <PANWDSBreadcrumbs
      mapping={{
        [`rulestacks`]: `${translate(`resources.ruleStacks.name`)} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
        [ruleStackName]: ruleStackName,
        [`objects`]: translate(`resources.objects.name`),
        [`categories`]: translate(`resources.category.name`),
        create: translate(`resources.category.fields.CreateButton`)
      }}
    />
    <PANTitle title={translate(`resources.category.fields.CreateButton`)} divider />

        <PANWDSForm
            onSubmit={save}
            toolbar={<CategoryCreateToolbar/>}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: 'auto' }}>
                    <PANTile title={translate(`resources.category.fields.General`)} subtitle={translate(`resources.category.fields.CreateSubtitle`)} size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        required
                                        title={translate(`resources.category.fields.Name`)}
                                        dataMetrics="cloudngfw-category-create-field-name"
                                        validate={composeValidators(isRequired, isCategoryName, maxLength(29), notPredefined)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.category.fields.Description`)}
                                        dataMetrics="cloudngfw-category-create-field-description"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="URLTargets"
                                        required
                                        // @ts-ignore
                                        component={PANWDSTextarea}
                                        enableArrayValue
                                        note="Enter one value per line"
                                        rowsMin={1}
                                        title={translate(`resources.category.fields.URLTargets`)}
                                        dataMetrics="cloudngfw-category-create-field-urltargets"
                                        validate={composeValidators(isRequired, isUrlList, maxLength(255))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="Action"
                                        // @ts-ignore
                                        component={PANWDSSelect}
                                        title={translate(`resources.category.fields.Action`)}
                                        dataMetrics="cloudngfw-category-create-field-action"
                                        items={[
                                            { text: "Alert", value: "alert" },
                                            { text: "Allow", value: "allow" },
                                            { text: "Block", value: "block" },
                                            { text: "None", value: "none" },
                                        ]}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>

        </PANWDSForm>
    </>
  );
};

export default withRouter(CategoryCreate);
