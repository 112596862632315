import { makeStyles } from "@material-ui/core";
import { Button, Select } from "@panwds/react-ui";
import { PANFieldContainer, PANHelpText } from "../FormElements/styles";
import {isEmpty} from "lodash";
import { RemoveIcon } from '@panwds/icons';
import {useEffect} from 'react';
import {PANWDSLabel} from "./PANWDSLabel";
import classNames from "classnames";
import {SelectItems} from '../../types'
import {find} from 'lodash';

const useStyles = makeStyles((theme) => ({
    error: {
        border: '1px solid #D13C3C',
        borderRadius: 4,
    },
    errorText: {
        color: "#D13C3C",
    },
    buttonText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: "100%",
        textAlign: "left",
    },
    readOnly: {
        "--tw-bg-opacity": 1,
        backgroundColor: "rgb(244 245 245/var(--tw-bg-opacity))",
        opacity: 1,
    },
    button: {
        color: "#333333",
        "&:hover": {
            color: "#333333",
        }
    }
}));

export const PANWDSSelect = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    const classes = useStyles();
    const existerror = !!(touched && error);
    let { items = [], title, required, enableMultiSelect, enableArrayInput, dataMetrics = undefined, loading = false,
        placeholder = '', disabled, allowEmpty = false, defaultSingleFirst=false, readOnly = false }: {items: SelectItems[]} = props;
    let value = inputProps.value || inputProps.value === 0 ? inputProps.value : [];
    if (typeof value === 'string' || typeof value === 'number') {
        value = [{value}]
    }

    useEffect(() => {
        if (value?.length === 0 && defaultSingleFirst && items?.length === 1) {
            inputProps.onChange(items[0].value);
        }
    }, [items, value]);

    if (enableArrayInput) {
        items = items.map((v: string) => ({ value: v }));
        value = value.map((v: string) => ({ value: v }));
    }

    const onClear = (event: any) => {
      event.stopPropagation();
      inputProps.onChange("");
    };

    return <PANFieldContainer>
        {title && <PANWDSLabel disabled={disabled} required={required} title={title}/>}
        <Select
            fitToItemWidth
            items={items}
            selectedItem={value}
            onChange={({ selectedItem, selectedItems }) => {
                if (enableArrayInput) {
                    inputProps.onChange(selectedItems.map(({ value }) => value))
                } else {
                    inputProps.onChange(selectedItem.value);
                }
            }}
            dataMetrics={dataMetrics}
            enableMultiSelect={enableMultiSelect}
            button={
                <Button addClassName={classNames(classes.button, readOnly && classes.readOnly)} isMenu fullWidth dataMetrics={dataMetrics ? dataMetrics + '-button' : title + '-button'}>
                    <span className={classes.buttonText}>
                        {value.length
                            ? value.map((item: any, data: any) => {
                                let selectedItem = find(items, function(o)  {return o.value === item.value;});
                                return selectedItem && selectedItem.text ? String(selectedItem.text) : String(selectedItem?.value || item.value);
                            }).join(", ")
                            : placeholder ? placeholder : "Select"}
                    </span>
                  {!enableMultiSelect && allowEmpty && !isEmpty(value) &&
                    <span onClick={(event: any) => onClear(event)}><RemoveIcon size={"md"}/></span>}
                </Button>
            }
            formatOption={{
                height: (item, defaultSize) => defaultSize * 1.2,
                view: (item) => (
                  <div className="tw-flex tw-gap-2 tw-items-center">
                    {item.text ? item.text : item.value}
                  </div>
                ),
              }}
            fullWidth
            isLoading={loading}
            disabled={disabled || readOnly}
        />
        {existerror && (<PANHelpText>{error}</PANHelpText>)}
    </PANFieldContainer>;
};
