import {
  Button,
  ButtonGroup,
  ClickAwayListener, Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import ArrowDownOutlined from '@material-ui/icons/ArrowDropDownOutlined';
import { useRef, useState } from 'react';
import { ISplitButtonAction } from '../../types';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles((theme) => ({
  ButtonContainer: {
    boxSizing: "border-box"
  },
  DropDownButton: {
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "normal",
    border: "1px solid #DADBDB",
    padding: "4px",
    color: '#006FCC',
    background: "#FFFFFF",
    marginRight: theme.spacing(1),
    width: "180px",
    textTransform: "capitalize",
    "&>span": {
      justifyContent: "center",
    }
  },
  Label: {
    color: "#006FCC",
    fontSize: "12px",
    fontWeight: "normal",
    marginLeft: "4px"
  },
  openInNewIcon: {
    width: '12px',
    height: '12px',
    marginLeft: '3px',
    color: '#006FCC',
  },
  externalLinkText: {
    color: '#006FCC',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 600,
    marginLeft: '20px'
  }
}));

export interface ISplitButtonProps {
  title: string,
  buttons: ISplitButtonAction[],
  disabled?: boolean,
  icon?: 'external'
};

export const SplitButton = (props : ISplitButtonProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleToggle = () => {
    setOpen((prevOpen: any) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const isDisabled = (value:any) => {
    if (value===undefined){
      return false;
    }
    return !value;
  }
  return (
    (props && props.buttons) && (
    <section className={classes.ButtonContainer}>
      <ButtonGroup ref={anchorRef} aria-label="split button">
          <Button
            className={classes.DropDownButton}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            disabled={props.disabled}
            onClick={handleToggle}
          >
            {props.title}
            <ArrowDownOutlined />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current || null} role={undefined} transition disablePortal style={{ zIndex: 100, }}>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right bottom',
              }}>
              <Paper elevation={2}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList style={{ padding: 0, borderRadius: 2, width:200 }} id="split-button-menu">
                    {props.buttons &&
                     props.buttons.map((option: any, index: any) => (
                        <MenuItem
                          key={index}
                          selected={index === selectedIndex}
                          onClick={(event) => {
                            setSelectedIndex(index);
                            setOpen(false);
                            option.handleAction(event, index);
                          }}
                          disabled={isDisabled(option.enabled)}
                        >
                        <div className={props.icon === 'external' ? classes.externalLinkText : ''}>{option.menuText}</div>
                        {props.icon === 'external' && <OpenInNewIcon className={classes.openInNewIcon}/>}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </section>
  ));
}

export default SplitButton;
