import { RouteUri } from '../routeUri';
import { ApplicationConfigManager } from '../types';

export const getRuleStackEndpoint = (ruleStackName: any) => {
    let newUrlToClick = "";
    newUrlToClick = RouteUri.RuleList.replace(":rulestackname", ruleStackName);
    return newUrlToClick;
}

export const getFirewallEndpointLink= (firewallName: any, accountId: any) => {
    let newTabFormat = "";
    newTabFormat = RouteUri.NGFirewallSettings.replace(":firewallname", firewallName);
    let newTabToClick = `${newTabFormat}?AccountId=${accountId}`
    return newTabToClick;
}

export const getRegion = () => {
    const region = ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionCode;
    return region;
}

export const getLogsMetricsEndpoints = (logDestinationType?: string, logDestination?: string, type?: 'Logs' | 'Metrics') => {
    //"S3" | "CloudWatchLogs" | "KinesisDataFirehose" | "metrics"
    let urlFormat = '';
    let region = getRegion();
    if(logDestinationType === 'CloudWatchLogs') {
        urlFormat = `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/${logDestination}`;
    }
    if(logDestinationType === 'S3') {
        urlFormat = `https://s3.console.aws.amazon.com/s3/buckets/${logDestination}?region=${region}&tab=objects`;
    } 
    if(logDestinationType === 'KinesisDataFirehose') {
        urlFormat = `https://${region}.console.aws.amazon.com/kinesis/home?region=${region}#/streams/details/${logDestination}/monitoring`;
    }
    if(type === 'Metrics' && logDestinationType !== undefined) {
        urlFormat = `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#metricsV2:graph=~()`;
    }
    return window.open(urlFormat, '_blank');
}

export const getCFTEndpoint = () => {
    let cftEndpoint = '';
    let region = getRegion();
    cftEndpoint = `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks?filteringStatus=active&filteringText=&viewNested=true&hideStacks=false`;
    return cftEndpoint;
}

export const getXAccountRoleNames = (roleName: string) => {
    let roleNameLinkLastIndex = roleName.substring(roleName.lastIndexOf('/') + 1);
    let region = getRegion();
    let roleNameLink = `https://${region}.console.aws.amazon.com/iamv2/home?${region}#/roles/details/${roleNameLinkLastIndex}`;
    return  window.open(roleNameLink, '_blank');
}


export const getVpcEndpointDags = (vpcId: any) => {
    let vpcEndpoint = '';
    let region = getRegion();
    vpcEndpoint = `https://${region}.console.aws.amazon.com/vpc/home?region=${region}#VpcDetails:VpcId=${vpcId}`;
    return vpcEndpoint;
} 