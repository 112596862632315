import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from '../../customHooks';
import { Body, Button, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '@panwds/react-ui';
import { ExclamationCircleIcon } from '@panwds/icons';
import { useState } from 'react';
import { PANWDSForm, PANWDSSelectWithSearch } from '../../components/PANWDSElements';
import { FormControl } from '@material-ui/core';
import { OnChange } from 'react-final-form-listeners';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
    iconedParagraph: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        gap: '10px',
    },
    toolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: '16px',
        borderTop: "1px solid #DADBDB",
        marginTop: 16,
    },
    modalNote: {
        marginTop: '10px',
    },
    simpleList: {
        border: '1px solid #D6D8DA',
        margin: '10px 0',
        width: 'auto',
        '& div': {
            padding: '6px 16px',
            textOverflow: 'ellipsis',
        },
        maxHeight: '200px',
        overflowX: "hidden",
        overflowY: "scroll"
    },
    oddItem: {
        backgroundColor: '#F4F5F5',
    },
    marginRight: {
        marginRight: '10px'
    }
}));


export const NotificationModal = ({ onConfirm, close, description, note, taskInProgress }: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (<>
        <Modal
            size="sm"
            confirmClose={false}
            onClose={close}
            isOpen
            style={{ top: "30%" }}
        >
            <ModalHeader title={translate(`resources.integrations.modal.notification`)} enableClose={false} />
            <ModalBody overflow>
                <Body>
                    {description}
                    {note &&
                        <div className={classes.modalNote}>
                            <strong>{translate(`resources.integrations.note`)}: </strong>{note}
                        </div>
                    }
                </Body>
            </ModalBody>
            <ModalFooter>
                <ModalCloseButton
                    disabled={taskInProgress}>{translate(`resources.integrations.cancel`)}</ModalCloseButton>
                <Button onClick={() => onConfirm()} appearance="primary" disabled={taskInProgress}>
                    {translate(`resources.integrations.confirm`)}
                </Button>
            </ModalFooter>
        </Modal>
    </>);
};

export const NotificationCMModal = ({ onConfirm, close, description, note, taskInProgress }: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (<>
        <Modal
            size="sm"
            confirmClose={false}
            onClose={close}
            isOpen
            style={{ top: "30%" }}
        >
            <ModalHeader title={translate(`resources.integrations.modal.notification`)} enableClose={false} />
            <ModalBody overflow>
                <Body>
                    {description}
                    {note &&
                        <div className={classes.modalNote}>
                            <strong>{translate(`resources.integrations.note`)}: </strong>{note}
                        </div>
                    }
                </Body>
            </ModalBody>
            <ModalFooter>
                <ModalCloseButton
                    disabled={taskInProgress}>{translate(`resources.integrations.cancel`)}</ModalCloseButton>
                <Button onClick={() => onConfirm()} appearance="primary" disabled={taskInProgress}>
                    {translate(`resources.integrations.confirm`)}
                </Button>
            </ModalFooter>
        </Modal>
    </>);
};

const SimpleList = ({ items }: { items: string[] }) => {
    const classes = useStyles();
    return (<div className={classes.simpleList}>
        {items.map((item, index) => <div {...(index & 1 ? { className: classes.oddItem } : {})}>{item}</div>)}
    </div>);
}

export const ErrorUnlinkModal = ({ close, dgs, forceUnlink, taskInProgress }: any) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (<>
        <Modal
            size="sm"
            confirmClose={false}
            onClose={close}
            isOpen
            style={{ top: "30%" }}
        >
            <ModalHeader icon={<ExclamationCircleIcon
                // className="tw-text-gray-600 dark:tw-text-dark-bg"
                size="md"
                color="red"
            />} title={translate(`resources.integrations.modal.warning`)} enableClose={false} />
            <ModalBody overflow>
                <Body>
                    {translate(`resources.integrations.modal.descriptionErrorUnlink`)}
                    <div className={classes.modalNote}>
                        <strong>{translate(`resources.integrations.note`)}: </strong>{translate(`resources.integrations.modal.noteErrorUnlink`)}
                    </div>
                    <SimpleList items={dgs.CloudDGS.map((dg: any) => dg.DGName)} />
                </Body>
            </ModalBody>
            <ModalFooter
                secondaryContent={
                    <Button
                        size="md"
                        appearance="secondary-destructive"
                        onClick={forceUnlink}
                        dataMetrics="cloudngfw-integrations-panorama-force-unlink"
                        disabled={taskInProgress}
                    >
                        {translate(`resources.integrations.force`)}
                    </Button>
                }>
                <Button onClick={close} disabled={taskInProgress || dgs.FMSOnboarded} addClassName={classes.marginRight}>
                    {translate(`resources.integrations.close`)}
                </Button>
            </ModalFooter>
        </Modal>
    </>);
};

export const SelectCDLModal = ({ onConfirm, close }: any) => {
    const translate = useTranslate();
    const items: any = [];
    const [cdl, setCdl] = useState(undefined);

    return (<>
        <Modal
            size="sm"
            confirmClose={false}
            onClose={close}
            isOpen
            style={{ top: "30%" }}
        >
            <ModalHeader title={translate(`resources.integrations.modal.selectCDL`)} enableClose={false} />
            <ModalBody overflow>
                <Body>
                    {translate(`resources.integrations.modal.selectDescription`)}
                    <PANWDSForm toolbar={false} onSubmit={() => {}}>
                        <FormControl fullWidth style={{ marginTop: "10px" }}>
                            <Field
                                name="cdls"
                                // @ts-ignore
                                component={PANWDSSelectWithSearch}
                                items={items}
                                title={translate(`resources.integrations.modal.cdlAccount`)}
                                required
                            />
                            <OnChange name="cdls">
                                {(selected: any[]) => setCdl(selected[0].value)}
                            </OnChange>
                        </FormControl>
                    </PANWDSForm>
                </Body>
            </ModalBody>
            <ModalFooter>
                <ModalCloseButton onClick={close}>{translate(`resources.integrations.cancel`)}</ModalCloseButton>
                {/* <Button onClick={() => onConfirm(cdl)} appearance="primary"> */}
                <Button onClick={close} appearance="primary">
                    {translate(`resources.integrations.confirm`)}
                </Button>
            </ModalFooter>
        </Modal>
    </>);
};
