import { FormControl, Grid, Typography } from "@material-ui/core";
import _, { isEmpty, uniqBy } from "lodash";
import { useCallback, useState, useContext, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router-dom";
import { PANTile, SaveButton, toast } from "../../../components";
import { PANCardField } from "../../../components/FormElements";
import { Vulnerability, AntiSpyware, Antivirus, URLCategory, FileBlocking } from "../../../images";
import { RouteUri } from "../../../routeUri";
import { PANWDSModal, PANWDSSelect } from '../../../components/PANWDSElements';
import { OnChange } from 'react-final-form-listeners';
import { GlobalContext } from "../../../context/global";
import * as DataTypes from "../../../api/FwaasDataTypes";
import { dataProvider } from "../../../dataProvider";
import { usePermissions, useTranslate } from "../../../customHooks";
import CircularLoader from "../../../components/CircularLoader/CircularLoader";
import { Toggle, Tooltip, Title, Subtitle } from "@panwds/react-ui";
import { InfoIcon } from '@panwds/icons';

const SecurityServices = ({ classes, ruleStackName, ...props }: any) => {
  const translate = useTranslate();
  const params = useParams();
  const globalContext = useContext(GlobalContext);
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [record, setRecord] = useState<any>({});
  const { permissions } = usePermissions();
  const [certChoices, setCertChoices] = useState<any[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [updating, setUpdating] = useState(false);

  const describeRulestack = () => {

    //@ts-ignore
    if (! isEmpty(globalContext.state)) {
      // @ts-ignore
      let rulestackData = _.get(globalContext.state, `${ruleStackName}`, {});
      if (! isEmpty(rulestackData)) {
        setRecord({ ...rulestackData });
        return;
      }
    }
    if (permissions?.DescribeRuleStack) {
      dataProvider.describe('ruleStacks', '',
        { RuleStackName: ruleStackName, Running: true })
        .then((res: DataTypes.IFwaasApiResponse) => {
          if (res.data) {
            setRecord({ ...res.data });
          } else {
            toast.error(res?.error, { toastId: `rulestack-describe-${ruleStackName}` });
          }
        }).catch((e: any) => {
          toast.error(e?.error, { toastId: `rulestack-describe-${ruleStackName}` });
        });
    }
  }

  const loadData = useCallback(async () => {
    try {
      const response = await dataProvider.get("certificate", { data: { RuleStackName: ruleStackName } });
      if (response.data) {
        const certs = response.data as string[];
        if (Array.isArray(certs)) {
          const choices = certs.map((ruleStack: any) => ({ text: ruleStack, value: ruleStack }));
          setCertChoices(uniqBy([...certChoices, ...choices], "value"));
        }
      } else {
        toast.error(response.error);
      }
    } catch (error: any) {
      toast.error(error);
    } finally {
      //setDataLoaded(true);
    }
  }, [ruleStackName, certChoices, setCertChoices]);

  useEffect(() => {
      if (!isEmpty(record)) {
        // @ts-ignore
        globalContext.setGlobalState({ [ruleStackName]: { ...props.record } });
      }
      describeRulestack();
      loadData();
  }, []);

  const getFileBlockingProfileLink = () => {
    return RouteUri.FileBlockingProfiles.replace(":rulestackname", ruleStackName);
  }

  const getURLFilteringProfileLink = () => {
    return RouteUri.URLFilteringProfiles.replace(":rulestackname", ruleStackName);
  }

  const onSubmit = async (values: any, form: any) => {
    return;
  };

  const updatingStates = useCallback((keys: string[], status: boolean) => {
    const newState = Object.fromEntries(keys.map((key) => [key, status]));
    setLoading({ ...loading, ...newState });
  }, [loading, setLoading]);

  const handleOnChange = useCallback(async ({ title, key, currentValue, newValue, record, values, isMultiple, keysList, globalContext }: any) => {
    if (!permissions?.UpdateRuleStack && isEmpty(record)) {
      return;
    }
    // TODO this can be handled on the onsubmit method on rulestack edit
    //const record = {...record};
    let data = {};
    if (isMultiple) {
      data = values;
      updatingStates(keysList, true)
    } else {
      data = _.set({}, key, newValue || null);
      updatingStates([key], true);
    }
    _.merge(record.RuleStackCandidate, _.get(data, "RuleStackCandidate"));
    const payload = {
      RuleStackName: _.get(params, "rulestackname"),
      RuleStackEntry: { ...record.RuleStackCandidate },
    } as any;
    payload['RuleStackEntry']['LookupXForwardedFor'] = (payload?.RuleStackEntry?.LookupXForwardedFor) ? "SecurityPolicy" : 'None';
    try {
      const response = await dataProvider.update("ruleStacks", payload);
      if (response.data) {
        toast.success(`${title} profile updated`);
        setRecord({ ...record });
        globalContext.setGlobalState({ [ruleStackName]: { ...record } });
      } else {
        setRecord(_.set(record, key, currentValue));
        toast.error(response?.error, { toastId: `rulestack-update-${_.get(data, "RuleStackCandidate")}` });
      }
    } catch (error: any) {
      setRecord(_.set(record, key, currentValue));
      toast.error(error.error, { toastId: `rulestack-update-${_.get(data, "RuleStackCandidate")}` });
    } finally {
      updatingStates([...(isMultiple ? keysList : [key])], false);
    }
  }, []);

  const handleChange = (e: any) => {
    setOpenConfirm(!openConfirm);
  };

  const update = () => {
    setUpdating(true);
    //@ts-ignore
    const payload = {
      RuleStackName: _.get(params, "rulestackname"),
      RuleStackEntry: { ...record.RuleStackCandidate },
    } as any;
    payload['RuleStackEntry']['LookupXForwardedFor'] = (payload?.RuleStackEntry?.LookupXForwardedFor) ? "SecurityPolicy" : 'None';
    payload['RuleStackEntry']['ProfileConfig']['AntiSpywareConfig']['CloudInlineAnalysis'] = !record?.RuleStackCandidate?.ProfileConfig?.AntiSpywareConfig?.CloudInlineAnalysis;
    dataProvider.update("ruleStacks", payload)
      .then(async (response: any) => {
        setOpenConfirm(false);
        setRecord({ ...record, CloudInlineAnalysis: !record?.RuleStackCandidate?.ProfileConfig?.AntiSpywareConfig?.CloudInlineAnalysis });
      })
      .catch((e: any) => {
        setOpenConfirm(false);
        toast.error(e?.error, { toastId: "tokens-update" });
        setRecord({ ...record, RuleStackCandidate: {...record.RuleStackCandidate, ProfileConfig: {...record.RuleStackCandidate.ProfileConfig, AntiSpywareConfig: {...record.RuleStackCandidate.ProfileConfig.AntiSpywareConfig, CloudInlineAnalysis: !record?.RuleStackCandidate?.ProfileConfig?.AntiSpywareConfig?.CloudInlineAnalysis }}}});
      }).finally(() => {
        setUpdating(false);
      })
  };

  return (
    (! isEmpty(record)) ? <Form
      onSubmit={onSubmit}
      initialValues={record}
      render={props => {
        let { handleSubmit, values } = props;
        return (
          <>
          <form onSubmit={handleSubmit} defaultValue={values} className={classes.fullHeightForm}>
            <div className={classes.pantileContainer}>
              <CircularLoader loading={isEmpty(record)} />
              <PANTile
                title={translate("resources.ruleStacks.securityService.threatPrevention")}
                size={12}
              >
              <Toggle
                  onClick={handleChange}
                  checked={record?.RuleStackCandidate?.ProfileConfig?.AntiSpywareConfig?.CloudInlineAnalysis}
                  dataMetrics={"threat-prevention"}
                  label={translate("resources.ruleStacks.securityService.advancedThreatPreventionEnabled")}
                  needLabel
                  labelPlacement="right"
              />
              <div className={classes.subtitle}>{translate("resources.ruleStacks.securityService.advancedThreatPreventionEnabledSubtitle")}
                <a target="_blank" href={"https://docs.paloaltonetworks.com/advanced-threat-prevention"}>{translate("resources.ruleStacks.securityService.document")}</a>
              </div>
              <div className={classes.featureBox} >
                <PANCardField
                  title={translate("resources.ruleStacks.securityService.IPSandSpyware.fields.VulnerabilityProfile.title")}
                  image={Vulnerability}
                  content={translate("resources.ruleStacks.securityService.IPSandSpyware.fields.VulnerabilityProfile.content")}
                  disabled={loading["RuleStackCandidate.Profiles.VulnerabilityProfile"] || !permissions?.UpdateRuleStack}
                >
                  <Field
                    name="RuleStackCandidate.Profiles.VulnerabilityProfile"
                    items={permissions?.UpdateVulnerabilityException ? [
                      { text: translate("resources.ruleStacks.securityService.options.bestPractice"), value: "BestPractice" },
                      { text: translate("resources.ruleStacks.securityService.options.disable"), value: "None" },
                    ] : undefined}
                    // @ts-ignore
                    component={PANWDSSelect}
                    disabled={loading["RuleStackCandidate.Profiles.VulnerabilityProfile"] || !permissions?.UpdateRuleStack}
                    loading={loading["RuleStackCandidate.Profiles.VulnerabilityProfile"]}
                    dataMetrics="cloudngfw-rulestack-security-service-vulnerability-profile"
                  />
                  <OnChange name="RuleStackCandidate.Profiles.VulnerabilityProfile">
                  {(selectedItem: any, item: any) => handleOnChange({
                      title: translate("resources.ruleStacks.securityService.IPSandSpyware.fields.VulnerabilityProfile.title"),
                      key: "RuleStackCandidate.Profiles.VulnerabilityProfile",
                      currentValue: item,
                      newValue: selectedItem,
                      record: values,
                      permissions: permissions,
                      globalContext: globalContext
                    })}
                  </OnChange>
                </PANCardField>
                <PANCardField
                  title={translate("resources.ruleStacks.securityService.IPSandSpyware.fields.AntiSpywareProfile.title")}
                  image={AntiSpyware}
                  content={translate("resources.ruleStacks.securityService.IPSandSpyware.fields.AntiSpywareProfile.content")}
                  disabled={loading["RuleStackCandidate.Profiles.AntiSpywareProfile"] || !permissions?.UpdateRuleStack}
                >
                  <Field
                    name="RuleStackCandidate.Profiles.AntiSpywareProfile"
                    items={permissions?.UpdateSpywareException ? [
                      { text: translate("resources.ruleStacks.securityService.options.bestPractice"), value: "BestPractice" },
                      { text: translate("resources.ruleStacks.securityService.options.disable"), value: "None" },
                    ] : undefined}
                    // @ts-ignore
                    component={PANWDSSelect}
                    disabled={loading["RuleStackCandidate.Profiles.AntiSpywareProfile"] || !permissions?.UpdateRuleStack}
                    loading={loading["RuleStackCandidate.Profiles.AntiSpywareProfile"]}
                    dataMetrics="cloudngfw-rulestack-security-service-anti-spyware-profile"
                  />
                  <OnChange name="RuleStackCandidate.Profiles.AntiSpywareProfile">
                  {(selectedItem: any, item: any) => handleOnChange({
                      title: translate("resources.ruleStacks.securityService.IPSandSpyware.fields.AntiSpywareProfile.title"),
                      key: "RuleStackCandidate.Profiles.AntiSpywareProfile",
                      currentValue: item,
                      newValue: selectedItem,
                      record: values,
                      permissions: permissions,
                      globalContext: globalContext
                    })}
                  </OnChange>
                </PANCardField>
                <PANCardField
                  title={translate("resources.ruleStacks.securityService.MalwareAndFileBase.fields.AntivirusProfile.title")}
                  image={Antivirus}
                  content={translate("resources.ruleStacks.securityService.MalwareAndFileBase.fields.AntivirusProfile.content")}
                  disabled={loading["RuleStackCandidate.Profiles.AntiVirusProfile"] || !permissions?.UpdateRuleStack}
                >
                  <Field
                    name="RuleStackCandidate.Profiles.AntiVirusProfile"
                    items={permissions?.UpdateAntiVirusException ? [
                      { text: translate("resources.ruleStacks.securityService.options.bestPractice"), value: "BestPractice" },
                      { text: translate("resources.ruleStacks.securityService.options.disable"), value: "None" },
                    ] : undefined}
                    // @ts-ignore
                    component={PANWDSSelect}
                    disabled={loading["RuleStackCandidate.Profiles.AntiVirusProfile"] || !permissions?.UpdateRuleStack}
                    loading={loading["RuleStackCandidate.Profiles.AntiVirusProfile"]}
                    dataMetrics="cloudngfw-rulestack-security-service-anti-virus-profile"
                  />
                  <OnChange name="RuleStackCandidate.Profiles.AntiVirusProfile">
                    {(selectedItem: any, item: any) => handleOnChange({
                      title: translate("resources.ruleStacks.securityService.MalwareAndFileBase.fields.AntivirusProfile.title"),
                      key: "RuleStackCandidate.Profiles.AntiVirusProfile",
                      currentValue: item,
                      newValue: selectedItem,
                      record: values,
                      permissions: permissions,
                      globalContext: globalContext
                    })}
                  </OnChange>
                </PANCardField>
              </div>
              </PANTile>
              <PANTile
                title={translate("resources.ruleStacks.securityService.MalwareAndFileBase.title")}
                subtitle={translate("resources.ruleStacks.securityService.MalwareAndFileBase.subtitle")}
                size={12}
              >
                <div className={classes.featureBox} >
                  <PANCardField
                    title={translate("resources.ruleStacks.securityService.MalwareAndFileBase.fields.FileBlockingProfile.title")}
                    image={FileBlocking}
                    content={translate("resources.ruleStacks.securityService.MalwareAndFileBase.fields.FileBlockingProfile.content")}
                    target={getFileBlockingProfileLink()}
                    disabled={loading["RuleStackCandidate.Profiles.FileBlockingProfile"] || !permissions?.UpdateRuleStack}
                    //@ts-ignore
                    viewMode={permissions?.UpdateFileBlockingAction && _.get(values, "RuleStackCandidate.Profiles.FileBlockingProfile", '') === "BestPractice"}
                    editMode={_.get(values, "RuleStackCandidate.Profiles.FileBlockingProfile", '') === "Custom"}
                  >
                    <Field
                      name="RuleStackCandidate.Profiles.FileBlockingProfile"
                      items={permissions?.UpdateFileBlockingAction ? [
                        { text: translate("resources.ruleStacks.securityService.options.bestPractice"), value: "BestPractice" },
                        { text: translate("resources.ruleStacks.securityService.options.disable"), value: "None" },
                        { text: translate("resources.ruleStacks.securityService.options.custom"), value: "Custom" },
                      ] : undefined}
                      showEditWithValue="Custom"
                      // @ts-ignore
                      component={PANWDSSelect}
                      disabled={loading["RuleStackCandidate.Profiles.FileBlockingProfile"] || !permissions?.UpdateRuleStack}
                      loading={loading["RuleStackCandidate.Profiles.FileBlockingProfile"]}
                      dataMetrics="cloudngfw-rulestack-security-service-file-blocking-profile"
                    />
                    <OnChange name="RuleStackCandidate.Profiles.FileBlockingProfile">
                    {(selectedItem: any, item: any) => handleOnChange({
                        title: translate("resources.ruleStacks.securityService.MalwareAndFileBase.fields.FileBlockingProfile.title"),
                        key: "RuleStackCandidate.Profiles.FileBlockingProfile",
                        currentValue: item,
                        newValue: selectedItem,
                        record: values,
                        permissions: permissions,
                        globalContext: globalContext
                      })}
                    </OnChange>
                  </PANCardField>
                  <PANCardField
                    title={translate("resources.ruleStacks.securityService.WebBasedThreat.fields.URLFilteringProfile.title")}
                    image={URLCategory}
                    content={translate("resources.ruleStacks.securityService.WebBasedThreat.fields.URLFilteringProfile.content")}
                    viewMode={_.get(values, "RuleStackCandidate.Profiles.URLFilteringProfile", '') === "BestPractice"}
                    editMode={_.get(values, "RuleStackCandidate.Profiles.URLFilteringProfile", '') === "Custom"}
                    target={getURLFilteringProfileLink()}
                    disabled={loading["RuleStackCandidate.Profiles.URLFilteringProfile"] || !permissions?.UpdateRuleStack}
                  >
                    <Field
                      name="RuleStackCandidate.Profiles.URLFilteringProfile"
                      items={[
                        { text: translate("resources.ruleStacks.securityService.options.bestPractice"), value: "BestPractice" },
                        { text: translate("resources.ruleStacks.securityService.options.disable"), value: "None" },
                        { text: translate("resources.ruleStacks.securityService.options.custom"), value: "Custom" },
                      ]}
                      // @ts-ignore
                      component={PANWDSSelect}
                      disabled={loading["RuleStackCandidate.Profiles.URLFilteringProfile"] || !permissions?.UpdateRuleStack}
                      loading={loading["RuleStackCandidate.Profiles.URLFilteringProfile"]}
                      dataMetrics="cloudngfw-rulestack-security-service-url-filtering-profile"
                    />
                    <OnChange name="RuleStackCandidate.Profiles.URLFilteringProfile">
                      {(selectedItem: any, item: any) => handleOnChange({
                        title: translate("resources.ruleStacks.securityService.WebBasedThreat.fields.URLFilteringProfile.title"),
                        key: "RuleStackCandidate.Profiles.URLFilteringProfile",
                        currentValue: item,
                        newValue: selectedItem,
                        record: values,
                        permissions: permissions,
                        globalContext: globalContext
                      })}
                    </OnChange>
                  </PANCardField>
                </div>
              </PANTile>
              <PANTile
                title={translate("resources.ruleStacks.securityService.EncryptedThreat.title")}
                subtitle={translate("resources.ruleStacks.securityService.EncryptedThreat.subtitle")}
                size={12}
              >
                <div className={classes.featureBox} >
                  <Typography className={classes.iconedParagraph}>
                    {translate("resources.ruleStacks.securityService.EncryptedThreat.fields.OutboundDecryptionProfile.title")}
                    <Tooltip label={translate("resources.ruleStacks.securityService.EncryptedThreat.fields.OutboundDecryptionProfile.content")}>
                      <InfoIcon size="xs" />
                    </Tooltip>
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} className="noLeftPadding">
                      <FormControl fullWidth>
                        <Field
                          name='RuleStackCandidate.Profiles.OutboundUntrustCertificate'
                          // @ts-ignore
                          component={PANWDSSelect}
                          items={certChoices}
                          disabled={loading["RuleStackCandidate.Profiles.OutboundUntrustCertificate"] || !permissions?.UpdateRuleStack}
                          loading={loading['RuleStackCandidate.Profiles.OutboundUntrustCertificate']}
                          title={translate(`resources.outboundDecryptionCert.fields.UntrustCert`)}
                          dataMetrics="cloudngfw-rulestack-security-service-outbound-untrust-certificate"
                          allowEmpty
                        />
                        <OnChange name='RuleStackCandidate.Profiles.OutboundUntrustCertificate'>
                        {(selectedItem: any, item: any) => handleOnChange({
                            title: translate(`resources.outboundDecryptionCert.fields.UntrustCert`),
                            key: "RuleStackCandidate.Profiles.OutboundUntrustCertificate",
                            currentValue: item,
                            newValue: selectedItem,
                            record: values,
                            permissions: permissions,
                            globalContext: globalContext
                          })}
                        </OnChange>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="noLeftPadding">
                      <FormControl fullWidth>
                        <Field
                          name='RuleStackCandidate.Profiles.OutboundTrustCertificate'
                          // @ts-ignore
                          component={PANWDSSelect}
                          items={certChoices}
                          disabled={loading["RuleStackCandidate.Profiles.OutboundTrustCertificate"] || !permissions?.UpdateRuleStack}
                          loading={loading['RuleStackCandidate.Profiles.OutboundTrustCertificate']}
                          title={translate(`resources.outboundDecryptionCert.fields.TrustCert`)}
                          dataMetrics="cloudngfw-rulestack-security-service-outbound-trust-certificate"
                          allowEmpty
                        />
                        <OnChange name='RuleStackCandidate.Profiles.OutboundTrustCertificate'>
                        {(selectedItem: any, item: any) => handleOnChange({
                            title: translate(`resources.outboundDecryptionCert.fields.TrustCert`),
                            key: "RuleStackCandidate.Profiles.OutboundTrustCertificate",
                            currentValue: item,
                            newValue: selectedItem,
                            record: values,
                            permissions: permissions,
                            globalContext: globalContext
                          })}
                        </OnChange>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </PANTile>
            </div>
          </form>
            <PANWDSModal
              title={'Warning'}
              onClose={() => setOpenConfirm(false)}
              isOpen={openConfirm}
              size="sm"
              dataTestId="cloudngfw-security-services-edit-confirm-modal"
              dataMetrics="cloudngfw-security-services-edit-confirm-modal"
              modalBody={record?.RuleStackCandidate?.ProfileConfig?.AntiSpywareConfig?.CloudInlineAnalysis
              ? <><div className={classes.modalBodyTitle}>{translate("resources.ruleStacks.securityService.disableATP")}</div>
              <div className={classes.modalBodySubTitle}>{translate("resources.ruleStacks.securityService.disableATPSubtitle")}
                <a target="_blank" href={"https://docs.paloaltonetworks.com/advanced-threat-prevention"}>{translate("resources.ruleStacks.securityService.document")}</a>
              </div></>
              : <><div className={classes.modalBodyTitle}>{translate("resources.ruleStacks.securityService.enableATP")}</div>
              <div className={classes.modalBodySubTitle}>{translate("resources.ruleStacks.securityService.enableATPSubtitle")}
                <a target="_blank" href={"https://docs.paloaltonetworks.com/advanced-threat-prevention"}>{translate("resources.ruleStacks.securityService.document")}</a>
              </div></>}
              modalFooter={{
                footerRequired: true,
                cancelButtonRequired: true,
                featureSpecificButton: <SaveButton
                  appearance={record?.RuleStackCandidate?.ProfileConfig?.AntiSpywareConfig?.CloudInlineAnalysis ? "primary-destructive" : "primary"}
                  size="md"
                  label={translate(record?.RuleStackCandidate?.ProfileConfig?.AntiSpywareConfig?.CloudInlineAnalysis ? 'resources.tokens.values.disable' : 'resources.tokens.values.enable')}
                  onClick={() => update()}
                  saving={updating}
                  submitOnEnter={false}
                  dataMetrics="cloudngfw-security-services-confirm-modal-save"
                  {...props}
                />
                }}
            />
          </>
        );
      }} /> : <CircularLoader loading={true}/>
  );
}

export default SecurityServices;
