import React, {useEffect} from "react";
const emptyParams = {};
import useCheckAuth from "./useCheckAuth";

export default (params: any = emptyParams) => {
    const checkAuth = useCheckAuth();
    useEffect(() => {
        checkAuth(params).catch(() => {});
    }, [checkAuth, params]);
};
