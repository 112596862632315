import { FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useEffect, useState, Fragment } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import {Field, FormSpy} from "react-final-form";
import { useHistory, withRouter } from 'react-router-dom';
import { Button as PANWDSButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast} from '../../components';
import { Row } from "../../components/FormElements";
import { dataProvider } from '../../dataProvider';
import { isAlphaNumeric, isRequired, maxLength, composeValidators } from '../../utils/validate';
import {PANWDSBreadcrumbs, PANWDSCheckboxField, PANWDSForm, PANWDSInput} from '../../components/PANWDSElements';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

const CertificateEdit = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const [record, setRecord] = useState<any>({});
  const { permissions } = usePermissions();

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;
  const title = ruleStackName;

  // get the feed name from route params
  const certificateName = props.match.params.certificatename;

  useEffect(() => {
    if (permissions?.DescribeCertificateObject) {
      dataProvider.describe('certificate', '',
        { RuleStackName: ruleStackName, Name: certificateName })
        .then((res: any) => {
          if (res.data) {
            setRecord({ ...res.data });
          } else {
            toast.error(res?.error, { toastId: "certificate-describe" });
          }
        }).catch((e: any) => {
        toast.error(e?.error, { toastId: "certificate-describe" });
      });
    }
  }, [ruleStackName, certificateName, permissions]);

  const save = useCallback(
    async (values) => {
      if (!permissions?.UpdateCertificateObject) {
        return;
      }
      let payload = { ...values.CertificateObjectCandidate, RuleStackName: values.RuleStackName, Name: values.Name };
      if (payload['CertificateSelfSigned']) {
        delete payload['CertificateSignerArn']
      }
      try {
          await dataProvider.update("certificate", payload);
        //toast.success("Success");
        history.goBack();
        return;
      } catch (error: any) {
        toast.error(error?.error, { toastId: "certificate-update" });
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, permissions]
  );

  const SaveToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-certificate-edit-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          submitOnEnter={true}
          disabled={!permissions?.UpdateCertificateObject}
          className={classes.button}
          dataMetrics="cloudngfw-certificate-edit-save-button"
          {...toolbarProps}
        />
      </div>
    )
  };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: translate(`resources.ruleStacks.name`),
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`certificates`]: translate(`resources.certificate.name`),
          [certificateName]: certificateName
        }}
      />
      <PANTitle title={title} divider />

        <PANWDSForm
            onSubmit={save}
            toolbar={<SaveToolbar />}
            initialValues={record}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: 'auto' }}>
                    <PANTile title={translate(`resources.certificate.fields.General`)} subtitle={translate(`resources.certificate.fields.CreateSubtitle`)} size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.certificate.fields.Name`)}
                                        dataMetrics="cloudngfw-certificate-edit-field-name"
                                        required
                                        validate={composeValidators(isRequired, maxLength(63), isAlphaNumeric)}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="CertificateObjectCandidate.Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.certificate.fields.Description`)}
                                        dataMetrics="cloudngfw-certificate-edit-field-description"
                                        validate={maxLength(512)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="noLeftPadding">

                                <FormSpy subscription={{ values: true }}>
                                    {({values, ...rest }) => {
                                        let certificateSelfSigned = (values.CertificateObjectCandidate) ? values.CertificateObjectCandidate.CertificateSelfSigned : undefined;
                                        //@ts-ignore
                                        return (<FormControl fullWidth>
                                            <Field
                                                name="CertificateObjectCandidate.CertificateSignerArn"
                                                // @ts-ignore
                                                component={PANWDSInput}
                                                title={translate(`resources.certificate.fields.CertificateSignerArn`)}
                                                dataMetrics="cloudngfw-certificate-edit-field-signerarn"
                                                disabled={certificateSelfSigned}
                                            />
                                        </FormControl>)
                                    }}
                                </FormSpy>
                            </Grid>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="CertificateObjectCandidate.CertificateSelfSigned"
                                        // @ts-ignore
                                        component={PANWDSCheckboxField}
                                        label={translate(`resources.certificate.fields.SelfSignedCertificate`)}
                                        dataMetrics="cloudngfw-certificate-edit-field-selfsigned"
                                        type="checkbox"
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(CertificateEdit);
