import { FilterComponent } from "../../../components/Filters";

export const getTagsFilters = (vpcData: any, accountData: any) => {
    const vpcFilters = [
        {
            Component: FilterComponent,
            label: "VPC",
            name: "VPC",
            required: true,
            items: vpcData,
            columnValue: 'VpcId',
            dataTestId: "VpcId",
            enableStyles: true
        },
        {
            Component: FilterComponent,
            label: "AWS Account ID",
            name: "Account",
            context: {
                enableMultiSelect: true,
                items: accountData,
            },
            required: true,
            columnValue: 'Account',
            dataTestId: "Account"
        }
    ]
    return vpcFilters;
};