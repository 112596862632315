import React, {cloneElement} from "react";
import {
    Switch,
    useHistory, useLocation,
} from "react-router-dom";
import {PublicRoutes} from "./publicRoutes";
import ProtectedRoutes from "./protectedRoutes";
import {Security} from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import {useOktaAuthClient} from "../customHooks";
import {RouteUri} from "../routeUri";
import {CallbackRoutes} from "./CallbackRoutes";

export interface RouterProps {
    catchAll?: any;
    children?: any;
    customRoutes?: any;
    loading?: any;
}

const Index = (props: RouterProps) => {
    const history = useHistory();
    const location = useLocation();
    //useScrollToTop();

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <Security oktaAuth={useOktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
                {CallbackRoutes.map((route, key) => {
                    if (route.props.path === location.pathname && route.props.exact) {
                        return cloneElement(route, {key});
                    }
                })}
                {PublicRoutes.map((route, key) => {
                    if (route.props.path === location.pathname && route.props.exact) {
                        if (localStorage.getItem('idToken')) {
                            history.push(RouteUri.Dashboard);
                            return;
                        }
                    }
                    return cloneElement(route, {key});
                })}
                <ProtectedRoutes {...props}/>
            </Switch>
        </Security>
    );
}

export default Index;
