import Applications from '../../ApplicationsDetail-list.json';

export const generateBackgroundColors = (risk: any, classes: any) => {
  let backgroundClass;
  let colorClass;
  const numberId = parseInt(risk, 10);
  const modulo = numberId % 21;
  if (modulo < 14) {
    colorClass = classes.white;
  } else {
    colorClass = classes.black;
  }

  switch (modulo) {
    case 1: {
      backgroundClass = classes.risk1;
      break;
    }
    case 2: {
      backgroundClass = classes.risk2;
      break;
    }
    case 3: {
      backgroundClass = classes.risk3;
      break;
    }
    case 4: {
      backgroundClass = classes.risk4;
      break;
    }
    case 5: {
      backgroundClass = classes.risk5;
      break;
    }
    default: break;
  }

  return {
    backgroundClass,
    colorClass,
  };
};

export const pluck = (list: any, propertyName: string) => {
  let newList = list?.map(function(i: any) {
    if(propertyName?.includes('.')) {
      let prop = propertyName?.split('.');
        return i[prop[0]][prop[1]];
    }
    if(Array.isArray(i[propertyName]) && i[propertyName]?.length > 0) {
      for(let j = 0; j < i[propertyName].length; j++) {
        return (i[propertyName])[j];
      }
    }
  });
  return newList;
}

export const pluckNew = (list: any, propertyName: string) => {
  let newList = list?.map(function(i: any) {
    let listFormed = i[propertyName];
    if(propertyName === 'Monitored') {
      listFormed = listFormed.toString() !== 'false' ? 'Enabled' : 'Disabled';
    }
    return listFormed;
  });
  return newList;
}

export const getList = (objKey: string) => {
  let isNestedArray = false;
  let uniqueListItems: any[] = [];
  if(objKey.includes('Characteristics')) {
    return ['CapableFileTransfer', 'DenyAction', 'Evasive', 'ExcessiveBandwidthUse', 'HasKnownVulnerability', 'ProneToMisuse', 'SaaS', 'TunnelOtherApplications', 'UsedByMalware', 'WidelyUsed'];
  } else {
    const listItems = pluck(Applications, objKey);
    uniqueListItems = listItems?.filter((item: any, i: any, ar: any) => ar.indexOf(item) === i);
    uniqueListItems = uniqueListItems.filter(Boolean)
    let uniqueList: any[] = []
    for(let i = 0; i < uniqueListItems?.length; i++) {
      if(Array.isArray(uniqueListItems[i])) {
        isNestedArray = true;
        for(let j = 0; j < uniqueListItems[i].length; j++) {
          uniqueList.push(uniqueListItems[i][j]);
        }
      }
    }
    return isNestedArray ? uniqueList.sort() : uniqueListItems.sort();
  }
}
