import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@mui/material";
import { Card, CardHeader, CardBody, Link } from "@panwds/react-ui";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import { RouteUri } from "../../../routeUri";
import { usePermissions, useTranslate } from "../../../customHooks";
import { CircleCheckIcon, CircleEmptyIcon } from "@panwds/icons";
import React from "react";
import {PANWDSTooltip} from "../../../components/PANWDSElements";
import {useAppSelector} from "../../../app/hooks";

const useStyles = makeStyles((theme) => ({
  cardStyles: {
    padding: "16px 16px 16px",
  },
  gridStyles: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "0",
    "&>div": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "&>a": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#006FCC",
      padding: "16px 0 0",
      textDecoration: "none",
      cursor: "pointer",
        width: "max-content"
    },
  },
  onboarding: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#333333",
    padding: "0 0 16px",
    "&>a": {
      cursor: "pointer",
    },
  },
  onboardingNoPerm: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#333333",
    padding: "0 0 8px",
    "&>a": {
      cursor: "pointer",
    },
  },
  stepsCompleted: {
    padding: "0 16px 8px 0",
  },
  statusStyles: {
    display: "flex",
    alignItems: "center",
    paddingTop: "16px",
    "&>div": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#333333",
    },
    "&>a": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#006FCC",
      padding: "0 16px 3px 13px",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  toComplete: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#707070",
    paddingLeft: "30px",
  },
  resources: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#333333",
    padding: "0",
  },
  upperBody: {
    paddingBottom: "40px",
  },
  upperBodyNoPerm: {
    paddingBottom: "17px",
  },
  onClickDismiss: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#006FCC",
    paddingBottom: "16px",
    "&>a": {
      cursor: "pointer",
    },
  },
  noPermissions: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#707070",
    paddingBottom: "16px",
  },
  listNoPerm: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#707070",
    paddingBottom: "23px",
  },
  createNGFW: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#B8B8B8",
    padding: "0 16px 3px 13px",
  },
  emptyIcon: {
    color: "#33CCB8",
  },
  checkIcon: {
    color: "#33CCB8",
  },
  emptyIconGrey: {
    color: "#B8B8B8",
  },
  cardHeader: {
    background: "#FFFFFF",
  },
    disableLink: {
        color: "var(--light-text-disabled, #B8B8B8)!important",
        pointerEvents: "none"
}
}));

export const GettingStartedCard = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [completed, setCompleted] = useState(0);
  const [steps, setSteps] = useState(0);
  const [bgColor, setBgColor] = useState("#006FCC");
  const [onClickDismiss, setOnClickDismiss] = useState(false);
  const history = useHistory();
  const { ruleStacksLength, firewallsLength, hasRules } = props;
    const reduxState = useAppSelector((state) => state);

  const onClickOfCreateRuleStack = () => {
    history.push(RouteUri.RuleStackList + "?pendo=s3uBzyp2dkIYXyaL2NJsJQSD_1s");
  };

  const onClickOfCreateFirewall = () => {
    history.push(
      RouteUri.NGFirewallList + "?pendo=yoWDjDpuYHb_o4C0Zh5EXXkv3CY"
    );
  };

  const onClickOfRulesAndObjects = () => {
    history.push(RouteUri.RuleStackList + "?pendo=XMOxWMHHiBhwY_IFPkpCxZC-eF0");
  };

  useEffect(() => {
    let complete = 0;
    let stepAcc = 0;
    if (ruleStacksLength > 0) {
      complete += 33;
      stepAcc += 1;
    }
    if (firewallsLength > 0) {
      complete += 34;
      stepAcc += 1;
    }
    if (props?.hasRules) {
      complete += 33;
      stepAcc += 1;
    }
    setCompleted(complete);
    setSteps(stepAcc);
  }, [ruleStacksLength, firewallsLength, hasRules]);

  const getExternalLinks = () => (
    <>
      <div className={classes.resources}>
        {translate(`resources.overview.resouces`)}
      </div>
      <Link
        dataMetrics="cloudngfw-overviewScreen-getting-card-about"
        external
        href="https://live.paloaltonetworks.com/t5/blogs/how-native-is-cloud-ngfw-for-aws/ba-p/476736"
      >
        {translate(`resources.overview.about`)}
      </Link>
      <Link
        dataMetrics="cloudngfw-overviewScreen-getting-card-aboutWithAws"
        external
        href="https://www.youtube.com/playlist?list=PLD6FJ8WNiIqWmfcE18dj7ItUiDECreaEc"
      >
        {translate(`resources.overview.aboutWithAws`)}
      </Link>
      <Link
        dataMetrics="cloudngfw-overviewScreen-getting-card-whatsNew"
        external
        href="https://docs.paloaltonetworks.com/cloud-ngfw/aws/cloud-ngfw-on-aws/cloud-ngfw-for-aws-release-updates/whats-new-cloud-ngfw-for-aws"
      >
        {translate(`resources.overview.whatsNew`)}
      </Link>
      <Link
        dataMetrics="cloudngfw-overviewScreen-getting-card-deploymentGuide"
        external
        href="https://live.paloaltonetworks.com/t5/cloud-ngfw-articles/cloud-ngfw-for-aws-deployment-guide/ta-p/477001"
      >
        {translate(`resources.overview.deploymentGudie`)}
      </Link>
      <Link
        dataMetrics="cloudngfw-overviewScreen-getting-card-liveCommunityLink"
        external
        href="https://live.paloaltonetworks.com/t5/cloud-ngfw-help-center/ct-p/Cloud_NGFW"
      >
        {translate(`resources.overview.liveCommunityLink`)}
      </Link>
      <Link
        dataMetrics="cloudngfw-overviewScreen-getting-card-faq"
        external
        href="https://live.paloaltonetworks.com/t5/cloud-ngfw-articles/cloud-ngfw-for-aws-faq/ta-p/476671"
      >
        {translate(`resources.overview.faq`)}
      </Link>
      <Link
        dataMetrics="cloudngfw-overviewScreen-getting-card-serviceStatus"
        external
        href="https://status.paloaltonetworks.com"
      >
        {translate(`resources.overview.serviceStatus`)}
      </Link>
        <PANWDSTooltip showTooltip={!reduxState.support.cspEnable} label={translate("csp.registerCSP")} >
            <div style={{width: "max-content", padding: "16px 0 0"}}>
                <Link
                    dataMetrics="cloudngfw-overviewScreen-getting-card-createACase"
                    external
                    addClassName={(permissions.hasOwnProperty("CreateACase") && !permissions.CreateACase) ? classes.disableLink : ""}
                    href={process.env.REACT_APP_SUPPORT_GET_HELP_URL}
                >
                    {translate(`resources.overview.createACase`)}
                </Link>
            </div>
        </PANWDSTooltip>
    </>
  );

  const getImpStepsInCongfw = () => (
    <div className={classes.upperBodyNoPerm}>
      <div
        className={classes.onboardingNoPerm}
        data-metrics="cloudngfw-overviewScreen-getting-card-onboarding-else"
      >
        <span>{translate(`resources.overview.onboarding`)}</span>
      </div>
      <div
        className={classes.noPermissions}
        data-metrics="cloudngfw-overviewScreen-getting-card-no-permissions"
      >
        {translate(`resources.overview.noPermissions`)}
      </div>
      <ol>
        <li
          className={classes.listNoPerm}
          data-metrics="cloudngfw-overviewScreen-getting-card-list-rs"
        >
          {translate(`resources.overview.createRulestacks`)}
        </li>
        <li
          className={classes.listNoPerm}
          data-metrics="cloudngfw-overviewScreen-getting-card-list-rules"
        >
          {translate(`resources.overview.createRulesAndObjects`)}
        </li>
        <li
          className={classes.listNoPerm}
          data-metrics="cloudngfw-overviewScreen-getting-card-list-ngfw"
        >
          {translate(`resources.overview.createFirewalls`)}
        </li>
      </ol>
    </div>
  );

  const getLinks = getExternalLinks();
  const getSteps = getImpStepsInCongfw();

  return (
    <div
      data-metrics="cloudngfw-overviewScreen-getting-card-div"
      id="getting-started-card-div"
      className={classes.cardStyles}
    >
      <Card
        data-metrics="cloudngfw-overviewScreen-getting-card"
        dataTestId="getting-started-card"
      >
        <CardHeader
          title={translate(`resources.overview.getStarted`)}
          dataTestId="getting-started-card-header"
          data-metrics="cloudngfw-overviewScreen-getting-card-header"
          addClassName={classes.cardHeader}
        />
        <CardBody
          data-metrics="cloudngfw-overviewScreen-getting-card-body"
          dataTestId="getting-started-card-body"
        >
          {!props?.readOnly ? (
            <div data-metrics="cloudngfw-overviewScreen-getting-started-body">
              <Grid
                data-metrics="cloudngfw-overviewScreen-getting-card-grid"
                className={classes.gridStyles}
              >
                {permissions?.CreateRuleStack && !onClickDismiss && (
                  <>
                    <div
                      className={classes.onboarding}
                      data-metrics="cloudngfw-overviewScreen-getting-card-onboarding"
                    >
                      <span>{translate(`resources.overview.onboarding`)} </span>
                      <Link
                        dataMetrics="cloudngfw-overviewScreen-getting-card-dismiss-permissions"
                        onClick={() => setOnClickDismiss(!onClickDismiss)}
                      >
                        ({translate(`resources.overview.onDismiss`)})
                      </Link>
                    </div>
                    <div
                      className={classes.stepsCompleted}
                      data-metrics="cloudngfw-overviewScreen-getting-card-stepsCompleted"
                    >
                      <span>
                        {translate(`resources.overview.setUpProgress`)}
                      </span>
                      <span>{` ${completed}%`}</span>
                      <span>{` (${steps}`}</span>
                      <span>
                        {translate(`resources.overview.completedProgress`)}
                      </span>
                    </div>
                    <ProgressBar
                      data-metrics="cloudngfw-overviewScreen-getting-card-progressbar"
                      bgcolor={bgColor}
                      completed={completed}
                    />
                    <div className={classes.upperBody}>
                      <div
                        className={classes.statusStyles}
                        data-metrics="cloudngfw-overviewScreen-getting-card-status"
                      >
                        {props.loading ? (
                          <>
                            <Skeleton
                              variant="text"
                              width="20px"
                              height="20px"
                            />
                            <span
                              className={classes.createNGFW}
                              data-metrics="cloudngfw-overviewScreen-getting-card-list-grey-rulestack"
                            >
                              {translate(`resources.overview.createRulestacks`)}
                            </span>
                          </>
                        ) : (
                          <>
                            {!ruleStacksLength ? (
                              <CircleEmptyIcon
                                className={classes.emptyIcon}
                                data-metrics="cloudngfw-overviewScreen-RulestackLength-CircleEmptyIcon-img"
                                size="md"
                              />
                            ) : (
                              <CircleCheckIcon
                                className={classes.checkIcon}
                                data-metrics="cloudngfw-overviewScreen-RulestackLength-CircleCheck-img"
                                size="md"
                              />
                            )}
                            <Link
                              dataMetrics="cloudngfw-overviewScreen-getting-card-onClickOfCreateRuleStack"
                              onClick={() => onClickOfCreateRuleStack()}
                            >
                              {translate(`resources.overview.createRulestacks`)}
                            </Link>
                          </>
                        )}
                      </div>
                      <div className={classes.toComplete}>
                        {translate(`resources.overview.threeMins`)}
                      </div>
                      <div className={classes.statusStyles}>
                        {props.loading ? (
                          <>
                            {" "}
                            <Skeleton
                              variant="text"
                              width="20px"
                              height="20px"
                            />
                            <span
                              className={classes.createNGFW}
                              data-metrics="cloudngfw-overviewScreen-getting-card-list-grey-rules"
                            >
                              {translate(
                                `resources.overview.createRulesAndObjects`
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            {!hasRules ? (
                              <CircleEmptyIcon
                                className={classes.emptyIcon}
                                data-metrics="cloudngfw-overviewScreen-hasrules-CircleEmptyIcon-img"
                                size="md"
                              />
                            ) : (
                              <CircleCheckIcon
                                className={classes.checkIcon}
                                data-metrics="cloudngfw-overviewScreen-hasrules-CircleCheck-img"
                                size="md"
                              />
                            )}
                            <Link
                              dataMetrics="cloudngfw-overviewScreen-getting-card-onClickOfRulesAndObjects"
                              onClick={() => onClickOfRulesAndObjects()}
                            >
                              {translate(
                                `resources.overview.createRulesAndObjects`
                              )}
                            </Link>
                          </>
                        )}
                      </div>
                      <div className={classes.toComplete}>
                        {translate(`resources.overview.fiveMinutes`)}
                      </div>
                      <div className={classes.statusStyles}>
                        {props.loading ? (
                          <>
                            <Skeleton
                              variant="text"
                              width="20px"
                              height="20px"
                            />
                            <span
                              className={classes.createNGFW}
                              data-metrics="cloudngfw-overviewScreen-getting-card-list-onClickOfCreateFirewall"
                            >
                              {translate(`resources.overview.createFirewalls`)}
                            </span>
                          </>
                        ) : permissions?.CreateFirewall ? (
                          <>
                            {firewallsLength <= 0 ? (
                              <CircleEmptyIcon
                                className={classes.emptyIcon}
                                data-metrics="cloudngfw-overviewScreen-firewallsLength-CircleEmptyIcon-img"
                                size="md"
                              />
                            ) : (
                              <CircleCheckIcon
                                className={classes.checkIcon}
                                data-metrics="cloudngfw-overviewScreen-firewallsLength-CircleCheck-img"
                                size="md"
                              />
                            )}
                            {props.loading ? (
                              <Skeleton
                                variant="text"
                                width="20px"
                                height="20px"
                              />
                            ) : (
                              <Link
                                dataMetrics="cloudngfw-overviewScreen-getting-card-onClickOfCreateFirewall"
                                onClick={() => onClickOfCreateFirewall()}
                              >
                                {translate(
                                  `resources.overview.createFirewalls`
                                )}
                              </Link>
                            )}
                          </>
                        ) : (
                          <>
                            <CircleEmptyIcon
                              className={classes.emptyIconGrey}
                              data-metrics="cloudngfw-overviewScreen-firewallsLength-CircleEmptyIcon-img"
                              size="md"
                            />
                            <span
                              className={classes.createNGFW}
                              data-metrics="cloudngfw-overviewScreen-getting-card-list-onClickOfCreateFirewall"
                            >
                              {translate(`resources.overview.createFirewalls`)}
                            </span>
                          </>
                        )}
                      </div>
                      <div className={classes.toComplete}>
                        {translate(`resources.overview.threeMins`)}
                      </div>
                    </div>
                  </>
                )}
                {!permissions?.CreateRuleStack &&
                  !onClickDismiss && getSteps }
                {onClickDismiss && (
                  <div className={classes.onClickDismiss}>
                    <Link
                      data-metrics="cloudngfw-overviewScreen-show-guide"
                      onClick={() => setOnClickDismiss(!onClickDismiss)}
                    >
                      {translate(`resources.overview.showGuide`)}
                    </Link>
                  </div>
                )}
                {getLinks}
              </Grid>
            </div>
          ) : (
            <div data-metrics="cloudngfw-overviewScreen-getting-started-body">
              <Grid
                data-metrics="cloudngfw-overviewScreen-getting-card-grid"
                className={classes.gridStyles}
              >
                {getSteps}
                {getLinks}
              </Grid>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default GettingStartedCard;
