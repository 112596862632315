import { makeStyles} from '@material-ui/core';
import { FilterBar, useFilters } from "@panwds/react-filters";
import { Button as PANWDSButton, Tooltip }  from "@panwds/react-ui";
import {InfoIcon, SyncIcon} from '@panwds/icons';
import { TableProvider, ConnectedTable, ConnectedTableLayout, ConnectedTableFilter, ConnectedGroupBySelect, ResultsSummary } from '@panwds/react-table';
import { FC, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { SaveButton } from "../../components";
import EmptyListImage from '../../images/empty_firewall.svg';
import {IDropDownActions, IFiltersConfig, IPANSearchbar, ISearchBarFilters} from '../../types';
import './PANWDSTable.css';
import _, { get, isEmpty } from 'lodash';
import PANWDSButtonDropdown from './PANWDSButtonDropdown';
import { onSearchChangesUtils } from '../../utils/search';
import { PANWDSModal } from './PANWDSModal';
import {useTranslate} from '../../customHooks';
import { CSVLink } from "react-csv";
import Skeleton from '@mui/material/Skeleton';
import {PANWDSTooltip} from "./index";


const useStyles = makeStyles((theme) => ({
  boxContainer: (props: any) => ({
    borderTop: '1px solid #DADBDB',
    borderLeft: '1px solid #DADBDB',
    borderRight: '1px solid #DADBDB',
    borderRadius: '4px 4px 0px 0px',
    background: '#fff',
    padding: 16,
    [theme.breakpoints.down(750)]: {
      padding: "16px 5px",
    },
  }),
  boxLayout: (props: any) => ({
    display: "flex",
    padding: "0",
    [theme.breakpoints.down(800)]: {
      display: props.nested ? "block" : "flex",
    },
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
    margin: 0,
  }),
  ButtonContainer: (props:any) => ({
    display: "flex",
    gap: theme.spacing(1),
    lex: '0 0 50%',
    [theme.breakpoints.down(props.nested ? 900:800)]: {
      gap: "2px",
      lex: '0 0 55%',
    },
    [theme.breakpoints.down(800)]: {
      padding: props.nested ? "5px 0" : "0",
      gap: "2px",
    },
      '& .refresh-icon': {
          padding: '0.375rem',
          '& > span': {
              marginRight: 0
          }
      }
  }),
  title: {
    color: "#333333",
    fontSize: 16,
  },
  description: {
    color: "#707070",
    fontWeight: 400,
    fontSize: 12,
    marginTop: 6,
  },
  SelectContainer: {
    position: "relative",
    display: "flex",
  },
  TitleBox: {
    display: "flex",
    flexDirection: "column",
    flex: '0 0 40%',
  },
  DropDownButton: {
    borderRadius: "4px",
    border: "1px solid #DADBDB",
    padding: "6px 16px",
    color: "#006FCC",
    background: "#FFFFFF",
    textTransform: 'capitalize',
    [theme.breakpoints.down(750)]: {
      padding: "6px 1px 6px 3px",
      "&>span": {
        justifyContent: 'center',
      }
    },
  },
  button: {
    textTransform: 'capitalize',
  },
  SearchBarStyle: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: "4px",
    border: "1px solid #DADBDB",
    padding: "0 5px",
    background: "#FFFFFF",
  },
  SearchBarIconStyle: {
    fontSize: '1rem'
  },
  cellStyles: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '20px',
  },
  infiniteScrollTableBox: (props:any) => ({
    padding: 16,
    overflowX: 'auto',
    '& div.overflow-table-container > div div.tw-flex-grow.tw-overflow-auto': {
      height: `${props.offsetTableHeight ? 'calc(100vh - ' + props.offsetTableHeight + 'px)' : '100vh'}`
    }
  }),
  paginatedScrollTableHeight: (props: any) => ({
    overflowY: 'auto',
      overflowX: 'auto',
      height: `${
        props.offsetTableHeight
          ? "calc(100vh - " + props.offsetTableHeight + "px)"
          : "100vh"
      }`,
  }),
  styleFiletrsCointainer: {
    borderBottom: '2px solid #e4e3e2',
    // "&>div": {
    //   "&>div": {
    //     "&>div": {
    //       "&>span": {
    //         "&>span": {
    //           // width: '60%',
    //         }
    //       }
    //     }
    //   }
    // }
  },
  styleFiletrsCointainerOnlySearch: {
    border: 'none',
      "&>div": {
        "&>div": {
          "&>div": {
            padding: "0",
            "&>div": {
                display: 'none',
            },
            "&>span": {
              "&>span": {
                "&>span": {
                  "&>div": {
                    "&>span": {
                      "&>button": {
                        display: 'none',
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  },
  customTableBox: (props:any) => ({
    ...props.tableContainerStyle
  }),
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
    color: "#333333",
  },
  downloadCsvStyles: {
    textDecoration: "none",
    color: '#006FCC'
  },
  iconedSubtitle: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '14px',
    color: '#707070',
    marginTop: '4px'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export interface CustomStyleProps {
  display?: string,
  flexFlow?: string,
  padding?: string,
  boxSizing?: string,
  height?: string,
  overflowX?: string,
  overflowY?: string
}

export interface PANGridProps {
  gridData: any[],
  columns: any,
  title: string,
  dataMetrics?: string,
  permissions?: any,
  loadGridData?: any,
  offsetTableHeight?: any,
  infiniteScroll?: boolean,
  subtitle?: string,
  subtitleInfo?: string,
  idProperty: string,
  loadingText?: string,
  loading?: boolean,
  handleSelectionChange?: Function,
  gridStyle?: any,
  multiSelect?: boolean,
  singleSelect?: boolean,
  checkboxColumn?: boolean,
  sortable?: boolean,
  resizable?: boolean,
  filterable?: boolean,
  pagination?: boolean,
  columnMinWidth?: number,
  onRenderRow?: any,

  toolbarTitle?: string,
  showToolbar?: boolean,
  dropDownActionsArray?: IDropDownActions[],

  hideTitle?: boolean,
  showTileTitle?: boolean;
  emptyTitle?: string,
  emptySubtitle?: string,
  groupBy?: string[],
  lockedRows?: any[],
  dataTestId: string,
  searchBar?: IPANSearchbar,
  setHandle?: any,
  nested?: boolean,
  groupByEnabled?: boolean
  rowSize?: 'cozy' | 'standard' | 'comfy',
  serachFilterRequired?: boolean
  filterBar?: ISearchBarFilters;
  searchFilterRequired?: boolean
  filterBarRequired?: boolean;
  filterConfig?: IFiltersConfig[];
  tableMinHeight?: string | number,
  enablePagination?: boolean
  isBackgroundFetching?: any
  showSelectGroupBy?: boolean,
  initialGroupBy?: string[],
  tableContainerStyle?: CustomStyleProps,
  tableInnerContainerClassName?: string,
  loadDependencies?: any[],
  overflow?: boolean,
  overflowTable?: boolean,
  useOldToolbarButtons?: boolean,
  disableRow?: Function,
  disableActionsBtn?: boolean | Function,
  loadDateFilter?: any;
  noPaginatedScrollTableHeight?: boolean;
}

 const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  let doit: any;
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', () => {
      clearTimeout(doit);
      doit = setTimeout(updateSize, 100);
    });
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const PANWDSTable: FC<PANGridProps> = (props) => {
  const translate= useTranslate();
  const [selected, setSelected] = useState<any[]>([]); // TODO to be removed
  const [width, height] = useWindowSize();
  const classes = useStyles({nested: props.nested, tableContainerStyle: props.tableContainerStyle, offsetTableHeight: props.offsetTableHeight});
  const [firstLoad, setFirstLoad] = useState(true);
  const tableInstanceRef = useRef<any>();
  const { filterBar } = props;
  const { filters } = useFilters('FilterAndTable');
  const [tableData, setTableData] = useState(props?.gridData);
  const [classNameForEmpty, setClassNameForEmpty] =  useState(false);
  const [selectedItems, setSelectedItems] = useState<never[]>([]);
  const [refreshColWidth, setRefreshColWidth] = useState(false);

  const getSelectedItems = () => {
    if(tableInstanceRef.current) {
      return tableInstanceRef.current.selectedFlatRows.map((row: any) => row.original);
    }
    return [];
  };

  const getSelectedRows = () => {
    if(tableInstanceRef.current){
      return tableInstanceRef.current.selectedFlatRows.map((row: any) => row);
    }
    return [];
  };

  const getButtonState = () => {
    if(tableInstanceRef.current){
      return tableInstanceRef?.current?.selectedFlatRows?.length > 0;
    }
    return false
  }

  // default these props to true
  let isMultiselect = props.multiSelect === undefined || props.multiSelect === true;
  let isCheckboxColumn = props.checkboxColumn === undefined || props.checkboxColumn === true;
  let isSortable = props.sortable === undefined || props.sortable === true;
  let isResizable = props.resizable === undefined || props.resizable === true;
  let isFilterable = props.filterable === undefined || props.filterable === true;
  let loading = props.loading;

  // set default column width
  let columnMinWidth = props.columnMinWidth === undefined ? 75 : props.columnMinWidth;
  let showToolbar = props.showToolbar;
  let { dropDownActionsArray } = props

  const [dropdownsState, setDropdownsState] = useState<boolean[]>([]);

  const getGridDataValue = (name: string, gridData: any) => {
    let value = get(gridData, name);
    if (value) return value + '';
    return '';
  }

  useEffect(() => {
    if(filterBar?.filterBarRequired && filterBar?.filterConfig) {
      // let searchRequired = onlySearchFilterBarRequired;
      if(filterBar?.onlySearchFilterBarRequired && filters?.searchBar !== '') {
        onSearchChangesUtils({
          columns: props?.columns,
          value: filters?.searchBar,
          gridData: props?.gridData,
          getGridDataValue,
          callback: (data: any) => {
            setTableData(data);
          }
        })
      } else {
        let newGridData = _.cloneDeep(props.gridData);

        if(!filterBar?.filterConfig) return;

        // Construct filtersObject from filterConfig and filters
        let filtersObject = filterBar.filterConfig.reduce((acc: any, filterConfigItem: any) => {
          acc[filterConfigItem.name] = filters[filterConfigItem.name] || [];
          return acc;
        }, {});

        // Apply filters
        Object.entries(filtersObject).forEach(([filterProp, filterValues]: any) => {
          if (filterValues.length > 0) {
            const columnProperty = filterBar.filterConfig.find((f: any) => f.name === filterProp)?.columnValue;

            if (columnProperty) {
              if (columnProperty.includes('.')) {
                // Nested property filter logic
                const prop = columnProperty.split('.');
                newGridData = newGridData.filter(d => {
                  if (Array.isArray(d[prop[0]][prop[1]])) {
                    return d[prop[0]][prop[1]].some((val: any) => filterValues.includes(val));
                  } else {
                    return filterValues.includes(d[prop[0]][prop[1]]);
                  }
                });
              } else {
                // Special handling for the 'Period' filter
                if (columnProperty === 'Period') {
                  newGridData = props.loadDateFilter(filterValues);
                } else if (columnProperty === 'Characteristics') {
                  // Characteristic filter logic
                  newGridData = newGridData.filter(d => {
                    let newval = displayOptions(d[columnProperty]);
                    return filterValues.some((r: any) => newval.includes(r));
                  });
                } else {
                  // Other filters logic
                  newGridData = newGridData.filter(d => {
                    if (Array.isArray(d[columnProperty])) {
                      return d[columnProperty].some((val: any) => filterValues.includes(val));
                    } else {
                      return filterValues.includes(d[columnProperty]);
                    }
                  });
                }
              }
            }
          }
        });

        // Set the filtered data
        setTableData(newGridData);
      }
    }
  }, [props, filters, filterBar?.filterConfig]); //props is required for App id table functionality

  const displayOptions = (filterOption: any) =>
  {
      let newRowsObj = [];

      for (var key in filterOption)
      {
        if(key === 'DenyAction' && filterOption[key] !== '') {
          newRowsObj.push(key);
        }
        else if (filterOption[key] === true)
          newRowsObj.push(key);
      }
      return newRowsObj;
  }

  useEffect(() => {
    let states = [];
    for (let dda in dropDownActionsArray) {
      states.push(false);
    }
    setDropdownsState(states);
  }, [dropDownActionsArray]);
  const buttonElActions = useRef(new Array(dropDownActionsArray?.length));

  const toogleDropdowns = (index: number, value?: boolean) => {
    let states: boolean[] = [];
    dropdownsState.map(dd => states.push(dd));
    states[index] = !states[index];
    setDropdownsState(states);
  }

  const [confirmDelete, setConfirmDelete] = useState<any>({open: false});
  const showConfirmModal = (type: string, callback: Function) => {
    if (type === 'delete'){
      setConfirmDelete({open: true, callback});
    }
  };

  const ConfirmDeleteModal =
    <PANWDSModal
      title={translate(`panwdsTable.confirmDelete`)}
      onClose={() => setConfirmDelete({open: false})}
      isOpen={confirmDelete.open}
      size="sm"
      dataTestId="cloudngfw-panwds-delete-confirm-modal"
      dataMetrics="cloudngfw-panwds-delete-confirm-modal"
      modalBody={translate(`panwdsTable.sureDelete`)}
      modalBodyStylingRequired
      modalFooter={{
        footerRequired: true,
        cancelButtonRequired: true,
        featureSpecificButton: <SaveButton
          appearance="primary"
          size="md"
          label={translate(`panwdsTable.confirm`)}
          onClick={() => {
                    confirmDelete.callback(getSelectedItems());
                    setConfirmDelete({open: false});
                  }}
          dataMetrics="cloudngfw-panwds-delete-confirm-modal-button"
          {...props}
      />
      }}
    />

  if(firstLoad && props.permissions && props.loadGridData) {
    const dependeciesloaded = props.loadDependencies && props.loadDependencies.every( dep => !isEmpty(dep));
    if(dependeciesloaded || !props.loadDependencies) {
      props.loadGridData();
      setFirstLoad(false);
    }
  }

  const onColumnWidthChange = (columnWidths: {[key: string]: number}) => {
    // dataTestId is required, will be used as key in the local storage
    if (props.dataTestId && Object.keys(columnWidths).length) {
      const tableWidth = document?.querySelector<HTMLElement>(`div[data-testid="${props.dataTestId ? props.dataTestId : 'connected-table'}"]`)?.offsetWidth || false;
      if (tableWidth) {
        const panwdsColumns = JSON.parse(localStorage.getItem('panwds-columns') || "{}");
        Object.keys(columnWidths).map((column:string) => {
          // we store proportion
          _.set(panwdsColumns, `${props.dataTestId}.${column}`, columnWidths[column] / tableWidth)
        })
        localStorage.setItem('panwds-columns', JSON.stringify(panwdsColumns));
      }
    }
  };

  const customWidth = (columnName:string, tableWidth: number) => {
    if (props.dataTestId) {
      try{
        const localTableInfo = JSON.parse(localStorage.getItem('panwds-columns') || "");
        const savedWidth = _.get(localTableInfo, `${props.dataTestId}.${columnName}`, false);
        if(savedWidth > 1 ) {
          return false;
        }
        if(savedWidth) {
          return tableWidth * savedWidth;
        }
        return false;
      } catch (error) {
        return false;
      }
    }
    return false;
  }


  const parseColumns = () => {
    const totalColumns = props.columns.length;
    const hasColumnWithSize = props.columns.find( (col: any) => col.columnSize )
    const tableWidth = document?.querySelector<HTMLElement>(`div[data-testid="${props.dataTestId ? props.dataTestId : 'connected-table'}"]`)?.offsetWidth || false;
    if(tableWidth) {
      props.columns.map( (col: any) => {
        const localCustomWidth = customWidth(col.accessor, tableWidth);
        if (localCustomWidth) {
          col.width = localCustomWidth;
        }else if(hasColumnWithSize) {
          col.width = col.columnSize ? (tableWidth / 12) * col.columnSize : (tableWidth / 12);
        } else {
          col.width = tableWidth / totalColumns;
        }
        col.minWidth = col.minWidth ? col.minWidth : 100;
        col.Cell = (cellProps: any) => {
          const { column, value } = cellProps;
          if(col.noTooltip || typeof value !== 'string') { // if value is not string, it'll break
            return <span className={classes.cellStyles} style={{width: column.width + 'px'}}>
              {col.render ? col.render(cellProps) : value}
            </span>
          }
          return <Tooltip label={value}><span className={classes.cellStyles} style={{width: (column.width - 10) + 'px'}}>
            {col.render ? col.render(cellProps) : value}
          </span></Tooltip>
        }
      })
      return props.columns;
    }
    return [];
  }

  const checkDisableActions = (selectedRows: any) => {
    if( selectedRows.length === 0 ){
      return true;
    }
    return (props.disableActionsBtn && typeof props.disableActionsBtn === "function" && props.disableActionsBtn(selectedRows)) ||
    typeof props.disableActionsBtn === "boolean" && props.disableActionsBtn;
  }

  useEffect(() => {
    if(props.infiniteScroll){
      const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && props.isBackgroundFetching && !loading && props.permissions && props.infiniteScroll) {
          setSelected(getSelectedRows());
          props.loadGridData();
         }
      }

      const queryString = 'div.overflow-table-container > div > div:last-child > div > div.tw-flex-grow.tw-overflow-auto';
      const allWithClass = document.querySelector(queryString);

      allWithClass?.addEventListener('scroll', handleScroll);

      return () => {
        allWithClass?.removeEventListener('scroll', handleScroll);
      }
    }
  }, [props.isBackgroundFetching, props.loading]);

  useEffect(() => {
    if(selected.length !== 0 && !loading && tableInstanceRef.current) {
      selected.forEach( selectedRow => {
        selectedRow.toggleRowSelected(true);
      })
    }
  }, [props.gridData]);

  let columns: any = parseColumns();

  useEffect(() => {
    columns = parseColumns();
    if(!refreshColWidth) {
      setRefreshColWidth(true)
    }
  }, [width, height]);

  useEffect(() => {
    setRefreshColWidth(false)
  }, [refreshColWidth]);

  let tableDataRowStyle = filterBar?.filterBarRequired ? tableData : props.gridData;

  useEffect(() => {
    if(!props.infiniteScroll && props.isBackgroundFetching && !firstLoad) {
      props.loadGridData();
    }
  }, [props.isBackgroundFetching]);

  let classNameContainer = props.infiniteScroll || props.overflowTable ? 'overflow-table-container tw-flex tw-flex-col' : '';
  classNameContainer += isMultiselect || props.singleSelect ? ' row-selection' : ' no-row-selection';
  classNameContainer = props.singleSelect ? classNameContainer + ' single-selection' : classNameContainer;
  classNameContainer = props.tableInnerContainerClassName ? classNameContainer + ' ' + props.tableInnerContainerClassName : classNameContainer;
  classNameContainer += tableDataRowStyle?.length === 0 || classNameForEmpty ? ' noDataStyles' : '';

  return (
    <div className={props.tableContainerStyle ? classes.customTableBox : classes.infiniteScrollTableBox}>
      <div className={classNameContainer}>
        <TableProvider
          columns={refreshColWidth ? [] : columns}
          data={filterBar?.filterBarRequired ? tableData : props.gridData}
          enablePagination={props?.enablePagination}
          isLoading={loading}
          //isBackgroundFetching={loading}
          enableRowSelect={isMultiselect || props.singleSelect}
          enableResizeColumns={isResizable}
          enableColumnSort={isSortable}
          rowSelectMode={props.singleSelect ? "single" : "multiple"}
          tableInstanceRef={tableInstanceRef}
          initialState={{
            groupBy: props.initialGroupBy || [],
          }}
          onTableFilterChange={() => {
            if(tableInstanceRef?.current) {
              setClassNameForEmpty(tableInstanceRef?.current?.flatRows?.length === 0);
            }
          }}
          onSelectedRowsChange={(record: any) => {
            setSelectedItems(record);
          }}
          onColumnWidthChange={onColumnWidthChange}
        >
          <ConnectedTableLayout
            //isBackgroundFetching={loading}
            dataMetrics={props.dataMetrics}
            // minimumHeight="calc(100vh - 1000px)"
            enableHeaderTitle={true}
            dataTestId={props.dataTestId ? props.dataTestId : ''}
            overflow={props.overflowTable || props.infiniteScroll}
            resultsSummary={
              props?.multiSelect && <ResultsSummary selected={getSelectedItems().length} totalRecords={props?.gridData?.length} isBackgroundFetching={props?.isBackgroundFetching && props?.isBackgroundFetching !== 'done'}/>
            }
            controlLeft={
              <>
                <div className={classes.titleHeader}>
                {
                  props.showTileTitle ?
                  <div className={classes.iconedParagraph}>
                    {props.title ?? ''}
                    {props.subtitle ? <Tooltip label={props.subtitle} defaultPlacement="right">
                      <InfoIcon size="xs" />
                    </Tooltip> : ''}
                  </div>
                  : null
                }
                {props.subtitleInfo
                      ? <div className={classes.iconedSubtitle}>{props.subtitleInfo ?? ""}</div>
                      : props.loading && props.subtitleInfo && <div className={classes.iconedSubtitle}><Skeleton variant="text" width="100px" height="15px" /></div>
                }
                {
                  props.showSelectGroupBy ?
                  <ConnectedGroupBySelect /> : null
                }
                </div>
              </>
            }
            controlRight={
              showToolbar ?
              <>
                {props.searchFilterRequired && <ConnectedTableFilter />}
                {filterBar?.filterBarRequired &&  filterBar?.onlySearchFilterBarRequired &&
                  <div className={classes.styleFiletrsCointainerOnlySearch} key={props?.dataTestId}>
                    <FilterBar
                      key={props?.dataTestId}
                      name={'FilterAndTable'}
                      filters={filterBar?.filterConfig ? filterBar?.filterConfig : []}
                      saveOnUnmount={false}
                    />
                  </div>
                }
                <section className={classes.ButtonContainer}>
                {/* select all and deselect all rows in the table */}
                {/* <BatchActionsResultsSummary showSelectedAll={true} /> */}
                {dropDownActionsArray && dropDownActionsArray?.length > 0 &&
                  dropDownActionsArray?.map((dropDownActions, ddIndex) => {
                    const ddType = dropDownActions.type ?? 'secondary';
                    if (["button", "button_secondary"].includes(ddType)) {
                      return (
                        <PANWDSButton
                          key={ddIndex}
                          // className={classes.button}
                          appearance={dropDownActions?.appearance ? dropDownActions?.appearance : 'secondary'}
                          size="md"
                          disabled={dropDownActions?.disabledAddLogic ? isEmpty(selectedItems): dropDownActions.disabled}
                          data-metrics={dropDownActions?.dataMetrics || ''}
                          //@ts-ignore
                          onClick={() => dropDownActions?.handleButtonAction(getSelectedItems())}
                          >
                            {dropDownActions.title}
                        </PANWDSButton>
                      );
                    } else if (["button_with_tooltip"].includes(ddType)) {
                        return (
                            <PANWDSTooltip showTooltip={dropDownActions?.showTooltip}
                                           label={translate("csp.registerCSP")}>
                                <div>
                                    <PANWDSButton
                                        key={ddIndex}
                                        // className={classes.button}
                                        appearance={dropDownActions?.appearance ? dropDownActions?.appearance : 'secondary'}
                                        size="md"
                                        disabled={dropDownActions?.disabledAddLogic ? isEmpty(selectedItems): dropDownActions.disabled}
                                        data-metrics={dropDownActions?.dataMetrics || ''}
                                        //@ts-ignore
                                        onClick={() => dropDownActions?.handleButtonAction(getSelectedItems())}
                                    >
                                        {dropDownActions.title}
                                    </PANWDSButton>
                                </div>
                            </PANWDSTooltip>
                        )
                    } else if(["icon"].includes(ddType)) {
                        return (
                            <PANWDSButton
                                key={ddIndex}
                                aria-label={'refresh-icon'}
                                addClassName={'refresh-icon'}
                                icon={<SyncIcon size="md" />}
                                appearance="tertiary"
                                data-metrics={dropDownActions?.dataMetrics || ""}
                                onClick={() => dropDownActions?.handleButtonAction(getSelectedItems())}
                            > </PANWDSButton>
                        );
                    } else if(["downloadCsv"].includes(ddType)) {
                      return ( <PANWDSButton
                        key='cloudngfw-action-button'
                        appearance="secondary"
                        size="md"
                        data-metrics={dropDownActions?.dataMetrics || ''}
                        disabled={dropDownActions?.disabled}
                      >
                        {!dropDownActions?.disabled
                        ? <CSVLink className={classes.downloadCsvStyles} data={props?.gridData} filename={dropDownActions?.fileName}>{dropDownActions.title}</CSVLink>
                        : dropDownActions.title
                        }
                      </PANWDSButton>
                      )
                    }
                    return (
                      <PANWDSButtonDropdown
                        key={ddIndex}
                        buttonTitle={dropDownActions.title}
                        items={
                          dropDownActions.actionsMap.map(({ menuText, handleAction, confirmModal, dataMetrics }, index) => {
                            return {
                              value: menuText,
                              context: {
                                handleAction,
                                confirmModal,
                                dataMetrics
                              }
                            }
                          })
                        }
                        disabled={dropDownActions.type === "primary" ? false : checkDisableActions(getSelectedItems())}
                        appearance={dropDownActions?.appearance ? dropDownActions?.appearance : 'secondary'}
                        dataMetrics={dropDownActions?.dataMetrics || ''}
                        onChange={(e: any) => {
                          const { selectedItem: { context: { confirmModal, handleAction }} } = e;
                          if (confirmModal){
                            showConfirmModal(confirmModal, handleAction);
                          } else {
                            handleAction(getSelectedItems());
                          }
                        }}
                      />
                    )})
                }
                { confirmDelete.open && ConfirmDeleteModal}
                </section>
              </>
              : null
            }
          >
          {filterBar?.filterBarRequired && !filterBar.onlySearchFilterBarRequired &&
              <div className={classes.styleFiletrsCointainer} key={props?.dataTestId}>
                <FilterBar
                  key={props?.dataTestId}
                  name={'FilterAndTable'}
                  filters={filterBar?.filterConfig ? filterBar?.filterConfig : []}
                  saveOnUnmount={false}
                />
              </div>
            }
            <div className={!props.noPaginatedScrollTableHeight ? classes.paginatedScrollTableHeight : ""}>
              <ConnectedTable
                shouldDisableRow={(row: { row: any }) => {
                  if(props.lockedRows) {
                    let isRowDisabled = false;
                    props.lockedRows.forEach( (locked, index) => {
                      if(index === row?.row?.index) {
                        isRowDisabled = true;
                      }
                    })
                    return isRowDisabled;
                  }
                  return (props.disableRow && props.disableRow(row)) || false;
                }}
                dataTestId={props.dataTestId}
                rowSize={props.rowSize ? props.rowSize : 'standard'}
                emptyStateProps={{
                  description: props.emptySubtitle ? props.emptySubtitle : undefined,
                  heading: props.emptyTitle ? props.emptyTitle : undefined,
                  graphic:  (
                    <img width='50px' height='50px' src={EmptyListImage} />
                  ),
                  // style: ({
                  //  marginTop: '-28%'
                  // })
                }}
              />
            </div>
          </ConnectedTableLayout>
        </TableProvider>
      </div>
    </div>
  );
}
