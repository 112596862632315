import UsersCreate from './UsersCreate';
import UsersEdit from './UsersEdit';
import UsersList from './UsersList';

const users = {
    create: UsersCreate,
    list: UsersList,
    edit: UsersEdit
};

export default users;
