import { InputSearchBarFilter } from "../../../components/Filters/InputSearchBarFilters";

export const searchFilters = (searchString: any) => [
    {
        Component: InputSearchBarFilter,
        static: true,
        required: true,
        label: "SearchBar",
        name: "searchBar",
        defaultValues: searchString ? searchString : '',
        //defaultValues: 'Global',
        searchRequired: true,
    }
];
