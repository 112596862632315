import { Typography } from "@material-ui/core";
import {
    Button as PANWDSButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Tooltip,
} from "@panwds/react-ui";
import { extend } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { usePermissions, useTranslate } from "../../customHooks";
import { useHistory, withRouter } from "react-router-dom";
import { PANTitle, toast } from "../../components";
import CircularLoader from "../../components/CircularLoader/CircularLoader";
import { PanwdsTableLightWithFilters } from "../../components/PANWDSElements";
import { dataProvider } from "../../dataProvider";
import { nameStyleCursor } from "../../layout/styles";
import { RouteUri } from "../../routeUri";
import jwt_decode from "jwt-decode";
import { InfoIcon, DownloadIcon, DeleteIcon, PlusIcon } from "@panwds/icons";
import _ from "lodash";
import ManageAccountModal from "../components/ManageAccountModal";
import { arrayOfStringsSortType } from '@panwds/react-table';

const useStyles = makeStyles((theme) => ({
    iconedParagraph: {
        display: "flex",
        alignItems: "center",
        marginTop: "5px",
        gap: "10px",
    },
}));

const AccountsList = (props: any) => {
    const nameClass = nameStyleCursor();
    const [gridData, setGridData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [accountPropertyDetails, setAccountPropertyDetails] = useState(<></>);
    const [openAccountPropertyModal, setOpenAccountPropertyModal] = useState(false);
    const [openManageXAccountRoleModal, setOpenManageXAccountRoleModal] = useState(false);
    const [upateToken, setUpdateToken] = useState("");
    const [manageXAccountRoleModalData, setManageXAccountRoleModalData] = useState({});
    const [nextToken, setNextToken] = useState<any>(undefined);
    const [recursiveLoading, setRecursiveLoading] = useState(false);

    const classes = useStyles();

    const translate = useTranslate();
    const history = useHistory();
    const title = translate(`resources.accounts.name`);


    const { permissions } = usePermissions();

    useEffect(() => {
        if (nextToken) {
            loadLinkAccount([]);
        }
    }, [nextToken]);


    const mergeArrayOfObjects = (
        xAccountData: any,
        linkAccountData: any,
        selector = "key"
    ) => {
        let result: any[] = [];
        xAccountData.forEach((dat: any) => {
            const foundIndex = linkAccountData.findIndex(
                (ori: any) => ori[selector] === dat[selector]
            );
            if (foundIndex >= 0)
                result.push(extend(dat, linkAccountData[foundIndex]));
            else result.push(dat);
        });

        linkAccountData.forEach((dat: any) => {
            const foundIndex = result.findIndex(
                (ori: any) => ori[selector] === dat[selector]
            );
            if (foundIndex === -1) result.push(dat);
        });
        return result;
    };

    const generateUpdateToken = (data: any) => {
        setLoading(true);
        const payload: any = {
            AccountId: data?.AccountId,
        };
        //@ts-ignore
        dataProvider
            .update("accounts", payload)
            .then((response) => {
                setOpenAccountPropertyModal(false);
                let updateToken = response.data;
                setUpdateToken(updateToken?.UpdateToken);
                setAccountProperty(data, updateToken?.UpdateToken);
            })
            .catch((e: any) => {
                toast.error(e?.error, { toastId: "linkaccount-update-token" });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const loadLinkAccount = (currentData: any[]) => {
        setLoading(true);
        if (!permissions?.ListLinkAccount) {
            if (permissions && !permissions?.ListLinkAccount) {
                // TODO toast is not showing, but if we add toast container it will show
                // many times until permissions is loaded, need to rethink this logic
                toast.warning(translate("permissions.cantView"));
            }
            setLoading(false);
            return;
        }
        dataProvider.getList("accounts", {
            data: { NextToken: (nextToken) ? nextToken : undefined }
        })
            .then((values: any) => {
                const XAccountDetails = values.data.XAccountDetails;
                const AccountDetails = values.data.AccountDetails;
                let accountData = mergeArrayOfObjects(
                    XAccountDetails,
                    AccountDetails,
                    "AccountId"
                );
                let index: number = Math.floor(100000 + Math.random() * 900000);
                accountData = accountData.map((data) => ({
                    id: index++,
                    ...data,
                }));
                if (!values?.nextToken) {
                    setRecursiveLoading(false);
                }
                if (!nextToken) { // it is first call, discard any prevData
                    setGridData([...accountData]);
                    setRecursiveLoading(true);
                } else {
                    setGridData([...gridData, ...accountData]);
                }
                setNextToken(values?.nextToken);
                setLoading(false);
            })
            .catch((e: any) => {
                if (e?.noToast) {
                    return;
                }
                toast.error(e?.error, { toastId: "xaccountroles-getList" });
                setLoading(false);
            });
    };

    const getManageAccountModal = () => {
        return (
            <ManageAccountModal
                isOpenTags={openManageXAccountRoleModal}
                setOpenTags={setOpenManageXAccountRoleModal}
                rowData={manageXAccountRoleModalData}
                callBackList={(values: any) => handleSave(values)}
                loading={loading}
            />
        );
    };

    useEffect(() => {
        return () => {
            dataProvider.abort("accounts");
            dataProvider.abort("xaccountroles");
        };
    }, []);

    useEffect(() => {
        loadLinkAccount([]);
    }, [permissions]);

    const prepareData = (rowData: any) => {
        const buidConstraintsXAccountIAMRoles = (data: any) => {
            let roles = [];
            if (data?.RuleStack?.NetworkMonitoringRole) {
                roles.push(
                    translate(
                        `resources.accounts.fields.NetworkMonitoringRole`
                    )
                );
            }
            if (data?.RuleStack?.DecryptionRole) {
                roles.push(
                    translate(`resources.accounts.fields.DecryptionRole`)
                );
            }
            if (data?.Firewall?.EndpointRole) {
                roles.push(
                    translate(`resources.accounts.fields.EndpointRole`)
                );
            }
            if (data?.Firewall?.LoggingRole) {
                roles.push(
                    translate(`resources.accounts.fields.LoggingRole`)
                );
            }
            return (
                <Tooltip label={roles.join(", ")}>
                    <span>{roles.join(", ")}</span>
                </Tooltip>
            );
        }

        const buidConstraintsOnboardingStatus = (data: any) => {
            if (data.OnboardingStatus === "Pending") {
                return (
                    <a
                        href={data?.CloudFormationTemplateURL}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {data?.OnboardingStatus}
                    </a>
                );
            } else if (data?.OnboardingStatus === "UnsubscriptionPending") {
                return (
                    <div className={classes.iconedParagraph}>
                        <span>{data?.OnboardingStatus} </span>
                        <Tooltip
                            label={
                                <>
                                    <strong>
                                        {translate(
                                            `resources.accounts.fields.UnsubscribePendingTooltip1`
                                        )}
                                    </strong>
                                    <div>
                                        {translate(
                                            `resources.accounts.fields.UnsubscribePendingTooltip2`
                                        )}
                                    </div>
                                    <div>
                                        {translate(
                                            `resources.accounts.fields.UnsubscribePendingTooltip3`
                                        )}
                                    </div>
                                    <div>
                                        {translate(
                                            `resources.accounts.fields.UnsubscribePendingTooltip4`
                                        )}
                                    </div>
                                </>
                            }
                        >
                            <InfoIcon size="xs" />
                        </Tooltip>
                    </div>
                );
            } else {
                return <span>{data?.OnboardingStatus}</span>;
            }
        }

        const buidConstraintsPlaceholder = (data: any) => {
            let actions = [];
            actions.push(
                <Tooltip
                    label={translate(
                        `resources.accounts.fields.ManageXAccountRoles`
                    )}
                >
                    <div
                        className="tw-cursor-pointer"
                        onClick={() => setXAccountRoleArn(data)}
                    >
                        <PlusIcon
                            className="tw-text-gray-600 dark:tw-text-dark-bg"
                            size="md"
                            data-metrics="cloudngfw-users-manageXAccountRole-btn"
                        />
                    </div>
                </Tooltip>
            );
            if (data?.CloudFormationTemplateURL) {
                let key = "templateURL";
                let start =
                    data?.CloudFormationTemplateURL.indexOf(key);
                if (start === -1) {
                    return <></>;
                }
                start += key.length + 1;
                let end =
                    data?.CloudFormationTemplateURL.substr(
                        start
                    ).indexOf("&");
                let cftUrl = data?.CloudFormationTemplateURL.substr(
                    start,
                    end
                );
                cftUrl = decodeURIComponent(cftUrl);
                actions.push(
                    <Tooltip
                        label={translate(
                            `resources.accounts.fields.DownloadCFT`
                        )}
                    >
                        <a href={cftUrl} target="_blank" rel="noreferrer">
                            <DownloadIcon
                                className="tw-text-gray-600 dark:tw-text-dark-bg"
                                size="md"
                                data-metrics="cloudngfw-users-cft-btn"
                            />
                        </a>
                    </Tooltip>
                );
            }
            if (
                !permissions?.AssociateGlobalRuleStack &&
                permissions?.DeleteLinkAccount
            ) {
                actions.push(
                    <Tooltip
                        label={translate(`resources.accounts.fields.Delete`)}
                    >
                        <div
                            className="tw-cursor-pointer"
                            onClick={() => deleteAction(data)}
                        >
                            <DeleteIcon
                                className="tw-text-gray-600 dark:tw-text-dark-bg"
                                size="md"
                                data-metrics="cloudngfw-users-delete-btn"
                            />
                        </div>
                    </Tooltip>
                );
            }
            return (
                <div className="tw-flex tw-items-center tw-gap-4">
                    {actions.map((action, index) => (
                        <div key={index}>{action}</div>
                    ))}
                </div>
            );
        };

        const value = rowData.map((data: any) => ({
            AccountId: data?.AccountId,
            AccountIdJSX:
                data?.OnboardingStatus === "UnsubscriptionPending" ? (
                    <span>{data?.AccountId}</span>
                ) : (
                    <span
                        className={nameClass.blueColor}
                        onClick={(evt) => onRowClick(data, evt)}
                    >
                        {data?.AccountId}
                    </span>
                ),
            XAccountIAMRoles: buidConstraintsXAccountIAMRoles(data),
            AccountProperty: (
                <span
                    onClick={() => setAccountProperty(data)}
                    className={nameClass.blueColor}
                >
                    {translate(`resources.accounts.fields.CheckDetails`)}
                </span>
            ),
            OnboardingStatus: buidConstraintsOnboardingStatus(data),
            placeholder: buidConstraintsPlaceholder(data)
        }));

        return value;
    };

    const getRowValueByColumnID = (
        row: any,
        columnId: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
      ) => {
        return row.values[columnId];
      }

    let columns = [
        {
            id: "AccountId",
            accessor: "AccountId",
            Header: translate(`resources.accounts.fields.AccountId`),
            columnSize: 2,
            minWidth: 120,
            sortType: 'basic',
            Cell: ({ row }: any) => <>{row?.original?.AccountIdJSX}</>
        },
        {
            id: "XAccountIAMRoles",
            accessor: "XAccountIAMRoles",
            Header: translate(`resources.accounts.fields.XAccountIAMRoles`),
            columnSize: 4,
            minWidth: 175,
            sortType: arrayOfStringsSortType,
            noTooltip: true,
        },
        {
            id: "AccountProperty",
            accessor: "AccountProperty",
            Header: translate(`resources.accounts.fields.AccountProperty`),
            columnSize: 2,
            minWidth: 150,
            noTooltip: true,
            disableSortBy: true
        },
        {
            id: "OnboardingStatus",
            accessor: "OnboardingStatus",
            Header: translate(`resources.accounts.fields.OnboardingStatus`),
            columnSize: 2,
            sortType: (
                rowA: any,
                rowB: any,
                columnId: string
            ) => {
                const a = getRowValueByColumnID(rowA, columnId)?.props?.children || "";
                const b = getRowValueByColumnID(rowB, columnId)?.props?.children || "";
                return a === b ? 0 : a > b ? 1 : -1;
            },
            noTooltip: true,
        },
        {
            accessor: "placeholder",
            Header: translate(`resources.accounts.fields.Actions`),
            columnSize: 2,
            noTooltip: true,
            disableSortBy: true
        }
    ];

    const setAccountProperty = (data: any, token: string = "") => {
        setAccountPropertyDetails(
            <>
                <div style={{ wordWrap: "break-word" }}>
                    <p>
                        <Typography variant="body2">External ID:</Typography>
                    </p>
                    <Input allowCopy readOnly value={data?.ExternalId} />
                    <p>
                        <Typography variant="body2">
                            CloudNGFW Account ID:
                        </Typography>
                    </p>
                    <Input allowCopy readOnly value={data?.ServiceAccountId} />
                    <p>
                        <Typography variant="body2">SNS Topic ARN:</Typography>
                    </p>
                    <Input allowCopy readOnly value={data?.SnsTopicArn} />
                    <p>
                        <Typography variant="body2">Token:</Typography>
                    </p>
                    {upateToken || token || data?.UpdateToken ? (
                        <>
                            <Input
                                allowCopy
                                readOnly
                                value={upateToken || token || data?.UpdateToken}
                            />
                            <Typography variant="caption" color="textSecondary">
                                This token is valid until{" "}
                                {
                                    //@ts-ignore
                                    new Date(
                                        jwt_decode(
                                            upateToken ||
                                            token ||
                                            data?.UpdateToken
                                        )["exp"] * 1000
                                    ).toString()
                                }
                            </Typography>
                        </>
                    ) : (
                        <PANWDSButton
                            disabled={!permissions?.CreateUpdateToken}
                            color="secondary"
                            size="sm"
                            onClick={() => generateUpdateToken(data)}
                        >
                            Generate Update Token
                        </PANWDSButton>
                    )}
                </div>
            </>
        );
        setOpenAccountPropertyModal(true);
    };

    const setXAccountRoleArn = (data: any) => {
        setOpenManageXAccountRoleModal(true);
        setManageXAccountRoleModalData(data);
    };

    const deleteAction = (selected: any) => {
        setLoading(true);
        if (selected && Array.isArray(selected)) {
            selected.map((item: any) => {
                if (item?.AccountId) {
                    dataProvider
                        .delete("accounts", {
                            id: item?.AccountId,
                            previousData: item,
                        })
                        .then(async (response: any) => {
                            window.location.reload();
                        })
                        .catch((e: any) => {
                            toast.error(e?.error, {
                                toastId: "accounts-delete",
                            });
                            setLoading(false);
                        });
                }
                return item;
            });
        } else if (selected && selected?.AccountId) {
            dataProvider
                .delete("accounts", {
                    id: selected?.AccountId,
                    previousData: selected,
                })
                .then(async (response: any) => {
                    window.location.reload();
                })
                .catch((e: any) => {
                    toast.error(e?.error, { toastId: "accounts-delete" });
                    setLoading(false);
                });
        }
    };

    const onRowClick = useCallback(
        (rowProps, event) => {
            history.push(
                RouteUri.AccountEdit.replace(":id", rowProps.AccountId)
            );
        },
        [history]
    );

    const toolbarActions: any[] = [];
    // { menuText: translate(`resources.accounts.fields.ManageXAccountRoles`), handleAction : setOpenManageXAccountRoleModal(true), dataMetrics: "cloudngfw-users-manageXAccountRole-btn", },
    if (permissions?.CreateLinkAccount) {
        toolbarActions.push({
            title: translate(`resources.accounts.fields.CreateButton`),
            actionsMap: [],
            handleButtonAction: () => history.push(RouteUri.AccountCreate),
            dataMetrics: "cloudngfw-accounts-create-btn",
            type: "button",
            appearance: "primary",
            disabled:
                permissions &&
                permissions.hasOwnProperty("AssociateGlobalRuleStack"),
        });
    }

    const handleSave = (toolBarProps: any) => {
        let payload: any = {};
        if (toolBarProps?.Firewall !== undefined) {
            payload = { Firewall: toolBarProps?.Firewall };
        }
        if (toolBarProps?.RuleStack !== undefined) {
            payload = { ...payload, RuleStack: toolBarProps?.RuleStack };
        }
        setLoading(true);
        dataProvider
            .update("xaccountroles", payload)
            .then(async (response: any) => {
                if (response?.data) {
                    loadLinkAccount([]);
                } else {
                    toast.error(response?.error, {
                        toastId: "xaccountrole-update",
                    });
                }
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, {
                    toastId: "xaccountrole-update",
                });
            })
            .finally(() => {
                setOpenManageXAccountRoleModal(false);
                setLoading(false);
            });
    };

    const manageAccountModal = getManageAccountModal();

    return (
        <>
            <PANTitle divider title={title} paddingContainer="16px" />
            <PanwdsTableLightWithFilters
                columns={columns}
                gridData={prepareData(gridData)}
                title={title}
                subtitle={translate(`resources.accounts.fields.ListSubtitle`)}
                showToolbar={true}
                dropDownActionsArray={toolbarActions}
                showTileTitle={true}
                emptyTitle={translate(`resources.accounts.fields.EmptyTitle`)}
                emptySubtitle={translate(
                    `resources.accounts.fields.EmptySubtitle`
                )}
                loading={loading}
                backgroundLoading={nextToken}
                singleSelect={false}
                multiSelect={false}
                offsetTableHeight={160}
                overflowTable={true}
                dataMetrics="cloudngfw-account-table"
                dataTestId="cloudngfw-account-table"
                resizable={true}
                enablePagination={true}
                searchFilterRequired
                disableActionsBtn={(rowsSelected: any) => {
                    return (
                        rowsSelected.filter(
                            (row: any) =>
                                row.OnboardingStatus === "UnsubscriptionPending"
                        ).length > 0
                    );
                }}
            />
            <Modal
                onClose={() => setOpenAccountPropertyModal(false)}
                isOpen={openAccountPropertyModal}
                size="md"
                dataMetrics="account-proprty-modal"
            >
                <ModalHeader
                    title={translate(
                        "resources.accounts.modal.AccountPropertyDetails"
                    )}
                    dataMetrics="account-property-modalHeader"
                    enableClose={true}
                />
                <ModalBody dataMetrics="account-property-modalBody">
                    {accountPropertyDetails}
                    <CircularLoader loading={loading} />
                </ModalBody>
                <ModalFooter>
                    <ModalCloseButton>Close</ModalCloseButton>
                </ModalFooter>
            </Modal>
            {openManageXAccountRoleModal && manageAccountModal}
        </>
    );
};

export default withRouter(AccountsList);
