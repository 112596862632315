import {
    Button as MuiButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps } from '@material-ui/core/Button';
import { FC, ReactElement } from 'react';
import classnames from 'classnames';

interface ExtendedIconProps {
    icon: ReactElement
};

type IconButtonProps = ButtonProps & ExtendedIconProps;

const useStyles = makeStyles(
    {
        button: {
            border: '1px solid #DADBDB',
            minWidth: '10px',
            padding: '4px',
            margin: '3px',
        },
    },
);

const IconButton: FC<IconButtonProps> = props => {
    const {
        color,
        disabled,
        className,
        size,
        icon,
        onClick,
    } = props;
    const classes = useStyles(props);

    return (<MuiButton
        style={{ textTransform: 'capitalize' }}
        className={classnames(classes.button, className)}
        color={color}
        size={size}
        disabled={disabled}
        onClick={onClick
        }
    >
        {icon}
    </MuiButton>
    );
};

export default IconButton;