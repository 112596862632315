// TODO: remove this code from here
import * as EndPoints from "./FwaasUriEndPoints";
import {ApplicationConfigManager} from "../types";
import EventEmitter from "../eventEmitter";
import {authProvider} from "../authProvider";

const isSuccess = (response: any): boolean => {
    return (response?.ResponseStatus?.ErrorCode === EndPoints.API_STATUS)
}

const buildError = (response: any, exception: any, options: any = {}) => {
    let errInfo;
    if ((response && response.status === 403) || (exception && exception?.body?.status === 403)) {
        console.log("Access Denied with 403, logout triggered :" + response.status);
        ApplicationConfigManager.getInstance().clearSession();
        EventEmitter.emit("logoutUser", () => {});
        authProvider.logout(null);
        errInfo = {
            code: response.status,
            error: response?.json?.message || "Session expired, please login again"
        };
    } else {
        if (response) {
            errInfo = {
                code: response.status,
                error: response?.json?.ResponseStatus?.Reason || "Something went wrong!"
            };
        } else {
            let message = exception?.body?.ResponseStatus?.Reason || exception?.body?.message || exception?.message || exception?.error || exception?.json?.message;
            errInfo = {
                code: 0,
                error: message || exception || "Something went wrong!"
            };
        }
    }
    return { ...errInfo, ...options };
}

export {isSuccess, buildError};
