import { SelectFilter } from "@panwds/react-filters";
import type { SelectFilterProps } from "@panwds/react-filters";
import { makeStyles } from "@material-ui/core";

const getItems = (props: SelectFilterProps) => {
  const items: any[] = [];
  props?.items && props?.items?.forEach((item: any) => {
    items.push({
      key: item,
      value: item,
      children: item
    });
  })
  return items;
}

const useStyles = makeStyles((theme) => ({
  filterStyles: {
    width: "25%",
    zIndex: 1
  },
}));

export const FilterComponent = (props: any) => {
  const classes = useStyles();
  return <SelectFilter addClassName={props.enableStyles ? classes.filterStyles : ""} key={props.dataTestId} {...props} items={getItems(props)} enableMultiSelect enableSelectAll enableSearch />;
}
