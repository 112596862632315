import {Row} from "../../../components/FormElements";
import {FormControl} from "@material-ui/core";
import {Field, FormSpy} from "react-final-form";
import {PANWDSForm, PANWDSInput, PANWDSRadioField, PANWDSSelectWithSearch} from "../../../components/PANWDSElements";
import {composeValidators, isRequired, maxLength} from "../../../utils/validate";
import {InfoIcon, LinkSimpleIcon, LinkSimpleSlashIcon} from "@panwds/icons";
import {generateIntegrationsMap, generateSNtoCSPIDMap, getCSPIdFromSN, getValuesForSecondarySN} from "../utils";
import {useTranslate} from "../../../customHooks";
import {Button} from "@panwds/react-ui";
import {SaveButton} from "../../../components";
import _ from "lodash";
import {OnChange} from "react-final-form-listeners";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    iconedParagraph: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        gap: '10px',
    },
    toolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: '16px',
        borderTop: "1px solid #DADBDB",
        marginTop: 16,
    },
    innerToolbar: {
        display: "flex",
        gap: theme.spacing(1),
    },
    notes: {
        fontSize: 12,
        color: '#333333',
    },
}));

export const PanoramaForm = (props: any) => {
    const {close, cancel, panoramaRecord, record, saveRecord, taskInProgress, regions, unlink} = props;
    const translate = useTranslate();
    const classes = useStyles();
    const isEditMode = !!record?.Panorama;
    const linkCompleted = !!record?.Panorama?.SecondarySN;
    const integrationsAll = panoramaRecord?.IntegrationsAll || [];

    const SNcspIdMap: any = generateSNtoCSPIDMap(integrationsAll);
    const integrationMap = generateIntegrationsMap(integrationsAll);

    const SaveToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        return (
            <div className={(panoramaRecord) ? classes.toolbar : ""}>
                {isEditMode
                    ? <Button
                        size="md"
                        appearance="secondary-destructive"
                        disabled={taskInProgress}
                        onClick={unlink}
                        dataMetrics="cloudngfw-integrations-panorama-unlink"
                    >
                        {translate(`resources.integrations.unlink`)}
                    </Button>
                    : <div></div>}
                {panoramaRecord &&
                    <div className={classes.innerToolbar}>
                        <Button
                            size="md"
                            appearance="secondary"
                            disabled={taskInProgress}
                            onClick={() => cancel()}
                            dataMetrics="cloudngfw-integrations-panorama-cancel"
                        >
                            {translate(`resources.integrations.cancel`)}
                        </Button>
                        <SaveButton
                            appearance="primary"
                            size="md"
                            label={translate('resources.integrations.continue')}
                            submitOnEnter={true}
                            loading={taskInProgress}
                            disabled={taskInProgress || linkCompleted}
                            dataMetrics="cloudngfw-integrations-panorama-continue"
                            {...toolbarProps}
                        />
                    </div>}
            </div>
        );
    };

    const findByValue = (obj: any, val: any) => Object.keys(obj).filter((k: string) => obj[k] === val)[0];

    const preSaveRecord = (values: any) => {
        const isCDLEnabled = !!integrationMap[values.PrimarySN].CDL.Enabled;
        const PrimarySN = integrationMap[values.PrimarySN].Panorama.SerialNumber;
        const SecondarySN = values.SecondarySN && integrationMap[values.SecondarySN].Panorama.SerialNumber;
        if (isCDLEnabled) { // OLD FLOW PRESERVED
            saveRecord({
                LinkName: values.LinkName,
                Panorama: {
                    PrimarySN,
                    ...(SecondarySN ? { SecondarySN } : {}),
                    CspSupportAccountId: getCSPIdFromSN(PrimarySN, integrationsAll),
                },
                LogAnalytics: {
                    Enabled: true
                }
            });
        } else { // NON CDL FLOW
            if (values.configure === 'cancel') {
                close()
            } else {
                saveRecord({
                    LinkName: values.LinkName,
                    Panorama: {
                        PrimarySN,
                        ...(SecondarySN ? { SecondarySN } : {}),
                        CspSupportAccountId: getCSPIdFromSN(PrimarySN, integrationsAll),
                        Region: values.region,
                    },
                    LogAnalytics: {
                        Enabled: false,
                    }
                });
            }
        }
    };

    const findKey = (sn: string) => {
        return _.findKey(integrationMap, (o) => o.Panorama.SerialNumber === sn);
    }


    const regionOptions =  Object.keys(regions).map(k => {
        return { text:regions[k], value: k };
    });

    let initialValues: any = undefined;
    try {
        initialValues = (
            record?.Panorama
                ? {
                    PrimarySN: findKey(record.Panorama.PrimarySN),
                    SecondarySN: findKey(record.Panorama.SecondarySN),
                    configure: "agree",
                    region: [regions[record.Panorama.Region || record.LogAnalytics.CortexDataLake.Region]]
                }
                : undefined
        );
    } catch (e) { }

    //@ts-ignore
    const WhenFieldChanges = ({ field, becomes, set, to }) => (
        <Field name={set} subscription={{}}>
            {(
                { input: { onChange } }
            ) => (
                <FormSpy subscription={{}}>
                    {({ form }) => (
                        <OnChange name={field}>
                            {() => {
                                if (becomes) {
                                    onChange(to);
                                }
                            }}
                        </OnChange>
                    )}
                </FormSpy>
            )}
        </Field>
    );

    const isPrimarySNCDLEnabled = (formData: any) => {
        let isEnabled: any = undefined;
        if (formData.PrimarySN) {
            isEnabled = !!integrationMap[formData.PrimarySN]?.CDL?.Enabled;
        }
        return isEnabled;
    };

    const allIntegrationsCount = integrationsAll.reduce((a: any, c: any) => a + c.Integrations.length, 0);

    const primarySNOptions = Object.keys(integrationMap).map(k => {
        if (integrationMap[k].CDL?.Enabled) {
            return { value: k, iconLeft: <LinkSimpleIcon size="sm" /> };
        }
        return { value: k, iconLeft: <LinkSimpleSlashIcon size="sm" /> };
    });

    return (
        <PANWDSForm
            toolbar={<SaveToolbar />}
            onSubmit={preSaveRecord}
            initialValues={initialValues}
        >
            {panoramaRecord &&
                <Row>
                    <FormControl fullWidth>
                        <Field
                            title={translate(`resources.integrations.panel.LinkName`)}
                            name="LinkName"
                            // @ts-ignore
                            component={PANWDSInput}
                            required
                            inputProps={{ "data-test-id": "integrations-link-name" }}
                            validate={composeValidators(isRequired, maxLength(128))}
                            dataMetrics="integrations-link-name"
                            row
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <Field
                            name="PrimarySN"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.integrations.panel.primaryPanoramaSN`)}
                            items={primarySNOptions}
                            required
                            validate={isRequired}
                            disabled={taskInProgress || isEditMode}
                        />
                    </FormControl>

                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.PrimarySN && values.PrimarySN === values.SecondarySN) {
                                return (<div className={classes.iconedParagraph}>
                                    <InfoIcon size="sm" />
                                    {translate(`resources.integrations.panel.linkingFailed`)}
                                </div>);
                            } else return null;
                        }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (allIntegrationsCount > 1 && values.PrimarySN !== undefined) {
                                return (
                                    <FormControl fullWidth>
                                        <Field
                                            name="SecondarySN"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.integrations.panel.haPeerSerialNumber`)}
                                            items={getValuesForSecondarySN(values.PrimarySN, integrationMap, SNcspIdMap)}
                                            disabled={taskInProgress || (isEditMode && initialValues.SecondarySN)}
                                        />
                                    </FormControl>);
                            } else return null;
                        }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (isPrimarySNCDLEnabled(values) === false) {
                                return <>
                                    <FormControl fullWidth>
                                        <div className={classes.notes}>
                                            {translate(`resources.integrations.panel.panoramaNotEnabled`)}
                                        </div>
                                        <Field
                                            name="configure"
                                            // @ts-ignore
                                            component={PANWDSRadioField}
                                            options={[
                                                {
                                                    label: translate(`resources.integrations.panel.panoramaCancel`),
                                                    value: "cancel"
                                                },
                                                {
                                                    label: translate(`resources.integrations.panel.panoramaAgree`),
                                                    value: "agree"
                                                },
                                                // { label: translate(`resources.integrations.panel.panoramaAllow`), value: "allow" }
                                            ]}
                                            validate={isRequired}
                                            required
                                            disabled={taskInProgress || isEditMode}
                                        />
                                    </FormControl>
                                </>
                                    ;
                            } else return null;
                        }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.configure === "agree" && isPrimarySNCDLEnabled(values) === false) {
                                return (
                                    <FormControl fullWidth>
                                        <Field
                                            name="region"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.integrations.panel.selectRegion`)}
                                            items={regionOptions}
                                            required
                                            validate={isRequired}
                                            disabled={taskInProgress || isEditMode}
                                        />
                                    </FormControl>);
                            } else return null;
                        }}
                    </FormSpy>
                    <WhenFieldChanges
                        field="PrimarySN"
                        becomes={true}
                        set="SecondarySN"
                        to={""}
                    />
                    <div className={classes.notes}>
                        <strong>{translate(`resources.integrations.note`)}: </strong>{translate(`resources.integrations.panel.notePM`)}
                    </div>
                </Row>
            }
        </PANWDSForm>
    );
};

export default PanoramaForm;
