import { makeStyles } from "@material-ui/core/styles";
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { toast } from '../../components';
import { dataProvider } from "../../dataProvider";
import { nameStyleCursor } from '../../layout/styles';
import { RouteUri } from '../../routeUri';
import { GetRulesListData } from '../../utils/GetRulesListData';
import { PANWDSTableLight } from '../../components/PANWDSElements';
// @ts-ignore
import { flatPropertiesAndArrayValue, actionMapping } from './Utils/functions';
import { RulePriorityEdit } from './RulesPriorityEdit';
import { ITableToolbar } from "../../types";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: "transparent",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    padding: "0 16px 0 0",
    alignItems: 'baseline',
    marginTop: '0px',
    minHeight: 'initial !important',
  },
  backdrop: {
    position: "absolute",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  cellStyles: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '20px',
  },
}));

interface RuleListProps {
  scope: "Local" | "Global" | undefined,
  ruleStackName: string,
  readOnly?: boolean,
  triggerDeleteRule?: (deleteRuleTriggered: any) => void,
  checkboxColumn?: boolean,
  showToolbar?: boolean,
  firewallName?: string, // if set, will be used to redirect to firewall page
};

const RulesList = ({
  scope,
  ruleStackName,
  readOnly = false,
  triggerDeleteRule = () => { },
  checkboxColumn = true,
  showToolbar = true,
  firewallName = ""
}: RuleListProps) => {
  const classes = useStyles();
  const nameClass = nameStyleCursor();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [localGridData, setLocalGridData] = useState([]);
  const [globalPreGridData, setGlobalPreGridData] = useState([]);
  const [globalPostGridData, setGlobalPostGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskInProgress, setTaskInProgress] = useState(false);
  const [nextToken, setNextToken] = useState<any>(undefined);
  const [preNextToken, setPreNextToken] = useState<any>(undefined);
  const [postNextToken, setPostNextToken] = useState<any>(undefined);
  const [recursiveLoading, setRecursiveLoading] = useState(false);
  const [gridMode, setGridMode] = useState<"list" | "editPriority">("list");
  const history = useHistory();

  const search = useLocation().search;
  const accountId = new URLSearchParams(search).get('AccountId');

  const loadData = () => {
    GetRulesListData(permissions, false, scope, setLoading,
      ruleStackName, setLocalGridData, setGlobalPreGridData,
      setGlobalPostGridData, setRecursiveLoading, setNextToken, setPreNextToken, setPostNextToken,
      localGridData, globalPreGridData, globalPostGridData, nextToken, preNextToken, postNextToken);
  }

  const deleteAction = async (selected: any) => {
    if (!permissions?.DeleteSecurityRule) {
      toast.warning(translate("permissions.cantExecute"));
      return;
    }
    if (selected && Array.isArray(selected)) {
      const calls: any = [];
      const ruleNames: string[] = [];
      setTaskInProgress(true);
      selected.map((item: any) => {
        if (item.RuleName) {
          calls.push(dataProvider.delete("rules", { id: item.RuleName, previousData: item }));
          ruleNames.push(item.RuleName);
        }
        return item;
      });
      await Promise.all(calls)
        .then((response) => {
          toast.success("Rules deleted succesfully.");
          // we remove data only fron the grid states
          removeFromGridData(ruleNames);
          triggerDeleteRule(true);
        })
        .catch((e) => toast.error(e?.error?.error, { toastId: "rules-delete" }))
        .finally(() => {
          setTaskInProgress(false);
          setRecursiveLoading(false)
        });
    }
  };

  const removeFromGridData = (ruleNames: string[]) => {
    if (localGridData.length) {
      setLocalGridData([...localGridData.filter((rs: any) => !ruleNames.includes(rs.Rule?.RuleName))]);
    }
    if (globalPreGridData.length) {
      setGlobalPreGridData([...globalPreGridData.filter((rs: any) => !ruleNames.includes(rs.Rule?.RuleName))]);
    }
    if (globalPostGridData.length) {
      setGlobalPostGridData([...globalPostGridData.filter((rs: any) => !ruleNames.includes(rs.Rule?.RuleName))]);
    }
  }

  useEffect(() => {
    if (scope) {
      setLoading(true);
      setRecursiveLoading(true);
      loadData();
    }
  }, []);

  useEffect(() => {
    if (nextToken || preNextToken || postNextToken) {
      loadData();
    }
  }, [nextToken,  preNextToken, postNextToken]);

  const toolbarActions: ITableToolbar[] = [];
  toolbarActions.push({
    type: "button",
    title: "Move Rule",
    dataMetrics: "cloudngfw-rules-move-priority-btn",
    action: () => setGridMode('editPriority'),
    appearance: 'secondary',
    disabled: recursiveLoading || taskInProgress || (localGridData.length < 2 && (globalPreGridData.length < 2 && globalPostGridData.length < 2)),
  });
  if (permissions?.DeleteSecurityRule) {
    toolbarActions.push({
      type: "dropdown",
      title: translate(`common.actions`),
      dataMetrics: "cloudngfw-rules-actions-btn",
      actions: [{ title: translate(`common.delete`), action: deleteAction, dataMetrics: "cloudngfw-rules-delete-btn" },],
      requireSelectedRows: true,
    });
  }
  if (scope && scope !== 'Global' && permissions?.CreateSecurityRule) {
    toolbarActions.push({
      type: 'button',
      title: translate(`resources.rules.fields.CreateButton`),
      dataMetrics: "cloudngfw-rules-create-local-btn",
      action: () => history.push(RouteUri.RuleCreate.replace(":rulestackname", ruleStackName) + "?rulelistname=LocalRule"),
      appearance: 'primary'
    });
  } else if (scope && scope !== 'Local') {
    toolbarActions.push({
      type: "dropdown",
      title: "Create Rule",
      actions: [
        { title: "Pre Rule", action: () => { history.push(RouteUri.RuleCreate.replace(":rulestackname", ruleStackName) + "?rulelistname=PreRule") }, dataMetrics: "cloudngfw-rules-create-global-pre-btn", },
        { title: "Post Rule", action: () => { history.push(RouteUri.RuleCreate.replace(":rulestackname", ruleStackName) + "?rulelistname=PostRule") }, dataMetrics: "cloudngfw-rules-create-global-post-btn", }
      ],
      appearance: 'primary',
    });
  }

  const onRowClick = useCallback((rowData) => {
    if (!permissions?.DescribeSecurityRule) {
      return;
    }
    if (firewallName) {
      // click from firewall page
      history.push(RouteUri.NGFirewallRulesUsage.replace(":firewallname", firewallName)
        .replace(":rulename", rowData.Rule.RuleName) +
        "?rulestackname=" + rowData.RuleStackName +
        "&rulelistname=" + rowData.RuleListName +
        "&AccountId=" + accountId +
        "&priority=" + rowData.Priority);
    } else {
      history.push(RouteUri.RuleEdit.replace(":rulestackname", ruleStackName)
        .replace(":rulename", rowData.Rule.RuleName) +
        "?rulelistname=" + rowData.RuleListName +
        "&priority=" + rowData.Priority);
    }
  }, []);

  const customSourceStyle = (cellProps: any) => {
    const { data } = cellProps;
    return data?.Rule?.NegateSource ? {
      textDecoration: 'line-through'
    } : {}
  };

  const prepareData = (rowData: any) => {
    const buidConstratints = (data: any) => {
      const constraints = [];
      if (data.Rule?.Applications.length > 0) {
        constraints.push(`Application: ${data.Rule?.Applications.toString()}`);
      }
      if (!isEmpty(data.Rule?.Category)) {
        constraints.push(`URL Category: ${Object.values(data.Rule?.Category).join(", ")}`);
      }
      if (data.Rule?.Protocol) {
        constraints.push(`Protocol: ${data.Rule?.Protocol}`);
      } else if (data.Rule?.ProtPortList.length > 0) {
        constraints.push(`Protocol: ${data.Rule?.ProtPortList.join(", ")}`);
      }
      return <span title={decodeURI(constraints.join('%0A'))} className={classes.cellStyles}>{constraints.join(', ')}</span>;
    }

    const value = rowData.map((data: any) => ({
      RuleName: data.Rule?.RuleName,
      RuleStackName: data.RuleStackName,
      Priority: data.Priority,
      RuleNameJSX: (readOnly
        ? <span>{data.Rule?.RuleName}</span>
        : <span className={`${classes.cellStyles} ${nameClass.blueColor}`} title={data.Rule?.RuleName} onClick={(evt) => onRowClick(data)}>{data.Rule?.RuleName}</span>),
      Enabled: data.Rule?.Enabled ? translate(`generic.yes`) : translate(`generic.no`),
      Source: flatPropertiesAndArrayValue(data.Rule?.Source),
      SourceJSX: (<span
        style={(data.Rule?.NegateSource) ? { textDecoration: "line-through" } : {}}
        title={decodeURI(flatPropertiesAndArrayValue(data.Rule?.Source, '%0A'))}
        className={classes.cellStyles}>
        {flatPropertiesAndArrayValue(data.Rule?.Source)}
      </span>),
      Destination: flatPropertiesAndArrayValue(data.Rule?.Destination),
      DestinationJSX: (<span
        style={(data.Rule?.NegateDestination) ? { textDecoration: "line-through" } : {}}
        title={decodeURI(flatPropertiesAndArrayValue(data.Rule?.Destination, '%0A'))}
        className={classes.cellStyles}>
        {flatPropertiesAndArrayValue(data.Rule?.Destination)}
      </span>),
      //@ts-ignore
      Action: actionMapping[data.Rule?.Action],
      Constraints: buidConstratints(data),
      Logging: data.Rule?.Logging ? translate(`generic.yes`) : translate(`generic.no`),
      DecryptionRuleType: data.Rule?.DecryptionRuleType ?? 'None',
      RuleListName: data.RuleListName,
    }));

    return value;
  };

  const columns: any[] = [
    {
      accessor: 'Priority',
      Header: translate(`resources.rules.fields.Priority`),
      columnSize: 0.75,
    },
    {
      id: 'RuleName',
      accessor: 'RuleName',
      Header: translate(`resources.rules.fields.RuleName`),
      columnSize: 2,
      Cell: ({ row }: any) => <>{row?.original?.RuleNameJSX}</>,
    },
    {
      accessor: 'Enabled',
      Header: translate(`resources.rules.fields.Enabled`),
      columnSize: 0.5,
    },
    {
      accessor: 'Source',
      Header: translate(`resources.rules.fields.Source`),
      columnSize: 2,
      style: customSourceStyle,
      Cell: ({ row }: any) => <>{row?.original?.SourceJSX}</>,
    },
    {
      accessor: 'Destination',
      Header: translate(`resources.rules.fields.Destination`),
      columnSize: 2,
      Cell: ({ row }: any) => <>{row?.original?.DestinationJSX}</>,
    },
    {
      accessor: 'Action',
      Header: translate(`resources.rules.fields.Action`),
      columnSize: 0.5,
    },
    {
      accessor: 'Constraints',
      Header: translate(`resources.rules.fields.Constraints`),
      columnSize: 2,
    },
    {
      accessor: 'Logging',
      Header: translate(`resources.rules.fields.Logging`),
      columnSize: 0.75,
    },
    {
      accessor: 'DecryptionRuleType',
      Header: translate(`resources.rules.fields.DecryptionRuleType`),
      columnSize: 1,
    },
  ];

  if (scope && scope !== 'Local') {
    columns.push({ accessor: 'RuleListName' })
  }

  const priorityEditCallBack = (from: string) => {
    setGridMode('list');
    if (from === 'cancel') {
      setGridMode('list');
    } else {
      // call refresh table
      setLoading(true);
      setRecursiveLoading(true);
      GetRulesListData(permissions, false, scope, setLoading, ruleStackName,
        setLocalGridData, setGlobalPreGridData, setGlobalPostGridData, setRecursiveLoading,
        setNextToken, setPreNextToken, setPostNextToken, [], [], [], undefined, undefined, undefined);
    }
  };

  const tableData = scope && scope === 'Local' ? localGridData : [...globalPreGridData, ...globalPostGridData];
  const subtitle = scope === "Global" ? translate(`resources.rules.fields.GlobalListSubtitle`) : translate(`resources.rules.fields.LocalListSubtitle`);

  return (<>
    {gridMode === 'list' &&
      <PANWDSTableLight
        columns={columns}
        data={prepareData(tableData)}
        toolbarActionsArray={toolbarActions}
        title={translate(`resources.rules.name`)}
        subtitle={subtitle}
        offsetTableHeight={305}
        loading={loading}
        backgroundLoading={recursiveLoading}
        lockRows={taskInProgress}
        initialGroupBy={(scope && scope !== 'Local') ? ['RuleListName'] : []}
        searchFilterRequired
        dataMetrics="cloudngfw-rules-table"
        dataTestId="cloudngfw-rules-table"
        enableRowSelect={checkboxColumn}
        showToolbar={showToolbar}
      />}
    {gridMode === 'editPriority' &&
      <RulePriorityEdit
        localGridData={localGridData}
        globalPreGridData={globalPreGridData}
        globalPostGridData={globalPostGridData}
        scope={scope}
        updateCallback={priorityEditCallBack}
      />}
  </>);
}

export default withRouter(RulesList);
