import {Button, makeStyles} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import {
    PANCard,
    PANCardActions, PANCardContent,
    PANCardHeader, PANFieldContainer,
    PANHelpText
} from "./styles";
import {ChangeEvent} from "react";
import EditIcon from "@material-ui/icons/Edit";
import {useTranslate} from '../../customHooks';


const useStyles = makeStyles((theme) => ({
    panCard: {
        width: "678px"
    },
    panwdsSelect: {
        maxWidth: "268px",
        width: "100%",
        "& > div": {
            marginBottom: "unset"
        },
    },
    panwdsSelectButtonText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: "100%",
        textAlign: "left",
    },
    error: {
        border: "1px solid #D13C3C",
        borderRadius: 4,
    },
    errorText: {
        color: "#D13C3C",
    },
    switch: {
        float: "right",
    },
    button: {
        textTransform: "capitalize",
        minWidth: "64px"
    },
    ButtonContainer: {
        background: "black",
        border: "1px solid #DADBDB",
        color: '#006FCC',
        float: "right",
        backgroundColor: '#FFFFFF',
        padding: '2px 4px 2px 8px',
        fontSize: 12,
        margin: 5,
        borderRadius: 4,
        "&:after": {
            borderBottom: "None",
        },
        "&:before": {
            borderBottom: "None",
        }
    },
}));

export const PANCardField = (
    {
        title,
        image,
        content,
        onChange,
        viewMode,
        onClick,
        disabled,
        error,
        children,
        target,
        label,
        editMode,
    }: {
        title?: string;
        image?: any;
        content?: string;
        onChange?: ((event: ChangeEvent<HTMLInputElement>, value: string) => void) | undefined;
        viewMode?: boolean;
        disabled?: boolean;
        onClick?: any;
        error?: any;
        children?: any;
        target?: any;
        label?: string;
        editMode?: boolean;

    }
) => {
    const classes = useStyles();
    const history = useHistory();
    const translate = useTranslate();
    const existError = !!(error);


    return (
        <PANFieldContainer>
            <PANCard className={classes.panCard}>
                <PANCardHeader
                    title={title}
                ></PANCardHeader>
                <PANCardContent>
                    {<img src={image}/>}
                    {content}
                </PANCardContent>

                <PANCardActions>
                    <div className={classes.panwdsSelect}>
                        {children}
                    </div>
                    {(viewMode || editMode) && (
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={disabled}
                            startIcon={editMode && <EditIcon />}
                            //@ts-ignore
                            onClick={() => {
                                history.push(target);
                            }}
                        >
                            {viewMode ? translate("common.view") : translate("common.edit")}
                        </Button>
                    )}
                </PANCardActions>

            </PANCard>
            {existError && <PANHelpText id={label}>{error}</PANHelpText>}
        </PANFieldContainer>
    );
};
