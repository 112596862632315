
const getRSFromUrl = () => {
    const url = window.location.href;
    if (url.indexOf('/rulestacks/') !== -1) {
        const urlParts = url.slice(url.indexOf('/rulestacks/')).split('/');
        if (urlParts.length >= 3) {
            return urlParts[2];
        }
    }
    return '';
}

export { getRSFromUrl };
