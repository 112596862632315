import { FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useCallback, useEffect, useState, Fragment } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { Field } from "react-final-form";
import { useHistory, withRouter } from 'react-router-dom';
import { Button as PANWDSButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from '../../components';
import { Row } from "../../components/FormElements";
import {PANWDSBreadcrumbs, PANWDSForm, PANWDSInput, PANWDSTextarea} from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import { isAlphaNumeric, isPrefixList, isRequired, maxLength, composeValidators } from '../../utils/validate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  }
}));

const PrefixEdit = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { permissions } = usePermissions();
  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  // get the feed name from route params
  const prefixListName = props.match.params.prefixlistname;

  const [record, setRecord] = useState<any>({});
  const title = prefixListName;

  useEffect(() => {
    if (permissions?.DescribePrefixList) {
      dataProvider.describe('prefix', '', { RuleStackName: ruleStackName, Name: prefixListName })
        .then((res: any) => {
          if (res.data) {
            setRecord({ ...res.data });
          } else {
            toast.error(res?.error, { toastId: "prefix-describe" });
          }
        }).catch((e: any) => {
        toast.error(e?.error, { toastId: "prefix-describe" });
      });
    }
  }, [ruleStackName, prefixListName, permissions]);

    const save = useCallback(
        async (values) => {
            if (!permissions?.UpdatePrefixList) {
                return;
            }
            if (values && values.PrefixListCandidate && values.PrefixListCandidate['PrefixList']) {
                setSubmitting(true);
                let payload = {...values.PrefixListCandidate, RuleStackName: values.RuleStackName, Name: values.Name};
                try {
                    await dataProvider.update("prefix", payload);
                    //toast.success("Success");
                    history.goBack();
                    return;
                } catch (error: any) {
                    toast.error(error?.error, {toastId: "prefix-update"});
                    return;
                } finally {
                    setSubmitting(false)
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [history, permissions]
    );

  const SaveToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          dataTestId="rulestack-create-cancel"
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-prefix-edit-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          disabled={!permissions?.UpdatePrefixList}
          submitOnEnter={true}
          loading={submitting}
          dataMetrics="cloudngfw-prefix-edit-save-button"
          {...toolbarProps}
        />
      </div>
    )
  };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: translate(`resources.ruleStacks.name`),
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`prefixlist`]: translate(`resources.prefix.name`),
          [prefixListName]: prefixListName
        }}
      />
      <PANTitle title={title} divider />
        <PANWDSForm
            toolbar={<SaveToolbar />}
            onSubmit={save}
            initialValues={record}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: 'auto' }}>
                    <PANTile title={translate(`resources.prefix.fields.General`)} subtitle={translate(`resources.prefix.fields.CreateSubtitle`)} size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Name"
                                        required
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.prefix.fields.Name`)}
                                        dataMetrics="cloudngfw-prefix-edit-field-name"
                                        validate={composeValidators(isAlphaNumeric, isRequired, maxLength(58))}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="PrefixListCandidate.Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.prefix.fields.Description`)}
                                        dataMetrics="cloudngfw-prefix-edit-field-description"
                                        validate={maxLength(512)}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>

                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="PrefixListCandidate.PrefixList"
                                        required
                                        // @ts-ignore
                                        component={PANWDSTextarea}
                                        enableArrayValue
                                        note="Enter one value per line"
                                        rowsMin={1}
                                        title={translate(`resources.prefix.fields.PrefixList`)}
                                        dataMetrics="cloudngfw-prefix-edit-field-list"
                                        validate={composeValidators(isRequired, isPrefixList)}
                                        initialValue={record && record.PrefixListCandidate ? record.PrefixListCandidate.PrefixList : ""}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(memo(PrefixEdit));
