import { Tooltip } from '@panwds/react-ui';
import { InfoIcon } from '@panwds/icons';
import {useCallback, useContext, useEffect, useState} from 'react';
import { makeStyles} from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import { PANTitle, toast } from '../../components';
import {PANWDSBreadcrumbs, PanwdsTableLightWithFilters} from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import {GlobalContext} from "../../context/global";
import * as DataTypes from "../../api/FwaasDataTypes";
import _ from 'lodash';
import { useTranslate } from '../../customHooks';

const useStyles = makeStyles((theme) => ({
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
  }
}));

const UrlFilteringList = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const title = translate(`resources.urlFiltering.name`)
  const [loadingPredefined, setLoadingPredefined] = useState(false);
  const [loadingCustom, setLoadingCustom] = useState(false);
  const [nextToken, setNextToken]: any = useState();

  const [customURLData, setCustomURLData] = useState<any>([]);
  const [customGridData, setCustomGridData] = useState<any>([]);
  const [predefinedGridData, setPredefinedGridData] = useState<any>([]);
  const [showDropdownActions, setShowDropdownActions] = useState<any>(false);

  // get the id from route params
  const ruleStackName = props.match.params.rulestackname;

  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    async function checkRecord() {
      // @ts-ignore
      if (!_.isEmpty(ruleStackName) || !_.isEmpty(globalContext.state)) {
        setLoadingPredefined(true);
        setLoadingCustom(true);
        // @ts-ignore
        let record = _.get(globalContext.state, `${ruleStackName}`, {});
        if (_.isEmpty(record)) {
          record = await getRecord();
        }
        const urlFilteringProfile = await _.get(record, "RuleStackCandidate.Profiles.URLFilteringProfile", '');
        if (urlFilteringProfile === 'Custom') {
          setShowDropdownActions(true);
          loadPredefined('Custom');
          loadCustom(false, 'Custom');
        } else {
          loadPredefined('BestPractice');
          loadCustom(false, 'Custom');
        }
      }
    }
    checkRecord();
  }, []);

  const prepareData = (rowData: any) => {
    const value = rowData?.map((data: any) => ({
        Name: data?.Name,
        NameJSX:  (data?.Override
        ? (<div className={classes.iconedParagraph}>{ data?.Name }<Tooltip label='Overriden'><InfoIcon size="xs" /></Tooltip></div>)
        : data?.Name),
        Action: data?.Action,
    }));
    return value;
  };

  let columns = [
    {
      accessor: 'Name',
      Header: translate(`resources.urlFiltering.fields.Name`),
      columnSize: 7,
      Cell: ({ row }: any) => <>{row?.original?.NameJSX}</>,
    },
    {
      accessor: 'Action',
      columnSize: 3,
      Header: translate(`resources.urlFiltering.fields.Action`),
    }
  ];

  const getRecord = () => {
    return dataProvider.describe('ruleStacks', '',
      { RuleStackName: ruleStackName })
      .then((res: DataTypes.IFwaasApiResponse) => {
        if (res.data) return res?.data;
      }).catch((e: any) => {
        return {};
      });
  };

  const loadPredefined = useCallback((typeSelected: any) => {
    dataProvider.getList("predefinedUrlCategory", {
      pagination: { page: 1, perPage: 5 },
      sort: { field: 'Name', order: 'ASC' },
      filter: {},
      data: { RuleStackName: ruleStackName, typeSelected }
    }).then(async (response: any) => {
      if(response.data){
      let prefefinedCategories = response.data.Response.CategoriesRunning;
      setPredefinedGridData(prefefinedCategories);
      }else {
        toast.error(response?.error, { toastId: "predefinedUrlCategory-getList" });
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "predefinedUrlCategory-getList" });
      }).finally(() => {
        setLoadingPredefined(false);
      });
  }, [ruleStackName]);

  const loadCustom = (refresh=false, typeSelected?: any) => {
    dataProvider.getList("category", {
      data: { RuleStackName: ruleStackName, NextToken: (nextToken) ? nextToken : undefined, typeSelected  }
    }).then(async (response: any) => {
      if (response.total > 0) {
        //@ts-ignore
        let customUrlCategory = response.data.map(custom => {
          // TO CHECK is allows default action for url category
          return { Name: custom.Name, Action: custom.URLCategoryCandidate?.Action ?? "allow" }
        });
        if (refresh) {
          setCustomGridData(customUrlCategory);
          setCustomURLData(response.data);
        } else {
          setCustomGridData([...customGridData, ...customUrlCategory]);
          setCustomURLData([...customURLData, ...response.data]);
        }
      }
      setNextToken(response?.nextToken);
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "category-getList" });
      }).finally(() => {
        setLoadingCustom(false);
      });
  };

  useEffect(() => {
    if (nextToken){
      loadCustom(false, showDropdownActions? 'Custom' : 'BestPractice');
    }
  }, [nextToken]);

  const updateCustomCategory = (action: string, selected: any[]) => {
    setLoadingCustom(true);
    setCustomGridData([]);
    let calls: any = [];
    selected.forEach(({Name}) => {
      let custom: any = customURLData.find((custom: any) => custom.Name === Name);
      if (custom?.URLCategoryCandidate?.Action === action) {
        toast.success(translate(`resources.urlFiltering.noOverride`))
      } else {
        let payload: any = { ...custom?.URLCategoryCandidate, Action: action, Name: Name, RuleStackName: ruleStackName };
        calls.push(dataProvider.update("category", payload));
      }
    })
    Promise.all(calls).finally(() => loadCustom(true, showDropdownActions? 'Custom' : 'BestPractice'));
  }

  const updatePredefinedCategory = (action: string, selected: any[]) => {
    setLoadingPredefined(true);
    setPredefinedGridData([]);
    let calls: any = [];
    selected.forEach(({Name}) => {
      let predefinedSelected: any = predefinedGridData.find((predefined: any) => predefined.Name === Name);
      if (predefinedSelected?.Action === action) {
        toast.success(translate(`resources.urlFiltering.noOverride`))
      } else {
        let payload: any = { Action: action, Name: Name, RuleStackName: ruleStackName };
        calls.push(dataProvider.update("predefinedUrlCategory", payload));
      }
    });
    Promise.all(calls).finally(() => loadPredefined(showDropdownActions ? 'Custom' : 'BestPractice'));
  };

  const dropDownActionsArray = [
    {
      title: translate(`resources.urlFiltering.dropdown.setSiteAccess`),
      actionsMap: [
        { menuText: translate(`resources.urlFiltering.dropdown.alert`), handleAction: (selected: any) => updateCustomCategory("alert", selected), dataMetrics: "cloudngfw-urlfiltering-alert-btn" },
        { menuText: translate(`resources.urlFiltering.dropdown.allow`), handleAction: (selected: any) => updateCustomCategory("allow", selected), dataMetrics: "cloudngfw-urlfiltering-allow-btn" },
        { menuText: translate(`resources.urlFiltering.dropdown.block`), handleAction: (selected: any) => updateCustomCategory("block", selected), dataMetrics: "cloudngfw-urlfiltering-block-btn" },
        { menuText: translate(`resources.urlFiltering.dropdown.none`), handleAction: (selected: any) => updateCustomCategory("none", selected), dataMetrics: "cloudngfw-urlfiltering-none-btn" },
      ]
    }
  ];

  const predefinedDropDownActionsArray = [
    {
      title: translate(`resources.urlFiltering.dropdown.setSiteAccess`),
      actionsMap: [
        { menuText: translate(`resources.urlFiltering.dropdown.alert`), handleAction: (selected: any) => updatePredefinedCategory("alert", selected), dataMetrics: "cloudngfw-urlfiltering-predefined-alert-btn" },
        { menuText: translate(`resources.urlFiltering.dropdown.allow`), handleAction: (selected: any) => updatePredefinedCategory("allow", selected), dataMetrics: "cloudngfw-urlfiltering-predefined-allow-btn" },
        { menuText: translate(`resources.urlFiltering.dropdown.block`), handleAction: (selected: any) => updatePredefinedCategory("block", selected), dataMetrics: "cloudngfw-urlfiltering-predefined-block-btn" },
      ]
    }
  ];

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: translate(`resources.ruleStacks.name`),
          [ruleStackName]: ruleStackName,
          [`securityservices`]: translate(`resources.securityservices.name`),
          urlfiltering: translate(`resources.urlFiltering.name`)
        }}
      />
      <PANTitle title={title} divider />
         <PanwdsTableLightWithFilters
          columns={columns}
          gridData={prepareData(customGridData)}
          title={translate(`resources.urlFiltering.customCategories`)}
          showToolbar={true}
          dropDownActionsArray={showDropdownActions ? dropDownActionsArray : undefined}
          showTileTitle={true}
          loading={loadingCustom}
          singleSelect={false}
          multiSelect={showDropdownActions}
          offsetTableHeight={520}
          overflowTable={true}
          dataMetrics="cloudngfw-urlfiltering-custom-table"
          dataTestId="cloudngfw-urlfiltering-custom-table"
          resizable={true}
          enablePagination={true}
          searchFilterRequired={true}
        />
        <PanwdsTableLightWithFilters
          columns={columns}
          gridData={prepareData(predefinedGridData)}
          title={translate(`resources.urlFiltering.predefinedCategories`)}
          showToolbar={true}
          dropDownActionsArray={showDropdownActions ? predefinedDropDownActionsArray : undefined}
          showTileTitle={true}
          loading={loadingPredefined}
          singleSelect={false}
          multiSelect={showDropdownActions}
          offsetTableHeight={520}
          overflowTable={true}
          dataMetrics="cloudngfw-urlfiltering-predefined-table"
          dataTestId="cloudngfw-urlfiltering-predefined-table"
          resizable={true}
          enablePagination={true}
          searchFilterRequired={true}
        />
    </>
  );
};

export default withRouter(UrlFilteringList);
