import {BreadcrumbItem, Breadcrumbs} from "@panwds/react-ui";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {isEmpty} from "lodash";

const useStyles = makeStyles((theme) => ({
    Breadcrumbs: {
        padding: "16px",
        background: "#FFFFFF"
    },
}));

type PANWDSBreadcrumbs = {
    path?: string;
    mapping: any;
    queryParams?: string;
};

const Index = ({mapping, queryParams = ""}: PANWDSBreadcrumbs) => {
    const history = useHistory();
    const classes = useStyles();
    const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

    useEffect(() => {
        const path: string = history.location?.pathname;
        const split: any = path?.split("/") ?? [];

        if (split && split.length > 0) {
            const res = split.reduce(
                (accumulator: any, currentValue: any, idx: number) => [
                    ...accumulator,
                    {
                        label: currentValue,
                        to: `${
                            idx === 1 ? "" : accumulator[accumulator.length - 1].to
                        }/${currentValue}`,
                    },
                ]
            );
            setBreadcrumbs(res);
        }

    }, [history.location.pathname]);

    const handleRedirect = (path: string) => {
        if (!isEmpty(queryParams)) {
            path = `${path}${queryParams}`
        }
        history.push(path);
    }

    //TODO: Check whether we require query parameters or not, because the only query parameters
    // we are sending are from FirewallRuleUsage.tsx, which we are not using.

    return (
        <div className={classes.Breadcrumbs}>
            <Breadcrumbs>
                {breadcrumbs?.map((item, idx, origin) => {
                    return (
                        <BreadcrumbItem
                            onClick={() => handleRedirect(item.to)} key={`${idx}-${item.to}`}
                            isCurrent={origin.length - 1 === idx}
                        >
                            {mapping[item.label]}
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
};

export default Index;
