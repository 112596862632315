import { forwardRef, useMemo } from "react";
import { Table } from "../../../../../components/PANWDSElements";
import { useTranslate } from "../../../../../customHooks";

const AWSServiceIPsTableComponent = forwardRef<HTMLInputElement, any>(
    ({ value, ...props }, ref) => {
        const translate = useTranslate();

        const columns = useMemo(
            () => [
                {
                    accessor: "IPAddress",
                    Header: translate('resources.firewallsV2.publicIPs'),
                    
                    columnSize: "auto",
                    Cell: ({ row }: any) => <span>{row?.original?.IPAddress}</span>,
                },
                {
                    accessor: "IPStatus",
                    Header: translate('generic.used'),
                    columnSize: "auto",
                    Cell: ({ row }: any) => (
                        <span>{row?.original?.IPStatus === "FREE" ? translate(`generic.no`) : translate(`generic.yes`)}</span>
                    ),
                },
            ],
            [translate]
        );

        return (
            <div>
                <Table
                    tableComponentData={value || []}
                    tableComponentColumns={columns}
                    tableComponentFilterBar={{
                        hideAddFilter: true,
                        filterConfig: undefined,
                    }}
                />
            </div>
        );
    }
);

export default AWSServiceIPsTableComponent;