import { EventEmitter, HCRoot, initEngines } from "@fawkes/ui-product-help";
import "@fawkes/ui-product-help/dist/bundle.css";
import {
  Box,
  Button,
  Divider,
  Drawer,
  makeStyles,
  styled,
  Theme,
} from "@material-ui/core";
import {
  CommentIcon,
  InformationalIcon,
  PlayCircleIcon,
  CertificateIcon,
  UsersIcon,
  SignalIcon,
  FileCodeIcon,
} from "@panwds/icons";
import { Link } from "@panwds/react-ui";
import _, { isArray } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./in-product-help.css";
import { useTranslate } from "./customHooks";

const useSidebarStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
  },
  root: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  paperAnchorTop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "50%",
  }
}));

const useStylesToggle = makeStyles((theme: Theme) => ({
  drawerHide: {
    display: "none",
  },
  drawerShow: {
    display: "flex",
    position: "absolute",
    right: "0",
    height: "100%",
    flexGrow: "initial",
    flexBasis: "auto",
  },
}));

const useCustomStyle = makeStyles((theme: Theme) => ({
  singleLineLink: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  singleLineLinkLastChild: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: "8px",
      marginRight: "10px",
    },
  },
  externalLinksContainer: {
    margin: "32px 32px 16px 8px",
  },
}));

const KnowledgeCenter = () => {
  const engines = initEngines({
    organizationId: process.env.REACT_APP_ENGINES_ORGANIZATION_ID,
    accessToken: process.env.REACT_APP_ENGINES_ACCESS_TOKEN,
    searchSearchHub: "CloudNGFW_Help_SH",
    searchPipeline: "CloudNGFW_Help_QP",
    recommendationsSearchHub: "CloudNGFW_Recommendations_SH",
    recommendationsPipeline: "CloudNGFW_Recommendations_QP",
  });
  const HCAppRef = useRef();
  const location = useLocation();

  const [openedArticle, setOpenedArticle] = useState();
  const [mode, setMode] = useState("default");
  const [toggle, setToggle] = useState(false);
  const [reload, setReload] = useState(false);

  const classes = useSidebarStyles();
  const customStyleClass = useCustomStyle();
  const classesToggle = useStylesToggle();
  const onGenerateEngineContextOptions = () => ({
    referrer: window.location.href,
    location_host: window.location.host,
    location_path: (location.pathname || "").replace(/\/$/, ""),
    location_search: (location.search || "").replace(/^\?/, ""),
    location_hash: (location.hash || "").replace(/^#\?/, ""),
  });

  const [engineContextOptions, setEngineContextOptions] = useState(
    onGenerateEngineContextOptions()
  );

  useEffect(() => {
    setEngineContextOptions(onGenerateEngineContextOptions());
  }, [location]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  function toggleKnowledgeCenter() {
    setToggle(!toggle);
  }

  function reloadKnowledgeCenter() {
    setReload(true);
  }

  function closeKnowledgeCenter() {
    setToggle(false);
  }

  function openKnowledgeCenter() {
    setToggle(true);
  }

  useEffect(() => {
    EventEmitter.on("toggleKnowledgeCenter", toggleKnowledgeCenter);
    EventEmitter.on("reloadKnowledgeCenter", reloadKnowledgeCenter);
    EventEmitter.on("openKnowledgeCenter", openKnowledgeCenter);
    EventEmitter.on("closeKnowledgeCenter", closeKnowledgeCenter);
    EventEmitter.on("close", closeKnowledgeCenter);
    EventEmitter.on("changeMode", setMode);
    EventEmitter.on("suggestionSelect", setOpenedArticle);
    EventEmitter.on("resultSelect", setOpenedArticle);
    EventEmitter.on("recommendationSelect", setOpenedArticle);

    if (toggle) {
      EventEmitter.emit("reloadKnowledgeCenter");
    }

    return () => {
      EventEmitter.off("toggleKnowledgeCenter", toggleKnowledgeCenter);
      EventEmitter.off("reloadKnowledgeCenter", reloadKnowledgeCenter);
      EventEmitter.off("openKnowledgeCenter", openKnowledgeCenter);
      EventEmitter.off("closeKnowledgeCenter", closeKnowledgeCenter);
      EventEmitter.off("close", closeKnowledgeCenter);
      EventEmitter.off("changeMode", setMode);
      EventEmitter.off("suggestionSelect", setOpenedArticle);
      EventEmitter.off("resultSelect", setOpenedArticle);
      EventEmitter.off("recommendationSelect", setOpenedArticle);
    };
  }, [toggle, location]);

  const translate = useTranslate();

  const isNPSGuide = (guide: any) =>
    isArray(guide.polls) &&
    guide.polls.some((g: any) => g.attributes.type.includes("NPS"));

  const onGeneratePendoGuides = () => {
    let guides = window.pendo?.guides || [];
    const activeGuide = window.pendo?.getActiveGuide?.();
    if (activeGuide) {
      if (!guides.some((g: any) => g.id === activeGuide.guide.id)) {
        // active guide is not present in the list of available guides for some reason
        guides.push(activeGuide.guide);
      }
    }

    guides = guides.filter(
      (g: any) =>
        g.isMultiStep &&
        g.steps[0] &&
        !isNPSGuide(g) &&
        _.isFunction(g.steps[0].canShow) &&
        g.steps[0].canShow()
    );
    guides = guides.filter((g: any) => g.state === "public");
    return guides;
  };

  return (
    <Drawer
      classes={classes}
      anchor="right"
      variant="permanent"
      open={toggle}
      onClose={closeKnowledgeCenter}
      className={toggle ? classesToggle.drawerShow : classesToggle.drawerHide}
    >
      <div>
        <div className={classes.paperAnchorTop}>
          {!reload && (
            <HCRoot
              guides={onGeneratePendoGuides()}
              engines={engines}
              engineContextOptions={engineContextOptions}
              initialMode={mode}
              initialArticle={openedArticle}
              show={toggle}
              showHeader={true}
              ref={HCAppRef}
            />
          )}
        </div>
      </div>
      <Divider />
      <div className={customStyleClass.externalLinksContainer}>
        <div className={customStyleClass.singleLineLink}>
          <div className={customStyleClass.iconText}>
            <CommentIcon
              className="tw-text-blue-600 dark:tw-text-dark-bg-blue"
              size="sm"
              data-metrics="cngfw-icons-create-a-case"
            />
            <Link
              target="_blank"
              href={process.env.REACT_APP_SUPPORT_GET_HELP_URL}
              external
            >
              {translate("resources.knowledgeCenter.createACase")}
            </Link>
          </div>
        </div>
        <div className={customStyleClass.singleLineLink}>
          <div className={customStyleClass.iconText}>
            <PlayCircleIcon
              className="tw-text-blue-600 dark:tw-text-dark-bg-blue"
              size="sm"
              data-metrics="cngfw-icons-cloudNgfwVideoPlaylist"
            />
            <Link
              target="_blank"
              href="https://www.youtube.com/playlist?list=PLD6FJ8WNiIqWmfcE18dj7ItUiDECreaEc"
              external
            >
              {translate("resources.knowledgeCenter.cloudNgfwVideoPlaylist")}
            </Link>
          </div>
        </div>
        <div className={customStyleClass.singleLineLink}>
          <div className={customStyleClass.iconText}>
            <CertificateIcon
              className="tw-text-blue-600 dark:tw-text-dark-bg-blue"
              size="sm"
              data-metrics="cngfw-icons-getTrainedInNgfw"
            />
            <Link
              target="_blank"
              href="https://live.paloaltonetworks.com/t5/blogs/new-digital-course-for-cloud-ngfw-for-aws-in-beacon/ba-p/480213"
              external
            >
              {translate("resources.knowledgeCenter.getTrainedInNgfw")}
            </Link>
          </div>
        </div>
        <div className={customStyleClass.singleLineLink}>
          <div className={customStyleClass.iconText}>
            <UsersIcon
              className="tw-text-blue-600 dark:tw-text-dark-bg-blue"
              size="sm"
              data-metrics="cngfw-icons-cloudNgfwLiveCommunity"
            />
            <Link
              target="_blank"
              href="https://live.paloaltonetworks.com/t5/cloud-ngfw-help-center/ct-p/Cloud_NGFW"
              external
            >
              {translate("resources.knowledgeCenter.cloudNgfwLiveCommunity")}
            </Link>
          </div>
        </div>
        <div className={customStyleClass.singleLineLink}>
          <div className={customStyleClass.iconText}>
            <SignalIcon
              className="tw-text-blue-600 dark:tw-text-dark-bg-blue"
              size="sm"
              data-metrics="cngfw-icons-viewStatusPage"
            />
            <Link target="_blank" href="https://status.paloaltonetworks.com" external>
              {translate("resources.knowledgeCenter.viewStatusPage")}
            </Link>
          </div>
        </div>
        <div className={customStyleClass.singleLineLinkLastChild}>
          <div className={customStyleClass.iconText}>
            <FileCodeIcon
              className="tw-text-blue-600 dark:tw-text-dark-bg-blue"
              size="sm"
              data-metrics="cngfw-icons-apiDocumentation"
            />
            <Link target="_blank" href="https://pan.dev/cloudngfw/aws/api" external>
              {translate("resources.knowledgeCenter.apiDocumentation")}
            </Link>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default KnowledgeCenter;
