import {Redirect, Route, Switch} from "react-router-dom";
import { RouteUri } from "../routeUri";
import ProtectedRoutesUri from "./protectedRoutesUri";
import React, {Children, cloneElement, createElement, ReactElement, useEffect} from "react";
import {useAuthState} from "../customHooks";
import {Layout} from '../layout';
import EventEmitter from "../eventEmitter";
import { ApplicationConfigManager } from "../types";
import { isEmpty } from "lodash";

interface RoutesWithLayoutProps {
    children?: any;
    customRoutes?: any;
    catchAll? : any;
    [key: string]: any;
}

const RoutesWithLayout = (props: RoutesWithLayoutProps) => {
    const { catchAll, children, customRoutes } = props;
    const {authenticated} = useAuthState();
    useEffect(() => {
        EventEmitter.emit('oktaRegistrationModal', true);
    }, []);

    return (
        <Switch>
            {customRoutes &&
                customRoutes.map((route: any, key: any) => cloneElement(route, { key }))}

            {Children.map(children, (child: any) => (
                <Route
                    key={child.props.name}
                    path={`/${child.props.name}`}
                    render={props =>
                        cloneElement(child, {
                            // The context prop instruct the Resource component to
                            // render itself as a standard component
                            intent: 'route',
                            ...props,
                        })
                    }
                />
            ))}
        </Switch>
    );
};

const Index = (props: any) => {
    const  {children} = props;
    const childrenToRender = children as Array<ReactElement<any, any>>;

    useEffect(() => {
        if(isEmpty(ApplicationConfigManager.getInstance().getConfig().xApiKey) && !isEmpty(localStorage.getItem('idToken'))){
            EventEmitter.emit("multiTenantModal", true);
        }
    }, []);

    if (!localStorage.getItem('idToken')) {
        return <Redirect to={{
            pathname: RouteUri.Login,
        }} />
    }
    if(isEmpty(ApplicationConfigManager.getInstance().getConfig().xApiKey)){
        return <></>
    }
    return (
        <Route
            path="/"
            render={(routeProps): any => {
                return createElement(
                    Layout,
                    {},
                    <RoutesWithLayout
                        {...routeProps}
                        customRoutes={ProtectedRoutesUri}
                    >
                        {Children.map(
                            childrenToRender,
                            (
                                child: React.ReactElement<any>
                            ) =>
                                cloneElement(child, {
                                    key: child.props.name,
                                    intent: 'route',
                                })
                        )}
                    </RoutesWithLayout>
                )
            }}
        />
    );
}

export default Index;
