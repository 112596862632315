import { Box, FormControlLabel, FormGroup } from "@material-ui/core";
import { Checkbox } from "@panwds/react-ui";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { PANHelpText, PANMutedText } from "../FormElements/styles";
import {PANWDSLabel} from "./PANWDSLabel";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  singleCheckbox: {
    paddingLeft: '0px',
  },
  formControl: {
    margin: 0,
    "&>span": {
      marginLeft: '8px'
    }
  }
});

export const PANCheckbox = (props: any & { label?: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        className={classes.formControl}
        style={props?.group ? {paddingRight: "20px", paddingTop: "5px", paddingBottom: "5px"} : {}}
        control={
          <Checkbox
            addClassName={props?.group ? classes.root : clsx(classes.root, classes.singleCheckbox) }
            disabled={props?.disabled}
            color="default"
            checked={props?.checked}
            //inputProps={{ "aria-label": "decorative checkbox" }}
            {...props}
          />
        }
        label={props?.label}
      />
        {props?.muted && (<div className="tw-ml-6"><PANMutedText>{props.muted}</PANMutedText></div>)}
    </>
  );
};

const PANCheckboxGroup = (props: any) => {
  const [opts, setOpts] = useState([]);

  useEffect(() => {
    let newObj: any = [];
    props?.options.forEach((element: string) => {
      newObj.push({ name: element, checked: (props.initValue) ? props.initValue.includes(element) : false })
    });
    setOpts(newObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.initValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let tempOpts = [...opts]

    tempOpts.forEach((value: any) => {
      if (value?.name === event.target.name) {
        //@ts-ignore
        value.checked = event.target.checked;
      }
    });
    setOpts(tempOpts);

    props?.onChange({
      ...tempOpts
    });
  };

  return (
    <>
        {props.label && <PANWDSLabel title={props.label}/>}
      <FormGroup row>
        {opts.map((opt: any, idx: number) => (
          <PANCheckbox
            key={idx}
            onChange={handleChange}
            label={opt.name}
            name={opt.name}
            //@ts-ignore
            checked={opt.checked}
            required={props.required}
            onClick={props.onClick}
            group={"true"}
            disabled={props.disabled || false}
          />
        ))}
          {props?.muted && (<div className="tw-ml-6"><PANMutedText>{props.muted}</PANMutedText></div>)}
        <PANHelpText>{props.error}</PANHelpText>
      </FormGroup>
    </>
  );
};

export const PANWDSCheckboxField = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {

  if (props.isGroup) {
    return (
      <Box>
        <PANCheckboxGroup
          error={error}
          onChange={inputProps.onChange}
          onClick={inputProps.onClick}
          label={props.label}
          required={props.required}
          options={props?.options}
          {...props}
        />
      </Box>
    );
  }

  return (
    <Box>
      <PANCheckbox
        onChange={inputProps.onChange}
        label={props.label}
        required={props.required}
        checked={inputProps.checked}
        {...props}
      />
    </Box>
  );
};
