import { Backdrop, Box, CircularProgress, FormControl, Grid, Link as MuiLink, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ErrorIcon from '@material-ui/icons/Error';
import { find, cloneDeep } from 'lodash';
import PropTypes from "prop-types";
import { useCallback, useEffect, useState, Fragment } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { Field, FormSpy } from "react-final-form";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { Button as PANWDSButton, LoadingButton } from "@panwds/react-ui";
import * as DataTypes from "../../api/FwaasDataTypes";
import {
  IntervalCallerComponent, PANModal,
  PANTile, PANTitle, SaveButton, toast
} from "../../components";
import { Row } from "../../components/FormElements";
import { PANFormLabel } from "../../components/FormElements/styles";
import { dataProvider } from "../../dataProvider";
import {
  isRequired,
  maxValue,
  minValue,
  isProtocolAndPortArray,
  multilineUniqueIpV4CIDRS,
  isAppsArray,
  composeValidators,
  requiredValidator,
  maxLength
} from '../../utils/validate';
import { RouteUri } from '../../routeUri';
import {
    PANWDSTextarea, PANWDSChipInput, PANWDSSelectWithSearch, PANWDSSelectsAndInput,
    PANWDSRadioField, PANWDSCheckboxField, PANWDSInput, PANWDSSelect, PANWDSForm, PANWDSBreadcrumbs
} from '../../components/PANWDSElements';
import { decode } from 'html-entities';
import { AppsModalComponent } from './Components/ModalComponent/AppsModalComponent';

export const PANMainContainer = withStyles(() => ({
  root: {
    background: "#F4F5F5",
    padding: "16px 16px 80px 16px",
    position: "relative",
    height: "100%",
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
    border: "1px solid #DADBDB",
    width: "100%",
  },
  description: {
    fontSize: 14,
    color: "#707070",
  },
  error: {
    fontSize: 14,
    color: "#D13C3C",
  },
  errorIcon: {
    fontSize: 20,
    margin: '0 5px -4px 0',
  },
  linkText: {
    color: "#006FCC",
    fontWeight: 600,
    textDecoration: "none",
  },
  media: {
    height: 140,
  },
  header: {
    border: "1px solid #DADBDB",
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  toolbar: {
    background: "transparent",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    padding: '10px 0',
    alignItems: 'baseline'
  },
  panTableClone: {
    display: 'flex',
    borderRadius: '4px',
    border: '1px solid #DADBDB',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& > div': {
      borderBottom: '1px solid #DADBDB',
      padding: '10px 15px 10px 15px',
      margin: 0,
    },
    '& .MuiGrid-item': {
      padding: '0 10px 0 0',
    },
    width: '550px',
    position: 'relative',
  },
  panTableCloneTitle: {
    fontSize: '0.875rem',
    padding: '15px 15px 0 15px !important',
    display: 'grid',
    gap: 15,
    gridTemplateColumns: '35% 65%',
  },
  usageGrid: {
    display: 'flex',
    fontSize: "12px",
    "& > div + div": {
      borderLeft: "solid 1px #bbb",
    },
    "& > div": {
      padding: "0 30px",
    },
    color: "#888"
  },
  /* usageGridLeft: {
    '&>div': {
      lineHeight: '1.8em',
    },
    marginLeft: 'auto',
    textAlign: 'center',
  }, */
  usageGridRight: {
    '&>div': {
      lineHeight: '1.8em',
    },
    // marginRight: 'auto',
    margin: 'auto',
    textAlign: 'center',
  },
  usageGridNumber: {
    margin: "10px 0 0 0",
    fontSize: "30px",
    color: "#777",
    fontWeight: 300,
  },
  counterDateInfo: {
    color: "#888",
    width: '100%',
    textAlign: 'right',
    fontSize: "12px",
    marginBottom: '5px',
  },
  backdrop: {
    position: "absolute",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
}));


export const RuleHitCounters = ({ RuleStackName, RuleListName, Priority, FirewallName, AccountId }: any) => {
  const [counterData, setCounterData] = useState<any>(undefined);
  const [openConfirmReset, setOpenConfirmReset] = useState(false);
  const [doIntervalCall, setDoIntervalCall] = useState(false);
  const [lastRequestTimeStamp, setLastRequestTimeStamp] = useState(undefined); // TODO unused, consider removing
  const [inProgress, setInProgress] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();

  useEffect(() => {
    refreshHitCounters();
  }, []);

  const describeCounters = () => {
    dataProvider.describe('counters', '', { RuleStackName, RuleListName, Priority, FirewallName, AccountId })
      .then((res: any) => {
        if (res.data) {
          if (res.data) {
            setCounterData({ ...res?.data });
          }
          if (res.data?.RequestTimeStamp === undefined || res.data?.RequestTimeStamp !== lastRequestTimeStamp) {
            setDoIntervalCall(false);
          }
        }
      })
      .finally(() => setDoIntervalCall(false));
  };

  const refreshHitCounters = () => {
    setDoIntervalCall(true);
    dataProvider.describe('counters', '', { RuleStackName, RuleListName, Priority, FirewallName, AccountId })
      .then((res: any) => {
        // setLastRequestTimeStamp(res.data.RequestTimeStamp);
        if (res.data) {
          setCounterData({ ...res?.data });
        }
        dataProvider.refresh('counters', '', { RuleStackName, RuleListName, Priority, FirewallName, AccountId })
          .then((res: any) => {
            setTimeout(describeCounters, 2000);
          });
      });
  };

  const resetHitCounter = () => {
    setInProgress(true);
    setDoIntervalCall(true);
    dataProvider.describe('counters', '', { RuleStackName, RuleListName, Priority, FirewallName, AccountId })
      .then((res: any) => {
        // setLastRequestTimeStamp(res.data.RequestTimeStamp);
        if (res.data) {
          setCounterData({ ...res?.data });
        }
        dataProvider.reset('counters', '', { RuleStackName, RuleListName, Priority, FirewallName, AccountId })
          .then((res: any) => {
            setTimeout(describeCounters, 2000);
            setCounterData({ ...counterData, HitCount: 0 });
          }).finally(() => {
            setOpenConfirmReset(false);
            setInProgress(false);
          });
      });
  };

  return (<>
    {<IntervalCallerComponent interval={15000} updateCallback={refreshHitCounters} />}
    <div className={classes.counterDateInfo}>
      {translate(`resources.rules.counters.lastRefreshedDate`)}: {counterData ? (new Date(counterData.TimeStamp)).toLocaleString() : 'N/A'}
      {doIntervalCall && <> <CircularProgress size={11} thickness={2} /></>}
    </div>
    <div className={classes.usageGrid}>
      { /* <div className={classes.usageGridLeft}>
        <div>{translate(`resources.rules.counters.appSeen`)}</div>
        <div className={classes.usageGridNumber}>{counterData ? counterData.AppSeen : 'N/A'}</div>
      </div> */ }
      <div className={classes.usageGridRight}>
        <div>
          {translate(`resources.rules.counters.hitCount`)} (
          <MuiLink
            component="button"
            variant="body2"
            style={{ color: '#006FCC' }}
            onClick={() => setOpenConfirmReset(true)}
          >
            {translate(`resources.rules.counters.reset`)}
          </MuiLink>
          )
          {doIntervalCall && <> <CircularProgress size={11} thickness={2} /></>}
        </div>
        <div className={classes.usageGridNumber}>{counterData ? counterData.HitCount : "N/A"}</div>
      </div>
    </div>
    <PANModal
      handleClose={() => setOpenConfirmReset(false)}
      open={openConfirmReset}
      title={translate(`resources.rules.counters.resetHitCounter`)}
      maxWidth="xs"
      dismissButton={<PANWDSButton
        size="md"
        appearance="secondary"
        onClick={() => setOpenConfirmReset(false)}
        dataMetrics="cloudngfw-rules-edit-reset-hit-counter-modal-cancel"
      >
        {translate(`resources.rules.counters.cancel`)}
      </PANWDSButton>}
      submitButton={
        <LoadingButton
          dataResult={{
            loading: !!inProgress,
            success: true
          }}
          size="md"
          appearance="primary"
          disabled={inProgress}
          onClickCallback={() => resetHitCounter()}
          dataMetrics="cloudngfw-rules-edit-reset-hit-counter-modal-submit"
        >
          {translate(`resources.rules.counters.reset`)}
        </LoadingButton>
      }
    >
      <span>{translate(`resources.rules.counters.sureToResetCounter`)}</span>
    </PANModal>
  </>)
};

const RulesEdit = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;

  const [listsLoaded, setListsLoaded] = useState(false);
  const [record, setRecord] = useState<any>({});
  const { permissions } = usePermissions();
  const [rulestackData, setRulestackData] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;
  const ruleName = props.match.params.rulename;
  const queryRulelistname = new URLSearchParams(search).get('rulelistname');
  const queryPriority = new URLSearchParams(search).get('priority');

  // get the rulelist name from route params
  const ruleListName = queryRulelistname ?? props.match.params.rulelistname;
  const title = ruleName;
  const subtitle = (
    ruleListName === "LocalRule"
      ? translate(`resources.rules.fields.LocalListSubtitle`)
      : translate(`resources.rules.fields.GlobalCreateSubtitle`)
  );

  // get the priority from route params
  const rulePriority = queryPriority ?? props.match.params.priority;

  const queryParams = "?rulelistname=" + ruleListName + "&priority=" + rulePriority;

  useEffect(() => {
    if (permissions?.DescribeSecurityRule) {
      dataProvider.describe('rules', '', { RuleStackName: ruleStackName, RuleListName: ruleListName, Priority: rulePriority })
        .then((res: any) => {
          if (res.data) {
            if (res.data?.RuleEntryCandidate?.Protocol && !["any", "application-default"].includes(res.data?.RuleEntryCandidate?.Protocol)) {
              res.data.RuleEntryCandidate.ProtPortList = [res.data.RuleEntryCandidate.Protocol];
              res.data.RuleEntryCandidate.Protocol = "select";
            }
            if (res.data.RuleEntryCandidate.Protocol === undefined) {
              res.data.RuleEntryCandidate.Protocol = "select";
            }
            if (!res.data?.RuleEntryCandidate?.DecryptionRuleType) {
              res.data.RuleEntryCandidate.DecryptionRuleType = "None";
            }
            // pre-process
            if (res.data.RuleEntryCandidate.Source.Cidrs && res.data.RuleEntryCandidate.Source.Cidrs[0] === 'any') {
              res.data.RuleEntryCandidate.Source.Type = 'any';
              delete res.data.RuleEntryCandidate.Source.Cidrs;
            } else {
              res.data.RuleEntryCandidate.Source.Type = res.data.RuleEntryCandidate.NegateSource ? 'exclude' : 'match';
            }
            if (res.data.RuleEntryCandidate.Destination.Cidrs && res.data.RuleEntryCandidate.Destination.Cidrs[0] === 'any') {
              res.data.RuleEntryCandidate.Destination.Type = 'any';
              delete res.data.RuleEntryCandidate.Destination.Cidrs;
            } else {
              res.data.RuleEntryCandidate.Destination.Type = res.data.RuleEntryCandidate.NegateDestination ? 'exclude' : 'match';
            }
            if (res.data.RuleEntryCandidate.Applications && res.data.RuleEntryCandidate.Applications[0] === 'any') {
              res.data.RuleEntryCandidate.Applications = { Type: 'any' };
            } else {
              res.data.RuleEntryCandidate.Applications = { Type: 'match', Apps: res.data.RuleEntryCandidate.Applications };
            }
            if (Object.keys(res.data.RuleEntryCandidate?.Category || {}).length === 0) {
              res.data.RuleEntryCandidate.Category = { Type: 'any' };
            } else {
              res.data.RuleEntryCandidate.Category.Type = 'match';
            }
            if (res.data.Priority !== undefined) {
              res.data.Priority = String(res.data.Priority);
            }
            setRecord({ ...res.data });
          } else {
            toast.error(res?.error);
          }
        }).catch((e: any) => {
          toast.error(e?.error);
        });
    }
  }, [location.pathname, permissions]);

  const [tableOption, setTableOptions] = useState<{
    prefixlists: string[],
    fqdnlists: string[],
    ipFeedlists: string[],
    urlFeedlists: string[],
    countries: string[],
    urlcategories: string[],
    applications: string[],
    signedCertificates: string[],
  }>({
    prefixlists: [],
    fqdnlists: [],
    ipFeedlists: [],
    urlFeedlists: [],
    countries: [],
    urlcategories: [],
    applications: [],
    signedCertificates: [],
  });

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ margin: 16 }}
      >
        {value === index && children}
      </div>
    );
  }



  const save = useCallback(
    async (values) => {
      if (!permissions?.UpdateSecurityRules) {
        toast.warning(translate("permissions.cantExecute"));
        return;
      }
      let { RuleStackName, RuleListName, Priority, RuleEntryCandidate } = cloneDeep(values);
      let payload: any = {
        RuleEntry: RuleEntryCandidate, RuleStackName: RuleStackName,
        RuleListName: RuleListName, Priority: Priority
      };

      if (record.Priority !== Priority) {
        payload.UpdatedPriority = parseInt(Priority);
        payload.Priority = parseInt(record.Priority);
      } else {
        payload.Priority = parseInt(record.Priority);
      }
      if (!["any", "application-default"].includes(payload['RuleEntry']['Protocol'])) {
        delete payload['RuleEntry']['Protocol'];
      } else {
        delete payload['RuleEntry']['ProtPortList'];
      }

      // process source
      if (payload.RuleEntry.Source?.Type === 'any') {
        payload['RuleEntry']['Source'] = { Cidrs: ['any'] };
        payload.RuleEntry.NegateSource = false;
      } else {
        payload.RuleEntry.NegateSource = payload['RuleEntry']['Source']['Type'] === 'exclude';
        delete payload['RuleEntry']['Source']['Type'];
      }

      // process destination
      if (payload.RuleEntry.Destination?.Type === 'any') {
        payload['RuleEntry']['Destination'] = { Cidrs: ['any'] };
        payload.RuleEntry.NegateDestination = false;
      } else {
        payload.RuleEntry.NegateDestination = payload['RuleEntry']['Destination']['Type'] === 'exclude';
        delete payload['RuleEntry']['Destination']['Type'];
      }

      // process applications
      payload.RuleEntry.Applications = payload.RuleEntry.Applications.Type === 'any' ?
        ['any'] :
        payload.RuleEntry.Applications.Apps;

      // process category
      if (payload.RuleEntry.Category.Type === 'any') {
        payload.RuleEntry.Category = {};
      } else {
        payload.RuleEntry.Category.Feeds = payload.RuleEntry.Category.Feeds;
        payload.RuleEntry.Category.URLCategoryNames = payload.RuleEntry.Category.URLCategoryNames;
        delete payload.RuleEntry.Category.Type;
      }

      if (payload?.RuleEntry?.DecryptionRuleType === "None") {
        delete payload.RuleEntry.DecryptionRuleType;
      }

      setSubmitting(true);
      try {
        await dataProvider.update("rules", payload);
        //toast.success("Success");
        history.goBack();
        return;
      } catch (error: any) {
        toast.error(error?.error?.error, { toastId: "rules-update" });
      } finally {
        setSubmitting(false);
      }
    }, [history, permissions, record]
  );

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    dataProvider.describe('ruleStacks', '', { RuleStackName: ruleStackName })
      .then((res: DataTypes.IFwaasApiResponse) => {
        if (res.data) {
          setRulestackData({ ...res.data });
        }
      })
      .catch((e: any) => {
        // TODO handle invalid rs name
      });
  }, []);

  useEffect(() => {
    getTableOptions();
  }, [record]);

  const getTableOptions = async () => {
    if (record && record.RuleStackName) {
      const res = await getAllList();
      // dataProvider.getList("applications", {
      //   pagination: { page: 1, perPage: 5 },
      //   sort: { field: 'Name', order: 'ASC' },
      //   filter: {},
      //   data: record
      // }).then(async (response:any) => {
      //   //@ts-ignore
      //   let responseObj = {applications: response?.data[0]?.Applications || []};

      //   setTableOptions((prevState) => ({ ...prevState, ...responseObj }));
      // })
      //   .catch((e) => {
      //     console.log("Error", e);
      //   });
      setTableOptions((prevState) => ({ ...prevState, ...res }));
    }
  }

  const getAllList = async (): Promise<{
    prefixlists: string[];
    fqdnlists: string[];
    ipFeedlists: string[];
    urlFeedlists: string[];
    countries: string[];
    urlcategories: string[];
  }> => {
    let data = { RuleStackName: ruleStackName, RuleListName: ruleListName };
    return Promise.all([
      dataProvider.get("prefixlists", { data: data }),
      dataProvider.get("fqdnlists", { data: data }),
      dataProvider.get("feed", { data: { ...data, Type: "IP_LIST" } }),
      dataProvider.get("feed", { data: { ...data, Type: "URL_LIST" } }),
      dataProvider.get("countries", { data: { ...data } }),
      dataProvider.get("category", { data: { ...data } }),
      dataProvider.get("predefinedUrlCategory", { data: data }),
      dataProvider.get("certificate", { data: { ...data, selfsigned: false } })
    ])
      .then(values => {
        setListsLoaded(true);
        let urlCategoriesData = (values[5]?.data) ? values[5].data.map((item: any) => ({text: item, value: item})) : [];
        let predefinedUrlCategoryData = (values[6]?.data) ? values[6].data.map((item: any) => ({text: item, value: item})) : []
        return {
          prefixlists: (values[0]?.data) ? values[0].data.map((item: any) => ({text: item, value: item})) : [],
          fqdnlists: (values[1]?.data) ? values[1].data.map((item: any) => ({text: item, value: item})) : [],
          ipFeedlists: (values[2]?.data) ? values[2].data.map((item: any) => ({text: item, value: item})) : [],
          urlFeedlists: (values[3]?.data) ? values[3].data.map((item: any) => ({text: item, value: item})) : [],
          countries: (values[4]?.data) ? values[4].data.map((item: any) => ({text: item.Description, value: item.Code})) : [],
          urlcategories: urlCategoriesData.concat(predefinedUrlCategoryData),
          signedCertificates: values[7]?.data,
        };
      }).catch();
  }

  const SaveToolbar = (toolbarProps: any) => {
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={toolbarProps?.saving}
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-rules-edit-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          submitOnEnter={true}
          loading={submitting}
          disabled={!permissions?.UpdateSecurityRules}
          dataMetrics="cloudngfw-rules-edit-save-button"
          {...toolbarProps}
        />
      </div>
    )
  };

  const ready = Object.keys(record).length > 0 && listsLoaded;
  const breadcrumbMapping = {
    [`rulestacks`]: translate(`resources.ruleStacks.name`),
    [ruleStackName]: ruleStackName,
    [`rules`]: translate(`resources.rules.name`),
    [ruleListName]: ruleListName,
    [ruleName]: ruleName,
  };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={breadcrumbMapping}
      />
      <PANTitle title={title} divider />
      <PANWDSForm
        toolbar={<SaveToolbar />}
        onSubmit={save}
        initialValues={record}
        //key={key}
        key={String(ready)}
        style={{ margin: 16 }}
      >
        <Fragment>
          <Grid container style={{ gap: 16, width: 'auto' }}>
            <PANTile
              title={translate(`resources.rules.fields.General`)}
              subtitle={subtitle}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <Field
                    name="RuleEntryCandidate.RuleName"
                    // @ts-ignore
                    component={PANWDSInput}
                    required
                    disabled
                    validate={requiredValidator(translate("validation.required"))}
                    title={translate(`resources.rules.fields.RuleName`)}
                    dataMetrics="cloudngfw-rules-edit-field-name"
                  />
                </Grid>
              </Row>
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <Field
                    name="RuleEntryCandidate.Description"
                    // @ts-ignore
                    component={PANWDSInput}
                    title={translate(`resources.rules.fields.Description`)}
                    validate={maxLength(512)}
                    dataMetrics="cloudngfw-rules-edit-field-description"
                  />
                </Grid>
              </Row>
              <Row>
                <Grid item sm={12} md={6} lg={5} className="noLeftPadding">
                  <Field
                    name="Priority"
                    // @ts-ignore
                    component={PANWDSInput}
                    required
                    note="Rules with lower priority are evaluated first. Each rule within a rule group must have a unique priority setting."
                    validate={composeValidators(requiredValidator(translate("validation.required")), minValue(1), maxValue(1000000))}
                    title={translate(`resources.rules.fields.RulePriority`)}
                    type="number"
                    dataMetrics="cloudngfw-rules-edit-field-priority"
                  />
                </Grid>
              </Row>

              {/* <Grid item xs={6}>
                <Field
                  name="RuleEntryCandidate.Tags"
                  // @ts-ignore
                  component={PANChip}
                  options={[{}]}
                  addNew
                  addNewLabel="Add new"
                  addFunc={(args: any) => setRulesOpen(true)}
                  label={translate(`resources.rules.fields.Tags`)}
                />
              </Grid> */}
              <Row>
                <Grid item sm={12} className="noLeftPadding">
                  <Field
                    name="RuleEntryCandidate.Enabled"
                    // @ts-ignore
                    component={PANWDSCheckboxField}
                    label={translate(`resources.rules.fields.Enabled`)}
                    muted={translate(`resources.rules.fields.EnabledSubtitle`)}
                    type="checkbox"
                  />
                </Grid>
              </Row>
              <Backdrop className={classes.backdrop} open={Object.keys(record).length === 0}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.Source`)}
              subtitle={translate(`resources.rules.fields.SourceSubtitle`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntryCandidate.Source.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" },
                        {
                          label: translate(`resources.rules.fields.excludeNegateMatch`),
                          value: "exclude"
                        },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        if (['match', 'exclude'].includes(values?.RuleEntryCandidate?.Source?.Type)) {
                          return <>
                            <Field
                              name="RuleEntryCandidate.Source.Cidrs"
                              row
                              // @ts-ignore
                              component={PANWDSTextarea}
                              title={translate(`resources.rules.fields.Cidrs`)}
                              note={translate(`resources.rules.fields.CidrsNote`)}
                              validate={multilineUniqueIpV4CIDRS}
                              enableArrayValue
                              placeholder="10.1.0.0/10&#10;10.1.1.5"
                            />
                            <Field
                              name="RuleEntryCandidate.Source.PrefixLists"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.PrefixList`)}
                              items={tableOption.prefixlists}
                              enableArrayInput
                              enableMultiSelect
                            />
                            <Field
                              name="RuleEntryCandidate.Source.Countries"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.Countries`)}
                              items={tableOption.countries}
                              enableArrayInput
                              enableMultiSelect
                            />
                            <Field
                              name="RuleEntryCandidate.Source.Feeds"
                              row
                              // @ts-ignore
                              component={PANWDSSelectWithSearch}
                              title={translate(`resources.rules.fields.Feeds`)}
                              items={tableOption.ipFeedlists}
                              enableArrayInput
                              enableMultiSelect
                            />
                          </>;
                        }
                        return <></>;
                      }}
                    </FormSpy>

                  </FormControl>
                  <Backdrop className={classes.backdrop} open={!ready}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.Destination`)}
              subtitle={translate(`resources.rules.fields.DestinationSubtitle`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntryCandidate.Destination.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" },
                        {
                          label: translate(`resources.rules.fields.excludeNegateMatch`),
                          value: "exclude"
                        },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>{({ values }) => {
                      if (['match', 'exclude'].includes(values?.RuleEntryCandidate?.Destination?.Type)) {
                        return <>
                          <Field
                            name="RuleEntryCandidate.Destination.Cidrs"
                            row
                            // @ts-ignore
                            component={PANWDSTextarea}
                            title={translate(`resources.rules.fields.Cidrs`)}
                            note={translate(`resources.rules.fields.CidrsNote`)}
                            validate={multilineUniqueIpV4CIDRS}
                            enableArrayValue
                            placeholder="10.1.0.0/10&#10;10.1.1.5"
                          />
                          <Field
                            name="RuleEntryCandidate.Destination.PrefixLists"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.rules.fields.PrefixList`)}
                            items={tableOption.prefixlists}
                            enableArrayInput
                            enableMultiSelect
                          />
                          <Field
                            name="RuleEntryCandidate.Destination.FqdnLists"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.rules.fields.FQDNList`)}
                            items={tableOption.fqdnlists}
                            enableMultiSelect
                            enableArrayInput
                          />
                          <Field
                            name="RuleEntryCandidate.Destination.Countries"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.rules.fields.Countries`)}
                            items={tableOption.countries}
                            enableMultiSelect
                            enableArrayInput
                          />
                          <Field
                            name="RuleEntryCandidate.Destination.Feeds"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.rules.fields.Feeds`)}
                            items={tableOption.ipFeedlists}
                            enableMultiSelect
                            enableArrayInput
                          />
                        </>;
                      }
                      return <></>;
                    }}
                    </FormSpy>

                  </FormControl>

                  <Backdrop className={classes.backdrop} open={!ready}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={decode(translate(`resources.rules.fields.Application`))}
              subtitle={translate(`resources.rules.fields.ApplicationSubtitle`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntryCandidate.Applications.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>{({ values }) => {
                      if (values?.RuleEntryCandidate?.Applications?.Type === 'match') {
                        return <>
                          <Field
                            name="RuleEntryCandidate.Applications.Apps"
                            row
                            // @ts-ignore
                            component={AppsModalComponent}
                            title={translate(`resources.rules.fields.Applications`)}
                            validate={composeValidators(isAppsArray)}
                            required
                            fromEditRow={true}
                            disabled={!permissions?.UpdateSecurityRules}
                            editRowSelectedData={values?.RuleEntryCandidate.Applications.Apps}
                          />
                        </>
                      }
                      return <></>;
                    }}
                    </FormSpy>

                  </FormControl>

                  <Backdrop className={classes.backdrop} open={!ready}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.URLFiltering`)}
              subtitle={translate(`resources.rules.fields.URLFilteringSubtitles`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntryCandidate.Category.Type"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        { label: translate(`resources.rules.fields.match`), value: "match" }
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>{({ values }) => {
                      if (values?.RuleEntryCandidate?.Category?.Type === 'match') {
                        return <>
                          <Field
                            name="RuleEntryCandidate.Category.URLCategoryNames"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.rules.fields.URLCategory`)}
                            items={tableOption.urlcategories}
                            enableArrayInput
                            enableMultiSelect
                          />
                          <Field
                            name="RuleEntryCandidate.Category.Feeds"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.rules.fields.Feeds`)}
                            items={tableOption.urlFeedlists}
                            enableMultiSelect
                            enableArrayInput
                          />
                        </>;
                      }
                      return <></>;
                    }}
                    </FormSpy>

                  </FormControl>

                  <Backdrop className={classes.backdrop} open={!ready}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.ProtocolAndPorts`)}
              subtitle={translate(`resources.rules.fields.ProtocolAndPortsSubtitles`)}
              size={12}
            >
              <Row>
                <Grid item xs={12} sm={12} md={6} lg={5} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntryCandidate.Protocol"
                      row
                      // @ts-ignore
                      component={PANWDSRadioField}
                      options={[
                        { label: translate(`resources.rules.fields.any`), value: "any" },
                        {
                          label: translate(`resources.rules.fields.applicationDefault`),
                          value: "application-default"
                        },
                        { label: translate(`resources.rules.fields.select`), value: "select" },
                      ]}
                      title={translate(`resources.rules.fields.MatchCriteria`)}
                      initValue="any"
                    />

                    <FormSpy subscription={{ values: true }}>{({ values }) => {
                      if (values?.RuleEntryCandidate?.Protocol === 'select') {
                        return <Field
                          name="RuleEntryCandidate.ProtPortList"
                          // @ts-ignore
                          component={PANWDSSelectsAndInput}
                          options={[
                            { value: "TCP" },
                            { value: "UDP" },
                          ]}
                          selectLabel={translate(`resources.rules.fields.Protocol`)}
                          textLabel={translate(`resources.rules.fields.PortNumber`)}
                          textNote={translate(`resources.rules.fields.ProtocolPortNote`)}
                          required
                          validate={composeValidators(isRequired, isProtocolAndPortArray)}
                          formatter={(value: any[]) => {
                            if (value.forEach) {
                              let protPort: any = {};
                              value.forEach((data: any) => {
                                if (data.split(":")[0] in protPort) {
                                  protPort[data.split(":")[0]] += `,${data.split(":")[1]}`;
                                } else {
                                  protPort[data.split(":")[0]] = data.split(":")[1];
                                }
                              });
                              let protPortItems = [];
                              for (let port in protPort) {
                                protPortItems.push([port, protPort[port]]);
                              }
                              return protPortItems;
                            }
                            return value;
                          }}
                          changeformatter={(items: any) => {
                            let protPorts = items.map((item: any) => item[1].split(',').map((port: string) => `${item[0]}:${port}`));
                            return protPorts.flat();
                          }}
                        />;
                      }
                      return <></>;
                    }}
                    </FormSpy>

                  </FormControl>

                  <Backdrop className={classes.backdrop} open={!ready}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Grid>
              </Row>
            </PANTile>

            <PANTile
              title={translate(`resources.rules.fields.Action`)}
              subtitle={translate(
                `resources.rules.fields.ActionSubtitle`
              )}
              size={12}
            >
              <Row>
                <Grid item xs={12} className="noLeftPadding">
                  <Field
                    name="RuleEntryCandidate.Action"
                    row
                    // @ts-ignore
                    component={PANWDSRadioField}
                    options={[
                      { label: translate(`resources.rules.fields.allow`), value: "Allow" },
                      { label: translate(`resources.rules.fields.deny`), value: "DenySilent" },
                      {
                        label: translate(`resources.rules.fields.resetServer`),
                        value: "DenyResetServer"
                      },
                      { label: translate(`resources.rules.fields.resetBoth`), value: "DenyResetBoth" },
                    ]}
                    title={translate(`resources.rules.fields.Action`)}
                  />
                </Grid>
              </Row>
              <Row>
                <Grid item xs={6} className="noLeftPadding">
                  <FormControl fullWidth>
                    <Field
                      name="RuleEntryCandidate.DecryptionRuleType"
                      // @ts-ignore
                      component={PANWDSSelect}
                      title={translate(`resources.rules.fields.DecryptionRuleType`)}
                      items={[
                        { text: translate(`resources.rules.fields.none`), value: "None" },
                        { text: translate(`resources.rules.fields.outbound`), value: "SSLOutboundInspection" },
                        { text: translate(`resources.rules.fields.inbound`), value: "SSLInboundInspection" },
                        // { text: translate(`resources.rules.fields.inboundNoInspect`), value: "SSLInboundNoInspection" },
                        { text: translate(`resources.rules.fields.outboundNoInspect`), value: "SSLOutboundNoInspection" },
                      ]}
                      dataMetrics="cloudngfw-rules-edit-field-decryption-rule-type"
                    />
                    <FormSpy subscription={{ values: true }}>{({ values }) => {
                      if (values?.RuleEntryCandidate?.DecryptionRuleType === 'None' || values?.RuleEntryCandidate?.DecryptionRuleType === 'SSLOutboundInspection' ||
                        values?.RuleEntryCandidate?.DecryptionRuleType === 'SSLOutboundNoInspection') {
                        delete values?.RuleEntryCandidate?.InboundInspectionCertificate
                      }

                      if (values?.RuleEntryCandidate?.DecryptionRuleType === 'SSLInboundInspection' || values?.RuleEntryCandidate?.DecryptionRuleType === 'SSLInboundNoInspection') {
                        const validSC = !!tableOption.signedCertificates.length;

                        if (!validSC) {
                          const profileUrl = RouteUri.CertificateList.replace(":rulestackname", ruleStackName);
                          return (<Typography className={classes.error}>
                            <ErrorIcon className={classes.errorIcon} />
                            {translate(`resources.rules.fields.InboundInspectCertError.prefix`)}<Link
                              to={profileUrl}
                              className={classes.linkText}>{translate(`resources.rules.fields.InboundInspectCertError.link`)}</Link>{translate(`resources.rules.fields.InboundInspectCertError.suffix`)}
                          </Typography>
                          );
                        }

                        return <Field
                          name="RuleEntryCandidate.InboundInspectionCertificate"
                          // @ts-ignore
                          component={PANWDSSelect}
                          title={translate(`resources.rules.fields.InboundInspectCert`)}
                          items={tableOption.signedCertificates.map((cert: string) => ({
                            text: cert,
                            value: cert
                          }))}
                          required
                          validate={composeValidators(isRequired)}
                          dataMetrics="cloudngfw-rules-edit-field-inbound-inspection-certificate"
                        />;
                      }

                      if (values?.RuleEntryCandidate?.DecryptionRuleType === 'SSLOutboundInspection') {
                        const trust = rulestackData.RuleStackCandidate?.Profiles?.OutboundTrustCertificate;
                        const unTrust = rulestackData.RuleStackCandidate?.Profiles?.OutboundUntrustCertificate;
                        if (trust || unTrust) {
                          return (<>
                            <Typography className={classes.description}><b>UnTrust
                              Certificate:</b> {unTrust ?? "N/A"}</Typography>
                            <Typography className={classes.description}><b>Trust
                              Certificate:</b> {trust ?? "N/A"}</Typography>
                          </>);
                        }
                        const profileUrl = RouteUri.SecurityServices.replace(":rulestackname", ruleStackName);
                        return (<Typography className={classes.error}>
                          <ErrorIcon className={classes.errorIcon} />
                          You haven't set up the Outbound Decryption profile yet.
                          Please edit the <Link to={profileUrl} className={classes.linkText}>Outbound
                            Decryption</Link> in security profiles.
                        </Typography>);
                      }
                      return <></>;
                    }}
                    </FormSpy>
                  </FormControl>

                  <Backdrop className={classes.backdrop} open={!ready}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Grid>
              </Row>
              <Row>
                <Grid item xs={12} className="noLeftPadding">
                  <FormControl>
                    <PANFormLabel>
                      {translate(`resources.rules.fields.Logging`)}
                    </PANFormLabel>
                    <Field
                      name="RuleEntryCandidate.Logging"
                      // @ts-ignore
                      component={PANWDSCheckboxField}
                      label={translate(`resources.rules.fields.Enabled`)}
                      type="checkbox"
                    />
                  </FormControl>
                </Grid>
              </Row>
            </PANTile>
          </Grid>
        </Fragment>
      </PANWDSForm>
    </>
  )
};
export default withRouter(RulesEdit);
