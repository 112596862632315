import RuleStackCreate from './RuleStackCreate';
import RuleStackEdit from './RuleStackEdit';
import RuleStackList from './RuleStackList';

const ruleStacks = 
{
    create: RuleStackCreate,
    list: RuleStackList,
    edit: RuleStackEdit
};

export default ruleStacks;
