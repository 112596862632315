import React from 'react';
import {
  Body,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@panwds/react-ui';
import { useTranslate } from '../../customHooks';

export type ConfirmationDialogProps = {
  isOpen: boolean;
  closeModal: () => void;
  handleActionCallback: (() => Promise<void>) | (() => void);
  message: string;
  title: string;
  saveButtonLabel: string;
  record?: Record<string, unknown> | undefined;
};

export const ConfirmationDialog = ({
  closeModal,
  isOpen,
  handleActionCallback,
  title,
  message,
  saveButtonLabel,
  record,
}: ConfirmationDialogProps) => {
  const translate = useTranslate();
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={closeModal}
      dataTestId="confirmation-dialog"
    >
      <ModalHeader title={title} />
      <ModalBody>
        <Body as={'div'}>
          {message}
        </Body>
      </ModalBody>
      <ModalFooter>
        <div className="tw-flex tw-flex-row tw-space-x-2 tw-justify-center">
          <Button
            dataMetrics="confirm-exit-btn"
            onClick={closeModal}
            focusOnMount
            appearance="secondary"
          >
            {translate(`generic.cancel`)}
          </Button>
          <Button
            dataMetrics="confirm-action-btn"
            appearance="primary-destructive"
            onClick={() => handleActionCallback(record)}
          >
            {saveButtonLabel}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
