import { Backdrop, Box, CircularProgress, FormControl, Grid, InputAdornment, InputBase } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { get, isEmpty } from 'lodash';
import { useEffect, useState, useMemo, useContext, useRef } from "react";
import { usePermissions, useTranslate } from '../../customHooks';
import { Field, Form } from "react-final-form";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { Button as PANWDSButton, LoadingButton, Modal, ModalBody, ModalFooter, ModalHeader, TabList, Tab, TabPanel, Tabs } from "@panwds/react-ui";
import * as DataTypes from "../../api/FwaasDataTypes";
import * as EndPoints from "../../api/FwaasUriEndPoints";
import { IntervalCallerComponent, PANTile, PANTitle, SplitButton, toast } from '../../components';
import { PANChip } from '../../components/FormElements';
import {PANWDSInput, PANWDSCheckboxField, PANWDSBreadcrumbs} from "../../components/PANWDSElements";
import { dataProvider } from "../../dataProvider";
import { firewalls, rules } from '../../resources';
import { RouteUri } from '../../routeUri';
import { ISplitButtonAction } from '../../types';
import { GetRulesLength } from '../../utils/GetRulesLength';
import { maxElements } from '../../utils/validate';
import SecurityServices from "./components/SecurityServices";
import SecurityObjects from "./components/SecurityObjects";
//import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { ApplicationConfigManager } from '../../types';
import CircularLoader from '../../components/CircularLoader/CircularLoader';
import cx from "classnames";
import { GlobalContext } from "../../context/global";
import {ReduxActions, ReduxResources} from "../../redux";
import {useAppDispatch} from "../../app/hooks";

export const PANMainContainer = withStyles(() => ({
  root: {
    background: "#F4F5F5",
    padding: "16px 16px 80px 16px",
    position: "relative",
    height: "100%",
  },
}))(Box);


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    border: '1px solid #DADBDB',
    width: '100%',
  },
  mainContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rulesEditWrapper: {
    position: 'relative',
    flexGrow: 1,
    "&>div:first-child": {
      background: "#fff"
    }
  },
  media: {
    height: 140,
  },
  header: {
    border: '1px solid #DADBDB',
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    margin: '0 16px 16px 0',
    backgroundColor: 'transparent'
  },
  pantileContainer: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gap: 16,
    position: 'relative',
    margin: 16,
  },
  diffContainer: {
    maxHeight: '60vh',
    minHeight: '40vh',
    overflow: 'scroll'
  },
  indicator: {
    backgroundColor: '#006FCC',
    left: 0,
  },
  featureBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: "column",
    gridGap: 16,
    "& > div": {
      width: '360px',
    },
    ['@media (max-width:1100px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'column',
    }
  },
  backdrop: {
    position: "absolute",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
  commitStatusString: {
    color: '#707070',
    fontSize: '12px',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 'left',
    marginTop: '5px'
  },
  commitStatus: {
    color: '#333333',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  commitStatusImage: {
    margin: '0px 4px'
  },
  confirmModal: {
    width: '100%',
    boxSizing: 'border-box',
    fontFamily: 'Lato',
    fontSize: "12px",
    minHeight: "200px",
  },
  commitToolbar: {
    width: '100%',
    textAlign: "right",
    "&>button + button": {
      marginLeft: "10px",
    }
  },
  fullHeightForm: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  modalBodyTitle: {
    margin: '10px 10px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#333333',
  },
  modalBodySubTitle: {
    margin: '10px 10px',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '20px',
    color: '#333333',
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#707070',
    margin: '10px 0'
  },
  objectsGrid: {
    gridTemplateColumns: '165px calc(100% - 165px)',
    display: 'grid',
    height: '100%',
  },
  modalTable: {
    margin: "20px 0 0 0",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    fontSize: "12px",
    "&>div:last-child": {
      borderBottom: "none",
    }
  },
  modalTableHead: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    fontWeight: 600,
    padding: "10px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  modalTableBody: {
    overflow: "scroll",
    maxHeight: "300px",
    "&>div": {
      padding: "10px 10px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  SearchBarStyle: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: "4px",
    border: "1px solid #DADBDB",
    padding: "0 5px",
    background: "#FFFFFF",
    [theme.breakpoints.down(750)]: {
      padding: "6px 1px 6px 3px",
    },
  },
  SearchBarIconStyle: {
    fontSize: '1rem'
  },
  loadingBlur: {
    filter: "blur(1px)",
  },
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
    color: "#333333",
  },
  tabs: {
    "& .MuiButtonBase-root.MuiTab-root": {
      color: "#707070",
      opacity: 1,
    },
    "& .Mui-selected": {
      color: "#333333 !important",
      fontWeight: 600,
      opacity: 1,
    }
  },
  tabPanelStyles: {
    margin: 0
  }
}));

let interval: any = undefined;

const AssociatedFirewallsModalBody = ({ scope, rulestackname, description }: any) => {
  const translate = useTranslate();
  const [associatedFirewalls, setAssociatedFirewalls] = useState<any>([]);
  const [searchData, setSearchData] = useState<any>([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    dataProvider.get('firewalls', { data: (scope === "Local") ? { rulestackname: rulestackname } : { globalrulestackname: rulestackname } })
      .then((res: any) => {
        if (res.data) {
          setAssociatedFirewalls([...res.data]);
          setSearchData([...res.data]);
          setLoaded(true);
        }
      })
  }, []);
  const classes = useStyles();

  const onSearchChange = (value: any) => {
    const lowerSearchText = value && value.toLowerCase();
    const newData = associatedFirewalls.filter((p: any) => {
      const v = (p.FirewallName).toLowerCase();
      return v.indexOf(lowerSearchText) !== -1;
    });
    if (newData.length > 0) {
      setSearchData([...newData]);
    } else {
      setSearchData([...associatedFirewalls]);
    }
  };

  return (
    <>
      <div className={classes.confirmModal}>
        {description}
        <div className={classes.modalTable}>
          <div className={classes.modalTableHead}>
            {translate("resources.ruleStacks.modal.tableTitle")}
            <Box>
              <InputBase
                disabled={associatedFirewalls.length === 0 && loaded}
                className={classes.SearchBarStyle}
                placeholder={'Search'}
                onChange={e => onSearchChange(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon className={classes.SearchBarIconStyle} />
                  </InputAdornment>
                }
              />
            </Box>
          </div>
          <div className={classes.modalTableBody}>
            {searchData.map((firewall: any) => (<div>{firewall.FirewallName}</div>))}
            {!loaded && <div>...</div>}
            {searchData.length === 0 && loaded && <div>{translate("resources.ruleStacks.modal.notFound")}</div>}
          </div>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={!loaded}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>);
};

const CommitActionsTitle = ({ ruleStackName, scope, updateCallback, ...props }: any) => {
  const globalContext = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [rulesLength, setRulesLength]: any = useState(0);
  const [firewallsCount, setFirewallsCount] = useState(0);
  const [firewallsCountLoading, setFirewallsCountLoading] = useState(true);

  const [openCommitModal, setOpenCommitModal] = useState(false);
  const [openValidatetModal, setOpenValidateModal] = useState(false);
  const [openRevertModal, setOpenRevertModal] = useState(false);
  const [commitStatus, setCommitStatus] = useState<any>(undefined);
  const translate = useTranslate();
  const [operationInProgress, setOpertionInProgress] = useState(false);
  //const [hasFirewalls, setHasFirewalls] = useState(false);
  const [commitStatusLoading, setCommitStatusLoading] = useState(true);
  const search = useLocation().search;
  const accountId = new URLSearchParams(search).get('AccountId');
  const { permissions } = usePermissions();

  const getDataFromContext = () => {
    //@ts-ignore
    if (!isEmpty(globalContext.state)) {
      // @ts-ignore
      const rulestackData = _.get(globalContext.state, `${ruleStackName}`, {});
      const rulesLengthData = get(rulestackData, `rulesLength`);
      const firewalldCountData = get(rulestackData, `firewallsCount`);
      if (rulesLengthData) {
        setRulesLength(rulesLengthData);
        setLoading(false);
      }
      if (firewalldCountData !== undefined) {
        setFirewallsCount(firewalldCountData);
        setFirewallsCountLoading(false);
      }
    }
  };

  const getLengthData = async (scope: string = "Local") => {
    //@ts-ignore
    const rulestackData = _.get(globalContext.state, `${ruleStackName}`, {});
    if (permissions?.DescribeRuleStack) {
      const rulesLengthData = await GetRulesLength(permissions, scope, setLoading, ruleStackName, setRulesLength);
      //@ts-ignore
      globalContext.setGlobalState({ [ruleStackName]: { ...rulestackData, rulesLength: rulesLengthData } });
    }

    dataProvider.get('firewalls', { data: (scope === "Local") ? { rulestackname: ruleStackName } : { globalrulestackname: ruleStackName } })
      .then(async (response: any) => {
        setFirewallsCountLoading(true);
        if (response.data?.length !== undefined) {
          setFirewallsCount(response.data.length);
          //@ts-ignore
          globalContext.setGlobalState(
            { [ruleStackName]: { ...rulestackData, firewallsCount: response.data.length } }
          );
        }
        setFirewallsCountLoading(false);
      }).catch((e: any) => {
        if (e?.noToast) {
          return;
        }
        toast.error(e?.error, { toastId: "firewalls-list" });
      });
  };

  // allow parent/sibling component to force a refresh of rules lengths
  props.forwardGetLengthData(getLengthData);

  useEffect(() => {
    getDataFromContext();
  }, []);

  useEffect(() => {
    if (scope !== undefined && loading) {
      getLengthData(scope);
    }
  }, [scope]);

  const dropDownActionsArray: ISplitButtonAction[] = [
    { menuText: translate("resources.ruleStacks.actions.deploy"), progressText: translate("resources.ruleStacks.actions.deploying"), handleAction: () => commitRuleStack() },
    { menuText: translate("resources.ruleStacks.actions.validate"), progressText: translate("resources.ruleStacks.actions.validating"), handleAction: () => validateRuleStack(), enabled: !!firewallsCount },
    { menuText: translate("resources.ruleStacks.actions.revert"), progressText: translate("resources.ruleStacks.actions.reverting"), handleAction: () => revertRuleStack() },
    { menuText: translate("resources.ruleStacks.actions.lastStatus"), progressText: translate("resources.ruleStacks.actions.fetching"), handleAction: () => commitstatusRulestack() },
  ];

  const commitRuleStack = (confirmed: boolean = false) => {
    if (!confirmed) {
      setOpenCommitModal(true);
      return;
    }
    setOpenCommitModal(false)
    //@ts-ignore
    dataProvider.commit("ruleStacks", { ruleStackName: ruleStackName })
      .then(async (response: any) => {
        setCommitStatusLoading(true);
        if (response.data) {
          // toast.info(translate("resources.ruleStacks.commit.progress").replace(
          //   "{rulestackName}", response?.data?.RuleStackName)
          // );
          setCommitStatus("Committing");
          setOpertionInProgress(true);
          toast.info(`${ruleStackName} commit is in progress.`);
          setCommitStatusLoading(false);
          return response.data;
        } else {
          toast.error(response?.error, { toastId: "rulestack-commit" });
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "rulestack-commit" });
      });
  };

  const commitstatusRulestack = () => {
    dataProvider.commitstatus("ruleStacks", { RuleStackName: ruleStackName, AccountId: accountId })
      .then((response: any) => {
        setCommitStatusLoading(true);
        if (response.error) {
          toast.error(response.error, { toastId: "rulestack-commit-status" });
        } else {
          updateCallback({ commitStatus: response.data.CommitStatus, validateStatus: response.data.ValidateStatus });
          if (response.data.CommitStatus === EndPoints.STATUS_SUCCESS) {
            toast.success(response.data.RuleStackName + " Commit : " + response.data.CommitStatus);
            setOpertionInProgress(false);
            setCommitStatus(response.data.CommitStatus);
            clearInterval(interval);
          } else if (response.data.CommitStatus === EndPoints.STATUS_PENDING) {
            //toast.info(response.data.RuleStackName + " Commit : " + response.data.CommitStatus);
            setCommitStatus(response.data.CommitStatus);
          } else if (response.data.CommitStatus === EndPoints.STATUS_FAILED) {
            if (response.data.CommitMessages) {
              for (const msg of response.data.CommitMessages) {
                toast.error(response.data.RuleStackName + " Status : " + msg, { toastId: "rulestack-commit-status" });
              }
              setCommitStatus(response.data.CommitStatus);
              //toast.error(response.data.RuleStackName + " Commit : " + response.data.CommitStatus);
              setOpertionInProgress(false);
              clearInterval(interval);
            }
          } else if (response.data.ValidateStatus === EndPoints.STATUS_SUCCESS) {
            toast.success(response.data.RuleStackName + " Validate : " + response.data.ValidateStatus);
            setOpertionInProgress(false);
            clearInterval(interval);
          } else if (response.data.ValidateStatus === EndPoints.STATUS_PENDING) {
            //toast.info(response.data.RuleStackName + " Validate : " + response.data.ValidateStatus);
          } else if (response.data.ValidateStatus === EndPoints.STATUS_FAILED) {
            if (response.data.ValidateMessages) {
              for (const msg of response.data.CommitMessages) {
                toast.info(response.data.RuleStackName + " Status : " + msg);
              }
              toast.error(response.data.RuleStackName + " Validate : " + response.data.ValidateStatus, { toastId: "rulestack-commit-status" });
              setOpertionInProgress(false);
              clearInterval(interval);
            }
          }
          setCommitStatusLoading(false);
        }
      }).catch((e: any) => {
        toast.error(e?.error, { toastId: "rulestack-commit-status" });
      });
  };

  const revertRuleStack = (confirmed: boolean = false) => {
    if (!confirmed) {
      setOpenRevertModal(true);
      return;
    }
    setOpenRevertModal(false)
    //@ts-ignore
    dataProvider.revert("ruleStacks", { RuleStackName: ruleStackName })
      .then(async (response: any) => {
        setCommitStatusLoading(true);
        if (response.data) {
          toast.info(translate("resources.ruleStacks.revert.progress").replace(
            "{rulestackName}", response?.data?.RuleStackName)
          );
          setOpertionInProgress(true);
          setCommitStatusLoading(false);
          return response.data;
        } else {
          toast.error(response?.error, { toastId: "rulestack-revert" });
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "rulestack-revert" });
      });
  };

  const validateRuleStack = (confirmed: boolean = false) => {
    if (!confirmed) {
      setOpenValidateModal(true);
      return;
    }
    setOpenValidateModal(false)
    //@ts-ignore
    dataProvider.validate("ruleStacks", { RuleStackName: ruleStackName })
      .then(async (response: any) => {
        setCommitStatusLoading(true);
        if (response.data) {
          toast.info(translate("resources.ruleStacks.validate.progress").replace(
            "{rulestackName}", response?.data?.RuleStackName)
          );
          setOpertionInProgress(true);
          setCommitStatusLoading(false);
          return response.data;
        } else {
          toast.error(response?.error, { toastId: "rulestack-validate" });
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "rulestack-validate" });
      })
      .finally(() => setOpenCommitModal(false));
  };

  useEffect(() => {
    dataProvider.commitstatus("ruleStacks", { RuleStackName: ruleStackName })
      .then((response: any) => {
        setCommitStatusLoading(true);
        if (response.error) {
          toast.error(response.error, { toastId: "rulestack-commit-status" });
        } else {
          setCommitStatus(response.data.CommitStatus);
          if (response.data.CommitStatus === EndPoints.STATUS_PENDING) {
            setOpertionInProgress(true)
          }
        }
        setCommitStatusLoading(false);
      }).catch((e: any) => {
        toast.error(e?.error, { toastId: "rulestack-commit-status" });
      });

    return () => {
      setCommitStatusLoading(true);
      setCommitStatus(undefined);
      setOpertionInProgress(false);
    };
  }, []);

  return (<>
    <PANTitle title={ruleStackName}
      children={[
        <SplitButton
          key={1}
          buttons={dropDownActionsArray}
          title={translate("resources.ruleStacks.actions.configActions")}
        //style={{ width: '85px' }}
        //disabled={operationInProgress}
        />,
      ]}
      overviewPanel={[
        { key: translate("resources.ruleStacks.overviewPanel.rulestackId"), values: [{ text: ruleStackName, isNormalText: true }] },
        { key: translate("resources.ruleStacks.overviewPanel.lastDeployment"), values: [{ text: commitStatus, isStatus: true }], isLoading: commitStatusLoading },
        { key: translate("resources.ruleStacks.overviewPanel.rules"), values: [{ text: rulesLength, isNormalText: true }], isLoading: loading },
        { key: translate("resources.ruleStacks.overviewPanel.associatedFirewalls"), values: [{ text: firewallsCount, isNormalText: true }], isLoading: firewallsCountLoading }]}
    />

    <Modal
      onClose={() => setOpenCommitModal(false)}
      isOpen={openCommitModal}
      size="md">
      <ModalHeader
        title={translate("resources.ruleStacks.modal.deployConf")}
        dataTestId="add-applications-modalHeader"
        enableClose={true}
      />
      <ModalBody>
        <AssociatedFirewallsModalBody scope={scope} rulestackname={ruleStackName} description={translate("resources.ruleStacks.modal.deployDescription")} />
      </ModalBody>
      <ModalFooter>
        <PANWDSButton
          size="md"
          appearance="secondary"
          onClick={() => setOpenCommitModal(false)}
          dataMetrics="cloudngfw-rulesstack-edit-commit-modal-cancel"
        >
          {translate("resources.ruleStacks.modal.cancel")}
        </PANWDSButton>
        <PANWDSButton
          size="md"
          appearance="primary"
          onClick={() => commitRuleStack(true)}
          dataMetrics="cloudngfw-rulesstack-edit-commit-modal-save"
        >
          {translate("resources.ruleStacks.modal.commit")}
        </PANWDSButton>
      </ModalFooter>
    </Modal>

    <Modal
      onClose={() => setOpenValidateModal(false)}
      isOpen={openValidatetModal}
      size="md">
      <ModalHeader
        title={translate("resources.ruleStacks.modal.validateConf")}
        enableClose={true}
      />
      <ModalBody>
        <AssociatedFirewallsModalBody scope={scope} rulestackname={ruleStackName} description={translate("resources.ruleStacks.modal.validateDescription")} />
      </ModalBody>
      <ModalFooter>
        <PANWDSButton
          size="md"
          appearance="secondary"
          onClick={() => setOpenValidateModal(false)}
          dataMetrics="cloudngfw-rulesstack-edit-validateConf-modal-cancel"
        >
          {translate("resources.ruleStacks.modal.cancel")}
        </PANWDSButton>
        <PANWDSButton
          size="md"
          appearance="primary"
          onClick={() => validateRuleStack(true)}
          dataMetrics="cloudngfw-rulesstack-edit-validateConf-modal-save"
        >
          {translate("resources.ruleStacks.modal.validate")}
        </PANWDSButton>
      </ModalFooter>
    </Modal>

    <Modal
      onClose={() => setOpenRevertModal(false)}
      isOpen={openRevertModal}
      size="md">
      <ModalHeader
        title={translate("resources.ruleStacks.modal.revertConf")}
        enableClose={true}
      />
      <ModalBody>
        <AssociatedFirewallsModalBody scope={scope} rulestackname={ruleStackName} description={translate("resources.ruleStacks.modal.revertDescription")} />
      </ModalBody>
      <ModalFooter>
        <PANWDSButton
          size="md"
          appearance="secondary"
          onClick={() => setOpenRevertModal(false)}
          dataMetrics="cloudngfw-rulesstack-edit-revertConfig-modal-cancel"
        >
          {translate("resources.ruleStacks.modal.cancel")}
        </PANWDSButton>
        <PANWDSButton
          size="md"
          appearance="primary"
          onClick={() => revertRuleStack(true)}
          dataMetrics="cloudngfw-rulesstack-edit-revertConfig-modal-save"
        >
          {translate("resources.ruleStacks.modal.revert")}
        </PANWDSButton>
      </ModalFooter>
    </Modal>

    {operationInProgress ? <IntervalCallerComponent interval={30000} updateCallback={commitstatusRulestack} /> : null}
  </>
  );
};

// const RuleStackConfigDiff = ({ record }: any) => {
//   const classes = useStyles();
//   const translate = useTranslate();

//   return <div className={classes.pantileContainer}>
//     <PANTile
//       title={translate("resources.ruleStacks.diff.title")}
//       size={12}
//     >
//       <div className={classes.diffContainer}>
//         <ReactDiffViewer
//           oldValue={JSON.stringify(record.RuleStackRunning, undefined, 2)}
//           newValue={JSON.stringify(record.RuleStackCandidate, undefined, 2)}
//           splitView={true}
//           compareMethod={DiffMethod.WORDS}
//           leftTitle={translate('resources.ruleStacks.diff.running')}
//           rightTitle={translate('resources.ruleStacks.diff.candidate')}
//         />
//       </div>
//     </PANTile>
//   </div>;
// }

const RuleStackEdit = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const translate = useTranslate();
  const location = useLocation();
  const globalContext = useContext(GlobalContext);
    const dispatch = useAppDispatch();

  const [record, setRecord] = useState<any>(undefined);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { permissions } = usePermissions();

  const scope = record?.RuleStackCandidate?.Scope;
  // get the id from route params
  //const firewallName = props.match.params.firewallname;
  //const search = useLocation().search;
  //const accountId = new URLSearchParams(search).get('AccountId');

  const breadcrumbMapping = {
    [`rulestacks`]: `${translate('resources.ruleStacks.name')} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
    [props.match.params.rulestackname]: props.match.params.rulestackname,
    [`prefixlist`]: translate(`resources.prefix.name`),
    [`fqdn`]: translate(`resources.fqdn.name`),
    [`categories`]: translate(`resources.category.name`),
    [`certificates`]: translate(`resources.certificate.name`),
    [`feeds`]: translate(`resources.feed.name`),
    [`objects`]: translate(`resources.objects.name`),
    [`securityservices`]: translate(`resources.securityservices.name`),
    [`rules`]: translate(`resources.rules.name`),
    [`settings`]: translate(`resources.rulestacksettings.name`),
    [`associatedfirewalls`]: translate(`resources.associatedfirewalls.name`),
  };

  const getPageIndex = () => {
    if (location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "rules") {
      return "tab0";
    } else if (location.pathname.indexOf("/securityservices") > 0) {
      return "tab1";
    } else if (location.pathname.indexOf("/objects") > 0) {
      return "tab2";
    } else if (location.pathname.indexOf("/associatedfirewalls") > 0) {
      return "tab3";
      /* } else if (location.pathname.indexOf("/configDiff") > 0) {
        setValue(4); */
    } else if (location.pathname.indexOf("/settings") > 0) {
      return "tab4";
    } else {
      history.replace(RouteUri.RuleList.replace(":rulestackname", props.match.params.rulestackname));
      return "tab0";
    }
  }

  const value = getPageIndex();

  const securityObjects = useMemo(() => <SecurityObjects />, []);

  const describeRulestack = () => {
    //@ts-ignore
    if (!isEmpty(globalContext.state)) {
      // @ts-ignore
      let rulestackData = _.get(globalContext.state, `${props.match.params.rulestackname}`, {});

      if (!isEmpty(rulestackData)) {
        setRecord({ ...rulestackData });
        return;
      }
    }

    if (permissions?.DescribeRuleStack) {
      dataProvider.describe('ruleStacks', '',
        { RuleStackName: props.match.params.rulestackname, Running: true })
        .then(async (res: DataTypes.IFwaasApiResponse) => {
          if (res.data) {
            if (res.data?.RuleStackCandidate?.LookupXForwardedFor) {
              //@ts-ignore
              res.data.RuleStackCandidate['LookupXForwardedFor'] = (res.data?.RuleStackCandidate?.LookupXForwardedFor === "SecurityPolicy") ? true : false
            }
            // setScope(res?.data?.RuleStackCandidate?.Scope)
            setRecord({ ...res.data });
            //@ts-ignore
            globalContext.setGlobalState({ [props.match.params.rulestackname]: { ...res.data } });
          } else {
            toast.error(res?.error, { toastId: `rulestack-describe-${props.match.params.rulestackname}` });
            history.goBack()
          }
        }).catch((e: any) => {
          toast.error(e?.error, { toastId: `rulestack-describe-${props.match.params.rulestackname}` });
          history.goBack();
        });
    }
  }

  useEffect(() => {
    describeRulestack();
  }, []);

  const handleChange = async (newValue: string) => {
    let newUrl = "";
    switch (newValue) {
      case "tab0": newUrl = RouteUri.RuleList.replace(":rulestackname", props.match.params.rulestackname); break;
      case "tab1": newUrl = RouteUri.SecurityServices.replace(":rulestackname", props.match.params.rulestackname); break;
      case "tab2": newUrl = RouteUri.ObjectList.replace(":rulestackname", props.match.params.rulestackname); break;
      case "tab3": {
          await dispatch(ReduxActions.resetListDetails({resource: ReduxResources.FIREWALL})({}))
              .then(() => {
                  newUrl = RouteUri.AssociatedFirewalls.replace(":rulestackname", props.match.params.rulestackname);
              });
          break;
      }
      // case 4: newUrl = RouteUri.ConfigDiff.replace(":rulestackname", ruleStackName); break;
      case "tab4": newUrl = RouteUri.RuleStackSettings.replace(":rulestackname", props.match.params.rulestackname); break;
    }
    history.replace(newUrl);
  };

  const onSubmit = async (formValues: any, form: any) => {
    let values = JSON.parse(JSON.stringify(formValues));
    values['RuleStackEntry'] = values.RuleStackCandidate;
    values['RuleStackEntry']['LookupXForwardedFor'] = (values?.RuleStackEntry?.LookupXForwardedFor) ? "SecurityPolicy" : 'None';
    setSubmitInProgress(true);
    const formState = form.getState();
    if (formState.dirty) {
      let isSuccess = true;
      const dirtyFields = get(formState, "dirtyFields");
      if (get(dirtyFields, "RuleStackCandidate.Description") || get(dirtyFields, "RuleStackCandidate.LookupXForwardedFor")) {
        try {
          const response = await dataProvider.update("ruleStacks", values);
          if (!response.data) {
            isSuccess = false;
            toast.error(response?.error, { toastId: "rulestack-update" });
          } else {
            let resultRecord = response.data;
            resultRecord['LookupXForwardedFor'] = (resultRecord.LookupXForwardedFor === "SecurityPolicy")
            //@ts-ignore
            delete resultRecord['id'];
            setRecord({ ...record, "RuleStackCandidate": { ...resultRecord } });
            //@ts-ignore
            globalContext.setGlobalState({ [props.match.params.rulestackname]: { ...record, "RuleStackCandidate": { ...resultRecord } } });
          }
        } catch (error: any) {
          isSuccess = false;
          toast.error(error?.error, { toastId: "rulestack-update" });
        }
      }
      if (get(dirtyFields, "Tags")) {
        const initialValues = formState.initialValues.Tags || [];
        const initialTags = Object.fromEntries(initialValues.map((tag: any) => [tag.Key, tag.Value]));
        const currentKeys = values.Tags.map((tag: any) => tag.Key);
        const newTags = values.Tags.filter((tag: any) => !(initialTags[tag.Key] && initialTags[tag.Key] === tag.Value));
        const deleteTags = initialValues.filter((tag: any) => !currentKeys.includes(tag.Key)).map((tag: any) => tag.Key);
        if (newTags.length) {
          try {
            const newTagsPayload = {
              resourceType: 'rulestacks',
              resourceName: values.RuleStackName,
              payload: { "Tags": newTags },
            } as any;
            const response = await dataProvider.update("tags", newTagsPayload);
            if (!response?.data) {
              isSuccess = false;
              toast.error(response?.error, { toastId: "rulestack-tags-update" });
            }

          } catch (error: any) {
            isSuccess = false;
            toast.error(error?.error, { toastId: "rulestack-tags-update" });
          }
        }
        if (deleteTags.length) {
          try {
            const deleteTagsPayload = {
              resourceType: 'rulestacks',
              resourceName: values.RuleStackName,
              payload: { "TagKeys": deleteTags },
            } as any;
            const response = await dataProvider.delete("tags", deleteTagsPayload);
            if (!response?.data) {
              isSuccess = false;
              toast.error(response?.error, { toastId: "rulestack-tags-delete" });
            }
          } catch (error: any) {
            isSuccess = false;
            toast.error(error?.error, { toastId: "rulestack-tags-delete" });
          }
        }
      }
      if (isSuccess) {
          dispatch(ReduxActions.updateResource({resource: ReduxResources.RULESTACK})({
              RuleStackName: values.RuleStackName
          }));
        // TODO since response return updated object we can avoid describe RS again
        //describeRulestack();
        toast.success(translate('resources.ruleStacks.update.success').replace("{rulestackName}", values.RuleStackName));
      }
    }
    setSubmitInProgress(false);
  };

  const SecurityServicesToolbar = (toolbarProps: any) => {
    let { pristine, valid } = toolbarProps;
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitInProgress}
          onClick={() => history.goBack()}
          dataMetrics="cloudngfw-rulestack-edit-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <LoadingButton
          dataResult={{
            loading: !!submitInProgress,
            success: true
          }}
          size="md"
          appearance="primary"
          type="submit"
          disabled={submitInProgress || pristine || !valid}
          dataMetrics="cloudngfw-rulestack-edit-save-button"
        >
          Save
        </LoadingButton>
      </div>
    );
  };

  // trick to call getLengthData in CommitActionsTitle from rulelist when rules are deleted
  let commitComponentUpdateLengthFunction = () => { };
  const setCommitComponentUpdateLengthFunction = (getLengthData: () => void) => {
    commitComponentUpdateLengthFunction = getLengthData;
  }

  const invokeDeleteRule = (deleteTriggered: any) => {
    if (deleteTriggered) {
      // review this tigger of ruleslength
      // GetRulesLength(permissions, scope, setLoading, props.match.params.rulestackname, setRulesLength);
      // describeRulestack();
      commitComponentUpdateLengthFunction();
    }
  }
  const onCommitStatusUpdate = ({ commitStatus, validateStatus }: any) => {
    if (commitStatus === EndPoints.STATUS_SUCCESS && value === "tab4") {
      describeRulestack();
    }
  };

  return (
    <div className={classes.mainContent}>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={breadcrumbMapping}
      />
      <CommitActionsTitle
        ruleStackName={props.match.params.rulestackname}
        scope={scope}
        updateCallback={onCommitStatusUpdate}
        forwardGetLengthData={setCommitComponentUpdateLengthFunction}
      />
      <Box className={classes.rulesEditWrapper}>
        {/* <Form
          onSubmit={onSubmit}
          initialValues={record}
          render={props => {
            let { handleSubmit, values } = props;
            return (
              <form onSubmit={handleSubmit} defaultValue={values} className={classes.fullHeightForm}> */}
        <Tabs>
        <TabList
          activeTabId={value}
          onActiveChange={(nextTabId) => handleChange(nextTabId)}
        >
          <Tab key="tab0" id="tab0">Rules</Tab>
          <Tab key="tab1" id="tab1">Security Services</Tab>
          <Tab key="tab2" id="tab2">Objects</Tab>
          <Tab key="tab3" id="tab3">Associated Firewalls</Tab>
          <Tab key="tab4" id="tab4">Settings</Tab>
        </TabList>

        <TabPanel addClassName={classes.tabPanelStyles} activeTabId={value.toString()} forTabId="tab0">
          <div className={cx(isEmpty(record) ? classes.loadingBlur : "")}>
            {record &&
              <rules.list
                // @ts-ignore
                scope={record.RuleStackCandidate.Scope}
                ruleStackName={record.RuleStackName }
                triggerDeleteRule={(deleteRuleTriggered: any) => invokeDeleteRule(deleteRuleTriggered)}
              />
            }
          </div>
        </TabPanel>

        <TabPanel addClassName={classes.tabPanelStyles} activeTabId={value.toString()} forTabId="tab1">
          <SecurityServices
            classes={classes}
            permissions={permissions}
            ruleStackName={props.match.params.rulestackname}
            submitInProgress={submitInProgress}
          />
        </TabPanel>

        <TabPanel addClassName={classes.tabPanelStyles} activeTabId={value.toString()} forTabId="tab2">
          <div className={cx(isEmpty(record) ? classes.loadingBlur : "")}>
            {securityObjects}
          </div>
        </TabPanel>

        <TabPanel addClassName={classes.tabPanelStyles} activeTabId={value.toString()} forTabId="tab3">
          <firewalls.list readyOnly RuleStackName={props.match.params.rulestackname} rulestackScreen={true} />
        </TabPanel>
        { /* <TabPanel value={value} index={4}>
                    <RuleStackConfigDiff record={record} />
                        </TabPanel> */ }
        <TabPanel addClassName={classes.tabPanelStyles} activeTabId={value.toString()} forTabId="tab4">
          <div className={cx(isEmpty(record) ? classes.loadingBlur : "")}>
            <CircularLoader loading={isEmpty(record)} />
            <Form
              onSubmit={onSubmit}
              initialValues={record}
              render={formProps => {
                let { handleSubmit, values } = formProps;
                return (
                  <form onSubmit={handleSubmit} defaultValue={values} className={classes.fullHeightForm}>
                    <div className={classes.pantileContainer}>
                      <PANTile title="RuleStackName" size={12}>
                        <Grid container style={{ gap: 16, width: 'auto' }}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="RuleStackName"
                              // @ts-ignore
                              component={PANWDSInput}
                              title="Name"
                              readOnly
                              source="RuleStackName"
                              dataMetrics="rulestack-edit-name-input"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="RuleStackCandidate.Description"
                              // @ts-ignore
                              component={PANWDSInput}
                              title="Description"
                              disabled={!permissions?.UpdateRuleStack}
                              dataMetrics="rulestack-edit-description-input"
                            //initialValue={formData?.RuleStackCandidate?.Scope}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="RuleStackCandidate.AccountId"
                              // @ts-ignore
                              component={PANWDSInput}
                              title="AccountId"
                              readOnly
                              dataMetrics="rulestack-edit-accountid-input"
                            //initialValue={formData?.RuleStackCandidate?.Scope}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <Field
                                name="Tags"
                                // @ts-ignore
                                component={PANChip}
                                options={[]}
                                addNew
                                addNewLabel="Add New"
                                label={`Tags`}
                                validate={maxElements(200)}
                                addDirectly
                                disabled={!permissions?.UnTagRulestack}
                                disableButton={!permissions?.TagRulestack}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="RuleStackCandidate.LookupXForwardedFor"
                              // @ts-ignore
                              component={PANWDSCheckboxField}
                              label={translate(`resources.ruleStacks.fields.XFF`)}
                              muted={translate(`resources.ruleStacks.fields.XFFLabel`)}
                              disabled={!permissions?.UpdateRuleStack}
                              dataMetrics="rulestack-edit-lookup-x-forwarded-for-checkbox"
                              type="checkbox"
                            />
                          </Grid>
                        </Grid>
                        <Backdrop className={classes.backdrop} open={submitInProgress}>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </PANTile>
                    </div>
                    <SecurityServicesToolbar {...formProps} />
                  </form>
                );
              }} />
          </div>
        </TabPanel>
        </Tabs>
      </Box>
    </div>
  );
};
export default withRouter(RuleStackEdit);
