import {CognitoUser} from "amazon-cognito-identity-js";
import {ApplicationConfigManager, ApplicationConfig} from "../types";
import jwt_decode from "jwt-decode";
import {authProvider} from "../authProvider";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import {isEmpty} from "lodash";

export type cognitoConfig = {
    userPool: any;
    user: any;
    userSession: any;
};

export default class CognitoManager {

    constructor() {
        //this.applicationConfig = ApplicationConfigManager.getInstance().getConfig()
    }

    private currentConfig: cognitoConfig = {
        user: null,
        userPool: null,
        userSession: null
    }

    async CognitoManager() {
        await this.loadConfig();
    }

    getConfig(): cognitoConfig {
        return this.currentConfig;
    }


    async loadConfig() {
        try {
            const applicationConfig = ApplicationConfigManager.getInstance().getConfig();
            this.currentConfig.userPool = new AmazonCognitoIdentity.CognitoUserPool({
                UserPoolId: applicationConfig.currentRegion.UserPoolId,
                ClientId: applicationConfig.currentRegion.UserPoolAppClientId
            });
            this.currentConfig.user = this.currentConfig.userPool.getCurrentUser();
            if (this.currentConfig.user !== null)
                await this.ensureSession();
        } catch (e) {
            throw e;
        }
    }

    clearSession() {
        if (this.currentConfig && this.currentConfig.user) {
            this.currentConfig.user.signOut();
            this.currentConfig.user = null;
        }
        if (this.currentConfig && this.currentConfig.userSession) {
            this.currentConfig.userSession = null;
        }
    }

    async isSessionAvailable() {
        try {
            const applicationConfig = ApplicationConfigManager.getInstance().getConfig();
            this.currentConfig.userPool = new AmazonCognitoIdentity.CognitoUserPool({
                UserPoolId: applicationConfig.currentRegion.UserPoolId,
                ClientId: applicationConfig.currentRegion.UserPoolAppClientId
            });
            this.currentConfig.user = await this.currentConfig.userPool.getCurrentUser();
            if (this.currentConfig.user !== null) {
                await this.currentConfig.user.getSession(async (err: any, session: AmazonCognitoIdentity.CognitoUserSession) => {
                    if (session) {
                        const idToken = session.getIdToken().getJwtToken();
                        localStorage.setItem('idToken', idToken);
                        this.setCognitoUserSession(session);
                        await this.loadConfig();
                    }
                });
            }
            return !isEmpty(this.currentConfig.user);
        } catch (e) {
            throw e;
        }
    }

    async ensureSession() {
        // console.log("Ensure: idtoken : " + localStorage.getItem('idToken'));
        const self = this;

        if (localStorage.getItem('idToken')) {
            let session: AmazonCognitoIdentity.CognitoUserSession = this.getCognitoUserSession();
            if (session) {
                await this.checkTokenExpiryAndRefresh(session);
            } else {
                const cognitoUser: CognitoUser = this.getCognitoUser();
                if (cognitoUser != null) {
                    await self.getSession(cognitoUser)
                } else {
                    // console.log("Ensure : clearing beacause there is no valid user");
                    self.clearSession();
                    await authProvider.logout(null);
                }
            }
        } else {
            // console.log("Ensure: clearing beacause there is no local storage for idToken");
            self.clearSession();
            await authProvider.logout(null);
        }
    }

    getSession(cognitoUser: CognitoUser) {
        const self = this;
        return new Promise((resolve, reject) => {
            cognitoUser.getSession(
                function (err: any, session: AmazonCognitoIdentity.CognitoUserSession) {
                    // console.log("Ensure:  Session : " + session);
                    if (!err && session) {
                        // console.log("Ensure : keeping session beacause there is a valid session");
                        self.setCognitoUserSession(session);
                        self.checkTokenExpiryAndRefresh(session).then(resolve).catch(reject);
                    } else {
                        self.clearSession();
                        authProvider.logout(null);
                        reject(err)
                    }
                });
        })
    }

    refreshSession(session: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const applicationConfigManager = ApplicationConfigManager.getInstance();
            let cognitoUser: CognitoUser = this.getCognitoUser();
            if (cognitoUser != null) {
                // console.log("Ensure: cognitouser : ", cognitoUser);
                // console.log("Ensure: Refreshing session");
                cognitoUser.refreshSession(
                    session.getRefreshToken(),
                    (err: any, newSession: any) => {
                        if (err) {
                            reject(err)
                        }
                        if (newSession && newSession.isValid) {
                            // console.log("Session refreshed");
                            this.setCognitoUserSession(newSession);

                            const idToken = newSession.getIdToken().getJwtToken();
                            localStorage.setItem('idToken', idToken);

                            //const decodedToken: any = jwt_decode(idToken);
                            // console.log("New Token stored with expiry time: " + new Date(decodedToken['exp']));
                            return resolve(newSession);
                        } else {
                            // console.log("refresh session failed");
                            return reject("refresh session failed");
                        }
                    }
                );
            } else {
                // console.log("Ensure: clearing beacause there is no cognito user");
                return reject("Cognito User doesn't exist");
            }

        });
    }

    async checkTokenExpiryAndRefresh(session: any) {
        const applicationConfigManager = ApplicationConfigManager.getInstance();
        if (session && session.getIdToken()) {
            const idToken = session.getIdToken().getJwtToken();
            const decodedToken: any = jwt_decode(idToken);
            if (decodedToken) {
                const secondsSinceEpoch = Math.round(Date.now() / 1000);
                // console.log("Ensure: Current Time : " + secondsSinceEpoch);
                // console.log("Ensure: Expirty time : " + decodedToken['exp']);

                if (decodedToken['exp'] - secondsSinceEpoch > 300) { // still valid and not expiring
                    return null;
                } else {
                    // token expiring in 300 secs, refresh ahead
                    try {
                        return await this.refreshSession(session);
                        // console.log("Ensure: Session refreshed", newSession);
                    } catch (e: any) {
                        if (decodedToken['exp'] > secondsSinceEpoch) {
                            // console.log("Ensure: Session refresh failed");
                            applicationConfigManager.clearSession();
                            await authProvider.logout(null);
                        }
                        if (e?.code === "NotAuthorizedException") {
                            applicationConfigManager.clearSession();
                            await authProvider.logout(null);
                        }
                        return null;
                    }
                }
            } else {
                // console.log("Ensure: decoding token failed");
                applicationConfigManager.clearSession();
                await authProvider.logout(null);
                return null;
            }
        } else {
            // console.log("Ensure: clearing beacause the IdToken is invalid");
            applicationConfigManager.clearSession();
            await authProvider.logout(null);
            return null;
        }
    }

    setConfigUser(configUser: CognitoUser) {
        this.currentConfig.user = CognitoUser;
    }

    getCognitoUser(): CognitoUser {
        if (this.currentConfig.user)
            return this.currentConfig.user;

        return this.currentConfig.userPool.getCurrentUser();
    }

    getCognitoUserSession(): AmazonCognitoIdentity.CognitoUserSession {
        //console.log("asking for session: ", this.currentConfig);
        return this.currentConfig.userSession;
    }

    setCognitoUserSession(session: AmazonCognitoIdentity.CognitoUserSession) {
        //console.log("setting cognito user session: ", session);
        this.currentConfig.userSession = session;
    }

    setCognitoUser(userName: string) {
        // console.log("setting cognito user with email : " + userName);
        if (userName) {
            if (this.currentConfig.user === null || this.currentConfig.user.getUsername() !== userName) {
                var userData: AmazonCognitoIdentity.ICognitoUserData = {
                    Username: userName,
                    Pool: this.currentConfig.userPool
                };
                // console.log("setting cognito user with new userName : " + userName);
                this.currentConfig.user = new AmazonCognitoIdentity.CognitoUser(userData);
            } else {
                // duplicate session, do nothing
                // console.log("current session is with same user");
            }
        }
    }
}
