import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import KnowledgeCenter from '../../KnowledgeCenter';
import {AppState} from '../../types';
import Menu from '../Menu';
import {darkTheme, lightTheme} from '../themes';
import {WrapLayout, CustomSidebar} from "./index";

const useLayoutStyles = makeStyles(theme => ({
    content: {
        flexDirection: "initial",
        width: "calc(100vw - 270px)"
    },
}));

const useLayoutContainerStyles = makeStyles(theme => ({
    contentContainer: {
        width: "100%",
        margin: "0 !important",
        display: "flex",
        padding: "0 !important",
        //zIndex: 2,
        flexGrow: 1,
        flexBasis: 0,
        paddingTop: "8px",
        paddingLeft: 0,
        flexDirection: "column",
    },
    showIcon: {
        position: 'fixed',
        bottom: '20px',
        left: '0px',
        color: 'white',
        background: "rgb(25, 34, 46) !important",
        zIndex: 100,
    }
}));

const Layout: React.FC = (props) => {
    //const CustomSidebar = (props: any) => <Sidebar {...props}  />;
    const {children, ...otherProps} = props;
    const classes = useLayoutStyles();
    const classesContainer = useLayoutContainerStyles();
    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );

    return (
        <WrapLayout
            sidebar={CustomSidebar}
            menu={Menu}
            theme={theme}
            customtheme={theme}
            customMenu={Menu}
            classes={classes}
            {...otherProps}
        >
            <div className={classesContainer.contentContainer}>
                {children}
            </div>
            <KnowledgeCenter/>
        </WrapLayout>
    );
};

export default Layout;
