import {
  Box,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import { Select, Input, Button } from '@panwds/react-ui';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import {useTranslate} from '../../customHooks';
import {PANWDSLabel} from "./PANWDSLabel";

const useStyles = makeStyles((theme) => ({
  Container: {
    display: "grid",
    gridTemplateColumns: "90px 1fr 40px",
    gridGap: "5px",
    width: "100%",
    "&>div": {
      alignSelf: "center",
    }
  },
  bottomContainer: {
    display: "grid",
    gridTemplateColumns: "70px 1fr",
    gridGap: "8px",
    alignItems: 'center',
    "&>button": {
      paddingLeft: '8px',
      "&>span": {
        "&>svg": {
          width: '18px',
          height: '18px',
        }
      }
    }
  },
  errorText: {
    color: "#D13C3C",
  },
  Note: {
    color: "#707070",
  },
  buttonText: {
    width: "100%",
    textAlign: "left",
  },
  buttonNameStyles: {
    margin: '2px 8px 0 0',
  }
}));

export const PANWDSSelectsAndInput = ({
  meta: { touched, error, submitFailed } = { touched: false, error: undefined, submitFailed: false },
  input: { ...inputProps },
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const existerror = !!((touched || submitFailed) && error);
  let items: any = props.formatter ? props.formatter(inputProps.value) : inputProps.value;
  if (items.map === undefined || items.length === 0) {
    items = [["", ""]];
  }

  const addInputs = () => {
    let lastIndex =  items.length - 1;
    if(items[lastIndex][0].trim() === ""){
      return;
    }
    items.push(["", ""]);
    handleChange("", -1, 0);
  };

  const removeInputs = (i: number) => {
    items.splice(i, 1);
    handleChange("", -1, 0);
  };

  const handleChange = (value: any, index: number, controlIndex: number) => {
    let updatedData = items.map((item: any, i: number) => {
      if (i === index) {
        item[controlIndex] = value;
      }
      return item;
    });
    if (props.changeformatter) {
      updatedData = props.changeformatter(updatedData);
    }
    inputProps.onChange(updatedData);
  };
  const selectedValues = items.map((item: any) => item[0]);
  const choices = props?.options || [];
  const { required, title, selectLabel, textLabel, textNote } = props;

  return (
    <div style={{ width: '100%' }}>
      <Box className={classes.Container}>
        <div>
            {selectLabel && <PANWDSLabel required={required} title={selectLabel}/>}
        </div>
        <div>{textLabel && <PANWDSLabel required={required} title={textLabel}/>}</div>
        <div></div>

        {items.map((item: any, index: number) => {
          // rowChoices = item.value or unused item values
          const rowChoices = choices.filter((choice: any) => (!selectedValues.includes(choice.value) || choice.value === item[0]));
          return (
            <>
              <div>
                <Select selectedItem={{ value: item[0] }} items={rowChoices}
                  onChange={({ selectedItem }) => handleChange(selectedItem.value, index, 0)}
                  dataTestId={props.dataTestId}
                  fullWidth
                  button={
                    <Button isMenu fullWidth>
                        <span className={classes.buttonText}>
                          {item[0]}
                        </span>
                    </Button>
                }
                />
              </div>
              <div>
                <Input {...props} {...inputProps} value={item[1]}
                  onChange={(e: any) => handleChange(e.target.value, index, 1)} />
              </div>
              <div>
                <IconButton
                  disabled={items.length < 2}
                  onClick={() => removeInputs(index)}
                  color="primary"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </>
          );
        })}
        { textNote && <><div /><div className={classes.Note}>{textNote}</div><div /></>}
      </Box>
      <div className={classes.bottomContainer}>
        <Button
          icon={<AddIcon />}
          appearance="secondary"
          onClick={addInputs}
          disabled={items.length === choices.length}
        >
          <span className={classes.buttonNameStyles}>{translate(`resources.rules.panwdsSelectAndInput.add`)}</span>
        </Button>
        <div>
          {existerror && (<FormHelperText id={props.label} className={classes.errorText}>{error}</FormHelperText>)}
        </div>
      </div>
    </div>
  )
}
