import { makeStyles } from "@material-ui/core";
import { FilterBar, useFilters } from "@panwds/react-filters";
import { Button, Button as PANWDSButton, ModalBody, Select, SelectItem, Tabs, Tooltip } from "@panwds/react-ui";
import {
  TableProvider,
  ConnectedTable,
  ConnectedTableLayout,
  ConnectedTableFilter,
} from "@panwds/react-table";
import { FC, useState, useEffect, useRef, useLayoutEffect } from "react";
import { SaveButton } from "../../components";
import EmptyListImage from "../../images/empty_firewall.svg";
import {
  IDropDownActions,
  IPANSearchbar,
  ISearchBarFilters,
  ITabsList,
} from "../../types";
import "./PANWDSTable.css";
import _, { isEmpty, kebabCase } from "lodash";
import { PANWDSModal } from "./PANWDSModal";
import {useTranslate} from '../../customHooks';
import { Tab, TabList, TabPanel } from "@panwds/react-ui";
import { SyncIcon } from "@panwds/icons";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles((theme) => ({
  ButtonContainer: (props: any) => ({
    display: "flex",
    gap: theme.spacing(1),
    lex: "0 0 50%",
    [theme.breakpoints.down(props.nested ? 900 : 800)]: {
      gap: "2px",
      lex: "0 0 55%",
    },
    [theme.breakpoints.down(800)]: {
      padding: props.nested ? "5px 0" : "0",
      gap: "2px",
    },
      '& .refresh-icon': {
          padding: '0.375rem',
          '& > span': {
              marginRight: 0
          }
      }
  }),
  title: {
    color: "#333333",
    fontSize: 16,
  },
  description: {
    color: "#707070",
    fontWeight: 400,
    fontSize: 12,
    marginTop: 6,
  },
  button: {
    textTransform: "capitalize",
  },
  cellStyles: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "20px",
  },
  infiniteScrollTableBox: (props: any) => ({
    padding: 16,
    paddingBottom: 0,
    '& > div > div > div.tw-border-solid:nth-child(2)': {
      borderTop: 'none'
    }
  }),
  paginatedScrollTableHeight: (props: any) => ({
    overflowY: 'auto',
      overflowX: 'hidden',
      height: `${
        props.offsetTableHeight
          ? "calc(100vh - " + props.offsetTableHeight + "px)"
          : "100vh"
      }`,
  }),
  styleFiletrsCointainer: {
    borderBottom: "2px solid #e4e3e2",
  },
  iconedParagraph: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  modalHeader: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    padding: '16px 16px 0',
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconedSubtitle: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '14px',
    color: '#707070',
    marginTop: '4px'
  },
  SearchContainer: {
    '& svg': {
        fill: '#575757'
    }
  },
  tabPanelStyles: {
    margin: 0
  }
}));

export interface PANGridProps {
  gridData: never[];
  columns: any;
  title: string;
  dataMetrics?: string;
  permissions?: any;
  loadGridData?: any;
  offsetTableHeight?: any;
  infiniteScroll?: boolean;
  subtitle?: string;
  loading?: boolean;
  multiSelect?: boolean;
  singleSelect?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  showToolbar?: boolean;
  dropDownActionsArray?: IDropDownActions[];
  showTileTitle?: boolean;
  emptyTitle?: string;
  emptySubtitle?: string;
  lockedRows?: any[];
  dataTestId?: string;
  searchBar?: IPANSearchbar;
  nested?: boolean;
  rowSize?: "cozy" | "standard" | "comfy";
  filterBar?: ISearchBarFilters;
  searchFilterRequired?: boolean;
  enablePagination?: boolean;
  isBackgroundFetching?: any;
  tableInnerContainerClassName?: string;
  overflowTable?: boolean;
  disableRow?: Function;
  disableActionsBtn?: boolean | Function;
  tabs: ITabsList;
  actions: any;
}

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  let doit: any;
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", () => {
      clearTimeout(doit);
      doit = setTimeout(updateSize, 100);
    });
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const PANWDSTabTable: FC<PANGridProps> = (props) => {
  const translate = useTranslate();
  const [selected, setSelected] = useState<any[]>([]); // TODO to be removed
  const [width, height] = useWindowSize();
  const classes = useStyles({
    nested: props.nested,
    offsetTableHeight: props.offsetTableHeight,
  });
  const tableInstanceRef = useRef<any>();
  const { filterBar } = props;
  const { filters } = useFilters("FilterAndTable");
  const [tableData, setTableData] = useState(props?.gridData);
  const [classNameForEmpty, setClassNameForEmpty] = useState(false);
  const [selectedItems, setSelectedItems] = useState<never[]>([]);
  const [refreshColWidth, setRefreshColWidth] = useState(false);
  const [selectedItemsDropdown, setSelectedItemsDropdown] = useState<SelectItem[]>([]);

  const getSelectedItems = () => {
    if (tableInstanceRef.current) {
      return tableInstanceRef.current.selectedFlatRows.map(
        (row: any) => row.original
      );
    }
    return [];
  };

  // default these props to true
  let isMultiselect =
    props.multiSelect === undefined || props.multiSelect === true;
  let isSortable = props.sortable === undefined || props.sortable === true;
  let isResizable = props.resizable === undefined || props.resizable === true;
  let loading = props.loading;

  let showToolbar = props.showToolbar;
  let { dropDownActionsArray } = props;

  useEffect(() => {
    if (filterBar?.filterBarRequired && filterBar?.filterConfig) {
        let newGridData = _.cloneDeep(props.gridData);

        if (!filterBar?.filterConfig) return;

        let filtersObjectNames: any = {};

        let filtersNameObj = filterBar?.filterConfig?.map((name: any) => name);

        for (const key of filtersNameObj) {
          filtersObjectNames[key] = [];
        }
        let filtersObject = { ...filtersObjectNames, ...filters };

        let columnProperty = "";
        // reset filters will set the table data to default
        let allFilterProps = Object?.keys(filtersObject);
        allFilterProps?.map((filter) => {
          let newFilterSelected = filtersObject[filter];
          if (newFilterSelected?.length === 0) {
            setTableData(props?.gridData);
          }
        });

        for (const filterProp in filtersObject) {
          if (filtersObject[filterProp]?.length > 0) {
            for (var i = 0; i < filterBar?.filterConfig?.length; i++) {
              if (filterBar?.filterConfig[i]?.name == filterProp) {
                columnProperty = filterBar?.filterConfig[i]?.columnValue;
                break;
              }
            }

            if (columnProperty?.includes(".")) {
              let prop = columnProperty?.split(".");
              newGridData =
                newGridData &&
                newGridData?.filter((d: any) => {
                  //if loop for tags and characteristic where we have comma separated values for column
                  if (Array.isArray(d[prop[0]][prop[1]])) {
                    for (const val of d[prop[0]][prop[1]]) {
                      if (filtersObject[filterProp]?.includes(val)) {
                        return true;
                      }
                    }
                    return false;
                  } else {
                    return filtersObject[filterProp]?.includes(
                      d[prop[0]][prop[1]]
                    );
                  }
                });
            } else {
              newGridData =
                newGridData &&
                newGridData?.filter((d: any) => {
                  if (columnProperty === "Characteristics") {
                    let newval = displayOptions(d[columnProperty]);
                    const found = filtersObject[filterProp].some((r: any) =>
                      newval.includes(r)
                    );
                    if (found) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    //if loop for tags and characteristic where we have comma separated values for column
                    if (Array.isArray(d[columnProperty])) {
                      for (const val of d[columnProperty]) {
                        if (filtersObject[filterProp]?.includes(val)) {
                          return true;
                        }
                      }
                      return false;
                    } else {
                      return filtersObject[filterProp]?.includes(
                        d[columnProperty]
                      );
                    }
                  }
                });
            }
          }
        }
        setTableData(newGridData);
    }
  }, [props, filters, filterBar?.filterConfig]);

  const displayOptions = (filterOption: any) => {
    let newRowsObj = [];

    for (var key in filterOption) {
      if (key === "DenyAction" && filterOption[key] !== "") {
        newRowsObj.push(key);
      } else if (filterOption[key] === true) newRowsObj.push(key);
    }
    return newRowsObj;
  };

  const [confirmDelete, setConfirmDelete] = useState<any>({ open: false });

  const getModalColumns = () =>  [
    {
      accessor: "VpcId",
      Header: translate(`resources.inventory.vpc.vpc`),
      columnSize: 4,
      render: ({ row }: any) => {
        return (
          <span
            key={`tags-record-${kebabCase(row?.original?.VpcId)}`}
            // className={classes.appColumnStyles}
            data-test-id={`tags-record-${kebabCase(row?.original?.VpcId)}`}
          >
            {row?.original?.VpcId}
          </span>
        );
      },
    },
  ]

  const getModalBody = () => {
    const groupName = selectedItemsDropdown?.toString()?.split('(')?.[0];
    return (
      <>
      <div className={classes.modalHeader}>
        {translate(`panwdsTabTable.confirmMessage`)} {groupName}?
      </div>
      <ModalBody addClassName="tw-overflow-auto">
        <TableProvider
          columns={getModalColumns()}
          data={confirmDelete?.selectedVPCs}
        >
          <ConnectedTableLayout>
            <ConnectedTable />
          </ConnectedTableLayout>
        </TableProvider>
    </ModalBody>
    </>
    )
  }

  const ConfirmDeleteModal = (
    <PANWDSModal
      title={translate(`panwdsTabTable.notification`)}
      onClose={() => setConfirmDelete({ open: false })}
      isOpen={confirmDelete.open}
      size="md"
      dataTestId="cloudngfw-panwds-set-vpc-group-confirm-modal"
      dataMetrics="cloudngfw-panwds-set-vpc-group-confirm-modal"
      modalBody={getModalBody()}
      modalFooter={{
        footerRequired: true,
        cancelButtonRequired: true,
        featureSpecificButton: (
          <SaveButton
            appearance="primary"
            size="md"
            label={translate(`panwdsTable.confirm`)}
            // redirect={false}
            onClick={() => {
              confirmDelete.callback(getSelectedItems(), selectedItemsDropdown);
              setConfirmDelete({ open: false });
            }}
            dataMetrics="cloudngfw-panwds-delete-confirm-modal-button"
            {...props}
          />
        ),
      }}
    />
  );

  const parseColumns = () => {
    const totalColumns = props.columns.length;
    const hasColumnWithSize = props.columns.find((col: any) => col.columnSize);
    const tableWidth =
      document?.querySelector<HTMLElement>(
        `div[data-testid="${
          props.dataTestId ? props.dataTestId : "connected-table"
        }"]`
      )?.offsetWidth || false;
    if (tableWidth) {
      props.columns.map((col: any) => {
        if (hasColumnWithSize) {
          col.width = col.columnSize
            ? (tableWidth / 12) * col.columnSize
            : tableWidth / 12;
        } else {
          col.width = tableWidth / totalColumns;
        }
        col.minWidth = col.minWidth ? col.minWidth : 100;
        col.Cell = (cellProps: any) => {
          const { column, value } = cellProps;
          if (col.noTooltip || typeof value !== "string") {
            // if value is not string, it'll break
            return (
              <span
                className={classes.cellStyles}
                style={{ width: column.width + "px" }}
              >
                {col.render ? col.render(cellProps) : value}
              </span>
            );
          }
          return (
            <span  className={classes.cellStyles} style={{ width: column.width - 10 + "px" }}>
              <Tooltip label={value}>
                <span
                  // style={{ width: column.width - 10 + "px" }}
                >
                  {col.render ? col.render(cellProps) : value}
                </span>
              </Tooltip>
            </span>
          );
        };
      });
      return props.columns;
    }
    return [];
  };

  useEffect(() => {
    if (selected.length !== 0 && !loading && tableInstanceRef.current) {
      selected.forEach((selectedRow) => {
        selectedRow.toggleRowSelected(true);
      });
    }
  }, [props.gridData]);

  let columns: any = parseColumns();

  useEffect(() => {
    columns = parseColumns();
    if (!refreshColWidth) {
      setRefreshColWidth(true);
    }
  }, [width, height]);

  useEffect(() => {
    setRefreshColWidth(false);
  }, [refreshColWidth]);

  const showConfirmModal = (type: string, callback: Function, selectedItem: any, selectedVPCs: any) => {
    if (type === 'delete'){
      setConfirmDelete({open: true, callback, selectedItem, selectedVPCs });
    }
  };

  const checkDisableActions = (selectedRows: any) => {
    if( selectedRows.length === 0 ){
      return true;
    }
    return (props.disableActionsBtn && typeof props.disableActionsBtn === "function" && props.disableActionsBtn(selectedRows)) ||
    typeof props.disableActionsBtn === "boolean" && props.disableActionsBtn;
  }

  let tableDataRowStyle = filterBar?.filterBarRequired ? tableData : props.gridData;

  let classNameContainer =
    props.infiniteScroll || props.overflowTable
      ? "overflow-table-container tw-flex tw-flex-col"
      : "";
  classNameContainer +=
    isMultiselect || props.singleSelect
      ? " row-selection"
      : " no-row-selection";
  classNameContainer = props.singleSelect
    ? classNameContainer + " single-selection"
    : classNameContainer;
  classNameContainer = props.tableInnerContainerClassName
    ? classNameContainer + " " + props.tableInnerContainerClassName
    : classNameContainer;
  classNameContainer +=
    tableDataRowStyle?.length === 0 || classNameForEmpty ? " noDataStyles" : "";

  return (
    <div className={classes.infiniteScrollTableBox}>
      <div className={classNameContainer}>
        <TableProvider
          columns={refreshColWidth ? [] : columns}
          data={filterBar?.filterBarRequired ? tableData : props.gridData}
          enablePagination={props?.enablePagination}
          isLoading={loading}
          enableRowSelect={isMultiselect || props.singleSelect}
          enableResizeColumns={isResizable}
          enableColumnSort={isSortable}
          rowSelectMode={props.singleSelect ? "single" : "multiple"}
          tableInstanceRef={tableInstanceRef}
          onTableFilterChange={() => {
            if (tableInstanceRef?.current) {
              setClassNameForEmpty(
                tableInstanceRef?.current?.flatRows?.length === 0
              );
            }
          }}
          onSelectedRowsChange={(record: any) => {
            setSelectedItems(record);
          }}
        >
          <ConnectedTableLayout
            dataMetrics={props.dataMetrics}
            enableHeaderTitle={true}
            dataTestId={props.dataTestId ? props.dataTestId : ""}
            controlLeft={
              <>
                <div className={classes.titleHeader}>
                {props.showTileTitle ? (
                  <div className={classes.iconedParagraph}>
                    {props.title ?? ""}
                  </div>
                ) : null}
                 {props.subtitle 
                 ? <div className={classes.iconedSubtitle}>{props.subtitle ?? ""}</div>
                 : <div className={classes.iconedSubtitle}><Skeleton variant="text" width="100px" height="15px" /></div>}
                 </div>
              </>
            }
            controlRight={
              showToolbar ? (
                <>
                    {props.searchFilterRequired && <div className={classes.SearchContainer}>
                        <ConnectedTableFilter placeholder={'Search'} />
                    </div>}
                  <div className={classes.ButtonContainer}>
                    {dropDownActionsArray &&
                      dropDownActionsArray?.length > 0 &&
                      dropDownActionsArray?.map((dropDownActions, ddIndex) => {
                        const ddType = dropDownActions.type ?? "secondary";
                        if (["button", "button_secondary"].includes(ddType)) {
                          return (
                            <PANWDSButton
                              key={ddIndex}
                              appearance={
                                dropDownActions?.appearance
                                  ? dropDownActions?.appearance
                                  : "secondary"
                              }
                              size="md"
                              disabled={
                                dropDownActions?.disabledAddLogic
                                  ? isEmpty(selectedItems)
                                  : dropDownActions.disabled
                              }
                              data-metrics={dropDownActions?.dataMetrics || ""}
                              //@ts-ignore
                              onClick={() => dropDownActions?.handleButtonAction(getSelectedItems())}
                            >
                              {dropDownActions.title}
                            </PANWDSButton>
                          );
                        } else if(["icon"].includes(ddType)) {
                          return (
                            <PANWDSButton
                              key={ddIndex}
                              aria-label={'refresh-icon'}
                              addClassName={'refresh-icon'}
                              icon={<SyncIcon size="md" />}
                              appearance="tertiary"
                              data-metrics={dropDownActions?.dataMetrics || ""}
                              onClick={() => dropDownActions?.handleButtonAction(getSelectedItems())}
                          > </PANWDSButton>
                          );
                        }
                        return (
                          <Select
                            key={dropDownActions.title}
                            button={
                              <Button isMenu>
                                {dropDownActions.title}
                              </Button>
                            }
                            items={dropDownActions?.getVPCGroupItems(getSelectedItems())}
                            onChange={(event: any) => {
                              setSelectedItemsDropdown(event?.selectedItem?.value);
                              if (dropDownActions.confirmModal) {
                                showConfirmModal(dropDownActions.confirmModal,dropDownActions.handleAction, selectedItemsDropdown, getSelectedItems());
                              } else {
                                dropDownActions.handleAction(getSelectedItems());
                              }
                            }}
                            disabled={dropDownActions.type === "primary" ? false : checkDisableActions(getSelectedItems())}
                            enableSearch
                          />
                        )
                      })}
                    {confirmDelete.open && ConfirmDeleteModal}
                  </div>
                </>
              ) : null
            }
          >
            <Tabs>
            <TabList
              activeTabId={props?.tabs?.activeTabValue?.toString()}
              onActiveChange={(nextTabId) => props?.tabs?.handleTabsChange(nextTabId)}
            >
              {props?.tabs?.tabsList?.map((tabObj) => <Tab key={tabObj?.id} id={tabObj?.id}>{tabObj?.tab}</Tab>)}
            </TabList>
            <TabPanel addClassName={classes.tabPanelStyles} activeTabId={props?.tabs?.activeTabValue?.toString()} forTabId={props?.tabs?.activeTabValue?.toString()}>
              {filterBar?.filterBarRequired &&
                (
                  <div
                    className={classes.styleFiletrsCointainer}
                    key={props?.dataTestId}
                  >
                    <FilterBar
                      key={filterBar?.filterConfig?.map((obj: any) => obj?.context?.items).join()}
                      name={"FilterAndTable"}
                      filters={
                        filterBar?.filterConfig ? filterBar?.filterConfig : []
                      }
                      saveOnUnmount={false}
                    />
                  </div>
                )}
              <div className={classes.paginatedScrollTableHeight}>
              <ConnectedTable
                shouldDisableRow={(row: { row: any }) => {
                  if (props.lockedRows) {
                    let isRowDisabled = false;
                    props.lockedRows.forEach((locked, index) => {
                      if (index === row?.row?.index) {
                        isRowDisabled = true;
                      }
                    });
                    return isRowDisabled;
                  }
                  return (props.disableRow && props.disableRow(row)) || false;
                }}
                dataTestId={props.dataTestId}
                rowSize={props.rowSize ? props.rowSize : "standard"}
                emptyStateProps={{
                  description: props.emptySubtitle
                    ? props.emptySubtitle
                    : undefined,
                  heading: props.emptyTitle ? props.emptyTitle : undefined,
                  graphic: (
                    <img width="100px" height="100px" src={EmptyListImage} />
                  ),
                  actions: props?.actions
                }}
              />
              </div>
            </TabPanel>
            </Tabs>
          </ConnectedTableLayout>
        </TableProvider>
      </div>
    </div>
  );
};
