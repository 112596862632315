import { FormLayout, SubmitButton, Textarea, Checkbox, Input } from '@panwds/react-form';
import { Button, Sheet, SheetBody } from "@panwds/react-ui";
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslate } from "../../../../../customHooks";
import { validateCIDRList } from '../../../../../utils/validate';

export const EndpointsEdit = (props: any) => {
    const { close, editEndpoint, editEndpointData, disableEgress } = props;
    const translate = useTranslate();

    const formMethods = useForm({defaultValues: editEndpointData});
    const {
        formState: { isDirty, }
    } = formMethods

    const onSubmit = useCallback(
        async (data, e) => {
            editEndpoint(data);
        }, []
    );

    return (
        <>
         <Sheet
            onClose={() => close()}
            title={translate(`resources.firewallsV2.endpointManagement.editEndpoint`)}
            isOpen
            showMask={true}
            position="md"
        >
            <SheetBody>
                <div style={{ position: 'relative' }}>
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <FormLayout>
                                <Input
                                    label={translate(`resources.firewallsV2.endpointManagement.endpointId`)}
                                    name="EndpointId"
                                    dataMetrics="cloudngfw-firewall-edit-endpoint-endpointId"
                                    disabled
                                />
                                <Textarea
                                    label={translate(`resources.firewallsV2.endpointManagement.endpointPrivatePrefix`)}
                                    name="Prefixes.PrivatePrefix.Cidrs"
                                    dataMetrics="cloudngfw-firewall-edit-endpoint-private-prefix"
                                    register={{ validate: validateCIDRList }}
                                />
                                <Checkbox
                                    label={translate(`resources.firewallsV2.enableEgressNat`)}
                                    name="EgressNATEnabled"
                                    dataMetrics="cloudngfw-firewall-edit-endpoint-enable-egressnat"
                                    disabled={disableEgress}
                                />

                                <div className="tw-flex tw-justify-end">
                                    <Button addClassName='tw-mr-2' appearance={"secondary"} onClick={() => close()}>
                                        {translate("generic.cancel")}
                                    </Button>
                                    <SubmitButton disabled={ !isDirty }>{translate(`generic.edit`)}</SubmitButton>
                                </div>
                            </FormLayout>
                        </form>
                    </FormProvider>
                </div>
            </SheetBody>
        </Sheet>
    </>
    );
};
