import { dataProvider } from "../dataProvider";
import { toast } from '../components';
import Emitter from "../eventEmitter";

export  const GetFirewallsLengthByAccount = async (setFirewallsLength: any, setLoading: any, setError?: any) => {
    setLoading(true);
    let firewallsList: any = [];
    let result: any = {};

    result = await dataProvider.get("firewalls", {
      // data: { 'accountid': accountId }
    });
    if(result.data) {
      firewallsList = [...(await result).data, ...firewallsList];
      setFirewallsLength(firewallsList.length);
    }
    if(result?.error) {
      setLoading(false);
      setError(result?.error)
      toast.error(result?.error, { toastId:  "firewalls-list" });
    }
    return;
}
