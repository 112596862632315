import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@mui/material';
import { CloseIcon, InfoIcon } from '@panwds/icons';
import { Card, CardHeader, CardBody, Tooltip, Button } from '@panwds/react-ui';
import { useState } from 'react';
import { Overview as OverviewImage } from "../../../images";
import { useTranslate } from '../../../customHooks';

const useStyles = makeStyles((theme) => ({
    gridStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        '&>div': {
            flexGrow: '1',
            flexBasis: '0',
            minWidth: '0',
        }
    },
    gridStylesRow: {
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'column',
    },
    gridStylesColumn: {
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'row',
    },
    rightSide: {
        display: 'flex',
        alignItems: 'center'
    },
    rightLeftSide: {
        width: '85%',
        '&>div': {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
            color: "#333333",
            marginBottom: '5px',
            marginRight: '31px',
            width: '75%'
        }
    },
    rightLeftSideSecond: {
        marginBottom: '16px',
    },
    noCardStyles: {
        display: 'none',
        padding: '16px 16px 0',
    },
    cardStyles: {
        padding: '16px 16px 0',
    },
    verticalLine: {
        borderLeft: '1px solid #DADBDB',
        width: '4px',
        height: '55px',
        marginLeft: '24px',
        marginRight: '24px'
    },
    firstRowCard: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        color: "#333333",
        paddingBottom: '21px'
    },
    secondRowCardHeader: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '20px',
        color: "#707070"
    },
    secondRowCardValue: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '20px',
        lineHeight: '32px',
        color: "#AC1818"
    },
    secondRowCardHeaderWithTooltip: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    cardHeader: {
        background: "#FFFFFF",
    }
}));

export const WelcomeCard = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [closeCard, setCloseCard] =  useState(false);

    const closeCardClicked = () => {
        setCloseCard(true)
    }

    return (
        <div data-metrics="cloudngfw-overviewScreen-welcome-card-container" id="welcome-card-div" className={closeCard ? classes.noCardStyles : classes.cardStyles}>
            <Card data-metrics="cloudngfw-overviewScreen-welcome-card" dataTestId='welcome-card'>
                <CardHeader
                    title={translate(`resources.overview.welcomeCardHeader`)}
                    dataTestId='welcome-card-header'
                    data-metrics="cloudngfw-overviewScreen-welcome-card-header"
                    addClassName={classes.cardHeader}
                />
                <CardBody data-metrics="cloudngfw-overviewScreen-welcome-card-body-main" dataTestId='welcome-card-body'>
                    <div data-metrics="cloudngfw-overviewScreen-welcome-card-body">
                        {/* <div data-metrics="cloudngfw-overviewScreen-welcome-card-grid" id="welcome-main-grid" className={classes.gridStyles}> */}
                            <Grid data-metrics="cloudngfw-overviewScreen-welcome-card-rightSide" id="welcome-right-grid" className={classes.rightSide}>
                                <div data-metrics="cloudngfw-overviewScreen-welcome-card-rightSide-2" className={classes.rightLeftSide}>
                                    <div>{translate(`resources.overview.welcomeRightLeftSideGrid`)}</div>
                                    <div className={classes.rightLeftSideSecond}>{translate(`resources.overview.welcomeRightLeftSideGridSecondLine`)}</div>
                                </div>
                                <img data-metrics="cloudngfw-overviewScreen-welcome-card-img" width="164px" height="120px" alt='quick-tour-overview-img' src={OverviewImage}/>
                            </Grid>
                        {/* </div> */}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default WelcomeCard;
