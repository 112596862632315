import {
  Button as MuiButton, CircularProgress, PropTypes as MuiPropTypes
} from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { LocationDescriptor } from 'history';
import PropTypes from 'prop-types';
import { FC, ReactElement, ReactNode, SyntheticEvent } from 'react';
import { useTranslate } from "../../customHooks";
import { Record, Identifier } from "../../types";

/**
 * A generic Button with side icon. Only the icon is displayed on small screens.
 *
 * The component translates the label. Pass the icon as child.
 * The icon displays on the left side of the button by default. Set alignIcon prop to 'right' to inverse.
 *
 * @example
 *
 * <Button label="Edit" color="secondary" onClick={doEdit}>
 *   <ContentCreate />
 * </Button>
 *
 */

type RedirectToFunction = (
    basePath: string,
    id: Identifier,
    data: any
) => string;

type RedirectionSideEffect = string | boolean | RedirectToFunction;

const Button: FC<ButtonProps> = props => {
  const {
    children,
    classes: classesOverride,
    className,
    color,
    disabled,
    label,
    size,
    loading,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const restProps = sanitizeButtonRestProps(rest);

  return (
    <MuiButton
      style={{ textTransform: 'capitalize' }}
      className={classnames(classes.button, className)}
      color={color}
      size={size}
      aria-label={label ? translate(label, { _: label }) : undefined}
      disabled={disabled || loading}
      {...restProps}
    >
      {label && (
        <span>
          {translate(label, { _: label })}
        </span>
      )}
      {loading && (
        <CircularProgress
          size={20}
          thickness={2}
        />
      )}
    </MuiButton>
  );
};

const useStyles = makeStyles(
  {
    button: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    label: {
      paddingLeft: '0.5em',
      textTransform: 'capitalize',
    },
    labelRightIcon: {
      paddingRight: '0.5em',
    },
    smallIcon: {
      fontSize: 20,
    },
    mediumIcon: {
      fontSize: 22,
    },
    largeIcon: {
      fontSize: 24,
    },
  },
  { name: 'RaButton' }
);

interface Props {
  alignIcon?: 'left' | 'right',
  children?: ReactElement,
  classes?: object,
  className?: string,
  color?: MuiPropTypes.Color,
  component?: ReactNode,
  to?: string | LocationDescriptor,
  disabled?: boolean,
  label?: string,
  size?: 'small' | 'medium' | 'large',
  icon?: ReactElement,
  redirect?: RedirectionSideEffect,
  variant?: string,
  // May be injected by Toolbar
  basePath?: string,
  mutationMode?: string,
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>,
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void,
  invalid?: boolean,
  onSave?: (values: object, redirect: RedirectionSideEffect) => void,
  saving?: boolean,
  submitOnEnter?: boolean,
  pristine?: boolean,
  record?: Record,
  resource?: string,
  undoable?: boolean,
  loading?: boolean;
}

export type ButtonProps = Props & MuiButtonProps;

export const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  onSave,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  mutationMode,
  validating,
  ...rest
}: any) => rest;

Button.propTypes = {
  alignIcon: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  loading: PropTypes.bool,
};

Button.defaultProps = {
  color: 'primary',
  size: 'small',
};

export default Button;
