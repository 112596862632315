import RulesSettings from "./RulesSettings";
import FirewallSettings from "./FirewallSettings";
import LogSettings from "./LogSettingForm";
import EndpointSettings from "./EndpointSettings";
import General from "./General";
import EgressNat from "./EgressNat";
import PolicyManagement from "./PolicyManagement";
import EndpointManagement from "./EndpointManagement";
import FirewallBanner from "./FirewallBanner";
import {ManageEgressNatTrafficContainer} from "./ManageEgressNatTraffic";
import PrivateAccess from "./PrivateAccess";

const FirewallSettingsComponent = {
    General,
    EgressNat,
    PolicyManagement,
    EndpointManagement,
    EndpointSettings,
    ManageEgressNatTrafficContainer,
    FirewallBanner,
    PrivateAccess
};

export {
    RulesSettings,
    FirewallSettings,
    LogSettings,
    FirewallSettingsComponent
}
