import { dataProvider } from "../dataProvider";
import { toast } from '../components';

export const GetRulesListData = async (permissions: any, needRefresh = false, scope: any, setLoading: any,
  ruleStackName: any, setLocalGridData: any, setGlobalPreGridData: any,
  setGlobalPostGridData: any, setRecursiveLoading: any, setNextToken: any, setPreNextToken: any,
  setPostNextToken: any, gridData: any, preGridDta: any, postGridData: any, nextToken?: any,
  preNextToken?: any, postNextToken?: any) => {
  if (!permissions?.ListSecurityRules) {
    return;
  }
  if (scope === "Local") {
      getRuleListData("LocalRule", ruleStackName, nextToken, gridData, setLoading, setLocalGridData, setRecursiveLoading, setNextToken);
  }else {
      getRuleListData("PreRule", ruleStackName, preNextToken, preGridDta, setLoading, setGlobalPreGridData, setRecursiveLoading, setPreNextToken);
      getRuleListData("PostRule", ruleStackName, postNextToken, postGridData, setLoading, setGlobalPostGridData, setRecursiveLoading, setPostNextToken);
  }
  return;
}

//@ts-ignore
async function getRuleListData(RuleListName: string, RulestackName: string, NextToken: any, prevData: any, setLoading: any, setGridData: any, setRecursiveLoading: any, setNextToken: any) {
  if(prevData.length && NextToken === "done"){
    return;
  }
  return dataProvider.getList("rules", {
    data: { 'RuleListName': RuleListName, 'RuleStackName': RulestackName, NextToken: (NextToken) ? NextToken : undefined }
  })
    .then(async (response: any) => {
      if (response.data) {
        //@ts-ignore
        let responseObj = response.data || [];
        if (!response?.nextToken) {
          setRecursiveLoading(false);
        }
        if (!NextToken){ // it is first call, discard any prevData
          setGridData([...responseObj]);
        } else {
          setGridData([...prevData, ...responseObj]);
        }
        setNextToken(response?.nextToken || "done");
      } else {
        toast.error(response?.error, { toastId: "rules-getList" });
      }
    })
    .catch((error: any) => {
      if (error?.noToast) {
        return;
      }
      toast.error(error?.error, { toastId: "rules-getList" });
    })
    .finally(() => {
      setLoading(false)
    });
}
