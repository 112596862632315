import { Theme, withStyles, Card, Box, Typography } from "@material-ui/core";
import background from '../images/background.png';
import { makeStyles } from '@material-ui/styles';

export const Background = withStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    color: "#333333",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%), url("${background}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}))(Box);

export const CustomCard = withStyles((theme: Theme) => ({
  root: {
    background: "#F9F9F9",
    minWidth: 450,
    minHeight: 450,
    padding: "27px 32px",
    margin: "auto",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
  },
}))(Card);

export const TransparentBackground = withStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    color: "#333333",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: `rgba(0, 0, 0, 0.7)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}))(Box);

export const FixedWidthCard = withStyles((theme: Theme) => ({
  root: {
    background: "#F9F9F9",
    width: 450,
    minHeight: 500,
    maxHeight: 730,
    padding: "27px 32px",
    margin: "auto",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    flexDirection: "column",
    fontSize: '14px',
    overflow: "auto",
    scrollbarColor: 'grey'
  },
}))(Card);

export const AccountTitle = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
    margin: '50px 0 20px 0'
  },
}))(Typography);

export const AccountTitleBig = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
    margin: '50px 0 40px 0'
  },
}))(Typography);

export const AccountDescriptionBig = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 20,
  },
}))(Typography);

export const Title = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 400,
    fontSize: 24,
    color: '#333333',
    textAlign: 'center',
    paddingTop: 20,
    flex: 1,
    '& > p': {
      fontSize: 12
    }
  },
}))(Typography);

export const nameStyle = makeStyles(() => ({
  blueColor: {
    color: '#006FCC',
    textDecoration: 'none',
  }
}));

export const nameStyleCursor = makeStyles(() => ({
  blueColor: {
    color: '#006FCC',
    cursor: 'pointer',
    textDecoration: 'none',
  }
}));
