import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { IPANModal } from "../../types";


const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      background: "#F4F5F5"
    },
    title: {
      fontSize: "16px",
      fontWeight: 400
    },
    closeIcon: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    closeButton: {
      borderRadius: "4px",
      border: "1px solid #DADBDB",
      padding: "6px 16px",
      color: "#006FCC",
      background: "#FFFFFF",
      marginRight: theme.spacing(1),
    }
  });

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    background: "#F4F5F5"
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeIcon}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const PANModal = withStyles(styles)(({
  open = false,
  handleClose,
  children,
  title,
  classes,
  maxWidth = "md",
  dismissButton,
  submitButton,
  customActions,
  contentStyle
}: IPANModal & WithStyles<typeof styles>) => {
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent style={contentStyle} dividers>
        {children}
      </DialogContent>
      <DialogActions>
        { dismissButton && dismissButton }
        { typeof submitButton !== 'undefined' && submitButton}
        { customActions && customActions }
      </DialogActions>
    </Dialog>
  );
});
