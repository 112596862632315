import { Breadcrumbs, makeStyles, Typography } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IPANBreadcrumbs } from "../../types";

const useStyles = makeStyles((theme) => ({
  Breadcrumbs: {
    padding: "16px",
    background: "#FFFFFF"
  },
  links: {
    color: "#006FCC",
    textDecoration: "none",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: 'Lato !important'
  },
  lastOpt: {
    color: "#333333",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: 'Lato !important'
  },
  mainContainer: {
    background: "#F4F5F5",
    padding: "16px",
  },
  wrapper: {
    padding: "25px",
  },
}));

export const getPageTitle = (title?: string) => title

export const PANBreadcrumbs = ({mapping, queryParams=""}:IPANBreadcrumbs) => {
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const path:string =history.location?.pathname;
    const split: any = path?.split("/") ?? [];

    if (split && split.length > 0 ) {
      const res = split.reduce(
        (accumulator: any, currentValue: any, idx: number) => [
          ...accumulator,
          {
            label: currentValue,
            to: `${
              idx === 1 ? "" : accumulator[accumulator.length - 1].to
            }/${currentValue}`,
          },
        ]
      );
      setBreadcrumbs(res);
    }
  }, [history.location.pathname]);

  const getQueryParam = (idx:any, origin:any) => {
    // TODO when clicking on the breadcrumbs, some need queryparams,
    // analize where it should be added to last one or all of them,
    // or implement a way to add it accordingly

    /* if(queryParams && origin.length === idx + 2) {
      return queryParams;
    }
    return "";*/
    return queryParams;
  }

  return (
    <Breadcrumbs
      separator={<KeyboardArrowRight fontSize="small" />}
      aria-label="breadcrumb"
      className={classes.Breadcrumbs}
    >
      {breadcrumbs?.map((item, idx, origin) =>
        origin.length - 1 !== idx ? (
          <Link
            key={`${idx}-${item.label}`}
            className={classes.links}
            to={`${item.to}${getQueryParam(idx, origin)}`}
          >
            {getPageTitle(mapping[item.label])}
          </Link>
        ) : (
          <Typography key={`${idx}-${item.label}`} className={classes.lastOpt}>
            {getPageTitle(mapping[item.label])}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};
