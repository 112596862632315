import {useFormState} from "react-final-form";
import React, {useEffect, useState} from "react";
import isEmpty from "lodash/isEmpty";
import {Banner, BannerAppearance} from "@panwds/react-ui";
import {useTranslate} from "../../../../customHooks";

type StateProps = {
    text: string,
    appearance: BannerAppearance
}

const Index = () => {
    const formState = useFormState();
    const translate = useTranslate();
    const initialState: StateProps = {
        text: '',
        appearance: "standard"
    };
    const [state, setState] = useState<StateProps>(initialState);

    useEffect(() => {
        checkForFirewallStatus();
    }, []);

    useEffect(() => {

        if (!isEmpty(formState.dirtyFields)) {
            setState({
                text: translate(`resources.firewalls.fields.FirewallNotSavedBannerText`),
                appearance: 'warning'
            });
        } else if (isEmpty(formState.dirtyFields)) {
            checkForFirewallStatus();
        }

    }, [formState.dirtyFields]);

    const checkForFirewallStatus = () => {
        const values = formState.values;
        if (values?.Status && values?.Status?.FirewallStatus === 'UPDATING') {
            setState({
                text: translate(`resources.firewalls.fields.FirewallUpdatingBannerText`),
                appearance: 'warning'
            });
        } else if (values?.Status && values?.Status?.FirewallStatus === 'UPDATE_COMPLETE') {
            setState({
                text: translate(`resources.firewalls.fields.FirewallUpdateSuccessBannerText`),
                appearance: 'success'
            });
        } else if (values?.Status && values?.Status?.FirewallStatus === 'UPDATE_FAIL') {
            // Use the FormattedMessage of react-intl to dynamic text
            setState({
                text: translate(`resources.firewalls.fields.FirewallUpdateFailedBannerText`),
                appearance: 'error'
            });
        } else {
            setState(initialState);
        }
    }

    return (
        <>
            {isEmpty(state.text) ? <></> :
                <Banner
                    type={"inline"}
                    appearance={state.appearance}
                    showIcon={true}
                    onClose={() => setState(initialState)}
                >
                    {state.text}
                </Banner>
            }

        </>
    );
};

export default Index;
