import {useTranslate} from "../../../customHooks";
import {Button, Tooltip} from "@panwds/react-ui";
import {SaveButton} from "../../../components";
import {CircleCheckIcon, InfoIcon} from "@panwds/icons";
import _ from "lodash";
import { PANWDSForm, PANWDSInput } from "../../../components/PANWDSElements";
import {generateCloudManagerIntegrationsMap, getCSPIdFromTenantName, getRegion, getTenantId, getTsgId, getUrl, getCDLId, getCDLRegion} from "../utils";
import CircularLoader from "../../../components/CircularLoader/CircularLoader";
import {Row} from "../../../components/FormElements";
import {FormControl} from "@material-ui/core";
import {Field} from "react-final-form";
import {composeValidators, isRequired, maxLength} from "../../../utils/validate";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    infoIcon: {
        fontSize: "14px",
    },
    iconedParagraph: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        gap: '10px',
    },
    iconedParagraphTop: {
        display: 'flex',
        alignItems: 'top',
        gap: '5px',
    },
    pendingGrayText: {
        marginTop: "5px",
        color: '#707070 !important',
        fontSize: "12px",
    },
    grayText: {
        color: '#707070',
        fontSize: "14px",
    },
    checkIcon: {
        color: '#006FCC',
        fontSize: '18px',
        marginBottom: "-2px",
    },
    toolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: '16px',
        borderTop: "1px solid #DADBDB",
        marginTop: 16,
    },
    innerToolbar: {
        display: "flex",
        gap: theme.spacing(1),
    },
    rightToolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'flex-end',
        padding: '0 16px',

    },
    sidePanelForm: {
        padding: '20px 16px',
        '& .ra-input ': {
            padding: '7px 0',
        }
    },
    circle: {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
    },
    circleActive: {
        backgroundColor: '#33CCB8',
    },
    circleInActive: {
        backgroundColor: '#707070;',
    },
    circlePending: {
        backgroundColor: '#EF9700',
    },
    iconAndText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    notes: {
        fontSize: 12,
        color: '#333333',
    },
    pending: {
        color: "#B8B8B8",
        "& a, & .iconedParagraph, & div": {
            color: "#B8B8B8",
        },
    },
    enabledColor: {
        color: '#33CCB8',
    },
    disabledColor: {
        color: '#707070',
    },
    labelControl: {
        fontSize: "12px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "10px 0px",
        '& > div': {
            marginBottom: "8px",
        },
        '& > div:first-child': {
            fontWeight: '600',
        },
    }
}));

const  CloudManagerDetailPanel = ({ close, taskInProgress, cloudManagers, selectedPanorama, isPending, saveRecord }: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const integrationsAll = cloudManagers?.IntegrationsAll || [];
    const integrationMap = generateCloudManagerIntegrationsMap(integrationsAll).flat();
    const SaveToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        return (
            <div className={classes.rightToolbar}>
                <Button
                    size="md"
                    appearance="secondary"
                    disabled={taskInProgress}
                    onClick={() => close()}
                    dataMetrics="cloudngfw-integrations-panorama-cancel"
                >
                    {translate(`resources.integrations.cancel`)}
                </Button>
                <SaveButton
                    appearance="primary"
                    size="md"
                    label={translate('resources.integrations.save')}
                    submitOnEnter={true}
                    loading={taskInProgress}
                    disabled={taskInProgress}
                    dataMetrics="cloudngfw-integrations-panorama-save"
                    {...toolbarProps}
                />
            </div>
        );
    };

    const preSaveRecord = (values: any) => {
        saveRecord({
            LinkName: values.LinkName,
            LinkId: selectedPanorama?.LinkId,
            CloudManager: {
                Action: 'link',
                TsgId: selectedPanorama?.CloudManager?.TsgId,
                TenantName: selectedPanorama?.CloudManager?.TenantName,
                TenantId: selectedPanorama?.CloudManager?.TenantId,
                Region: getRegion(selectedPanorama?.CloudManager?.TenantId, integrationMap),
                CspSupportAccountId: getCSPIdFromTenantName(selectedPanorama?.CloudManager?.TenantId, integrationsAll),
                Url: getUrl(selectedPanorama?.CloudManager?.TenantId, integrationMap),
                CDLId: getCDLId(selectedPanorama?.CloudManager?.TenantId, integrationMap),
                CDLRegion: getCDLRegion(selectedPanorama?.CloudManager?.TenantId, integrationMap)
            },
        });
    };

    const loadInitialValues = () => {
        let initialValues: any = {
            LinkName: selectedPanorama.LinkName,
        };
        return initialValues;
    };

    const initialValues = loadInitialValues();

    const getStatusIcon = (status: string) => {
        if (status === "active") {
            return classes.circleActive;
        }
        if (status === "pending") {
            return classes.circlePending;
        }
        return classes.circleInActive;
    };

    const getStatusText = (status: string) => {
        if (status === "active") {
            return translate(`resources.integrations.values.active`);
        }
        if (status === "pending") {
            return translate(`resources.integrations.values.pending`);
        }
        return translate(`resources.integrations.values.inactive`);
    };

    const policyLinkID = selectedPanorama?.LinkId ?? "";
    const url = selectedPanorama?.CloudManager?.Url ?? "";
    const policyStatus = <>
        <div className={classes.iconAndText}>
            <div className={`${classes.circle} ${getStatusIcon(selectedPanorama?.Status?.toLowerCase())}`}></div>
            {getStatusText(selectedPanorama?.Status?.toLowerCase())}
        </div>
        {selectedPanorama?.Status?.toLowerCase() === "pending" && <div className={classes.pendingGrayText}>{translate(`resources.integrations.panel.pendingDescription`)}</div>}
    </>;
    const logAnalitycsEnabled = selectedPanorama?.LogAnalytics?.Enabled
    const logAnalitycs = <div className={classes.iconAndText}>
        <CircleCheckIcon size="sm" className={logAnalitycsEnabled ? classes.enabledColor : classes.disabledColor} />
        {translate(`resources.integrations.values.${logAnalitycsEnabled ? 'enabled' : 'disabled'}`)}
    </div>;
    const policyTenantName = selectedPanorama?.CloudManager?.TenantName;

    return (
        <>
            <PANWDSForm
                toolbar={<SaveToolbar />}
                onSubmit={preSaveRecord}
                initialValues={initialValues}
            >
                <CircularLoader loading={taskInProgress} />
                <div>
                    <Row>
                        <FormControl fullWidth>
                            <Field
                                name="LinkName"
                                // @ts-ignore
                                component={PANWDSInput}
                                title={translate(`resources.integrations.fields.LinkName`)}
                                required
                                inputProps={{
                                    "data-test-id": "integrations-link-name"
                                }}
                                validate={composeValidators(isRequired, maxLength(128))}
                                dataMetrics="integrations-link-name"
                                disabled={isPending}
                            />
                        </FormControl>
                        <div className={classes.labelControl}>
                            <div className={classes.iconedParagraphTop}>
                                {translate(`resources.integrations.fields.linkID`)}
                                <Tooltip
                                    label="Link ID will be automatically generated once you finish editing Policy Management">
                                    <InfoIcon size="sm" className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                            <div>{policyLinkID}</div>
                        </div>
                        <div className={classes.labelControl}>
                            <div className={classes.iconedParagraphTop}>
                                {translate(`resources.integrations.fields.tenantName`)}
                            </div>
                            <div>{policyTenantName}</div>
                        </div>
                        <div className={classes.labelControl}>
                            <div>{translate(`resources.integrations.fields.status`)}</div>
                            <div>{policyStatus}</div>
                        </div>

                        <div className={classes.labelControl}>
                            <div>{translate(`resources.integrations.fields.logForwarding`)}</div>
                            <div>{logAnalitycs}</div>
                        </div>

                        {url !== "" && <div className={classes.labelControl}>
                            <div>{translate(`resources.integrations.fields.url`)}</div>
                            <a target="_blank" href={url}>{url}</a>
                        </div>}
                    </Row>
                    <hr />
                </div>
            </PANWDSForm>
        </>
    )
};

export default CloudManagerDetailPanel;
