import PropTypes from 'prop-types';
import React, { FC, ReactElement, SyntheticEvent } from 'react';
import { LoadingButton }  from "@panwds/react-ui";
import { ButtonAppearances } from '../../types';
import { useTranslate } from "../../customHooks";


/**
 * Submit button for resource forms (Edit and Create).
 *
 * @typedef {Object} Props the props you can use (other props are injected by the <Toolbar>)
 * @prop {string} className
 * @prop {string} label Button label. Defaults to 'ra.action.save', translated.
 * @prop {boolean} disabled Disable the button.
 * @prop {string} variant Material-ui variant for the button. Defaults to 'contained'.
 * @prop {ReactElement} icon
 * @prop {string|boolean} redirect Override of the default redirect in case of success. Can be 'list', 'show', 'edit' (for create views), or false (to stay on the creation form).
 * @prop {function} onSave (deprecated)
 * @prop {function} onSuccess Callback to execute instead of the default success side effects. Receives the dataProvider response as argument.
 * @prop {function} onFailure Callback to execute instead of the default error side effects. Receives the dataProvider error response as argument.
 * @prop {function} transform Callback to execute before calling the dataProvider. Receives the data from the form, must return that transformed data. Can be asynchronous (and return a Promise)
 *
 * @param {Props} props
 *
 * @example // with custom redirection
 *
 * <SaveButton label="post.action.save_and_edit" redirect="edit" />
 *
 * @example // with no redirection
 *
 * <SaveButton label="post.action.save_and_add" redirect={false} />
 *
 * @example // with custom success side effect
 *
 * const MySaveButton = props => {
 *     const notify = useNotify();
 *     const redirect = useRedirect();
 *     const onSuccess = (response) => {
 *         notify(`Post "${response.data.title}" saved!`);
 *         redirect('/posts');
 *     };
 *     return <SaveButton {...props} onSuccess={onSuccess} />;
 * }
 */
const SaveButton: FC<SaveButtonProps> = props => {
    const translate = useTranslate();
    const {
        invalid,
        label = translate('ra.action.save'),
        disabled,
        saving,
        submitOnEnter,
        onClick,
        loading,
        appearance,
        size,
        dataMetrics
    } = props;

    const type = submitOnEnter ? 'submit' : 'button';
    return (
        <LoadingButton
            dataResult={{
                loading: !!(loading || saving),
                success: true
            }}
            size={size}
            appearance={appearance ? appearance : 'primary'}
            dataTestId={dataMetrics || ''}
            type={type}
            onClickCallback={onClick}
            disabled={disabled || loading || invalid || saving}
            dataMetrics={dataMetrics || ''}
        >
            {label}
        </LoadingButton>
    );
};

interface Props {
    classes?: object,
    className?: string,
    icon?: ReactElement,
    invalid?: boolean,
    label?: string,
    onClick?: () => void,
    disabled?: boolean,
    saving?: boolean,
    submitOnEnter?: boolean,
    appearance?: ButtonAppearances,
    size?: 'lg' | 'md' | 'sm',
    dataMetrics?: string,
    // May be injected by Toolbar - sanitized in Button
    basePath?: string,
    mutationMode?: string,
    handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>,
    resource?: string,
    undoable?: boolean,
    loading?: boolean,
    validating?: boolean,
}

export type SaveButtonProps = Props;

SaveButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    saving: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    icon: PropTypes.element,
    loading: PropTypes.bool,
};

export default SaveButton;
