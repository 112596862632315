export const RouteUri = {
    Home: "/",
    Dashboard: "/overview",
    Register: "/register",
    Welcome: "/welcome",
    SetPassword: "/setPassword",
    ConfirmPassword: "/confirmPassword",
    ForgotPassword: "/forgotPassword",
    Login: "/login",
    LoginCallback: "/login/ssoredirect",
    CspCallback: "/csp/callback",
    Logout: "/logout",
    GetHelp: "/gethelp",

    //Firewall
    NGFirewallList: "/ngfirewalls",
    NGFirewallCreate: "/ngfirewalls/create",
    NGFirewallEdit: "/ngfirewalls/:firewallname",
    NGFirewallSettings: "/ngfirewalls/:firewallname/settings",
    NGFirewallRules: "/ngfirewalls/:firewallname/rules",
    NGFirewallRulesUsage: "/ngfirewalls/:firewallname/rules/:rulename",
    NGFirewallLogSettings: "/ngfirewalls/:firewallname/logsettings",
    NGFirewallEndpoints: "/ngfirewalls/:firewallname/endpoints",

    //Users
    UserList: "/users",
    UserCreate: "/users/create",
    UserEdit: "/users/edit",

    //Overview
    Overview: "/overview",

    //Accounts
    AccountList: "/accounts",
    AccountCreate: "/accounts/create",
    AccountEdit: "/accounts/:id",

    //RuleStacks
    RuleStackList: "/rulestacks",
    RuleStackCreate: "/rulestacks/create",
    RuleStackEdit: "/rulestacks/:rulestackname",

    RuleList: "/rulestacks/:rulestackname/rules",
    RuleCreate: "/rulestacks/:rulestackname/rules/create",
    RuleEdit: "/rulestacks/:rulestackname/rules/:rulename",
    RuleEditUsage: "/rulestacks/:rulestackname/rules/:rulename/usage",

    ObjectList: "/rulestacks/:rulestackname/objects",

    CertificateList: "/rulestacks/:rulestackname/objects/certificates",
    CertificateCreate: "/rulestacks/:rulestackname/objects/certificates/create",
    CertificateEdit: "/rulestacks/:rulestackname/objects/certificates/:certificatename",

    PrefixList: "/rulestacks/:rulestackname/objects/prefixlist",
    PrefixListCreate: "/rulestacks/:rulestackname/objects/prefixlist/create",
    PrefixListEdit: "/rulestacks/:rulestackname/objects/prefixlist/:prefixlistname",

    Feed: "/rulestacks/:rulestackname/objects/feeds",
    FeedCreate: "/rulestacks/:rulestackname/objects/feeds/create",
    FeedEdit: "/rulestacks/:rulestackname/objects/feeds/:feedname",

    FQDNList: "/rulestacks/:rulestackname/objects/fqdn",
    FQDNCreate: "/rulestacks/:rulestackname/objects/fqdn/create",
    FQDNEdit: "/rulestacks/:rulestackname/objects/fqdn/:fqdnname",

    URLCategories: "/rulestacks/:rulestackname/objects/categories",
    URLCategoryCreate: "/rulestacks/:rulestackname/objects/categories/create",
    URLCategoryEdit: "/rulestacks/:rulestackname/objects/categories/:categoryname",

    SecurityServices: "/rulestacks/:rulestackname/securityservices",

    URLFilteringProfiles: "/rulestacks/:rulestackname/securityservices/urlfiltering",
    URLFilteringProfileCreate: "/rulestacks/:rulestackname/securityservices/urlfiltering/create",
    URLFilteringProfileEdit: "/rulestacks/:rulestackname/securityservices/urlfiltering/:name",

    FileBlockingProfiles: "/rulestacks/:rulestackname/securityservices/fileblocking",
    FileBlockingProfileCreate: "/rulestacks/:rulestackname/securityservices/fileblocking/create",
    FileBlockingProfileEdit: "/rulestacks/:rulestackname/securityservices/fileblocking/:name",
    SecurityServiceItem: "/rulestacks/:rulestackname/securityservices/:type",

    OutboundDecryptionCert: "/rulestacks/:rulestackname/securityservices/outbounddecryptioncert",

    AssociatedFirewalls: "/rulestacks/:rulestackname/associatedfirewalls",
    ConfigDiff: "/rulestacks/:rulestackname/configDiff",
    RuleStackSettings: "/rulestacks/:rulestackname/settings",

    // Tenant Information
    Tenant: "/tenant",

    Integrations: "/integrations",

    Subscription: "/subscription",

    //Usage explorer
    Usage: "/usage",

    //Inventory
    InventoryList: "/inventory",
    VPCsList: "/inventory/vpcs",
    TagsList: "/inventory/tags",
}
