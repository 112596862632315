import { Button, Sheet, SheetBody } from "@panwds/react-ui";
import { useTranslate } from "../../../../../customHooks";
import { makeStyles } from "@material-ui/core/styles";
import { SaveButton } from "../../../../../components";
import { isNameUnique, isValidNetworkAddress } from "../../../../../utils/validate";
import { FormProvider, useForm } from "react-hook-form";
import { Checkbox, FormLayout, Input, RadioGroup } from "@panwds/react-form";
import React from "react";

const useStyles = makeStyles(() => ({
    sheetBottom: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "1rem",
        borderTop: "1px solid #DADBDB",
        gap: '10px'
    }
}));

const CustomIncludeExcludeCreate = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles();

    const formMethods = useForm({defaultValues: {
        Name: '',
        Enabled: true, 
        DiscoveryInclude: 'Include',
        NetworkAddress: '',
    }});

    const { setValue } = formMethods
    
    const onSubmit = React.useCallback(
        async (data, e) => {
            props?.createNetwork(data);
        }, []
    );

    const radioOptions = [
        { value: 'Include', label: translate('resources.firewallsV2.userid.include') },
        { value: 'Exclude', label: translate('resources.firewallsV2.userid.exclude') }
    ];

    return (
        <>
        <Sheet
            onClose={() => props?.close()}
            title={translate(
                `resources.firewallsV2.userid.addCustomIncludeExcludeNetwork`
            )}
            dataMetrics="manage-userid-create-include-exclude-network"
            dataTestId="manage-userid-create-include-exclude-network-test"
            isOpen
            showMask={true}
            position="md"
        >
            <SheetBody>
                <div style={{ position: 'relative' }}>
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <FormLayout>
                                <Input
                                    name="Name"
                                    autoFocus={true}
                                    label={translate(
                                        `resources.firewallsV2.userid.customIncludeExcludeNetworkName`
                                    )}
                                    requiredAsterisk
                                    register={{ validate: (value) => isNameUnique(props.items, value, "") }}
                                />
                                <Checkbox
                                    name="Enabled"
                                    label={translate(
                                        `resources.firewallsV2.userid.customIncludeExcludeNetworkEnabledHeader`
                                    )}
                                    description={translate(
                                        `resources.firewallsV2.userid.customIncludeExcludeNetworkEnabledHelpText`
                                    )}
                                />
                                <RadioGroup
                                    name="DiscoveryInclude"
                                    label={translate('resources.firewallsV2.userid.discovery')}
                                    defaultValue="Include"
                                    horizontal={true}
                                    items={radioOptions.map(option => ({
                                        value: option.value,
                                        children: option.label
                                    }))}
                                    requiredAsterisk
                                />
                                <Input
                                    name="NetworkAddress"
                                    label={translate(
                                        `resources.firewallsV2.userid.customIncludeExcludeNetworkNetworkAddress`
                                    )}
                                    placeholder="10.0.0.0/24" 
                                    register={{ validate: (value) => isValidNetworkAddress(value) }}
                                />
                                <div className={classes.sheetBottom}>
                                    <Button appearance={"secondary"} onClick={() => props?.close()}>
                                        {translate("generic.cancel")}
                                    </Button>

                                    <SaveButton
                                        appearance="primary"
                                        size="md"
                                        label={translate("generic.save")}
                                        submitOnEnter={true}
                                    />
                                </div>
                            </FormLayout>
                        </form>
                    </FormProvider>
                </div>
            </SheetBody>
        </Sheet>
        </>
    )
};

export { CustomIncludeExcludeCreate };