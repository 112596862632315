import { Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from "@panwds/react-ui";
import React from "react";
import { makeStyles } from "@material-ui/core";
import {useTranslate} from '../../customHooks';

const useStyles = makeStyles((theme) => ({
    modalBodyStyling: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        color: "#333333",
        // display: 'flex',
        alignItems: 'center',
        display: 'flex',
        paddingTop: '30px',
        justifyContent: 'center'
      }
}));

export interface PANWDSModalProps {
    onClose: () => void;
    isOpen: boolean;
    size: 'sm' | 'md' | 'lg' | 'xl' | 'full';
    dataTestId?: string;
    dataMetrics: string;
    modalBody: React.ReactNode;
    modalFooter?: IFooterOptions;
    title: any;
    modalBodyStylingRequired?: boolean;
    style?: any;
}

export interface IFooterOptions {
    footerRequired?: boolean;
    cancelButtonRequired?: boolean;
    featureSpecificButton?: React.ReactNode;
}

export const PANWDSModal = (props: PANWDSModalProps) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { onClose, isOpen, size, dataTestId, dataMetrics, modalBody, modalBodyStylingRequired, modalFooter, title, style } = props;

    return (
    <Modal
        onClose={onClose}
        isOpen={isOpen}
        size={size}
        dataTestId={dataTestId}
        dataMetrics={dataMetrics}
        style={style}
    >
        <ModalHeader
            title={title}
            dataTestId="add-applications-modalHeader"
            enableClose={true}
        />
        <ModalBody addClassName="tw-overflow-auto" dataTestId="add-applications-modalBody">
            <div className={modalBodyStylingRequired ? classes.modalBodyStyling: ''}>{modalBody}</div>
        </ModalBody>
        {modalFooter?.footerRequired && <ModalFooter dataTestId="add-applications-modalFooter">
            {modalFooter?.cancelButtonRequired && <ModalCloseButton dataMetrics={`${dataMetrics}-cancel-button`} onClick={onClose}>{translate(`resources.rules.appsModalComponent.cancel`)}</ModalCloseButton>}
            {modalFooter?.featureSpecificButton}
        </ModalFooter>}
    </Modal>
    );
};
