import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API, buildError, prepareHeaders } from '../../api/apis';
import { ListRulesRequest, ListRulesResponse } from '../../interfaces/interfaces';


export const rulesService = createApi({
  reducerPath: 'rulesApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => prepareHeaders(headers),
  }),
  endpoints: (builder) => ({
    getRulesByRuleStack: builder.query({
      query: (request: ListRulesRequest) => API.FETCH_RULES(request),
      transformResponse: (response: ListRulesResponse) => {
        return {
          ruleStackName: response.Response.RuleStackName,
          ruleListName: response.Response.RuleListName,
          ruleEntries: response.Response.RuleEntryCandidate || [],
          nextToken: response.Response.NextToken,
        };
      },
      transformErrorResponse: (response) => {
        // todo: Add data-dog logging here
        return buildError(response, "");
      },
    }),
  }),
});

export const { useGetRulesByRuleStackQuery, useLazyGetRulesByRuleStackQuery } = rulesService;