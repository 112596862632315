import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useEffect, useRef, useState } from 'react';
import { IChipOptions } from '../../types';
import { isArrayOfString } from '../../utils/verification';

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "5px 16px",
    background: "#FFFFFF",
    borderBottom: '1px solid #DADBDB',
  },
  ButtonContainer: {
    background: "#FFFFFF",
    border: "1px solid #DADBDB",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  DropDownButton: {
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "normal",
    border: "1px solid #DADBDB",
    padding: "4px",
    color: "inherit",
    background: "#FFFFFF",
    marginRight: theme.spacing(1),
  },
  Label: {
    color: "#006FCC",
    fontSize: "12px",
    fontWeight: "normal",
    marginLeft: "4px"
  },
}));

export const PANDropDown = ({ options, label, onChange, addNew, addNewLabel, addFunc, disabled, dataTestId, maxTextLength = 150 }:
  { options: string[] | IChipOptions[]; label: any; onChange:(prop: any) => void; addNew?: boolean; addNewLabel?: string; addFunc?: Function; disabled?: boolean, dataTestId?: string, maxTextLength?: number }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [opts, setOpts] = useState<any[]>([]);

  const displayOptionText = (text: String) => text.length> maxTextLength? text.slice(0, maxTextLength) + '...': text;

  const isArrayStrings = isArrayOfString(options);

  useEffect(() => {

    if (isArrayStrings) {
      return setOpts(options)
    }

    const newOpts = options.map(({ Value }: any, idx: number ) => Value)

    setOpts(newOpts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    onChange(isArrayStrings ? options[index] : { ...options[index] as object });
    setSelectedIndex(index ?? 0);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <ButtonGroup ref={anchorRef} aria-label="split button" >
          <Button
            disabled={disabled}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            className={classes.DropDownButton}
            data-test-id={dataTestId}
          >
              {label}
            <KeyboardArrowDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current || null} role={undefined} transition disablePortal style={{
            zIndex: 100,
          }}>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center bottom',
              }}
            >
              <Paper elevation={2}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList style={{ padding: 0, borderRadius: 4, }} id="split-button-menu">

                    {
                      addNew && (
                        <MenuItem data-test-id={`${dataTestId}-add-new`} onClick={(event) => addFunc?.(event)}>{addNewLabel}</MenuItem>
                      )
                    }

                    {opts.map((option, index) => (
                      <MenuItem
                        key={index}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        data-test-id={`${dataTestId}-item-${index}`}
                        title={option}
                      >
                        {displayOptionText(option)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
