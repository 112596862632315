import {Button, FormControlLabel, Switch, makeStyles, CircularProgress} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip } from "@panwds/react-ui";
import {useCallback} from "react";
import {PANRadio} from "./Radio";
import {useTranslate} from '../../customHooks';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    fontSize: "14px",
    color: "#707070",
    paddingLeft: "4px"
  },
  radioContainer: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    textTransform: "capitalize",
    color: '#006FCC',
    marginTop: "8px",
    justifyContent: "start",
    '& span': {
      justifyContent: "start",
    }
  },
  loadingForSelectIcon: {
    width: '15px !important',
    height: '15px !important',
  },
}));

export const PANSwitch = ({ meta, input, onChange = () => {}, disabled, loading, ...props}: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  const handleOnChangeSwitch = useCallback((event) => {
    const eventValue = event.target.checked ? props?.values?.enable : props?.values?.disable
    input.onChange(eventValue);
    onChange({ title: props.title, key: input.name, value: eventValue});
  }, [props, input, onChange]);

  const handleOnChangeCheckbox = useCallback((event) => {
    input.onChange(event.target.value);
    onChange({ title: props.title, key: input.name, value: event.target.value});
  }, [props, input, onChange]);

  const checked = input.value !== props?.values?.disable;
  const value = checked ? props?.values?.enable : props?.values?.disable;
  const label = checked ? translate("switch.enabled") : translate("switch.disabled");

  return (
    <div>
      <div>
        <span>{props.title}</span>
        <Tooltip label={props.content}>
          <InfoIcon className={classes.infoIcon} />
        </Tooltip>
      </div>
      <FormControlLabel
        label={label}
        control={<Switch {...input} value={value} checked={checked} onChange={handleOnChangeSwitch} disabled={disabled} />}
      />
      { loading && <CircularProgress color="inherit" className={classes.loadingForSelectIcon} />}
      {props.withCustom && checked && (
        <div className={classes.radioContainer}>
          <PANRadio
            row
            title={translate("resources.ruleStacks.profile")}
            name={input.name}
            disabled={disabled}
            options={[
              { label: props?.values?.enable, value: props?.values?.enable },
              { label: props?.values?.custom, value: props?.values?.custom },
              { label: props?.values?.disable, value: props?.values?.disable },
            ]}
            onChange={handleOnChangeCheckbox}
            initValue={input.value}
          />
          <Button
            variant="outlined"
            size="small"
            disabled={input.value !== props?.values?.custom}
            startIcon={<EditIcon />}
            href={props.target}
            className={classes.button}
          >
            {translate("common.edit")}
          </Button>
        </div>
      )}
    </div>
  )
};

export default PANSwitch;
