import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate } from '../../../customHooks';

const useStyles = makeStyles({
    fillerStyles: {
        height: '100%',
        borderRadius: 'inherit',
        textAlign: 'right',
        marginBottom: '16px'
      },
    containerStyles: {
        height: '10px',
        width: '60%',
        backgroundColor: "#e0e0de",
        borderRadius: '50px',
      },
      labelStyles: {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
      },
});


const ProgressBar = (props: any) => {
    const classes = useStyles(props);
    const translate = useTranslate();

    return (
      <div className={classes.containerStyles}>
        <div className={classes.fillerStyles} style={{ backgroundColor:`${props.bgcolor}`, width: `${props.completed}%`, }}>
          <span  className={classes.labelStyles}></span>
        </div>
      </div>
    );
  };

  export default ProgressBar;
