import { makeStyles } from "@material-ui/core";
import { Field } from "react-final-form";
import cx from 'classnames';
import { usePermissions, useTranslate } from '../../../../customHooks';
import {Button as ButtonPanwds, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, ConfirmationDialog, Tag, Tooltip }  from "@panwds/react-ui";
import { filters as filterConfig } from "../../Utils/filters";
import Applications from '../../../../ApplicationsDetail-list.json';
import { PANFieldContainer } from "../../../../components/FormElements/styles";
import { PANWDSTable, PANWDSTags } from "../../../../components/PANWDSElements";
import _, { kebabCase } from "lodash";
import { useState, useEffect } from "react";
import { generateBackgroundColors } from "../../../../components/Filters/utilGenerateMethods";

const useStyles = makeStyles((theme) => ({
    error: {
        border: '1px solid #D13C3C',
        borderRadius: 4,
    },
    errorText: {
        color: "#D13C3C",
    },
    appColumnStyles: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100px'
      },
      rowTable: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      risk1: {
        backgroundColor: '#33CCB8'
      },
      risk2: {
        backgroundColor: '#ffd745'
      },
      risk3: {
        backgroundColor: '#ef9700'
      },
      risk4: {
        backgroundColor: '#d13c3c'
      },
      risk5: {
        backgroundColor: '#8f000e'
      },
      profilePictureContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '5px'
      },
      profileIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '20px',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
      },
      white: {
        color:'white',
        marginLeft: '6px'
      },
      black: {
        color: 'black',
        marginLeft: '6px'
      },
      appsContainerStyles: {
        display: 'flex',
      }
}));

export const AppsModalComponent = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const existerror = !!(touched && error);
    const { items, validate, fromEditRow , editRowSelectedData} = props;
    const [gridData, setGridData] = useState<never[]>([]);
    const [selectedData, setSelectedData] = useState<never[]>([]);
    const [dependsOnData, setDependsOnData] = useState<never[]>([]);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [addedApplications, setAddedApplications] = useState<any[]>([]);

    const openModal = () =>  setIsOpen(true);

    const closeModal = () =>  setIsOpen(false);

    useEffect(() => {
        const gridData1: any = Applications;
        if(fromEditRow && editRowSelectedData && editRowSelectedData?.length > 0) {
          let nonDupGridData: any = _.uniqBy(gridData1, 'AppName');
          let prevSelectedData: any = nonDupGridData?.filter((mainRowEntry: any) => editRowSelectedData?.includes(mainRowEntry?.AppName));
          let prevDependsOnData: any = [];
          prevSelectedData?.forEach((selectedRow: any) => {
            if(selectedRow?.DependsOn?.length > 0) {
              let inDependsOnData = nonDupGridData?.filter((mainRowEntry: any) => selectedRow?.DependsOn?.includes(mainRowEntry.AppName));
              prevDependsOnData.push(...inDependsOnData);
            }
          });
          let uniqDependsOnForDependsOn = prevDependsOnData?.filter((data: any) => !prevSelectedData.find((selRow: any) => selRow.AppName === data.AppName));
          uniqDependsOnForDependsOn = _.uniqBy(uniqDependsOnForDependsOn, 'AppName');
          let combinedListOfPrevState: any = [...uniqDependsOnForDependsOn, ...prevSelectedData];
          let uniqCombinedListOfPrevState: any = _.uniqBy(combinedListOfPrevState, 'AppName');
          let prevGridData: any = nonDupGridData?.filter((mainRowEntry: any) => !uniqCombinedListOfPrevState?.some((selectedRowEntry: any) => selectedRowEntry.AppName === mainRowEntry.AppName));
          let selectedAppsArray: any = prevSelectedData?.map((selectedApp: any) => selectedApp.AppName);
          setSelectedData(prevSelectedData);
          setDependsOnData(uniqDependsOnForDependsOn);
          setAddedApplications(selectedAppsArray);
          setGridData(prevGridData);
        } else {
          let nonDupGridData: any = _.uniqBy(gridData1, 'AppName');
          setGridData(nonDupGridData);
        }
      }, []);

    const getCategorizedAppscolumns = () => [
        {
          accessor: 'AppName',
          Header: translate(`resources.rules.appsModalComponent.applications`),
          columnSize: 2.5,
          render: ({ row }: any) => {
            return (
                <span
                  key={`rulestack-record-${kebabCase(row?.original?.AppName?.toLowerCase())}`}
                  className={classes.appColumnStyles}
                  data-test-id={`rulestack-record-${kebabCase(row?.original?.AppName?.toLowerCase())}`}
                >
                  {row?.original?.AppName}
              </span>
            )
          }
        },
        {
          accessor: 'Properties.Category',
          Header: translate(`resources.rules.appsModalComponent.category`),
          columnSize: 1.5,
          render: ({ row }: any) => {
            return (
                <span
                  key={`rulestack-record-${kebabCase(row?.original?.Properties?.Category)}`}
                  className={classes.appColumnStyles}
                  data-test-id={`rulestack-record-${kebabCase(row?.original?.Properties?.Category)}`}
                >
                {row?.original?.Properties?.Category}
              </span>
            )
          }
        },
        {
          accessor: 'Properties.Subcategory',
          // id: 'Type',
          Header: translate(`resources.rules.appsModalComponent.subCategory`),
          columnSize: 1.5,
          render: ({ row }: any) => {
            return (
              <span
                key={`rulestack-record-${kebabCase(row?.original?.Properties?.Subcategory)}`}
                className={classes.appColumnStyles}
                data-test-id={`rulestack-record-${kebabCase(row?.original?.Properties?.Subcategory)}`}
              >
                {row?.original?.Properties?.Subcategory}
              </span>
            )
          }
        },
        {
          accessor: 'Properties.Technology',
          Header: translate(`resources.rules.appsModalComponent.technology`),
          columnSize: 1.5,
          render: ({ row }: any) => {
            return (
              <span
                key={`rulestack-record-${kebabCase(row?.original?.Properties?.Technology)}`}
                className={classes.appColumnStyles}
                data-test-id={`rulestack-record-${kebabCase(row?.original?.Properties?.Technology)}`}
              >
                {row?.original?.Properties?.Technology}
              </span>
            )
          }
        },
        {
          accessor: 'Properties.Risk',
          Header: translate(`resources.rules.appsModalComponent.risk`),
          columnSize: 1,
          render: ({ row }: any) => {
            const { backgroundClass, colorClass } = generateBackgroundColors(row?.original?.Properties?.Risk, classes);
            return (
              <div key={row?.original?.Properties?.Risk} className={classes.profilePictureContainer}>
                <div key={row?.original?.Properties?.Risk} className={cx(classes.profileIconContainer, backgroundClass)}>
                  <span key={row?.original?.Properties?.Risk}  data-test-id={`rulestack-record-${kebabCase(row?.original?.Properties?.Risk)}`} className={colorClass}>
                    {row?.original?.Properties?.Risk}
                  </span>
                </div>
              </div>
            )
          }
        },
        {
          accessor: 'Tag',
          Header: translate(`resources.rules.appsModalComponent.tags`),
          columnSize: 4,
          render: ({ row }: any) => {
              return (
                row?.original?.hasOwnProperty('Tag') && row?.original?.Tag?.length > 0 && row?.original?.Tag?.map((tag: any) => (
                  <Tag key={tag} readOnly>
                    {tag}
                  </Tag>
              )))
            }
        },
    ];

      const getSelectedAppscolumns = () => [
        {
          accessor: 'AppName',
          Header: translate(`resources.rules.appsModalComponent.applications`),
          minWidth: 100,
          maxWidth: 150,
          render: ({ row }: any) => {
            return (
              <span
                key={`rulestack-record-${kebabCase(row?.original?.AppName?.toLowerCase())}`}
                className={classes.appColumnStyles}
                data-test-id={`rulestack-record-${kebabCase(row?.original?.AppName?.toLowerCase())}`}
              >
              {row?.original?.AppName}
            </span>
            )
          }
        },
      ]

      const getDependsOnAppscolumns = () => [
        {
          accessor: 'AppName',
          Header: translate(`resources.rules.appsModalComponent.applications`),
          minWidth: 100,
          maxWidth: 150,
          render: ({ row }: any) => {
            return (
              <span
                key={`rulestack-record-${kebabCase(row?.original?.AppName?.toLowerCase())}`}
                className={classes.appColumnStyles}
                data-test-id={`rulestack-record-${kebabCase(row?.original?.AppName?.toLowerCase())}`}
              >
               {row?.original?.AppName}
            </span>
            )
          }
        },
      ]

      const getListOfDependsInSelected = () => {
        let dependsOnArray: any= [];
        dependsOnArray = selectedData?.map((selectedRow: any) => selectedRow.DependsOn);
        let listOfDependsOnArray:any = [].concat.apply([], dependsOnArray);
        let uniqueListOfDependsOnArray = [...new Set(listOfDependsOnArray)];
        return listOfDependsOnArray;
      }

      const addToSelectedArrayFromCategorized = (selectedRows: any) => {
        let formDependsOnArrayAdd: any= [];
        // hold an array with just unique AppName for selected
        let selectedIds:any = [...new Set(selectedRows.map((data: any) => data?.AppName))];
        //get the entire array of objects of depends on for the selected list including duplicates
        selectedRows?.forEach((selectedRow: any) => {
          if(selectedRow?.DependsOn?.length > 0) {
            let inDependsOnData = gridData?.filter((mainRowEntry: any) => selectedRow?.DependsOn?.includes(mainRowEntry.AppName));
            formDependsOnArrayAdd.push(...inDependsOnData);
          }
        });
        //get the uniq array of objects of depends on for the selected list.
        let dependsOnArrayAdd = _.uniqBy(formDependsOnArrayAdd, 'AppName');
        // get array objects of dependsOn without Selected Apps in depends on.
        let uniqDependsOnForSelected = dependsOnArrayAdd?.filter((data: any) => !selectedRows.find((selRow: any) => selRow.AppName === data.AppName));
        // hold an array with just unique AppName for depends on
        let dependsOnIds = [...new Set(uniqDependsOnForSelected.map((data: any) => data?.AppName))];
        // Save the new uniq depends on objects which includes new and existing dependsOn data table if any.
        let combinedListOfDependsOnAddCateogory: any = [ ...uniqDependsOnForSelected, ...dependsOnData.filter((data: any) => !dependsOnIds.includes(data.AppName))];
        // Save the new uniq selected objects (combination of selected and depends on) to delete from Categorized.
        let combinedListOfSelectedAddCateogory: any = [ ...uniqDependsOnForSelected, ...selectedRows.filter((data: any) => !dependsOnIds.includes(data.AppName))];

        setDependsOnData(combinedListOfDependsOnAddCateogory);
        Array.prototype.unshift.apply(selectedData, selectedRows);
        let nonDupSelectedData = _.uniqBy(selectedData, 'AppName');
        setSelectedData(nonDupSelectedData);
        let removeSelectedAppsInCategorized = gridData?.filter((mainRowEntry: any) => !combinedListOfSelectedAddCateogory?.some((selectedRowEntry: any) => selectedRowEntry.AppName === mainRowEntry.AppName));
        setGridData(removeSelectedAppsInCategorized);
      };

      const addToSelectedArrayFromDependsOn = async (selectedRows: any) => {
        let formDependsOnArrayAddFromDepends: any= [];
        //get the entire array of objects of depends on for the selected list including duplicates
        selectedRows?.forEach((selectedRow: any) => {
          if(selectedRow?.DependsOn?.length > 0) {
            let inDependsOnData = gridData?.filter((mainRowEntry: any) => selectedRow?.DependsOn?.includes(mainRowEntry.AppName));
            formDependsOnArrayAddFromDepends.push(...inDependsOnData);
          }
        });
        //get the uniq array of objects of depends on for the selected list.
        let dependsOnArrayAddfromDepends = _.uniqBy(formDependsOnArrayAddFromDepends, 'AppName');
        //dependsOnArrayAddfromDepends = selectedRows?.forEach((selectedRow: any) => gridData?.filter((mainRowEntry: any) => selectedRow?.DependsOn?.includes(mainRowEntry.AppName)));
        Array.prototype.unshift.apply(selectedData, selectedRows);
        let nonDupSelectedData = _.uniqBy(selectedData, 'AppName');
        Array.prototype.unshift.apply(dependsOnData, dependsOnArrayAddfromDepends);
        let nonDupDependsOnData = _.uniqBy(dependsOnData, 'AppName');
        setSelectedData(nonDupSelectedData);
        let removeSelectedAppsInDependsOn = dependsOnData?.filter((mainRowEntry: any) => !nonDupSelectedData?.some((selectedRowEntry: any) => selectedRowEntry?.AppName === mainRowEntry?.AppName));
        setDependsOnData(removeSelectedAppsInDependsOn);
        let removeSelectedAppsInCategorizedDepends = gridData?.filter((mainRowEntry: any) => !nonDupDependsOnData?.some((selectedRowEntry: any) => selectedRowEntry?.AppName === mainRowEntry?.AppName));
        setGridData(removeSelectedAppsInCategorizedDepends);
      };

      const removeFromSelected = (selectedRows: any) => {
        let listOfDepends = getListOfDependsInSelected();
        // let isDependsOnApp = selectedRows?.filter((mainRowEntry: any) => listOfDepends?.includes(mainRowEntry.AppName))
        let formDependsOnArrayFromDel: any = [];
        let formDependsOnArrayFromUnselected: any = [];

        selectedRows?.forEach((selectedRow: any) => {
          if(selectedRow?.DependsOn?.length > 0) {
            let inDependsOnDataDel = dependsOnData?.filter((mainRowEntry: any) => selectedRow?.DependsOn?.includes(mainRowEntry?.AppName));
            formDependsOnArrayFromDel.push(...inDependsOnDataDel);
          }
        });
        let dependsOnArrayFromDel = _.uniqBy(formDependsOnArrayFromDel, 'AppName');
        let keepSelectedAppsInSelected = selectedData?.filter((mainRowEntry: any) => !selectedRows?.some((selectedRowEntry: any) => selectedRowEntry?.AppName === mainRowEntry?.AppName));
        keepSelectedAppsInSelected?.forEach((dataRow: any) => {
            if(dataRow?.DependsOn?.length > 0) {
              let independsOnArrayFromUnselected = dependsOnData?.filter((mainRowEntry: any) => dataRow?.DependsOn?.includes(mainRowEntry?.AppName));
              formDependsOnArrayFromUnselected.push(...independsOnArrayFromUnselected);
            }
          });
        let dependsOnArrayFromUnselected = _.uniqBy(formDependsOnArrayFromUnselected, 'AppName');
        let unrelatedDependsOnForUnselected = _.differenceBy(dependsOnArrayFromDel, dependsOnArrayFromUnselected, 'AppName');
        let combinedListOfDependsOnDel: any = [...unrelatedDependsOnForUnselected, ...selectedRows];
        let keepAppsInDependsOn = dependsOnData?.filter((mainRowEntry: any) => !unrelatedDependsOnForUnselected?.some((selectedRowEntry: any) => selectedRowEntry.AppName === mainRowEntry.AppName));
        Array.prototype.unshift.apply(gridData, combinedListOfDependsOnDel);
        setSelectedData(keepSelectedAppsInSelected);
        setDependsOnData(keepAppsInDependsOn)
        setGridData(gridData);
      }

      const closeModalWithReset = () => {
        setShowConfirmationDialog(false);
        getPrevData();
        closeModal();
      }

      const addToApplications  = () => {
        let selectedAppsArray: any = selectedData?.map((selectedApp: any) => selectedApp.AppName);
        inputProps.onChange(items);
        setAddedApplications(selectedAppsArray);
        closeModal();
      }

      const toolbarActionsCategorized: any[] = [];
      toolbarActionsCategorized.push({
        title: translate(`resources.rules.appsModalComponent.addToSelectedApplications`),
        actionsMap: [],
        handleButtonAction: addToSelectedArrayFromCategorized,
        type: 'button',
        disabledAddLogic: true
      });

      const toolbarActionsSelected: any[] = [];
      toolbarActionsSelected.push({
        title: translate(`resources.rules.appsModalComponent.remove`),
        actionsMap: [],
        handleButtonAction: removeFromSelected,
        type: 'button',
        disabledAddLogic: true
      });

      const toolbarActionsDependsOn: any[] = [];
      toolbarActionsDependsOn.push({
        title: translate(`resources.rules.appsModalComponent.addToSelectedApplications`),
        actionsMap: [],
        handleButtonAction: addToSelectedArrayFromDependsOn,
        type: 'button',
        disabledAddLogic: true
      });

      const showConfirmation = () => {
        const gridData2: any = Applications
        let nonDupGridData: any = _.uniqBy(gridData2, 'AppName');
        let prevSelectedData: any = [];
        if(props.fromEditRow) {
          prevSelectedData =  nonDupGridData?.filter((mainRowEntry: any) => editRowSelectedData?.includes(mainRowEntry?.AppName));
        } else {
          prevSelectedData =  nonDupGridData?.filter((mainRowEntry: any) => addedApplications?.includes(mainRowEntry?.AppName));
        }
        if(_.isEqual(prevSelectedData, selectedData)) {
          setShowConfirmationDialog(false);
          closeModal();
          return false;
        } else {
          setShowConfirmationDialog(true);
          return true;
        }
      };

      const showConfirmationForClose = () => {
        const gridData2: any = Applications
        let nonDupGridData: any = _.uniqBy(gridData2, 'AppName');
        let prevSelectedData: any = [];
        if(props.fromEditRow) {
          prevSelectedData =  nonDupGridData?.filter((mainRowEntry: any) => editRowSelectedData?.includes(mainRowEntry?.AppName));
        } else {
          prevSelectedData =  nonDupGridData?.filter((mainRowEntry: any) => addedApplications?.includes(mainRowEntry?.AppName));
        }
        if(_.isEqual(prevSelectedData, selectedData)) {
          return false;
        } else {
          return true;
        }
      };

      const getPrevData = () => {
        const gridData2: any = Applications
        let nonDupGridData: any = _.uniqBy(gridData2, 'AppName');
        let prevSelectedData: any = [];
        let prevDependsOnData: any = [];
        if(props.fromEditRow) {
          prevSelectedData =  nonDupGridData?.filter((mainRowEntry: any) => editRowSelectedData?.includes(mainRowEntry?.AppName));
        } else {
          prevSelectedData =  nonDupGridData?.filter((mainRowEntry: any) => addedApplications?.includes(mainRowEntry?.AppName));
        }
        prevSelectedData?.forEach((selectedRow: any) => {
          if(selectedRow?.DependsOn?.length > 0) {
            let inDependsOnData = nonDupGridData?.filter((mainRowEntry: any) => selectedRow?.DependsOn?.includes(mainRowEntry.AppName));
            prevDependsOnData.push(...inDependsOnData);
          }
        });
        let uniqDependsOnForDependsOn = prevDependsOnData?.filter((data: any) => !prevSelectedData.find((selRow: any) => selRow.AppName === data.AppName));
        uniqDependsOnForDependsOn = _.uniqBy(uniqDependsOnForDependsOn, 'AppName');
        let combinedListOfPrevState: any = [...uniqDependsOnForDependsOn, ...prevSelectedData];
        let uniqCombinedListOfPrevState: any = _.uniqBy(combinedListOfPrevState, 'AppName');
        let prevGridData: any = nonDupGridData?.filter((mainRowEntry: any) => !uniqCombinedListOfPrevState?.some((selectedRowEntry: any) => selectedRowEntry.AppName === mainRowEntry.AppName));
        let selectedAppsArray: any = prevSelectedData?.map((selectedApp: any) => selectedApp.AppName);
        setSelectedData(prevSelectedData);
        setDependsOnData(uniqDependsOnForDependsOn);
        setAddedApplications(selectedAppsArray);
        setGridData(prevGridData);
        closeModal();
      }

      const onCancel = () => {
        setShowConfirmationDialog(false);
      };

    return <PANFieldContainer>
        <>
          <Field
              name={fromEditRow ? "RuleEntryCandidate.Applications.Apps" : "RuleEntry.Applications.Apps"}
              row
              // @ts-ignore
              component={PANWDSTags}
              items={addedApplications}
              title={translate(`resources.rules.fields.Applications`)}
              required
          />
           <div className={editRowSelectedData?.length > 0 || addedApplications.length > 0 ? classes.appsContainerStyles : ''}>
            <ButtonPanwds onClick={openModal} disabled={props.disabled}>
               { addedApplications.length <= 0 ? translate(`resources.rules.fields.appid.add`) : translate(`resources.rules.fields.appid.edit`)}
            </ButtonPanwds>
          </div>
        <Modal
            onClose={closeModalWithReset}
            isOpen={isOpen}
            size="xl"
            dataTestId="add-applications-modal"
            confirmClose={showConfirmationForClose()} //true if we want confimation dialogue
            confirmationDialog={
                showConfirmationDialog && (
                <ConfirmationDialog
                    actions={
                    <>
                        <ButtonPanwds onClick={onCancel}>{translate(`resources.rules.appsModalComponent.cancel`)}</ButtonPanwds>
                        <ButtonPanwds onClick={closeModalWithReset} focusOnMount appearance="secondary-destructive">
                        {translate(`resources.rules.appsModalComponent.discard`)}
                        </ButtonPanwds>
                    </>
                    }
                    prompt={translate(`resources.rules.appsModalComponent.promptMsgTitle`)}
                    title={translate(`resources.rules.appsModalComponent.discardChanges`)}
                />
                )
            }
            >
            <ModalHeader
                title={translate(`resources.rules.appsModalComponent.addApps`)}
                dataTestId="add-applications-modalHeader"
                enableClose={true}
            />
            <ModalBody dataTestId="add-applications-modalBody">
                <PANWDSTable
                    permissions={permissions}
                    columns={getCategorizedAppscolumns()}
                    gridData={gridData}
                    title={`${translate('resources.rules.appsModalComponent.categorizedApps')} (${gridData?.length})`}
                    idProperty={"CategorizedApps"}
                    searchFilterRequired={true}
                    showToolbar={true}
                    dropDownActionsArray={toolbarActionsCategorized}
                    showTileTitle
                    emptySubtitle={translate(`resources.rules.appsModalComponent.noCategorizedApps`)}
                    //loading={loading}
                    isBackgroundFetching={false}
                    singleSelect={false}
                    multiSelect={true}
                    dataTestId="applications-categorized-table"
                    tableInnerContainerClassName="applications-categorized-table"
                    infiniteScroll={false}
                    enablePagination={true}
                    rowSize="standard"
                    tableMinHeight='0'
                    noPaginatedScrollTableHeight={true}
                    filterBar = {{
                      filterBarRequired: true,
                      onlySearchFilterBarRequired: false,
                      filterConfig: filterConfig
                    }}
                    tableContainerStyle = {{
                        display: "flex",
                        flexFlow: "column",
                        padding: '16px 16px 4px 16px',
                        boxSizing: 'border-box',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        //minHeight: '0'
                }}
                />
                <div className={classes.rowTable}>
                    <PANWDSTable
                        permissions={permissions}
                        columns={getSelectedAppscolumns()}
                        gridData={selectedData}
                        title={`${translate('resources.rules.appsModalComponent.selectedApps')} (${selectedData?.length})`}
                        idProperty={"SelectedApps"}
                        showToolbar={true}
                        dropDownActionsArray={toolbarActionsSelected}
                        showTileTitle
                        emptySubtitle={translate(`resources.rules.appsModalComponent.noSelectedApps`)}
                        //loading={loading}
                        isBackgroundFetching={false}
                        singleSelect={false}
                        multiSelect={true}
                        dataTestId="applications-selected-table"
                        tableInnerContainerClassName="applications-selected-table"
                        noPaginatedScrollTableHeight={true}
                        infiniteScroll={false}
                        enablePagination={true}
                        rowSize="standard"
                        tableMinHeight='0'
                        tableContainerStyle = {{
                        display: "flex",
                        flexFlow: "column",
                        padding: '16px 8px 16px 16px',
                        boxSizing: 'border-box',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                    }}
                    />
                    <PANWDSTable
                        permissions={permissions}
                        columns={getDependsOnAppscolumns()}
                        gridData={dependsOnData}
                        title={`${translate('resources.rules.appsModalComponent.dependsOnApplications')} (${dependsOnData?.length})`}
                        idProperty={"DependsOnApps"}
                        showToolbar={true}
                        dropDownActionsArray={toolbarActionsDependsOn}
                        showTileTitle
                        emptySubtitle={translate(`resources.rules.appsModalComponent.noDependApps`)}
                        //loading={loading}
                        isBackgroundFetching={false}
                        singleSelect={false}
                        multiSelect={true}
                        tableInnerContainerClassName="applications-dependsOn-table"
                        dataTestId="applications-dependsOn-table"
                        infiniteScroll={false}
                        enablePagination={true}
                        noPaginatedScrollTableHeight={true}
                        rowSize="standard"
                        tableMinHeight='0'
                        tableContainerStyle = {{
                        display: "flex",
                        flexFlow: "column",
                        padding: '16px 16px 16px 8px',
                        boxSizing: 'border-box',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                    }}
                    />
                </div>
            </ModalBody>
            <ModalFooter dataTestId="add-applications-modalFooter">
                <ModalCloseButton onClick={showConfirmation}>{translate(`resources.rules.appsModalComponent.cancel`)}</ModalCloseButton>
                <ModalCloseButton disabled={selectedData?.length === 0} onClick={addToApplications}>{translate(`resources.rules.fields.appid.add`)}</ModalCloseButton>
            </ModalFooter>
        </Modal>
        </>
    </PANFieldContainer>;
};
