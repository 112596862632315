import { Banner, Button } from "@panwds/react-ui";
import { NotificationEntry } from "../api/FwaasDataModels";
import {ExternalLinkIcon} from "@panwds/icons";

const openNotificationLink = (notificationEntry: NotificationEntry) => {
    if (notificationEntry.Link?.Target) {
        window.open(notificationEntry.Link.Target, "_blank");
    }
}

const closeNotificationLink = (notificationEntry: NotificationEntry) => {
    if (notificationEntry.Msg) {
        return;
    }
}
const callSWUpgradeRedirectLink = (notificationEntry: NotificationEntry) => {
    if (notificationEntry.Link?.Text) {
        openNotificationLink(notificationEntry);
    }
    return;
}

export const renderNotificationBanner = (notificationEntry: NotificationEntry) => {
    return (notificationEntry &&  notificationEntry.Msg && notificationEntry.Link?.Target) &&
         (<>
         <div className="twds-space-y-4">
            <Banner
                type="inline"
                appearance="standard"
                showIcon
                //onClose={(event:any) => {closeNotificationLink(notificationEntry);}}
                dataMetrics={"cloudngfw-swupgrade-notification-redirect"}
                onClick={(event:any) => {callSWUpgradeRedirectLink(notificationEntry) }}
                actions={
                    <>
                        <Button appearance="primary" size="sm" dataMetrics={"cloudngfw-swupgrade-notification-redirect"} onClick={() => callSWUpgradeRedirectLink(notificationEntry)}>
                            {notificationEntry.Link?.Text}
                            {<ExternalLinkIcon size="xs" className="tw-pl-2" />}
                        </Button>
                    </>
                }
            >
                {notificationEntry.Msg}
            </Banner>
        </div>
        </>);
}

export const isNotificationExpired = (elapsedTime: any)  => {
    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    if (secondsSinceEpoch >= elapsedTime) {
        return true;
    }
    return false;
}

export const getNotificationList = (firewalls:any) => {
    var notificationList : NotificationEntry[] = [];
    let messagesSet = new Set();

    firewalls.forEach((firewall:any) => {
        if (firewall.Firewall.Notifications && firewall.Firewall.Notifications.length > 0) {
            firewall.Firewall.Notifications.forEach((notification: NotificationEntry) => {
                if (!messagesSet.has(notification.Msg)) {
                    messagesSet.add(notification.Msg);
                    notificationList.push(notification);
                }
            });
        }
    });
    return notificationList;
}
