import { useTranslate } from '../../customHooks';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Sheet, Tooltip } from "@panwds/react-ui";
import { SaveButton } from '../../components';
import { CircleCheckIcon, InfoIcon, LinkSimpleIcon, LinkSimpleSlashIcon } from '@panwds/icons';
import { PANWDSForm, PANWDSSelectWithSearch, PANWDSRadioField, PANWDSInput, PANWDSCheckboxField } from '../../components/PANWDSElements';
import { FormControl } from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { generateIntegrationsMap, generateSNtoCSPIDMap, getCSPIdFromSN, getValuesForSecondarySN } from './utils';
import { composeValidators, isRequired, maxLength } from '../../utils/validate';
import _ from 'lodash';
import { Row } from '../../components/FormElements';
import CircularLoader from '../../components/CircularLoader/CircularLoader';
import { useState } from 'react';
import { NotificationModal } from './Modals';

const useStyles = makeStyles((theme) => ({
    infoIcon: {
        fontSize: "14px",
    },
    iconedParagraph: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        gap: '10px',
    },
    iconedParagraphTop: {
        display: 'flex',
        alignItems: 'top',
        gap: '5px',
    },
    pendingGrayText: {
        marginTop: "5px",
        color: '#707070 !important',
        fontSize: "12px",
    },
    grayText: {
        color: '#707070',
        fontSize: "14px",
    },
    checkIcon: {
        color: '#006FCC',
        fontSize: '18px',
        marginBottom: "-2px",
    },
    toolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: '16px',
        borderTop: "1px solid #DADBDB",
        marginTop: 16,
    },
    innerToolbar: {
        display: "flex",
        gap: theme.spacing(1),
    },
    rightToolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'flex-end',
        padding: '0 16px',

    },
    sidePanelForm: {
        padding: '20px 16px',
        '& .ra-input ': {
            padding: '7px 0',
        }
    },
    circle: {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
    },
    circleActive: {
        backgroundColor: '#33CCB8',
    },
    circleInActive: {
        backgroundColor: '#707070;',
    },
    circlePending: {
        backgroundColor: '#EF9700',
    },
    iconAndText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    notes: {
        fontSize: 12,
        color: '#333333',
    },
    pending: {
        color: "#B8B8B8",
        "& a, & .iconedParagraph, & div": {
            color: "#B8B8B8",
        },
    },
    enabledColor: {
        color: '#33CCB8',
    },
    disabledColor: {
        color: '#707070',
    },
    labelControl: {
        fontSize: "12px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "10px 0px",
        '& > div': {
            marginBottom: "8px",
        },
        '& > div:first-child': {
            fontWeight: '600',
        },
    },
    securityServiceButton: {
        padding: 0,
        width: "fit-content",
        '&:hover': {
            background: 'none'
        }
    }
}));

export const PanoramaDetailPanel = ({ close, taskInProgress, panoramas, selectedPanorama, regions, saveRecord, onClickSecurityService }: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [confirmLink, setConfirmLink] = useState({ show: false, callBack: () => { } });

    const integrationsAll = panoramas?.IntegrationsAll || [];
    const SNcspIdMap: any = generateSNtoCSPIDMap(integrationsAll);
    const integrationMap = generateIntegrationsMap(integrationsAll);

    // if unlink process is pending
    const isPending = (!selectedPanorama?.Panorama && panoramas === undefined) || selectedPanorama?.Status === "Pending";
    const isActive = selectedPanorama?.Status === "Active";

    const SaveToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        return (
            <div className={classes.rightToolbar}>
                <Button
                    size="md"
                    appearance="secondary"
                    disabled={taskInProgress}
                    onClick={() => close()}
                    dataMetrics="cloudngfw-integrations-panorama-cancel"
                >
                    {translate(`resources.integrations.cancel`)}
                </Button>
                <SaveButton
                    appearance="primary"
                    size="md"
                    label={translate('resources.integrations.save')}
                    submitOnEnter={true}
                    loading={taskInProgress}
                    disabled={taskInProgress || isPending}
                    dataMetrics="cloudngfw-integrations-panorama-save"
                    {...toolbarProps}
                />
            </div>
        );
    };

    const preSaveRecord = (values: any) => {
        const SecondarySN = values.SecondarySN && integrationMap[values.SecondarySN].Panorama.SerialNumber;
        if (values.EnableLogAnalytics) {
            setConfirmLink({
                show: true, callBack: () => {
                    saveRecord({
                        ...selectedPanorama,
                        LinkId: selectedPanorama.LinkId,
                        LinkName: values.LinkName,
                        ...(SecondarySN ? { Panorama: { ...selectedPanorama.Panorama, SecondarySN } } : {}),
                        LogAnalytics: {
                            Enabled: true,
                            CdlStatus: "Inactive"
                        }
                    });
                }
            })
        } else {
            saveRecord({
                LinkId: selectedPanorama.LinkId,
                LinkName: values.LinkName,
                ...(SecondarySN ? { Panorama: { SecondarySN } } : {}),
            });
        }
    };

    const loadInitialValues = () => {
        let initialValues: any = {
            LinkName: selectedPanorama.LinkName,
            SecondarySN: ""
        };
        return initialValues;
    };

    const initialValues = loadInitialValues();

    const buildDeviceName = (serial: string, toString = false): string | JSX.Element => {
        let deviceName: string | JSX.Element = '';
        if (panoramas?.IntegrationsAll?.length) {
            panoramas.IntegrationsAll.forEach((panorama: any) => {
                panorama.Integrations.forEach((integration: any) => {
                    if (integration?.Panorama?.SerialNumber === serial) {
                        if (toString) {
                            // used as key for integrationsMap, need to match key format
                            deviceName = `${serial}${integration.Panorama.DeviceName ? ` [${integration.Panorama.DeviceName}]` : ''} - CSPID: ${panorama.CspId}`;
                        } else {
                            deviceName = <>{serial} {integration.Panorama.DeviceName ?
                                <i>({integration.Panorama.DeviceName})</i> : null}</>;
                        }
                    }
                });
            });
        }
        if(!deviceName && !toString){
            deviceName = `${serial}${selectedPanorama.Panorama.DeviceName ? ` [${selectedPanorama.Panorama.DeviceName}]` : ''} - CSPID: ${selectedPanorama.Panorama.CspSupportAccountId}`;
        }
        return deviceName;
    };

    const getStatusIcon = (status: string) => {
        if (status === "active") {
            return classes.circleActive;
        }
        if (status === "pending") {
            return classes.circlePending;
        }
        return classes.circleInActive;
    };

    const getStatusText = (status: string) => {
        if (status === "active") {
            return translate(`resources.integrations.values.active`);
        }
        if (status === "pending") {
            return translate(`resources.integrations.values.pending`);
        }
        return translate(`resources.integrations.values.inactive`);
    };

    // Policy Management
    const policyManagement = <div className={classes.iconAndText}>
        <CircleCheckIcon size="sm" className={classes.enabledColor} />
        {translate(`resources.integrations.values.enabled`)}
    </div>;
    const primarySN = selectedPanorama.Panorama.PrimarySN;
    const secondarySN = selectedPanorama.Panorama.SecondarySN;
    const policyLinkID = selectedPanorama.LinkId ?? "";
    const policyStatus = <>
        <div className={classes.iconAndText}>
            <div className={`${classes.circle} ${getStatusIcon(selectedPanorama?.Status?.toLowerCase())}`}></div>
            {getStatusText(selectedPanorama?.Status?.toLowerCase())}
        </div>
        {selectedPanorama?.Status?.toLowerCase() === "pending" && <div className={classes.pendingGrayText}>{translate(`resources.integrations.panel.pendingDescription`)}</div>}
    </>;
    let panoramaRegion = _.get(regions, selectedPanorama?.Panorama?.Region, selectedPanorama?.Panorama?.Region || "");

    // Log Fordwarding and Analytics
    const logAnalitycsEnabled = !!selectedPanorama?.LogAnalytics?.Enabled;
    const logAnalitycs = <div className={classes.iconAndText}>
        <CircleCheckIcon size="sm" className={logAnalitycsEnabled ? classes.enabledColor : classes.disabledColor} />
        {translate(`resources.integrations.values.${logAnalitycsEnabled ? 'enabled' : 'disabled'}`)}
    </div>;

    const cdlId = selectedPanorama?.LogAnalytics?.CortexDataLake?.Id ? <a target="_blank" href="https://login.paloaltonetworks.com/">{selectedPanorama?.LogAnalytics?.CortexDataLake?.Id}</a> : "";

    const logRegion = _.get(regions, selectedPanorama?.LogAnalytics?.CortexDataLake?.Region, selectedPanorama?.LogAnalytics?.CortexDataLake?.Region || "");
    if (!panoramaRegion) {
        panoramaRegion = logRegion;
    }
    // if there is no region under LogAnalytics, we asume it is noncdl
    const isNonCDL = selectedPanorama?.Panorama?.Region !== undefined;

    return (
        <Sheet
            onClose={() => close()}
            title={translate(`resources.integrations.panel.panoramaDetail`)}
            isOpen
            position="md"
            showMask={true}
        >
            <PANWDSForm
                toolbar={<SaveToolbar />}
                onSubmit={preSaveRecord}
                initialValues={initialValues}
            >
                <CircularLoader loading={taskInProgress} />
                <div className={`${classes.sidePanelForm} ${isPending ? classes.pending : ''}`} >
                    <Row>
                        <FormControl fullWidth>
                            <Field
                                name="LinkName"
                                // @ts-ignore
                                component={PANWDSInput}
                                title={translate(`resources.integrations.fields.LinkName`)}
                                required
                                inputProps={{
                                    "data-test-id": "integrations-link-name"
                                }}
                                validate={composeValidators(isRequired, maxLength(128))}
                                dataMetrics="integrations-link-name"
                                disabled={isPending}
                            />
                        </FormControl>
                        <div className={classes.labelControl}>
                            <div>{translate(`resources.integrations.fields.primaryPanoramaSN`)}</div>
                            <div>{buildDeviceName(primarySN)}</div>
                        </div>
                        {selectedPanorama.Panorama?.SecondarySN
                            ? <div className={classes.labelControl}>
                                <div>{translate(`resources.integrations.fields.haPeerSerialNumber`)}</div>
                                <div>{buildDeviceName(secondarySN)}</div>
                            </div>
                            : <FormControl fullWidth>
                                <Field
                                    title={translate(`resources.integrations.fields.haPeerSerialNumber`)}
                                    name="SecondarySN"
                                    row
                                    // @ts-ignore
                                    component={PANWDSSelectWithSearch}
                                    items={getValuesForSecondarySN(buildDeviceName(primarySN, true) as string, integrationMap, SNcspIdMap)}
                                    disabled={isPending}
                                />
                            </FormControl>}
                        <div className={classes.labelControl}>
                            <div className={classes.iconedParagraphTop}>
                                {translate(`resources.integrations.fields.linkID`)}
                                <Tooltip
                                    label="Link ID will be automatically generated once you finish editing Policy Management">
                                    <InfoIcon size="sm" className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                            <div>{policyLinkID}</div>
                        </div>
                        <div className={classes.labelControl}>
                            <div>{translate(`resources.integrations.fields.status`)}</div>
                            <div>{policyStatus}</div>
                        </div>
                        {panoramaRegion &&
                            <div className={classes.labelControl}>
                                <div>{translate(`resources.integrations.fields.region`)}</div>
                                <div>{panoramaRegion}</div>
                            </div>}
                        <div className={classes.labelControl}>
                            <div>{translate(`resources.integrations.fields.logForwarding`)}</div>
                            <div>{logAnalitycs}</div>
                        </div>
                        <div className={classes.labelControl}>
                            <div>{translate(`resources.integrations.fields.securityServices`)}</div>
                            <Button addClassName={classes.securityServiceButton}
                                    appearance="clear"
                                    onClick={onClickSecurityService}
                            >
                                {translate(`resources.integrations.fields.checkDetails`)}
                            </Button>
                        </div>
                        {!isNonCDL && (<>
                            {isActive
                                ? (<div className={classes.labelControl}>
                                    <div>{translate(`resources.integrations.fields.CDLID`)}</div>
                                    <div>{cdlId}</div>
                                </div>)
                                : <FormControl fullWidth>
                                    <Field
                                        name="EnableLogAnalytics"
                                        // @ts-ignore
                                        component={PANWDSCheckboxField}
                                        defaultValue={false}
                                        label={translate(`resources.integrations.fields.linkToCDL`)}
                                        muted={translate(`resources.integrations.fields.linkToCDLSub`)}
                                        dataMetrics="integrations-link-to-cdl-checkbox"
                                        type="checkbox"
                                        disabled={isPending}
                                    />
                                </FormControl>}
                            </>)
                        }
                    </Row>
                    <hr />
                </div>
            </PANWDSForm>
            {confirmLink.show &&
                <NotificationModal
                    onConfirm={confirmLink.callBack}
                    close={() => setConfirmLink({ show: false, callBack: () => { } })
                    }
                    description={translate(`resources.integrations.modal.descriptionLinked`)}
                    taskInProgress={taskInProgress}
                />}
        </Sheet >
    )
};

export const AddEditPolictyPanel = ({ close, cancel, panoramaRecord, record, saveRecord, taskInProgress, regions, unlink }: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const isEditMode = !!record?.Panorama;
    const linkCompleted = !!record?.Panorama?.SecondarySN;
    const integrationsAll = panoramaRecord?.IntegrationsAll || [];

    const SNcspIdMap: any = generateSNtoCSPIDMap(integrationsAll);
    const integrationMap = generateIntegrationsMap(integrationsAll);

    const SaveToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        return (
            <div className={(panoramaRecord) ? classes.toolbar : ""}>
                {isEditMode
                    ? <Button
                        size="md"
                        appearance="secondary-destructive"
                        disabled={taskInProgress}
                        onClick={unlink}
                        dataMetrics="cloudngfw-integrations-panorama-unlink"
                    >
                        {translate(`resources.integrations.unlink`)}
                    </Button>
                    : <div></div>}
                {panoramaRecord &&
                    <div className={classes.innerToolbar}>
                        <Button
                            size="md"
                            appearance="secondary"
                            disabled={taskInProgress}
                            onClick={() => cancel()}
                            dataMetrics="cloudngfw-integrations-panorama-cancel"
                        >
                            {translate(`resources.integrations.cancel`)}
                        </Button>
                        <SaveButton
                            appearance="primary"
                            size="md"
                            label={translate('resources.integrations.continue')}
                            submitOnEnter={true}
                            loading={taskInProgress}
                            disabled={taskInProgress || linkCompleted}
                            dataMetrics="cloudngfw-integrations-panorama-continue"
                            {...toolbarProps}
                        />
                    </div>}
            </div>
        );
    };

    const findByValue = (obj: any, val: any) => Object.keys(obj).filter((k: string) => obj[k] === val)[0];

    const preSaveRecord = (values: any) => {
        const isCDLEnabled = !!integrationMap[values.PrimarySN].CDL.Enabled;
        const PrimarySN = integrationMap[values.PrimarySN].Panorama.SerialNumber;
        const SecondarySN = values.SecondarySN && integrationMap[values.SecondarySN].Panorama.SerialNumber;
        if (isCDLEnabled) { // OLD FLOW PRESERVED
            saveRecord({
                LinkName: values.LinkName,
                Panorama: {
                    PrimarySN,
                    ...(SecondarySN ? { SecondarySN } : {}),
                    CspSupportAccountId: getCSPIdFromSN(PrimarySN, integrationsAll),
                },
                LogAnalytics: {
                    Enabled: true
                }
            });
        } else { // NON CDL FLOW
            if (values.configure === 'cancel') {
                close()
            } else {
                saveRecord({
                    LinkName: values.LinkName,
                    Panorama: {
                        PrimarySN,
                        ...(SecondarySN ? { SecondarySN } : {}),
                        CspSupportAccountId: getCSPIdFromSN(PrimarySN, integrationsAll),
                        Region: values.region,
                    },
                    LogAnalytics: {
                        Enabled: false,
                    }
                });
            }
        }
    };

    const findKey = (sn: string) => {
        return _.findKey(integrationMap, (o) => o.Panorama.SerialNumber === sn);
    }

    let initialValues: any = undefined;
    try {
        initialValues = (
            record?.Panorama
                ? {
                    PrimarySN: findKey(record.Panorama.PrimarySN),
                    SecondarySN: findKey(record.Panorama.SecondarySN),
                    configure: "agree",
                    region: [regions[record.Panorama.Region || record.LogAnalytics.CortexDataLake.Region]]
                }
                : undefined
        );
    } catch (e) { }

    //@ts-ignore
    const WhenFieldChanges = ({ field, becomes, set, to }) => (
        <Field name={set} subscription={{}}>
            {(
                { input: { onChange } }
            ) => (
                <FormSpy subscription={{}}>
                    {({ form }) => (
                        <OnChange name={field}>
                            {() => {
                                if (becomes) {
                                    onChange(to);
                                }
                            }}
                        </OnChange>
                    )}
                </FormSpy>
            )}
        </Field>
    );

    const isPrimarySNCDLEnabled = (formData: any) => {
        let isEnabled: any = undefined;
        if (formData.PrimarySN) {
            isEnabled = !!integrationMap[formData.PrimarySN]?.CDL?.Enabled;
        }
        return isEnabled;
    };

    const allIntegrationsCount = integrationsAll.reduce((a: any, c: any) => a + c.Integrations.length, 0);

    const primarySNOptions = Object.keys(integrationMap).map(k => {
        if (integrationMap[k].CDL?.Enabled) {
            return { value: k, iconLeft: <LinkSimpleIcon size="sm" /> };
        }
        return { value: k, iconLeft: <LinkSimpleSlashIcon size="sm" /> };
    });

    const regionOptions =  Object.keys(regions).map(k => {
        return { text:regions[k], value: k };
    });

    return (
        <Sheet
            onClose={() => close()}
            title={translate(`resources.integrations.panel.${isEditMode ? 'editPolicyManagement' : 'addPanorama'}`)}
            isOpen
        >
            <PANWDSForm
                toolbar={<SaveToolbar />}
                onSubmit={preSaveRecord}
                initialValues={initialValues}
            >
                {panoramaRecord &&
                    <div className={classes.sidePanelForm}>
                        <Row>

                            <FormControl fullWidth>
                                <Field
                                    title={translate(`resources.integrations.panel.LinkName`)}
                                    name="LinkName"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    required
                                    inputProps={{ "data-test-id": "integrations-link-name" }}
                                    validate={composeValidators(isRequired, maxLength(128))}
                                    dataMetrics="integrations-link-name"
                                    row
                                />
                            </FormControl>

                            <FormControl fullWidth>
                                <Field
                                    name="PrimarySN"
                                    row
                                    // @ts-ignore
                                    component={PANWDSSelectWithSearch}
                                    title={translate(`resources.integrations.panel.primaryPanoramaSN`)}
                                    items={primarySNOptions}
                                    required
                                    validate={isRequired}
                                    disabled={taskInProgress || isEditMode}
                                />
                            </FormControl>

                            <FormSpy subscription={{ values: true }}>
                                {({ values, ...rest }) => {
                                    if (values.PrimarySN && values.PrimarySN === values.SecondarySN) {
                                        return (<div className={classes.iconedParagraph}>
                                            <InfoIcon size="sm" />
                                            {translate(`resources.integrations.panel.linkingFailed`)}
                                        </div>);
                                    } else return null;
                                }}
                            </FormSpy>
                            <FormSpy subscription={{ values: true }}>
                                {({ values, ...rest }) => {
                                    if (allIntegrationsCount > 1 && values.PrimarySN !== undefined) {
                                        return (
                                            <FormControl fullWidth>
                                                <Field
                                                    name="SecondarySN"
                                                    row
                                                    // @ts-ignore
                                                    component={PANWDSSelectWithSearch}
                                                    title={translate(`resources.integrations.panel.haPeerSerialNumber`)}
                                                    items={getValuesForSecondarySN(values.PrimarySN, integrationMap, SNcspIdMap)}
                                                    disabled={taskInProgress || (isEditMode && initialValues.SecondarySN)}
                                                />
                                            </FormControl>);
                                    } else return null;
                                }}
                            </FormSpy>
                            <FormSpy subscription={{ values: true }}>
                                {({ values, ...rest }) => {
                                    if (isPrimarySNCDLEnabled(values) === false) {
                                        return <>
                                            <FormControl fullWidth>
                                                <div className={classes.notes}>
                                                    {translate(`resources.integrations.panel.panoramaNotEnabled`)}
                                                </div>
                                                <Field
                                                    name="configure"
                                                    // @ts-ignore
                                                    component={PANWDSRadioField}
                                                    options={[
                                                        {
                                                            label: translate(`resources.integrations.panel.panoramaCancel`),
                                                            value: "cancel"
                                                        },
                                                        {
                                                            label: translate(`resources.integrations.panel.panoramaAgree`),
                                                            value: "agree"
                                                        },
                                                        // { label: translate(`resources.integrations.panel.panoramaAllow`), value: "allow" }
                                                    ]}
                                                    validate={isRequired}
                                                    required
                                                    disabled={taskInProgress || isEditMode}
                                                />
                                            </FormControl>
                                        </>
                                            ;
                                    } else return null;
                                }}
                            </FormSpy>
                            <FormSpy subscription={{ values: true }}>
                                {({ values, ...rest }) => {
                                    if (values.configure === "agree" && isPrimarySNCDLEnabled(values) === false) {
                                        return (
                                            <FormControl fullWidth>
                                                <Field
                                                    name="region"
                                                    row
                                                    // @ts-ignore
                                                    component={PANWDSSelectWithSearch}
                                                    title={translate(`resources.integrations.panel.selectRegion`)}
                                                    items={regionOptions}
                                                    required
                                                    validate={isRequired}
                                                    disabled={taskInProgress || isEditMode}
                                                />
                                            </FormControl>);
                                    } else return null;
                                }}
                            </FormSpy>
                            <WhenFieldChanges
                                field="PrimarySN"
                                becomes={true}
                                set="SecondarySN"
                                to={""}
                            />
                            <div className={classes.notes}>
                                <strong>{translate(`resources.integrations.note`)}: </strong>{translate(`resources.integrations.panel.notePM`)}
                            </div>
                        </Row>
                    </div>
                }
            </PANWDSForm>
        </Sheet>
    );
};
