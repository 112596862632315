import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import CertificateCreate from './CertificateCreate';
import CertificateEdit from './CertificateEdit';
import CertificateList from './CertificateList';

const certificate = {
    create: CertificateCreate,
    list: CertificateList,
    edit: CertificateEdit,
    icon: InvoiceIcon,
};

export default certificate;