import {ComponentType, createElement, ReactElement} from "react";
import {Location} from "history";
import {usePermissions, useAuthenticated} from "../customHooks";

export interface WithPermissionsChildrenParams {
    permissions: any;
}

type WithPermissionsChildren = (
    params: WithPermissionsChildrenParams
) => ReactElement;

export interface WithPermissionsProps {
    authParams?: object;
    children?: WithPermissionsChildren;
    component?: any;
    location?: Location;
    render?: WithPermissionsChildren;
    staticContext?: object;
    [key: string]: any;
}

const WithPermissions = (props: WithPermissionsProps) => {
    const {
        authParams,
        children,
        render,
        component,
        staticContext,
        ...rest
    } = props;

    useAuthenticated(authParams);
    const { permissions } = usePermissions();
    // render even though the usePermissions() call isn't finished (optimistic rendering)
    if (component) {
        return createElement(component, { permissions, ...rest });
    }

    if (render) {
        return render({ permissions, ...rest });
    }

    if (children) {
        return children({ permissions, ...rest });
    }
    return <></>;
};

export default WithPermissions;
