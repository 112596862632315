import { Body, Bold, Button, Link, Modal, ModalHeader } from "@panwds/react-ui";
import { useEffect, useState } from "react";
import logo from "../../images/panw_logo.png";
import { Title } from "../styles";
import { makeStyles } from "@material-ui/core/styles";
import { ApplicationConfigManager } from "../../types";
import { SaveButton, toast } from "../../components";
import { dataProvider } from "../../dataProvider";
import { ReduxResources } from "../../redux";
import * as React from "react";
import { useTranslate, usePermissions } from "../../customHooks";
import { useHistory } from "react-router-dom";
import { RouteUri } from "../../routeUri";
import { FormattedMessage } from "react-intl";
import EventEmitter from "../../eventEmitter";

const useStyles = makeStyles((theme) => ({
    registrationModal: {
        padding: '30px 24px 50px'
    },
    modalHeader: {
        border: "none"
    },
    modalBody: {
        padding: "50px 0"
    },
    avatar: {
        paddingBottom: "12px",
        display: "flex",
        justifyContent: "center",
    },
    logo: {

    },
    loadingBlur: {
        filter: "blur(1px)",
    },
    btnGroup: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        gridGap: "15px",
    }
}));

export default ({ openModal }: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [seconds, setSeconds] = useState(undefined);
    const [disableResendEmail, setDisableResendEmail] = useState(false);
    const oktaRegistrationObj = localStorage.getItem("oktaRegistrationModal");
    const existsInCognito = oktaRegistrationObj && JSON.parse(oktaRegistrationObj)?.existsInCognito === "YES";
    const existsInOkta = oktaRegistrationObj && JSON.parse(oktaRegistrationObj)?.existsInOkta;
    const history = useHistory();
    const { permissions, rawPermissions } = usePermissions();

    useEffect(() => {
        if (openModal) setIsOpen(openModal);
    }, [openModal]);

    useEffect(() => {
        EventEmitter.on('oktaRegistrationModal', handleRegistrationModal);
        return () => {
            EventEmitter.off('oktaRegistrationModal', handleRegistrationModal);
        }
    }, []);

    const handleRegistrationModal = (payload: any) => {
        const oktaRegistrationObj = localStorage.getItem("oktaRegistrationModal");
        if (oktaRegistrationObj && JSON.parse(oktaRegistrationObj)?.existsInCognito === "YES") {
            setIsOpen(true);
        }
    }

    const handleResendEmail = () => {
        setDisableResendEmail(true);
        let timesRun = 60;
        setTimeout(() => {
            setDisableResendEmail(false);
        }, 61000);
        var breakTimer = setInterval(() => {
            setSeconds(timesRun);
            timesRun -= 1;
            if (timesRun === -1) {
                setSeconds(undefined);
                clearInterval(breakTimer);
            }
        }, 1000);
        const userEmail = ApplicationConfigManager.getInstance().getUserEmail();
        let payload: any = { UserName: userEmail };
        dataProvider.update('userMigration', payload)
            .then((res) => {
                toast.success(translate("oktaRegistrationModal.body.emailSent"));
            }).catch(e => {
                toast.error(e?.error?.error);
            })
    }

    const handleOnCancel = () => {
        localStorage.removeItem("oktaRegistrationModal");
        setIsOpen(false);
    }

    const handleOnRegister = () => {
        const userEmail = ApplicationConfigManager.getInstance().getUserEmail();
        setIsSubmitting(true);
        let payload: any = { UserName: userEmail };
        dataProvider.update('userMigration', payload)
            .then((res) => {
                //localStorage.removeItem("oktaRegistrationModal");
                if (existsInOkta === "ACTIVE") {
                    setIsOpen(false);
                    EventEmitter.emit("logoutUser", true);
                } else {
                    if (oktaRegistrationObj) {
                        let registrationStatus = JSON.parse(oktaRegistrationObj);
                        localStorage.setItem('oktaRegistrationModal', JSON.stringify({ ...registrationStatus, "existsInOkta": "CREATED" }));
                    }
                }
            }).catch(e => {
                setIsSubmitting(false);
                toast.error(e?.error?.error);
            }).finally(() => {
                setIsSubmitting(false);
            });
    }

    if (!isOpen) {
        return <div className="okta-modal"></div>;
    }

    return (
        <Modal isOpen={isOpen} onClose={handleOnCancel} style={{ width: '448px', maxHeight: "100%" }}>
            <ModalHeader addClassName={classes.modalHeader} />
            <div className={classes.registrationModal}>
                <div className={classes.avatar}>
                    <img src={logo} alt="logo" className={classes.logo} />
                </div>
                {/* @ts-expect-error Server Component */}
                <Title>{(existsInOkta === "ACTIVE") ? translate("oktaRegistrationModal.activeTitle") :
                    translate("oktaRegistrationModal.title")}</Title>
                <div className={classes.modalBody}>
                    <div style={{ lineHeight: "28px", fontSize: "14px" }}><Bold> {
                        (existsInOkta === "CREATED") ? translate("oktaRegistrationModal.body.createdTitle") :
                            ((existsInOkta === "PROVISIONED") || (existsInOkta === "STAGED") || (existsInOkta === "DEPROVISIONED")) ? translate("oktaRegistrationModal.body.provisionedTitle") :
                                (existsInOkta === "ACTIVE") ? translate("oktaRegistrationModal.body.activeTitle") :
                                    translate("oktaRegistrationModal.body.title")}</Bold></div>
                    <Body as="div" style={{ lineHeight: "24px" }}>
                        {(existsInOkta === "NO") ? translate("oktaRegistrationModal.body.content") :
                            (existsInOkta === "CREATED") ? <FormattedMessage
                                id={"oktaRegistrationModal.body.createdContent"}
                                values={{
                                    break: <br />,
                                    link: <Link
                                        dataMetrics="cloudngfw-get-login-help"
                                        external
                                        href="https://www.paloaltonetworks.com/services/support/login-assistance"
                                    >
                                        {translate(`oktaRegistrationModal.body.getHelp`)}
                                    </Link>
                                }} /> :
                                ((existsInOkta === "PROVISIONED") || (existsInOkta === "STAGED")|| (existsInOkta === "DEPROVISIONED")) ? <FormattedMessage
                                id={"oktaRegistrationModal.body.provisionedContent"}
                                values={{
                                    break: <br />,
                                    link: <Link
                                        dataMetrics="cloudngfw-get-login-help"
                                        external
                                        href="https://www.paloaltonetworks.com/services/support/login-assistance"
                                    >
                                        {translate(`oktaRegistrationModal.body.getHelp`)}
                                    </Link>
                                }} /> :
                                    (existsInOkta === "ACTIVE") ? translate("oktaRegistrationModal.body.activeContent") :
                                        translate("oktaRegistrationModal.body.content")}
                    </Body>
                </div>
                <div className={classes.btnGroup}>
                    {(existsInOkta === "NO" && existsInOkta !== "CREATED") &&
                        <Button appearance="secondary" disabled={isSubmitting} onClick={handleOnCancel}>{translate("oktaRegistrationModal.registerLater")}</Button>
                    }
                    {(existsInOkta === "CREATED" || existsInOkta === "PROVISIONED" || existsInOkta === "STAGED" || (existsInOkta === "DEPROVISIONED")) &&
                        <Button appearance="secondary" disabled={isSubmitting || disableResendEmail} onClick={handleResendEmail}>{translate("oktaRegistrationModal.resendEmail")} {seconds ? `( ${seconds} )` : ""}</Button>
                    }
                    {(existsInOkta === "CREATED" || existsInOkta === "PROVISIONED" || existsInOkta === "STAGED" || (existsInOkta === "DEPROVISIONED")) &&
                        <Button appearance="primary" disabled={isSubmitting} onClick={handleOnCancel}>{translate("oktaRegistrationModal.submit")}</Button>
                    }
                    {(existsInOkta === "NO" || existsInOkta === "ACTIVE") &&
                        <SaveButton
                            appearance="primary"
                            size="md"
                            label={(existsInOkta === "ACTIVE") ? translate("oktaRegistrationModal.activeSubmit") :
                                translate("oktaRegistrationModal.submit")}
                            onClick={handleOnRegister}
                            submitOnEnter={true}
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            dataMetrics="cloudngfw-okta-registration-modal-register-button"
                        />}
                </div>
            </div>
        </Modal>
    );
};
