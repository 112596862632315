import {ApplicationConfigManager} from "../types";
import {useOktaAuthClient} from "../customHooks";
import {isEmpty} from "lodash";
import {
    TokenManagerInterface,
    AccessToken,
    IDToken,
} from '@okta/okta-auth-js';
import {authProvider} from "../authProvider";



export type oktaConfig = {
    userPool: any;
    user: any;
    userSession: any;
};

export default class OktaManager {
    private currentConfig: oktaConfig = {
        user: null,
        userPool: null,
        userSession: null
    }

    async OktaManager() {
        await this.loadConfig();
    }

    getConfig(): oktaConfig {
        return this.currentConfig;
    }

    async loadConfig() {
        try {
            //const applicationConfig = ApplicationConfigManager.getInstance().getConfig();
            const tokenManager: TokenManagerInterface = useOktaAuthClient.tokenManager;
            const oktaIdToken: IDToken = await tokenManager.get('idToken') as IDToken;
            const accessToken: AccessToken = await tokenManager.get('accessToken') as AccessToken;
            if (accessToken && oktaIdToken) {
                if (isEmpty(this.currentConfig.user)) {
                    this.currentConfig.user = await useOktaAuthClient.token.getUserInfo(accessToken, oktaIdToken);
                    if (this.currentConfig.user !== null) await this.ensureSession();
                } else {
                    await this.ensureSession();
                }
            }
        } catch (e) {
            throw e;
        }
    }

    async isSessionAvailable() {
        try {
            const tokenManager: TokenManagerInterface = useOktaAuthClient.tokenManager;
            const oktaIdToken: IDToken = await tokenManager.get('idToken') as IDToken;
            const accessToken: AccessToken = await tokenManager.get('accessToken') as AccessToken;
            if (accessToken && oktaIdToken) {
                this.currentConfig.user = await useOktaAuthClient.token.getUserInfo(accessToken, oktaIdToken);
                if (this.currentConfig.user !== null) {
                    localStorage.setItem('idToken', oktaIdToken?.idToken);
                    await this.loadConfig();
                }
            }
            return !isEmpty(this.currentConfig.user);
        } catch (e) {
            throw e;
        }
    }

    async ensureSession() {
        if (localStorage.getItem('idToken')) {
            return useOktaAuthClient.tokenManager.get('idToken')
                .then(async (token) => {
                    if (token && !useOktaAuthClient.tokenManager.hasExpired(token)) {
                        // Token is valid
                        return token;
                    } else {
                        // Token has been removed due to expiration or error while renewing
                        await this.refreshSession();
                        //throw "Token expired";
                    }
                })
                .catch(async (err) => {
                    // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
                    console.error({OAuthError: err});
                    await authProvider.logout(null);
                });
        } else {
            await authProvider.logout(null);
        }
    }

    async refreshSession() {
        const tokenManager: TokenManagerInterface = useOktaAuthClient.tokenManager;
        await tokenManager.renew('idToken')
            .then((newToken: any) => {
                localStorage.setItem('idToken', newToken?.idToken);
            }).catch(e => {
                throw e;
            });
    }

    async clearSession() {
        if (this.currentConfig && this.currentConfig.user) {
            await useOktaAuthClient.signOut({clearTokensBeforeRedirect: true, revokeAccessToken:true, postLogoutRedirectUri: window.location.origin}).then().catch(console.error);
        } else {
            useOktaAuthClient.tokenManager.clear();
        }
    }
};
