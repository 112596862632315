import {createAsyncThunk} from "@reduxjs/toolkit";
import {dataProvider} from "../../dataProvider";
import {CreateType, FetchList} from "../utils/types";
import {ReduxResources} from "../index";
import {isEmpty} from "lodash";
import {authProvider} from "../../authProvider";


/**
 * Redux action creation
 *
 * @param resource : (string) one of ReduxResources enums
 * @param params : (any) object representing the resource to be created
 * @example fetchListDetails({resources: 'firewalls'})(params)
 */

// fetch list with details
export const fetchListDetails = ({resource}: FetchList) => createAsyncThunk(
    `${resource}/fetchListDetails`,
    async (params: any, {rejectWithValue}) => {
        try {
            return await dataProvider.getList(resource, params).then((res) => {
                return res;
            });
        } catch (e: any) {
            return rejectWithValue(e);
        }
    },
    {
        condition: (arg: any, { getState, extra }): any => {
            let needRefresh = false;
            if (arg.needRefresh) {
                needRefresh = true;
            }
            const reduxState = getState();
            // @ts-ignore
            const resourceState = reduxState[`${resource}`];
            if (!isEmpty(resourceState.listDetails?.data) && resourceState?.list?.data.length === resourceState?.listDetails?.total && !needRefresh) {
                return false;
            }
        },
    }
);

// fetch list with names
export const fetchList = ({resource}: FetchList) => createAsyncThunk(
    `${resource}/fetchList`,
    async (params: any = {}, {rejectWithValue}) => {
        try {
            return await dataProvider.get(resource, params).then((res: any) => {
                return res;
            });
        } catch (e: any) {
            return rejectWithValue(e.error)
        }
    }
);

export const compareList = ({resource}: FetchList) => createAsyncThunk(
    `${resource}/compareList`,
    async (params: any = {}, {getState, dispatch, rejectWithValue}) => {
        try {
            const res = await dataProvider.get(resource, params);
            const list = res.data;
            // @ts-ignore
            const stateListDetails = getState()[`${resource}`].listDetails;
            const listDetails = stateListDetails.data;
            if (listDetails.length > 0 && stateListDetails.nextToken === undefined) {
                let newListDetails = {};
                if (resource === ReduxResources.RULESTACK) {
                    let newListDetails = listDetails.filter((l: {RuleStackName: string}) => list.findIndex((ll: any) => ll.Name === l.RuleStackName) !== -1);
                    let difference = list.filter((l: {Name: string}) => newListDetails.findIndex((ll: any) => ll.RuleStackName === l.Name) === -1);
                    if (difference.length > 0) {
                        await difference.map(async (d: any) => {
                            await dispatch(describe({resource})({RuleStackName: d.Name}));
                        })
                    }
                }
                if (resource === ReduxResources.FIREWALL) {
                    let newListDetails = listDetails.filter((l: any) => list.findIndex((ll: any) => ll.FirewallName === l.Firewall.FirewallName) !== -1);
                    let difference = list.filter((l: any) => newListDetails.findIndex((ll: any) => ll.Firewall.FirewallName === l.FirewallName) === -1);
                    if (difference.length > 0) {
                        await difference.map(async (d: any) => {
                            await dispatch(describe({resource})({FirewallName: d.FirewallName, AccountId: d.AccountId}));
                        })
                    }
                }
                if (resource === ReduxResources.USER) {
                    let newListDetails = listDetails.filter((l: any) => list.findIndex((ll: any) => ll.UserName === l) !== -1);
                    let difference = list.filter((l: any) => newListDetails.findIndex((ll: any) => ll.UserName === l) === -1);
                    if (difference.length > 0) {
                        await difference.map(async (d: any) => {
                            await dispatch(describe({resource})({UserName: d}));
                        })
                    }
                }
                return {listDetails: !isEmpty(newListDetails) ? newListDetails: listDetails, list};
            } else {
                return {listDetails, list};
            }
        } catch (e: any) {
            return rejectWithValue(e.error)
        }
    }
);

export const describe = ({resource}: CreateType) => createAsyncThunk(
    `${resource}/describe`,
    async (params: any = {}, {rejectWithValue}) => {
        try {
            return await dataProvider.describe(resource, '', params);
        } catch (err: any) {
            return rejectWithValue(err.error);
        }
    }
)

export const resetListDetails = ({resource}: CreateType) => createAsyncThunk(
    `${resource}/resetListDetails`,
    async (params: any = {}, {rejectWithValue}) => {
        return {};
    }
)

export const create = ({resource}: CreateType) => createAsyncThunk(
    `${resource}/create`,
    async (params: any = {}, {dispatch, rejectWithValue, getState}) => {
        try {
            const res = await dataProvider.create(resource, params);
            if (resource === ReduxResources.FIREWALL || resource === ReduxResources.RULESTACK || resource === ReduxResources.USER) {
                if (resource === ReduxResources.USER) {
                    await authProvider.refreshToken({})
                }
                await dispatch(fetchList({resource})({}));

                // @ts-ignore
                if (!isEmpty(getState()[`${resource}`]?.listDetails.data) && getState()[`${resource}`]?.listDetails.nextToken === undefined) {
                    if (resource === ReduxResources.RULESTACK)
                        await dispatch(describe({resource})({RuleStackName: res.data.RuleStackName}));
                    if (resource === ReduxResources.FIREWALL)
                        await dispatch(describe({resource})({FirewallName: res.data.FirewallName, AccountId: res.data.AccountId}));
                    if (resource === ReduxResources.USER) {
                        //await authProvider.refreshToken({});
                        await dispatch(describe({resource})({UserName: res.data.UserName}));
                    }
                } else {
                    await dispatch(resetListDetails({resource})({}));
                }
            }
            return res;
        } catch (err: any) {
            return rejectWithValue(err.error)
        }
    }
)

export const updateResource = ({resource}: CreateType) => createAsyncThunk(
    `${resource}/update`,
    async (request: any = {}, {getState, dispatch, rejectWithValue}) => {
        try {
            let res = {};
            if (resource === ReduxResources.USER) {
                await authProvider.refreshToken({})
            }
            if (resource === ReduxResources.FIREWALL) {
                res = await dataProvider.describe(resource, '', {FirewallName: request.FirewallName, AccountId: request.AccountId});
            }
            if (resource === ReduxResources.RULESTACK) {
                res = await dataProvider.describe(resource, '', {RuleStackName: request.RuleStackName});
            }
            if (resource === ReduxResources.USER) {
                res = await dataProvider.describe(resource, '', {UserName: request.UserName});
            }
            return res;
        } catch (e: any) {
            return rejectWithValue(e.error)
        }
    }
)

export const deleteResource = ({resource}: CreateType) => createAsyncThunk(
    `${resource}/delete`,
    async (params: any = {}, {dispatch, rejectWithValue}) => {
        try {
            return await dispatch(fetchList({resource})({}));
        } catch (e: any) {
            return rejectWithValue(e.error)
        }
    }
)
