import { Button, Select } from "@panwds/react-ui";
import { FC } from 'react';
import { ButtonAppearances } from '../../types';

interface PANWDSButtonDropdownProps {
    items: any[],
    appearance: ButtonAppearances,
    buttonTitle: any,
    dataMetrics: string,
    onChange?: Function,
    size?: 'lg' | 'md' | 'sm',
    disabled?: boolean
  }

const PANWDSButtonDropdown: FC<PANWDSButtonDropdownProps> = ({
    buttonTitle, items, appearance, dataMetrics, onChange, size, disabled
})  => {

    return <Select
        button={
            <Button
                isMenu
                appearance={appearance ? appearance : 'primary'}
                dataMetrics={dataMetrics ? dataMetrics + '-button' : buttonTitle +'-button'}
                size={size ? size : 'md'}>
                    {buttonTitle}
            </Button>
        }
        dataMetrics={dataMetrics}
        items={items}
        disabled={disabled}
        onChange={(e: any) => onChange ? onChange(e) : null}
        fitToItemWidth
    />
}

export default PANWDSButtonDropdown;
