import IntegrationsListOld from './IntegrationsListOld';
import IntegrationsList from './IntegrationsList';

const integrations =
{
    oldList: IntegrationsListOld,
    list: IntegrationsList
};

export default integrations;
