import React, {useEffect, useState} from "react";
import eventEmitter from "../../eventEmitter";
import {Banner, BannerProps as PANWDSBannerProps, Link} from "@panwds/react-ui";
import isEmpty from "lodash/isEmpty";

interface BannerProps extends PANWDSBannerProps {
    label: string,
}

const PANWDSBannerContainer = () => {
    const [banner, setBanner] = useState(<></>);
    useEffect(() => {
        eventEmitter.on('BANNER.SHOW', callBannerFunc)
        return () => {
            eventEmitter.off('BANNER.SHOW', callBannerFunc);
        }
    }, []);

    const callBannerFunc = ({label, ...rest}: BannerProps) => {
        setBanner(
            <Banner
                type={rest.type || "inline"}
                showIcon={rest.showIcon || true}
                onClose={() => setBanner(<></>)}
                {...rest}
            >
                {label}
            </Banner>
        );
    };

    return banner;
};

const callPANWDBanner = ({label, ...rest}: BannerProps) => {
    if (!isEmpty(label))
        eventEmitter.emit('BANNER.SHOW', {label, ...rest});
}

export {callPANWDBanner, PANWDSBannerContainer};
