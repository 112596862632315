import { useEffect } from "react";

const IntervalCallerComponent = ({ updateCallback, interval=5000 }: any) => {
    useEffect(() => {
      const intervalId = setInterval(() => updateCallback(), interval);
      return () => clearInterval(intervalId);
    }, []);
  
    return <></>;
  };

  export default IntervalCallerComponent;
  