import { dataProvider } from "../dataProvider";
import { toast } from '../components';

export const GetRulesLength = async (permissions: any, scope: any, setLoading: any, ruleStackName: any, setRulesLength: any, setError?: any) => {
  if (!permissions?.ListSecurityRules) {
    return;
  }
  if (scope) {
    setLoading(true);
    if (scope === "Local") {
      let [result, error] = await getRuleListData("LocalRule", ruleStackName, setLoading, setRulesLength, setError);
      if(result) {
        return [result, undefined];
      }
      if(error) {
        return [undefined, error];
      }
    }
    else {
      Promise.all([
        getRuleListData("PreRule", ruleStackName, setLoading, setRulesLength, setError),
        getRuleListData("PostRule", ruleStackName, setLoading, setRulesLength, setError),
      ])
        .then(values => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          (setRulesLength) ? setRulesLength(values[0] + values[1]) : null;
          return [values[0] + values[1], undefined];
        })
        .catch((error: any) => {
          toast.error(error?.error, { toastId: "rules-getList" });
        })
    }
  }
  return;
}


async function getRuleListData(RuleListName: string, RulestackName: string, setLoading: any, setRulesListDataLength: any,  setError?: any) {
  return dataProvider.get("rules", {
    data: { 'RuleListName': RuleListName, 'RuleStackName': RulestackName }
  }).then(async (response: any) => {
    if (response?.data) {
      let responseObj = response.data || [];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (setRulesListDataLength) ? setRulesListDataLength(responseObj.length) : null;
      setLoading(false);
      return [responseObj.length, undefined];
    } else {
      setLoading(false);
      setError(response?.error);
      return [undefined, response?.error];
    }
  })
    .catch((e: any) => {
      toast.error(e?.error, { toastId: "rules-getList" });
    })
}
