import React, {
  useEffect,
  useRef,
  useState,
  createElement,
  ComponentType,
  ReactNode,
  ReactElement,
  ErrorInfo,
} from 'react';
import classnames from "classnames";
import {createMuiTheme} from "./createMuiTheme";
import compose from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import { ThemeOptions } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { withStyles, createStyles } from '@material-ui/styles';

const styles = (theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(7),
      },
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1,
    },
    content: {
      backgroundColor: theme.palette.background.default,
      //zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: 0,
      height: '100vh',
      overflow: 'auto',
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 5,
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  });

interface LayoutState {
  hasError: any;
  classes?: any;
  className?: string;
  error?: ComponentType<{
    error?: Error;
    errorInfo?: ErrorInfo;
    title?: string | ReactElement<any>;
  }>;
  customMenu?: any;
  notification?: ComponentType;
  sidebar?: any;
  theme?: ThemeOptions;
  children?: ReactNode[];
}

const LayoutWithoutTheme = ({
                              children,
                              classes,
                              className,
                              error: ErrorComponent,
                              customMenu,
                              notification,
                              sidebar,
                              ...props
                            } : LayoutState) => {
  return (
    <div
      className={classnames('layout', classes.root, className)}
      // {...props} //commented this piece to remove console errors
    >
      <div className={classes.appFrame}>
        <main className={classes.contentWithSidebar}>
          {createElement(sidebar, {
            children: createElement(customMenu),
          })}
          <div id="main-content" className={classes.content}>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

const EnhancedLayout = compose(
  withRouter,
  withStyles(styles, { name: 'RaLayout' })
)(LayoutWithoutTheme);


const WrapLayout = (props :any): JSX.Element => {
  const themeOverride = props.customtheme;
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(() => createMuiTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  );
};

export default WrapLayout;
