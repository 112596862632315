import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { IPANTitle } from "../../types";
import { Row } from "../FormElements";
import { PANSelectRegion } from "../SelectRegion";
import OverviewPanel from "../OverviewPanel/OverviewPanel";
import { Tag } from "@panwds/react-ui";
import {PANWDSBannerContainer} from "../Banner";


const useStyles = makeStyles((theme) => ({
  titleContainer: (props:any) => ({
    padding: props.paddingContainer ? props.paddingContainer : "4px 16px 20px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "white",
  }),
  title: {
    fontSize: "24px",
    fontWeight: 400,
    margin: 0,
    color: "#333333",
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: 400,
    margin: "4px 0 0",
    color: "#707070",
  },
  titleStyle: {
    display: "flex",
    alignItems: "end"
  },
}));


export const PANTitle = ({ title, children, region, tag, subtitle, divider, overviewPanel, paddingContainer }: IPANTitle) => {
  const classes = useStyles({paddingContainer});

  return (
    <>
      {region && <PANSelectRegion />}
      {(title || subtitle || tag || children || overviewPanel) &&
        <section className={classes.titleContainer} style={divider ? { borderBottom: "1px solid #DADBDB" } : { borderBottom: "none" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={classes.titleStyle}>
            <Typography variant="h2" className={classes.title} data-test-id="header-title">{title}</Typography>
            {tag && <Tag style={({marginLeft: "5px"})} appearance="blue">{tag}</Tag>}
            </div>
            <Grid>{children?.map((child: any, index) => <Row key={index}>{child}</Row>)}</Grid>
          </div>
          <Grid container>
            {overviewPanel?.map((data: any, index) => (
                <OverviewPanel key={index} panelData={data} />
            ))}
          </Grid>
          <p className={classes.subtitle} data-test-id="header-subtitle" dangerouslySetInnerHTML={{ __html: subtitle || "" }} />
        </section>
      }
      <PANWDSBannerContainer/>
    </>
  );
};
