import { Grid, Box, makeStyles } from "@material-ui/core";
import React from "react";
import { IPANTile } from "../../types";
import { Subtitle, TileContainer, Title, TitleContainer, TitleDividerContainer } from "./styles";

const useStyles = makeStyles((theme) => ({
  titleElements: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }
}));

export const PANTile = ({ children, title, size, subtitle, titlePadding="24px", padding = "24px", divider, titleActions, fontSize, titleVariant }: IPANTile) => {
  const classes = useStyles();
  return (
    divider ? (<Grid item xs={size}>
      <TileContainer elevation={2} >
        {title && (
          <TitleDividerContainer style={{ padding: titlePadding, }}>
            <div className={classes.titleElements}>
              <Title style={{fontSize: fontSize}}>{title}</Title>
              {titleActions ? <div>{titleActions}</div> : null }
            </div>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleDividerContainer>
        )}
        <Box style={{ padding: padding, }}>
          {children}
        </Box>
      </TileContainer>
    </Grid>) : (
      <Grid item xs={size}>
        <TileContainer elevation={2} style={{ padding: titlePadding, }}>
          {title && (
            <TitleContainer>
              <Title style={{fontSize: fontSize}}>{title}</Title>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </TitleContainer>
          )}
          {children}
        </TileContainer>
      </Grid>
    ));
};
