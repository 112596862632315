import React, { MouseEvent, ReactElement, useEffect, useState } from "react";
import { SearchIcon } from "@panwds/icons";
import { Input } from "@panwds/react-ui";
import { useFilters } from "@panwds/react-filters";

export type InputSearchBarFilterProps = {
  /** The name for this filter */
  name: string;
  /** The label to use on the left side of the Pill. The right side of the
   * Pill will appended with "Select <label>"
   */
  label: string;
  /**
   * A function that should get called when the filter is removed.
   * If set, the left side of the Pill will display a closing x icon.
   */
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Make the filter read only
   */
  disabled?: boolean;
  /**
   * Is the filter type required
   */
  required?: boolean;
  value?: string;
  onInputChange?: any,
  defaultValues?: string
};

export function InputSearchBarFilter(
  props: InputSearchBarFilterProps
): ReactElement {
  const { setFilters } = useFilters();
  const [value, setValue] = useState<string>(props?.value ? props.value : "");

  useEffect(() => {
    setValue(props?.defaultValues ? props?.defaultValues : '');
    setFilters({
      [props.name]: `${props?.defaultValues}`,
    });
  }, [props?.defaultValues]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
    setFilters({
      [props.name]: `${event.currentTarget.value}`,
    });
  };

  return (
    <Input
      key="input_search_bar"
      prefix={
        <SearchIcon
          size="sm"
          className="tw-text-gray-600 dark:tw-text-blue-steel-300"
        />
      }
      placeholder="Search"
      addClassName="tw-w-64 dark:tw-bg-blue-steel-900 dark:tw-border-blue-steel-800 tw-placeholder-gray-400 dark:tw-placeholder-blue-steel-400"
      disabled={props.disabled}
      value={value}
      onChange={onInputChange}
    />
  );
}
