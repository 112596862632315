import { useCallback } from 'react';

import { useIntl  } from 'react-intl';

export type Translate = (key: string, options?: any) => string;

const useTranslate = (): Translate => {
    const intl = useIntl();
    const translate = useCallback(
        (key: string, options?: any) =>
            intl.formatMessage({ id: key }) as string,
        // update the hook each time the locale changes
        [intl] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return intl ? translate : identity;
};

const identity = (key: string) => key;

export default useTranslate;
