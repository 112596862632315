import React, {ReactElement, useEffect, useState} from "react";
import EventEmitter from "../../eventEmitter";
import {useToast} from "@panwds/react-ui";
import {IFwaasApiError} from "../../api/FwaasDataTypes";
import isEmpty from "lodash/isEmpty";

interface ToastOptions {
    remove?: any;
    life?: any,
    autoDismiss?: boolean,
    toastId?: any
}

type ToastContent = string | string[] | ReactElement | IFwaasApiError | undefined;

const enum Type {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
    CLEAR = 'clear'
}

const ToastContainer = () => {
    const [toastId, setToastId] = useState('');
    const {toast} = useToast();
    useEffect(() => {
        EventEmitter.on('TOAST.SHOW', callToastFunc)
        return () => {
            EventEmitter.off('TOAST.SHOW', callToastFunc);
        }
    }, []);

    const callToastFunc = ({text, type, options}: any) => {
        if (isEmpty(options)) {
            toast(text, {
                appearance: type,
                ...options
            });
        }
        if (options && options.toastId !== toastId) {
            setToastId(options.toastId);
            toast(text, {
                appearance: type,
                ...options
            });
        }
    };

    return <></>
};

function createToastByType(
    content: ToastContent,
    options?: ToastOptions,
    type?: string
) {
    if (!isEmpty(content))
    EventEmitter.emit('TOAST.SHOW', {text: content, type, options});
}

const toast = {
    clear: (
        content: ToastContent,
        options?: ToastOptions
    ) => createToastByType(content, options, Type.CLEAR),
    success: (
        content: ToastContent,
        options?: ToastOptions
    ) => createToastByType(content, options, Type.SUCCESS),
    info: (
        content: ToastContent,
        options?: ToastOptions
    ) => createToastByType(content, options, Type.INFO),
    error: (
        content: ToastContent,
        options?: ToastOptions
    ) => {
        if (!isEmpty(content) && content?.hasOwnProperty('error')) {
            content = content?.error;
        }
        return createToastByType(content, options, Type.ERROR)
    },
    warning: (
        content: ToastContent,
        options?: ToastOptions
    ) => createToastByType(content, options, Type.WARNING),
};

export {ToastContainer, toast};
