import {OktaAuth} from "@okta/okta-auth-js";
import configuration from "../config.json"
import {isEmpty} from "lodash";

//TODO: need to validate if config is present or not
const oktaAuthClient = () => {
    if (isEmpty(configuration.oidc)) {

    } else {

    }
    return new OktaAuth(configuration.oidc);
};

export default new OktaAuth(configuration.oidc);
