import React, {createContext, useCallback, useMemo, useState} from "react";
import merge from "lodash/merge";

// @ts-ignore
const GlobalContext = createContext();

const GlobalProvider = ({children}: any) => {

  const [state, setState] = useState({});

  const setGlobalState = useCallback((updatedData: any) => {
    setState(prevData  => {
      return merge(prevData, updatedData);
    });
  }, [setState]);

  // memoize the full context value
  const contextValue = useMemo(() => ({
    state,
    setGlobalState
  }), [state, setGlobalState])

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export {GlobalProvider, GlobalContext};
