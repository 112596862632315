import { withRouter } from "react-router-dom";
import { FormControl, makeStyles } from "@material-ui/core";
import { useTranslate } from "../../customHooks";
import { Field } from "react-final-form";
import { PANWDSForm } from "../../components/PANWDSElements/PANWDSForm";
import { PANWDSInput } from "../../components/PANWDSElements/PANWDSInput";
import { Row } from "../../components/FormElements";
import {
    Button as PANWDSButton,
    Modal,
    ModalBody,
    ModalHeader,
} from "@panwds/react-ui";
import {
    composeValidators,
    isRequired,
} from "../../utils/validate";
import _ from "lodash";
import CircularLoader from "../../components/CircularLoader/CircularLoader";

const useStyles = makeStyles((theme) => {
    return {
        toolbar: {
            background: "transparent",
            display: "flex",
            gap: theme.spacing(1),
            justifyContent: "flex-end",
            padding: "16px 0 0",
            alignItems: "center",
        },
        modalBodyStyle: {
            padding: 0
        },
        loadingBlur: {
            filter: "blur(1px)",
        }
    };
});

const ManageAccountModal = ({
    isOpenTags,
    setOpenTags,
    rowData,
    callBackList,
    loading
}: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const handleSave = (values: any) => {
        callBackList(values);
    };

    const AccountsCreateToolbar = (toolbarProps: any) => {
        const translate = useTranslate();
        let { submitting, invalid, pristine } = toolbarProps;
        return (
            <div className={classes.toolbar}>
                <PANWDSButton
                    appearance="secondary"
                    disabled={submitting || loading}
                    onClick={() => setOpenTags(false)}
                >
                    Cancel
                </PANWDSButton>
                {
                    !(rowData?.Firewall?.EndpointRole &&
                        rowData?.Firewall?.LoggingRole &&
                        rowData?.RuleStack?.DecryptionRole &&
                        rowData?.RuleStack?.NetworkMonitoringRole) &&
                    <PANWDSButton
                        //@ts-ignore
                        appearance="primary"
                        disabled={
                            submitting ||
                            pristine ||
                            invalid || loading
                        }
                        type="submit"
                    >
                        Confirm
                    </PANWDSButton>}
            </div>
        );
    };

    return (
        <>
            <Modal
                onClose={() => setOpenTags(false)}
                isOpen={isOpenTags}
                size="md"
                dataMetrics="account-proprty-modal"
            >
              <ModalHeader
                title={translate("resources.accounts.fields.ManageXAccountRoles")}
                dataMetrics="account-property-modalHeader"
                enableClose={true}
              />
              <ModalBody addClassName={classes.modalBodyStyle} dataMetrics="account-property-modalBody">
                <CircularLoader loading={loading} />
                <div className={loading ? classes.loadingBlur : ""}>
                <PANWDSForm
                        toolbar={<AccountsCreateToolbar />}
                        onSubmit={handleSave}
                        requireStylingFooter={true}
                        sheetStyle={true}
                        footerStyle={{
                            height: "64px",
                            borderTop: "1px solid #DADBDB",
                            "& div": {
                                "& button": {
                                    padding: "16px",
                                },
                                "& button:last-child": {
                                    marginRight: "16px",
                                },
                            }
                        }}
                        initialValues={rowData}
                        key={"Vpc-group-edit"}
                    >
                         <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="Firewall.EndpointRole"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    required
                                    title={translate(`resources.accounts.fields.EndpointRoleArn`)}
                                    validate={composeValidators(isRequired, (value: string) => {
                                    return ((value === undefined || value === "") || value.indexOf(":") !== -1 && value.split(":").length === 6
                                        && value.split(":")[4] === rowData?.AccountId) ? undefined : translate(`resources.accounts.fields.InvalidArn`);
                                    })}
                                    note={translate(`resources.accounts.fields.EndpointRoleArnNote`)}
                                    dataMetrics="cloudngfw-account-endpoint-role"
                                    disabled={rowData?.Firewall?.EndpointRole}
                                />
                            </FormControl>
                        </Row>
                        <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="Firewall.LoggingRole"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    title={translate(`resources.accounts.fields.LoggingRoleArn`)}
                                    validate={composeValidators((value: string) => {
                                    return ((value === undefined || value === "") || value.indexOf(":") !== -1 && value.split(":").length === 6
                                        && value.split(":")[4] === rowData?.AccountId) ? undefined : translate(`resources.accounts.fields.InvalidArn`);
                                    })}
                                    dataMetrics="cloudngfw-account-logging-role"
                                    note={translate(`resources.accounts.fields.LoggingRoleArnNote`)}
                                    disabled={rowData?.Firewall?.LoggingRole}
                                />
                            </FormControl>
                        </Row>
                        <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="RuleStack.DecryptionRole"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    title={translate(`resources.accounts.fields.DecryptionRoleArn`)}
                                    validate={composeValidators((value: string) => {
                                    return ((value === undefined || value === "") || value.indexOf(":") !== -1 && value.split(":").length === 6
                                        && value.split(":")[4] === rowData?.AccountId) ? undefined : translate(`resources.accounts.fields.InvalidArn`);
                                    })}
                                    note={translate(`resources.accounts.fields.DecryptionRoleArnNote`)}
                                    dataMetrics="cloudngfw-account-descryption-role"
                                    disabled={rowData?.RuleStack?.DecryptionRole}
                                />
                            </FormControl>
                        </Row>
                        <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="RuleStack.NetworkMonitoringRole"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    title={translate(`resources.accounts.fields.NetworkMonitoringRoleArn`)}
                                    validate={composeValidators((value: string) => {
                                    return ((value === undefined || value === "") || value.indexOf(":") !== -1 && value.split(":").length === 6
                                        && value.split(":")[4] === rowData?.AccountId) ? undefined : translate(`resources.accounts.fields.InvalidArn`);
                                    })}
                                    note={translate(`resources.accounts.fields.NetworkMonitoringRoleArnNote`)}
                                    dataMetrics="cloudngfw-account-network-monitoring-role"
                                    disabled={rowData?.RuleStack?.NetworkMonitoringRole}
                                />
                            </FormControl>
                        </Row>
                    </PANWDSForm>
                    </div>
              </ModalBody>
            </Modal>
        </>
    );
};

export default withRouter(ManageAccountModal);
