import { isEmpty, get } from "lodash";
import { produce } from 'immer';

export const getFilteredTableData = (tableProps, filters) => {
    let newGridData = tableProps.tableComponentData;
    if (isEmpty(newGridData)) {
        return;
    }
    Object.entries(filters).forEach(([filterProp, filterValues]: any) => {
        const filterValueExists =
            filterProp && typeof filterValues === "object"
                ? !isEmpty(filterValues)
                : filterValues;
        if (filterValueExists) {
            newGridData = newGridData.filter((row) => {
                const filterConfig =
                    tableProps?.tableComponentFilterBar?.filterConfig.find(
                        (item) => item.name === filterProp
                    );
                if (filterConfig?.comparator) {
                    return filterConfig.comparator(
                        filterProp,
                        filterValues,
                        row
                    );
                } else {
                    return filterValues.includes(get(row, filterProp));
                }
            });
        }
    });
    return newGridData;
};

// Utility function for extracting specified fields
export const extractFieldsWithConfig = (state: any, config: any) => {
    return produce(state, (draft: any) => {
        for (const [key, fields] of Object.entries(config)) {
            if (state[key] !== undefined) { // Check if the key exists in the state
                draft[key] = fields.reduce((acc: any, field: any) => {
                    if (field in state[key]) {
                        acc[field] = state[key][field];
                    }
                    return acc;
                }, {});
            }
        }
    });
};
