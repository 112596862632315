import {ReactElement} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Card} from "@panwds/react-ui";
import CircularLoader from "../../components/CircularLoader/CircularLoader";
import logo from "../../images/panw_logo.png";
import {Title} from "../styles";

interface CustomCardType {
    children: ReactElement | ReactElement[],
    loading: boolean,
    title: string,
}


const useStyles = makeStyles((theme) => ({
    customCard: {
        margin: "auto",
        display: "flex",
        padding: "55px 32px 45px",
        overflow: "visible",
        minWidth: "386px",
        background: "#F9F9F9",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
        minHeight: "290px",
        borderRadius: "5px",
        flexDirection: "column",
    },
    avatar: {
        paddingBottom: "12px",
        display: "flex",
        justifyContent: "center",
    },
    logo: {

    },
    loadingBlur: {
        filter: "blur(1px)",
    },
}));

const AuthCustomCard = ({children, loading, title}: CustomCardType) => {
    const classes = useStyles();
    return (
        <Card className={classes.customCard}>
            <CircularLoader loading={loading} />
            <div className={loading ? classes.loadingBlur : ""}>
                <div className={classes.avatar}>
                    <img src={logo} alt="logo" className={classes.logo} />
                </div>
                {/* @ts-expect-error Server Component */}
                <Title>{title}</Title>
                {children}
            </div>
        </Card>
    );
};

export default AuthCustomCard;
