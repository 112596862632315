import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import { kebabCase } from 'lodash';
import { makeStyles } from "@material-ui/core";
import { toast } from '../../../components';
import { usePermissions } from '../../../customHooks';
import {useTranslate} from '../../../customHooks';
import { dataProvider } from '../../../dataProvider';
import { nameStyleCursor } from '../../../layout/styles';
import { Sheet, SheetBody, Button } from '@panwds/react-ui';
import { PANWDSTable } from '../../../components/PANWDSElements';

const useStyles = makeStyles(() => {
  return ({
    appColumnStyles: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100px'
    },
    tagNameHeader: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '20px',
      color: '#333333',
    },
    tagName: {
      background: '#F4F5F5',
      border: '1px solid #DADBDB',
      borderRadius: '4px',
      fontSize: '12px',
      height: '12px',
      padding: '12px 0 12px 8px',
      color: '#333333',
    },
    sheetContainer: {
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100vh",
      display: "flex",
    },
    footer: {
      justifyContent: "flex-end",
      display: "flex",
      padding: '16px 0',
      borderTop: "1px solid #DADBDB",
      "& button": {
        marginRight: "16px",
        padding: "16px",
      },
    },
  });
});

const AssociatedTagsIPsList = ({ isOpenTagIps, setOpenTagIps, clickedDetails, iPPrefixCallbackInvoked }: any) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const nameClass = nameStyleCursor();
    const [loading, setLoading] = useState(true);
    const [tagIPsData, setTagIPsData] = useState<never[]>([]);

    const loadAssociatedTagsIPsList = (needRefresh = false, accountId: any, vpcId: any, tagName: any) => {
      if (!tagIPsData.length || needRefresh) {
        if (!tagIPsData.length || needRefresh) {
          setTagIPsData([]);
          setLoading(true);
        }
        dataProvider.getList("vpcTagIPs", { data: { accountId, vpcId, tagName }})
          .then((response) => {
            if (response?.data) {
              // @ts-ignore
              setTagIPsData(getTagsPrefixFormat(response?.data));
            } else {
              toast.error(response, { toastId: `tags-list-error` });
            }
          })
          .catch((error: any) => {
            if (error?.noToast) {
              return;
            }
            toast.error(error?.error, { toastId: `tags-list-error` });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
      }
  };

  const getTagsPrefixFormat = (tagsPrefixData: any) => {
   let formatPrefixData: any = [];
   const account = tagsPrefixData?.AccountId;
   const vpcid = tagsPrefixData?.VpcId;
   tagsPrefixData?.Prefixes?.forEach((acc_vpc: any) => {
      formatPrefixData.push({
            IpPrefix: acc_vpc,
            Account: account,
            VpcId: vpcid
      });
   });
   return formatPrefixData;
  }

    useEffect(() => {
      loadAssociatedTagsIPsList(false, clickedDetails.account, clickedDetails.vpcid, clickedDetails.tagName);
    }, []);

    const onIpPrefixClicked = useCallback((rowProps: any, event: any) => {
      iPPrefixCallbackInvoked(rowProps);
    }, []);

    const getTagIPsColumns = () => [
      {
        accessor: 'IpPrefix',
        Header: translate(`resources.inventory.tagIPs.ipAddress`),
        columnSize: 6,
        render: ({ row }: any) => {
          return (
            <span
              key={`tagips-record-${kebabCase(row?.original?.IpPrefix?.toLowerCase())}`}
              className={classes.appColumnStyles}
              data-test-id={`tagips-record-${kebabCase(row?.original?.IpPrefix?.toLowerCase())}`}
            >
              {row?.original?.IpPrefix}
            </span>
          )
        }
      },
      {
        accessor: 'Action',
        Header: translate(`resources.inventory.tagIPs.action`),
        columnSize: 6,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-record-${kebabCase(row?.original?.VpcId)}`}
              className={nameClass.blueColor}
              data-test-id={`tags-record-${kebabCase(row?.original?.VpcId)}`}
              onClick={(evt) => onIpPrefixClicked(row, evt)}
            >
              {translate(`resources.inventory.tagIPs.checkAssociatedTags`)}
            </span>
          )
        }
      },
  ];

  return (
      <>
        <Sheet
          onClose={() => setOpenTagIps(false)}
          title={translate(`resources.inventory.tagIPs.tagDetails`)}
          isOpen={isOpenTagIps}
          showMask={true}
          position="md"
        >
          <div className={classes.sheetContainer}>
          <SheetBody isLoading={loading}>
            <div className={classes.tagNameHeader}>{translate(`resources.inventory.tags.tagName`)}</div>
            <div className={classes.tagName}>{clickedDetails.tagName}</div>
            <PANWDSTable
              permissions={permissions}
              columns={getTagIPsColumns()}
              gridData={tagIPsData}
              loading={loading}
              title={`${translate(`resources.inventory.tagIPs.associatedIpAddresses`)} (${tagIPsData?.length})`}
              searchFilterRequired={true}
              showToolbar={true}
              idProperty={"TagIPs"}
              showTileTitle
              emptySubtitle={translate(`resources.inventory.tagIPs.noassociatedIpAddresses`)}
              singleSelect={false}
              multiSelect={false}
              dataTestId="tagsIP-table"
              tableInnerContainerClassName="tagsIP-table"
              infiniteScroll={false}
              enablePagination={true}
              rowSize="standard"
              tableContainerStyle = {{
                display: "flex",
                flexFlow: "column",
                padding: '16px 0 16px 0',
                boxSizing: 'border-box',
                overflowX: 'hidden',
                overflowY: 'hidden'
              }}
            />
          </SheetBody>
          <div className={classes.footer}>
            <Button
              key="tags-IPs-table-button"
              appearance="secondary"
              size="sm"
              buttonPosition="right"
              data-metrics="cngfw-tags-IPs-table-button"
              //@ts-ignore
              onClick={() => setOpenTagIps(false)}
            >
              {translate(`resources.inventory.close`)}
            </Button>
          </div>
          </div>
        </Sheet>
      </>
  );
}

export default withRouter(AssociatedTagsIPsList);
