import {
  Box, Button as MuiButton, ClickAwayListener, Grow, IconButton, makeStyles, MenuItem, MenuList, Paper, Popper, Typography,
  InputBase, InputAdornment
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState, useRef } from "react";
import { IPANToolbar } from "../../types";
import { Button, PANModal } from "../../components";
import { useTranslate } from "../../customHooks";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderTop: '1px solid #DADBDB',
    borderLeft: '1px solid #DADBDB',
    borderRight: '1px solid #DADBDB',
    borderRadius: '4px 4px 0px 0px',
    background: '#fff',
    padding: 16,
    [theme.breakpoints.down(750)]: {
      padding: "16px 5px",
    },
  },
  boxLayout: (props: any) => ({
    display: "flex",
    padding: "0",
    [theme.breakpoints.down(800)]: {
      display: props.nested ? "block" : "flex",
    },
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
    margin: 0,
  }),
  ButtonContainer: (props:any) => ({
    display: "flex",
    gap: theme.spacing(2),
    lex: '0 0 50%',
    [theme.breakpoints.down(props.nested ? 900:800)]: {
      gap: "2px",
      lex: '0 0 55%',
    },
    [theme.breakpoints.down(800)]: {
      padding: props.nested ? "5px 0" : "0",
      gap: "2px",
    },
  }),
  title: {
    color: "#333333",
    fontSize: 16,
  },
  description: {
    color: "#707070",
    fontWeight: 400,
    fontSize: 12,
    marginTop: 6,
  },
  SelectContainer: {
    position: "relative",
    display: "flex",
  },
  TitleBox: {
    display: "flex",
    flexDirection: "column",
    flex: '0 0 40%',
  },
  DropDownButton: {
    borderRadius: "4px",
    border: "1px solid #DADBDB",
    padding: "6px 16px",
    color: "#006FCC",
    background: "#FFFFFF",
    textTransform: 'capitalize',
    [theme.breakpoints.down(750)]: {
      padding: "6px 1px 6px 3px",
      "&>span": {
        justifyContent: 'center',
      }
    },
  },
  button: {
    textTransform: 'capitalize',
  },
  SearchBarStyle: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: "4px",
    border: "1px solid #DADBDB",
    padding: "0 5px",
    background: "#FFFFFF",
  },
  SearchBarIconStyle: {
    fontSize: '1rem'
  }
}));

export const PANToolbar = ({
  description,
  refresh,
  title,
  dropDownActionsArray,
  searchBar = {
    show: false,
    onChange: () => {},
    disabled: true,
    text: 'Search'
  },
  nested,
  selectedItems,
}: IPANToolbar) => {
  const classes = useStyles({nested});
  const translate = useTranslate();

  const [dropdownsState, setDropdownsState] = useState<boolean[]>([]);
  useEffect(() => {
    let states = [];
    for (let dda in dropDownActionsArray) {
      states.push(false);
    }
    setDropdownsState(states);
  }, [dropDownActionsArray]);
  const buttonElActions = useRef(new Array(dropDownActionsArray?.length));

  const toogleDropdowns = (index: number, value?: boolean) => {
    let states: boolean[] = [];
    dropdownsState.map(dd => states.push(dd));
    states[index] = !states[index];
    setDropdownsState(states);
  }

  const [confirmDelete, setConfirmDelete] = useState<any>({open: false});
  const showConfirmModal = (type: string, callback: Function) => {
    if (type === 'delete'){
      setConfirmDelete({open: true, callback});
    }
  };

  /* Pan Modal not being used, so not changing this file */
  const ConfirmDeleteModal = <PANModal
    handleClose={() => setConfirmDelete({open: false})}
    open={confirmDelete.open}
    title={translate("toolbar.modal.confirmDeleteTitle")}
    maxWidth="xs"
    dismissButton={<Button
      variant="contained"
      color="secondary"
      onClick={() => setConfirmDelete({open: false})}
      label={translate("common.cancel")}
    />}
    submitButton={
      <Button
        variant="contained"
        label={translate("common.confirm")}
        onClick={() => {
          confirmDelete.callback(selectedItems);
          setConfirmDelete({open: false});
        }}
      />
    }
  >
    {translate("toolbar.modal.confirmDescription")}
  </PANModal>;

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxLayout}>
      <Box className={classes.TitleBox} data-test-id="grid-toolbar-header">
      {title && <Typography className={classes.title} data-test-id="grid-title">{title}</Typography>}
      </Box>
      <section className={classes.ButtonContainer}>
        {searchBar.show && (
          <InputBase
            disabled={searchBar.disabled}
            className={classes.SearchBarStyle}
            placeholder={searchBar.text}
            onChange={searchBar.onChange}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon className={classes.SearchBarIconStyle} />
              </InputAdornment>
            }
            defaultValue={searchBar.defaultValue!}
          />
        )}
        {refresh && (
          <IconButton onClick={() => refresh()} size="small" color="secondary">
            <RefreshIcon />
          </IconButton>
        )}
        {dropDownActionsArray && dropDownActionsArray.length > 0 &&
          dropDownActionsArray.map((dropDownActions, ddIndex) => {
            const ddType = dropDownActions.type ?? 'secondary';
            if (["button", "button_secondary"].includes(ddType)) {
              return (
                <MuiButton
                  className={classes.DropDownButton}
                  size="large"
                  variant={ddType === "button"? "contained"  : "text"}
                  color="primary"
                  disabled={dropDownActions.disabled}
                  //@ts-ignore
                  onClick={() => dropDownActions.handleButtonAction()}
                  key={ddIndex}
                  >
                    {dropDownActions.title}
                </MuiButton>
              );
            }
            return (
            <Box className={classes.SelectContainer} key={ddIndex}>
              <MuiButton
                color="primary"
                size="small"
                ref={ (el: never) => buttonElActions.current[ddIndex] = el }
                aria-controls={dropdownsState[ddIndex] ? "split-button-menu" : undefined}
                aria-expanded={dropdownsState[ddIndex] ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                variant={ddType === 'primary'? "contained" : 'text'}
                // variant={'text'}
                onClick={(e) => {
                  toogleDropdowns(ddIndex);
                  e.stopPropagation()
                }}
                className={classes.DropDownButton}
              >
                {dropDownActions.title}
                <KeyboardArrowDown />
              </MuiButton>
              <Popper
                open={!!dropdownsState[ddIndex]}
                role={undefined}
                transition
                disablePortal
                anchorEl={buttonElActions.current[ddIndex]}
                style={{
                  position: "absolute",
                  zIndex: 100,
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper elevation={2}>
                      <ClickAwayListener onClickAway={() => toogleDropdowns(ddIndex)}>
                        <MenuList style={{ padding: 0, borderRadius: 4, }} id="split-button-menu">
                          {dropDownActions.actionsMap.map(
                            ({ menuText, handleAction, confirmModal }, index) => (
                              <MenuItem
                                key={index}
                                onClick={(event) => {
                                  toogleDropdowns(ddIndex);
                                  if (confirmModal){
                                    showConfirmModal(confirmModal, handleAction);
                                  }else{
                                    handleAction(selectedItems);
                                  }
                                }
                                }
                              >
                                {menuText}
                              </MenuItem>
                            )
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          )})
        }

        </section>
      </Box>
      {description && <Typography className={classes.description} data-test-id="grid-description">{description}</Typography>}
      { confirmDelete.open && ConfirmDeleteModal}
    </Box>
  );
};
