import { useTranslate } from "../../customHooks";
import { makeStyles } from "@material-ui/core";
import { FilterBar } from "@panwds/react-filters";
import { useState } from "react";
import { ISearchBarFilters, IDropDownActions } from "../../types";
import SaveButton from "../Buttons/SaveButton";
import PANWDSButtonDropdown from "./PANWDSButtonDropdown";
import { Button as PANWDSButton } from "@panwds/react-ui";
import { PANWDSModal } from "./PANWDSModal";
import { ConnectedTableFilter, useSelectedRowData } from "@panwds/react-table";
import _, { isEmpty } from "lodash";

interface TableToolBarProps {
    searchFilterRequired: boolean;
    filterBar?: ISearchBarFilters;
    dataTestId: string;
    dropDownActionsArray: IDropDownActions[];
    disableActionsBtn?: boolean | Function;
}

const useStyles = makeStyles((theme) => ({
    ButtonContainer: (props: any) => ({
        display: "flex",
        gap: theme.spacing(1),
        lex: "0 0 50%",
        [theme.breakpoints.down(800)]: {
            gap: "2px",
            lex: "0 0 55%",
        },
        [theme.breakpoints.down(800)]: {
            padding: "0",
            gap: "2px",
        },
    }),
    styleFiletrsCointainerOnlySearch: {
        border: "none",
        "&>div": {
            "&>div": {
                "&>div": {
                    padding: "0",
                    "&>div": {
                        display: "none",
                    },
                    "&>span": {
                        "&>span": {
                            "&>span": {
                                "&>div": {
                                    "&>span": {
                                        "&>button": {
                                            display: "none",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
}));

export const TableToolBar = ({
    searchFilterRequired,
    filterBar = undefined,
    dataTestId = "",
    dropDownActionsArray = [],
    disableActionsBtn,
}: TableToolBarProps) => {
    const selectedItems = useSelectedRowData();
    const classes = useStyles({});
    const translate = useTranslate();
    const [confirmDelete, setConfirmDelete] = useState<any>({ open: false });

    const showConfirmModal = (type: string, callback: Function) => {
        if (type === "delete") {
            setConfirmDelete({ open: true, callback });
        }
    };

    const checkDisableActions = (selectedRows: any) => {
        if (selectedRows.length === 0) {
            return true;
        }
        return (
            (disableActionsBtn &&
                typeof disableActionsBtn === "function" &&
                disableActionsBtn(selectedRows)) ||
            (typeof disableActionsBtn === "boolean" && disableActionsBtn)
        );
    };

    const ConfirmDeleteModal = (
        <PANWDSModal
            title={translate(`panwdsTable.confirmDelete`)}
            onClose={() => setConfirmDelete({ open: false })}
            isOpen={confirmDelete.open}
            size="sm"
            dataTestId="cloudngfw-panwds-delete-confirm-modal"
            dataMetrics="cloudngfw-panwds-delete-confirm-modal"
            modalBody={translate(`panwdsTable.sureDelete`)}
            modalBodyStylingRequired
            modalFooter={{
                footerRequired: true,
                cancelButtonRequired: true,
                featureSpecificButton: (
                    <SaveButton
                        appearance="primary"
                        size="md"
                        label={translate(`panwdsTable.confirm`)}
                        onClick={() => {
                            confirmDelete.callback(selectedItems);
                            setConfirmDelete({ open: false });
                        }}
                        dataMetrics="cloudngfw-panwds-delete-confirm-modal-button"
                    />
                ),
            }}
        />
    );

    return (
        <>
            {searchFilterRequired && <ConnectedTableFilter />}
            {filterBar?.filterBarRequired &&
                filterBar?.onlySearchFilterBarRequired && (
                    <div
                        className={classes.styleFiletrsCointainerOnlySearch}
                        key={dataTestId}
                    >
                        <FilterBar
                            key={dataTestId}
                            name={"FilterAndTable"}
                            filters={
                                filterBar?.filterConfig
                                    ? filterBar?.filterConfig
                                    : []
                            }
                            saveOnUnmount={false}
                        />
                    </div>
                )}
            <section className={classes.ButtonContainer}>
                {dropDownActionsArray &&
                    dropDownActionsArray?.length > 0 &&
                    dropDownActionsArray?.map((dropDownActions, ddIndex) => {
                        const ddType = dropDownActions.type ?? "secondary";
                        if (["button", "button_secondary"].includes(ddType)) {
                            return (
                                <PANWDSButton
                                    key={ddIndex}
                                    appearance={
                                        dropDownActions?.appearance
                                            ? dropDownActions?.appearance
                                            : "secondary"
                                    }
                                    size="md"
                                    disabled={
                                        dropDownActions?.disabledAddLogic
                                            ? isEmpty(selectedItems)
                                            : dropDownActions.disabled
                                    }
                                    data-metrics={
                                        dropDownActions?.dataMetrics || ""
                                    }
                                    //@ts-ignore
                                    onClick={() =>
                                        dropDownActions?.handleButtonAction(
                                            selectedItems
                                        )
                                    }
                                >
                                    {dropDownActions.title}
                                </PANWDSButton>
                            );
                        }
                        return (
                            <PANWDSButtonDropdown
                                key={ddIndex}
                                buttonTitle={dropDownActions.title}
                                items={dropDownActions?.actionsMap?.map(
                                    (
                                        {
                                            menuText,
                                            handleAction,
                                            confirmModal,
                                            dataMetrics,
                                        },
                                        index
                                    ) => {
                                        return {
                                            value: menuText,
                                            context: {
                                                handleAction,
                                                confirmModal,
                                                dataMetrics,
                                            },
                                        };
                                    }
                                )}
                                disabled={
                                    dropDownActions.type === "primary"
                                        ? false
                                        : checkDisableActions(selectedItems)
                                }
                                appearance={
                                    dropDownActions?.appearance
                                        ? dropDownActions?.appearance
                                        : "secondary"
                                }
                                dataMetrics={dropDownActions?.dataMetrics || ""}
                                onChange={(e: any) => {
                                    const {
                                        selectedItem: {
                                            context: {
                                                confirmModal,
                                                handleAction,
                                            },
                                        },
                                    } = e;
                                    if (confirmModal) {
                                        showConfirmModal(
                                            confirmModal,
                                            handleAction
                                        );
                                    } else {
                                        handleAction(selectedItems);
                                    }
                                }}
                            />
                        );
                    })}
                {confirmDelete.open && ConfirmDeleteModal}
            </section>
        </>
    );
};
