import { makeStyles } from "@material-ui/core";
import { PANFieldContainer, PANHelpText, PANInputStyle, PANLabelStyle, PANMutedText } from "./styles";

const useStyles = makeStyles((theme) => ({
  error: {
    border: '1px solid #D13C3C',
    borderRadius: 4,
  },
  errorText: {
    color: "#D13C3C",
  }
}));

export const PANTextField = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {

  const existerror = !!(touched && error);

  return (
  <PANInput {...props} {...inputProps} existerror={existerror} error={error} />
)};


export const PANInput = (props: any) => {

  const classes = useStyles();

  return (
    <PANFieldContainer>
    {props.label && <PANLabelStyle shrink htmlFor={props.label} className={props?.existerror ? classes.errorText : ''}>
      {props.label} {props.required && (<strong className={classes.errorText}>*</strong>)}
    </PANLabelStyle>}
    <PANInputStyle
      id={props?.label ?? ''}
      className={props?.existerror ? classes.error : ''}
      {...props}
      existerror={props?.existerror ? "true" : undefined }
      error={props?.error ? true : false}
      fullWidth
    />

    {props?.muted && (<PANMutedText>{props.muted}</PANMutedText>)}
    {
      props?.existerror && !props?.hideError && (<PANHelpText id={props.label} >{props.error}</PANHelpText>)
    }
  </PANFieldContainer>
  )
}
