import {Route} from "react-router-dom";
import {RouteUri} from "../routeUri";
import CspCallback from "../layout/CspCallback";
import React from "react";

export const CallbackRoutes = [
    <Route exact
           path={RouteUri.CspCallback}
           render={() => (
               <CspCallback/>
           )}
    />,
];
