import { useCallback, useEffect, useState } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from '../../components';
import { PANWDSTable } from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import { nameStyleCursor } from '../../layout/styles';
import { RouteUri } from '../../routeUri';
import {onSearchChangesUtils} from "../../utils/search";
import { Tooltip } from '@panwds/react-ui';
import get from 'lodash/get';

const FeedList = (props: any) => {
  const nameClass = nameStyleCursor();
  const translate = useTranslate();
  const history = useHistory();
  const title = translate(`resources.feed.name`);
  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken]: any = useState();
  const lockPredefined: { position: string; render: ({ column }: any, computedProps: any) => never; }[] | undefined = [];
  const { permissions } = usePermissions();
  const PREDEFINED_LENGTH = 5;

  const [searchData, setSearchData] = useState([]);
  // @ts-ignore
  const [gridRef, setGridRef] = useState({
    current: {
      columns: []
    },
  });

  const deleteAction = (selected: any) => {
    if (!permissions?.DeleteIntelligentFeed) {
      toast.warning(translate("permissions.cantExecute"));
      return;
    }
    if (selected && Array.isArray(selected)) {
      setLoading(true);
      let calls:any = [];
      selected.map((item: any) => {
        if (item.Name) {
          calls.push(dataProvider.delete("feed", { id: item.Name, previousData: item }));
        }
        return item;
      });
      Promise.all(calls)
      .then((responses) => toast.success(translate(`resources.feed.delete.success`)))
      .catch((e: any) => toast.error(e?.error, { toastId: "feed-delete-error" }))
      .finally(() => {
        setLoading(false);
        loadGridData(true);
      });
    }
  }

  const getcolumns = () => [
    {
      accessor: 'Name',
      Header: translate(`resources.feed.fields.Name`),
      columnSize: 2,
      render: ({ row }: any) => <span
        className={nameClass.blueColor}
        onClick={(evt) => onRowClick(row, evt)}>
          {row?.original?.Name}
      </span>,
    },
    {
      accessor: 'FeedCandidate.Type',
      Header: translate(`resources.feed.fields.Type`),
      columnSize: 1,
      render: ({ row }: any) => {
        if (row?.original?.FeedCandidate) {
          return <span>{row?.original?.FeedCandidate?.Type}</span>
        }
        return null;
      },
    },
    {
      accessor: 'FeedCandidate.FeedURL',
      Header: translate(`resources.feed.fields.FeedURL`),
      columnSize: 3,
      noTooltip: true,
      render: ({ row }: any) => {
        if (row?.original?.FeedCandidate) {
          return (
            <Tooltip label={row?.original?.FeedCandidate?.FeedURL}>
                <span>{row?.original?.FeedCandidate?.FeedURL}</span>
            </Tooltip>
          )
        }
        return null;
      },
    },
    {
      accessor: 'FeedCandidate.Frequency',
      Header: translate(`resources.feed.fields.Frequency`),
      columnSize: 1,
      defaultFlex: 2,
      render: ({ row }: any) => {
        if (row?.original?.FeedCandidate) {
          return <span>{row?.original?.FeedCandidate?.Frequency}</span>
        }
        return null;
      },
    },
    {
      accessor: 'FeedCandidate.Description',
      Header: translate(`resources.feed.fields.Description`),
      columnSize: 5,
      noTooltip: true,
      render: ({ row }: any) => {
        if (row?.original?.FeedCandidate?.Description) {
          return (
            <Tooltip label={row?.original?.FeedCandidate?.Description}>
              <span>{row?.original?.FeedCandidate?.Description}</span>
            </Tooltip>
          )
        }
        return null;
      },
    }
  ];

  const loadGridData = (needRefresh = false) => {
    if (!permissions?.ListIntelligentFeed) {
      if (permissions && !permissions?.ListIntelligentFeed) {
        // TODO toast is not showing, but if we add toast container it will show
        // many times until permissions is loaded, need to rethink this logic
        toast.warning(translate("permissions.cantView"));
      }
      setLoading(false);
      return;
    }
    if ((gridData.length && nextToken) || !gridData.length || needRefresh) {
      if (!gridData.length || needRefresh) {
        setLoading(true);
      }
      dataProvider.getList("feed", {
        data: { RuleStackName: ruleStackName, NextToken: (nextToken) ? nextToken : undefined }
      }).then(async (response: any) => {
        if (response.data) {
          //@ts-ignore
          let responseObj = response.data;
          if (needRefresh) {
            setGridData(responseObj);
          } else {

            // TODO remove after BE fix duplicate predefined issue
            if (nextToken) { // not first call
              responseObj = responseObj.slice(5);
            }
            // end TODO

            //@ts-ignore
            setGridData([...gridData, ...responseObj]);
          }
          setNextToken(response?.nextToken);
        } else {
          toast.error(response?.error, { toastId: "feed-getList" });
        }
      })
        .catch((error: any) => {
          if (error?.noToast) {
            return;
          }
          toast.error(error?.error, { toastId: "feed-getList" });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  // set predefined rows (fixed possitioned)
  gridData.slice(0,PREDEFINED_LENGTH).forEach((element, index, array) => lockPredefined.push({
    position: 'start',
    render: ({column}:any, computedProps:any) => get(element, column.name)
  }));

  useEffect(() => {
    return () => {
      dataProvider.abort('feed');
    }
  }, []);

  const onRowClick = useCallback((rowProps, event) => {
    if (!permissions?.DescribeIntelligentFeed) {
      return;
    }
    history.push(
      RouteUri.FeedEdit.replace(":rulestackname", ruleStackName)
        .replace(":feedname", rowProps.original.Name));
  }, [history, permissions]);

  const getGridDataValue = (name: string, data: any) => {
    let value = get(data, name);
    if (value) return value + '';
    return '';
  }

  // @ts-ignore
  const onSearchBarChange = ({value}) => {
    onSearchChangesUtils({
      columns: gridRef?.current?.columns,
      value,
      gridData: gridData.slice(PREDEFINED_LENGTH),
      getGridDataValue,
      callback: (data: any) => {
        setSearchData(data);
      }
    })
  };

  const toolbarActions: any[] = [];
  if(permissions?.DeleteIntelligentFeed){
    toolbarActions.push({
      title: translate(`common.actions`),
      dataMetrics: "cloudngfw-feed-actions-btn",
      actionsMap: [{ menuText: translate(`common.delete`), handleAction: deleteAction, dataMetrics: "cloudngfw-intelligent-feed-delete-btn" },]
    });
  }
  if(permissions?.CreateIntelligentFeed){
    toolbarActions.push({
      title: translate(`resources.feed.fields.CreateButton`),
      actionsMap: [],
      dataMetrics: "cloudngfw-intelligent-feed-create-btn",
      handleButtonAction: () => history.push(RouteUri.FeedCreate.replace(":rulestackname", ruleStackName)),
      type: 'button',
      appearance: 'primary'
    });
  }

  return (
    <>
      <PANWDSTable
        setHandle={setGridRef}
        loadGridData={loadGridData}
        infiniteScroll={false}
        overflowTable={true}
        permissions={permissions}
        columns={getcolumns()}
        gridData={gridData}
        showSelectGroupBy={false}
        title={title}
        subtitle={translate(`resources.feed.fields.ListSubtitle`)}
        idProperty={"RuleStackName"}
        showToolbar={true}
        dropDownActionsArray={toolbarActions}
        showTileTitle={true}
        emptyTitle={translate(`resources.feed.fields.EmptyTitle`)}
        emptySubtitle={translate(`resources.feed.fields.EmptySubtitle`)}
        loading={loading}
        isBackgroundFetching={nextToken}
        singleSelect={false}
        multiSelect={true}
        lockedRows={lockPredefined}
        offsetTableHeight={305}
        tableMinHeight={550}
        useOldToolbarButtons={true}
        searchFilterRequired={true}
        dataMetrics="cloudngfw-feed-table"
        dataTestId="cloudngfw-feed-table"
      />
    </>
  );
};

export default withRouter(FeedList);
