import {  FormControl } from "@material-ui/core";
import { PANWDSInput } from "../components/PANWDSElements";
import { Button, Card } from "@panwds/react-ui";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Field, withTypes } from "react-final-form";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "../components";
import logo from "../images/panw_logo.png";
import { RouteUri } from "../routeUri";
import { Background, Title } from "./styles";
import { lightTheme } from "./themes";
import "./CustomPanwdsCard.css";
import CircularLoader from "../components/CircularLoader/CircularLoader";
import { isBlockedDomain, isEmail, isRequired, composeValidators } from "../utils/validate";
import {useTranslate} from '../customHooks';
import {pendoInitialize, pendoUpdate} from "./Pendo";
import { cognitoProvider } from "../authProvider";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    padding: "15px",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  formControl: {
    marginTop: "1em",
    width: "100%",
  },
  title: {
    fontWeight: 400,
    fontSize: 24,
    color: "#333333",
    textAlign: "center",
    paddingTop: 20,
  }
}));

interface ForgotPasswordFormValues {
  email?: string;
  oldPassword?: string;
  password?: string;
  reEnterPassword?: string;
}

const { Form } = withTypes<ForgotPasswordFormValues>();

const ForgotPassword = (props: any) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const [ForgotPasswordFailed, setForgotPasswordFailed] = useState(true);

  const handleSubmit = (auth: ForgotPasswordFormValues) => {
    setLoading(true);
    setForgotPasswordFailed(false);

    cognitoProvider
      .forgotPassword(auth)
      .then((response: any) => {
        pendoUpdate({UserName: auth.email});
        setForgotPasswordFailed(false);
      })
      .catch((error: Error) => {
        setLoading(false);
        toast.error(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message
        );
      });
  };

  const [userName, setUserName] = useState<any>();

  useEffect(() => {
    setUserName(localStorage.getItem("reset"));
    pendoInitialize({});
  }, []);

  const validate = (values: ForgotPasswordFormValues) => {
    const errors: ForgotPasswordFormValues = {};
    if (!values.email) {
      errors.email = translate("validation.required");
    }
    return errors;
  };

  if (ForgotPasswordFailed) {
    return (
      <>
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Background>
                <Card className="customPanwdsCard">
                  <div className={classes.avatar}>
                    <img src={logo} alt="logo" className={classes.logo} />
                  </div>
                  <Title>{translate("forgotPassword.name")}</Title>
                  <div className={classes.form}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="email"
                        // @ts-ignore
                        component={PANWDSInput}
                        autoFocus={true}
                        title={translate("forgotPassword.email")}
                        required
                        disabled={loading}
                        dataMetrics="cloudngfw-forgot-password"
                        type="text"
                        initialValue={userName}
                        validate={composeValidators(
                          isRequired,
                          isBlockedDomain,
                          isEmail
                        )}
                      />
                    </FormControl>
                  </div>
                  <Card className="actions">
                    <Button
                      appearance="primary"
                      size="md"
                      color="primary"
                      type="submit"
                      disabled={loading}
                      data-metrics="cloudngfw-forgot-password-button"
                    >
                      {translate("forgotPassword.button")}
                    </Button>
                  </Card>
                </Card>
              </Background>
            </form>
          )}
        />
      </>
    );
  } else {
    return (
      <Background>
        <div className="customPanwdsCard">
          <div className={classes.avatar}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          <Title>
            {translate("forgotPassword.successTitle")}
            <p>{translate("forgotPassword.successMessage")}</p>
          </Title>
          <Card className="actions">
            <Button
              appearance="primary"
              size="md"
              color="primary"
              data-metrics="cloudngfw-forgot-password-success-button"
              onClick={() => history.push(RouteUri.ConfirmPassword)}
            >
              {translate("confirmPassword.name")}
            </Button>
          </Card>
        </div>
      </Background>
    );
  }
};

ForgotPassword.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in ForgotPassword won't get
// the right theme
const ForgotPasswordWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <ForgotPassword {...props} />
  </ThemeProvider>
);

export default withRouter(ForgotPasswordWithTheme);
