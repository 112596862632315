import { useCallback, useEffect, useState } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from '../../components';
import { PANWDSTable } from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import { nameStyleCursor } from '../../layout/styles';
import { RouteUri } from '../../routeUri';
import {onSearchChangesUtils} from "../../utils/search";
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from '@panwds/react-ui';
import get from 'lodash/get';

const FQDNList = (props: any) => {
  const nameClass = nameStyleCursor();

  const translate = useTranslate();
  const history = useHistory();
  const { permissions } = usePermissions();
  const title = translate(`resources.fqdn.name`);

  const [gridData, setGridData] = useState([]); const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken]: any = useState();

  const [searchData, setSearchData] = useState([]);
  // @ts-ignore
  const [gridRef, setGridRef] = useState({
    current: {
      columns: []
    },
  });

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  const loadGridData = (needRefresh=false) => {
    if (!permissions?.ListFqdnList) {
      if (permissions && !permissions?.ListFqdnList) {
        // TODO toast is not showing, but if we add toast container it will show
        // many times until permissions is loaded, need to rethink this logic
        toast.warning(translate("permissions.cantView"));
      }
      setLoading(false);
      return;
    }
    if ((gridData.length && nextToken) || !gridData.length || needRefresh) {
      if (!gridData.length || needRefresh) {
        setLoading(true);
      }
      dataProvider.getList("fqdn", {
        pagination: { page: 1, perPage: 5 },
        sort: { field: 'Name', order: 'ASC' },
        filter: {},
        data: { RuleStackName: ruleStackName, NextToken: (nextToken) ? nextToken : undefined }
      }).then(async (response: any) => {
        if(response.data){
        //@ts-ignore
        let responseObj = response.data;
        if (needRefresh){
          setGridData(responseObj);
        } else {
          //@ts-ignore
          setGridData([...gridData, ...responseObj]);
        }
        setNextToken(response?.nextToken);
        }else{
          toast.error(response?.error, { toastId: "fqdn-getList" });
        }
      })
      .catch((e:any) => {
        if (e?.noToast) {
          return;
        }
        toast.error(e?.error, { toastId: "fqdn-getList" });
      })
      .finally(() => setLoading(false));
    }
  }

  // useEffect(() => {
  //   loadGridData();
  // }, [nextToken, permissions]);

  useEffect(() => {
    return () => {
      dataProvider.abort('fqdn');
    }
  }, []);

  const onRowClick = useCallback((rowProps, event) => {
    if (!permissions?.DescribeFqdnList) {
      return;
    }
    history.push(
      RouteUri.FQDNEdit.replace(":rulestackname", ruleStackName).replace(":fqdnname", rowProps.original.Name));
  }, [history, permissions]);

  const getcolumns = () => [
    {
      accessor: 'Name',
      Header: translate(`resources.fqdn.fields.Name`),
      columnSize: 2,
      render: ({ row }: any) => <span
        className={nameClass.blueColor}
        onClick={(evt) => onRowClick(row, evt)}>
          {row?.original?.Name}
      </span>,
    },
    {
      accessor: 'FqdnListCandidate.FqdnList',
      Header: translate(`resources.fqdn.fields.FqdnList`),
      columnSize: 5,
      noTooltip: true,
      render: ({ row }: any) => {
        if (row?.original?.FqdnListCandidate?.FqdnList) {
          const list = row?.original?.FqdnListCandidate?.FqdnList.map((entry: any) => {
            return entry
          }).join(", ");
          return <Tooltip label={list}>
            <span>{list}</span>
          </Tooltip>
        }
        return null;
      },
    },
    {
      accessor: 'FqdnListCandidate.Description',
      Header: translate(`resources.fqdn.fields.Description`),
      columnSize: 5,
      render: ({ row }: any) => {
        if (row?.original?.FqdnListCandidate?.Description) {
          return <span>{row?.original?.FqdnListCandidate?.Description}</span>
        }
        return null;
      },
    }
  ];

  const deleteAction = (selected: any) => {
    if (!permissions?.DeleteFqdnList) {
      toast.warning(translate("permissions.cantExecute"));
      return;
    }
    if (selected && Array.isArray(selected)) {
      let calls: any = [];
      setLoading(true);
      selected.map((item: any) => {
        if (item.Name) {
          calls.push(dataProvider.delete("fqdn", { id: item.Name, previousData: {...item, RuleStackName: ruleStackName} }));
        }
        return item;
      });
      Promise.all(calls)
        .then((responses) => toast.success(translate(`resources.fqdn.delete.success`)))
        .catch((e) => toast.error(e?.error, { toastId: "fqdn-delete-error" }))
        .finally(() => {
          setLoading(false);
          loadGridData(true);
        });
    }
  }

  const getGridDataValue = (name: string, data: any) => {
    let value = '';
    if (name === 'FqdnListCandidate.FqdnList') {
      const lists = get(data, name, []);
      value = lists ? JSON.stringify(lists) : ''
    } else {
      value = get(data, name);
    }
    if (value) return value + '';
    return '';
  }

  // @ts-ignore
  const onSearchBarChange = ({value}) => {
    onSearchChangesUtils({
      columns: gridRef?.current?.columns,
      value,
      gridData,
      getGridDataValue,
      callback: (data: any) => {
        setSearchData(data);
      }
    })
  };

  const toolbarActions: any[] = [];
  if(permissions?.DeleteFqdnList){
    toolbarActions.push({
      title: translate(`common.actions`),
      dataMetrics: "cloudngfw-fqdn-actions-btn",
      actionsMap: [{ menuText: translate(`common.delete`), handleAction: deleteAction, dataMetrics: "cloudngfw-fqdn-delete-btn" },]
    });
  }
  if(permissions?.CreateFqdnList){
    toolbarActions.push({
      title: translate(`resources.fqdn.fields.CreateButton`),
      dataMetrics: "cloudngfw-fqdn-create-btn",
      actionsMap: [],
      handleButtonAction: () => history.push(RouteUri.FQDNCreate.replace(":rulestackname", ruleStackName)),
      type: 'button',
      appearance: 'primary'
    });
  }

  return (
    <>
      <PANWDSTable
        setHandle={setGridRef}
        loadGridData={loadGridData}
        infiniteScroll={false}
        overflowTable={true}
        permissions={permissions}
        columns={getcolumns()}
        gridData={gridData}
        showSelectGroupBy={false}
        title={title}
        subtitle={translate(`resources.fqdn.fields.ListSubtitle`)}
        idProperty={"RuleStackName"}
        showToolbar={true}
        dropDownActionsArray={toolbarActions}
        showTileTitle={true}
        emptyTitle={translate(`resources.fqdn.fields.EmptyTitle`)}
        emptySubtitle={translate(`resources.fqdn.fields.EmptySubtitle`)}
        loading={loading}
        isBackgroundFetching={nextToken}
        singleSelect={false}
        multiSelect={true}
        useOldToolbarButtons={true}
        offsetTableHeight={305}
        tableMinHeight={550}
        searchFilterRequired={true}
        dataMetrics="cloudngfw-fqdn-table"
        dataTestId="cloudngfw-fqdn-table"
      />
    </>
  );
};

export default withRouter(FQDNList);
