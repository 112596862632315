import { Box, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { find, isEmpty } from 'lodash';
import {  useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import { Button as PANWDSButton, LoadingButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, toast } from '../../components';
import { Row } from '../../components/FormElements';
import { dataProvider } from '../../dataProvider';
import { validatePasswordRegex } from '../../utils/validate';
import {PANWDSInput, PANWDSModal, PANWDSBreadcrumbs, PANWDSSelectWithSearch} from '../../components/PANWDSElements';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { useTranslate } from '../../customHooks';
import {ReduxActions, ReduxResources} from "../../redux";
import {useAppDispatch} from "../../app/hooks";
import {ApplicationConfigManager} from "../../types";
import CircularLoader from '../../components/CircularLoader/CircularLoader';
import { useGetUserRolesAndPermissionsQuery } from '../../redux/services/user-roles-service';

export const PANMainContainer = withStyles(() => ({
  root: {
    background: '#F4F5F5',
    padding: '16px 16px 80px 16px',
    position: 'relative',
    height: '100%',
  },
}))(Box);


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    border: '1px solid #DADBDB',
    width: '100%',
  },
  media: {
    height: 140,
  },
  header: {
    border: '1px solid #DADBDB',
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
  },
}));

interface ChangePasswordFormValues {
  oldPassword?: string,
  password?: string,
  reEnterPassword?: string;
}

const UserEditToolbar = (toolbarProps: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const { pristine, submitting, valid, classes, handleSubmit, invalid, validating } = toolbarProps;
  return (
    <div className={classes.toolbar}>
      <PANWDSButton
        size="md"
        appearance="secondary"
        disabled={submitting}
        onClick={() => {
          history.goBack();
        }}
        dataMetrics="cloudngfw-users-edit-cancel"
      >
        {translate(`resources.users.fields.Cancel`)}
      </PANWDSButton>
      <LoadingButton
        dataResult={{
          loading: !!submitting,
          success: true
        }}
        size="md"
        appearance="primary"
        type="submit"
        disabled={submitting || pristine || !valid}
        dataMetrics="cloudngfw-users-edit-save"
      >
        {translate(`resources.users.fields.Save`)}
      </LoadingButton>
    </div>
  );
};

const UsersEdit = (props: any) => {
  const [record, setRecord] = useState<any>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const userName = decodeURIComponent(new URLSearchParams(search).get('username') || '');
  const { data: roleChoices, error: roleChoicesnError, isLoading: roleChoicesLoading } = useGetUserRolesAndPermissionsQuery();

  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const [open, setOpen] = useState(false)

  const handleRolesInputFormatter = (value: any) => {
    const values = value.split('/');
    let o: any = {};
    if (values.length > 1) {
      o.AccountId = values[0];
      o.Policy = values[1];
    } else {
      o.Policy = values[0];
    }
    return o;
  }

  const handleRolesValueFormat= (v: any) => {
    if (typeof v === 'string') {
      return v;
    }
    if(v.AccountId){
      return `${v.AccountId}/${v.Policy}`
    } else {
      return `${v.Policy}`
    }
  }

  useEffect(() => {
    dataProvider.describe('users', '', { UserName: userName }).then(async (res: any) => {
      if (res.data) {
        //@ts-ignore
        setRecord({ ...res.data });
      }
      else {
        toast.error(res?.error, { toastId: "users-describe" });
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "users-describe" });
      });
  }, []);

  const onSubmit = async (values: any, form: any) => {
    const userEmail = ApplicationConfigManager.getInstance().getUserEmail();
    setSubmitting(true);
    values.Permissions = values.Permissions.map((item: string) => handleRolesInputFormatter(item));
    dataProvider
      .update('users', values)
      .then(async (response: any) => {
        await dispatch(ReduxActions.updateResource({resource: ReduxResources.USER})({UserName: userName}));
        if (response?.data) {
          ApplicationConfigManager.getInstance().ensureUserDisplayName()
          history.goBack();
        }
        else {
          toast.error(response?.error, { toastId: "users-update" });
        }
      })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "users-update" });
      }).finally(() => {
        setSubmitting(false);
      });
  };

  const validate = (values: ChangePasswordFormValues) => {
    const errors: ChangePasswordFormValues = {};

    if (!values.oldPassword) {
      errors.oldPassword = translate('validation.required');
    }
    if (!values.password) {
      errors.password = translate('validation.required');
    }
    //@ts-ignore
    if (!validatePasswordRegex(values.password)) {
      errors.password = translate('validation.invalidPassword');
    }
    if (!values.reEnterPassword) {
      errors.reEnterPassword = translate('validation.required');
    }
    if (values.reEnterPassword !== values.password) {
      errors.reEnterPassword = translate('validation.missMatch');
    }
    return errors;
  };

  const { Form } = withTypes<ChangePasswordFormValues>();

  return (
    <>
      <CircularLoader loading={isEmpty(record) || submitting} />
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          users : translate(`resources.users.name`),
          [`edit`]: userName
        }}
      />
      <PANTitle title={record ? record.FirstName + ' ' + record.LastName : userName} divider />
      <Box style={{ position: 'relative' }}>
        <PANMainContainer style={{ padding: 0, paddingBottom: 80 }}>
          <Form
            onSubmit={onSubmit}
            initialValues={record}
            render={(formProps) => {
              const { handleSubmit, values } = formProps;
              return (
                <form onSubmit={handleSubmit} style={{ margin: 16 }}>
                  <Grid container style={{ width: 'auto' }}>
                    <PANTile
                      size={12}
                    >
                      <Row>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="FirstName"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.users.fields.FirstName`)}
                            dataMetrics="cloudngfw-users-edit-firstname"
                          />
                        </Grid>
                      </Row>
                      <Row>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="LastName"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.users.fields.LastName`)}
                            dataMetrics="cloudngfw-users-edit-lastname"
                          />
                        </Grid>
                      </Row>
                      {values && values?.Identity !== "Idp_O" && 
                      userName === ApplicationConfigManager.getInstance().getUserEmail() && 
                      <Row>
                        <Grid item xs={12} sm={6}>
                          <PANWDSButton
                            appearance='secondary'
                            size="lg"
                            disabled={isEmpty(record)}
                            onClick={() => setOpen(true)}
                          >
                            {translate(`resources.users.fields.ChangePassword`)}
                          </PANWDSButton>
                        </Grid>
                      </Row>
                      }
                      <Row>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="Permissions"
                            row
                            format={(value) => value?.map(handleRolesValueFormat)}
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.users.fields.Roles`)}
                            items={roleChoices}
                            loading={roleChoicesLoading}
                            enableArrayInput
                            enableMultiSelect
                            dataMetrics={"cloudngfw-users-edit-permissions"}
                          />
                        </Grid>
                      </Row>
                    </PANTile>
                  </Grid>
                  <UserEditToolbar {...formProps} submitting={submitting} classes={classes} />
                </form>
              );
            }}
          />
          <PANWDSModal
            title={translate('changePassword.title')}
            onClose={() => setOpen(false)}
            isOpen={open}
            size="md"
            dataTestId="cloudngfw-users-edit-modal"
            dataMetrics="cloudngfw-users-edit-modal"
            modalBody={<ChangePasswordForm validate={validate} Form={Form} onOpen={setOpen} />}
          />

        </PANMainContainer>
      </Box>
    </>
  );
};

export default withRouter(UsersEdit);
