export const flatPropertiesAndArrayValue = (object: any, splitter = ', ') => {
    const entries = [];
    for (let entry in object) {
        if (object[entry].length > 0) {
            entries.push(`${entry}: ${object[entry].join(', ')}`)
        }
    }
    return entries.join(splitter);
}

export const actionMapping = {
    DenySilent: "Deny",
    Allow: "Allow",
    DenyResetServer: "Reset Server",
    DenyResetBoth: "Reset Both",
}
