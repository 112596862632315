import {makeStyles} from "@material-ui/core";
import {PANFormLabel} from "../FormElements/styles";
import {Tooltip} from "@panwds/react-ui";

const useStyles = makeStyles((theme) => ({
    errorText: {
        color: "#B22D2D",
        marginLeft: "5px",
        width: "12px",
        height: "12px",
        fontSize: "22px",
    },
}));

export const PANWDSLabel = ({...props}) => {
    const classes = useStyles();
    const { title, required, disabled, content, icon } = props;
    return <>
        {title &&
            <PANFormLabel disabled={disabled}>{title} {required && (
                <div className={classes.errorText}>*</div>)} {content && (
                    <Tooltip label={content}>{icon}</Tooltip>)}
            </PANFormLabel>}
    </>
};
