import {Row} from "../../../components/FormElements";
import {FormControl} from "@material-ui/core";
import {Field} from "react-final-form";
import {PANWDSForm, PANWDSInput, PANWDSSelectWithSearch} from "../../../components/PANWDSElements";
import {composeValidators, isRequired, maxLength} from "../../../utils/validate";
import {generateCloudManagerIntegrationsMap, getTsgId, getRegion, getCSPIdFromTenantName, getTenantName, getCDLId, getCDLRegion, getUrl} from "../utils";
import {useTranslate} from "../../../customHooks";
import {Button} from "@panwds/react-ui";
import {SaveButton} from "../../../components";
import _ from "lodash";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    iconedParagraph: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        gap: '10px',
    },
    toolbar: {
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        padding: '16px',
        borderTop: "1px solid #DADBDB",
        marginTop: 16,
    },
    innerToolbar: {
        display: "flex",
        gap: theme.spacing(1),
    },
    notes: {
        fontSize: 12,
        color: '#333333',
    },
}));

export const CloudManagerForm = (props: any) => {
    const {close, cancel, cloudManagerRecord, record, saveRecord, taskInProgress, unlink} = props;
    const translate = useTranslate();
    const isEditMode = !!record?.Panorama;
    const integrationsAll = cloudManagerRecord?.IntegrationsAll || [];
    const integrationMap = generateCloudManagerIntegrationsMap(integrationsAll).flat();

    const SaveToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        return (
            <div className={(cloudManagerRecord) ? classes.toolbar : ""}>
                {isEditMode
                    ? <Button
                        size="md"
                        appearance="secondary-destructive"
                        disabled={taskInProgress}
                        onClick={unlink}
                        dataMetrics="cloudngfw-integrations-panorama-unlink"
                    >
                        {translate(`resources.integrations.unlink`)}
                    </Button>
                    : <div></div>}
                {cloudManagerRecord &&
                    <div className={classes.innerToolbar}>
                        <Button
                            size="md"
                            appearance="secondary"
                            disabled={taskInProgress}
                            onClick={() => cancel()}
                            dataMetrics="cloudngfw-integrations-cloudManager-cancel"
                        >
                            {translate(`resources.integrations.cancel`)}
                        </Button>
                        <SaveButton
                            appearance="primary"
                            size="md"
                            label={translate('resources.integrations.save')}
                            submitOnEnter={true}
                            loading={taskInProgress}
                            disabled={taskInProgress}
                            dataMetrics="cloudngfw-integrations-cloudManager-continue"
                            {...toolbarProps}
                        />
                    </div>}
            </div>
        );
    };

    const preSaveRecord = (values: any) => {
        const tenantId = values?.TenantName?.split(' ')[0];
        saveRecord({
            LinkName: values.LinkName,
            CloudManager: {
                Action: 'link',
                TsgId: getTsgId(tenantId, integrationMap),
                TenantId: tenantId,
                TenantName: getTenantName(tenantId, integrationMap),
                Region: getRegion(tenantId, integrationMap),
                CspSupportAccountId: getCSPIdFromTenantName(tenantId, integrationsAll),
                Url: getUrl(tenantId, integrationMap),
                CDLId: getCDLId(tenantId, integrationMap),
                CDLRegion: getCDLRegion(tenantId, integrationMap)
            },
        });
    }

    const tenantNameOptions = Object.keys(integrationMap).map(k => {
        return { text: `${integrationMap[k].TsgId} - ${integrationMap[k].TenantName}`, key: `${integrationMap[k].TsgId} - ${integrationMap[k].TsgId}`, value: `${integrationMap[k].TenantId} - ${integrationMap[k].TenantName}` };
    });

    return (
        <PANWDSForm
            toolbar={<SaveToolbar />}
            onSubmit={preSaveRecord}
        >
            {
                <Row>
                    <FormControl fullWidth>
                        <Field
                            title={translate(`resources.integrations.panel.name`)}
                            name="LinkName"
                            // @ts-ignore
                            component={PANWDSInput}
                            required
                            inputProps={{ "data-test-id": "integrations-link-name" }}
                            validate={composeValidators(isRequired, maxLength(128))}
                            dataMetrics="integrations-link-name"
                            row
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <Field
                            name="TenantName"
                            row
                            // @ts-ignore
                            component={PANWDSSelectWithSearch}
                            title={translate(`resources.integrations.panel.tenantName`)}
                            items={tenantNameOptions}
                            required
                            validate={isRequired}
                            disabled={taskInProgress || isEditMode}
                        />
                    </FormControl>
                </Row>
            }
        </PANWDSForm>
    );
};

export default CloudManagerForm;
