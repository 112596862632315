import { FormControl, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useCallback, useState } from 'react';
import {requiredValidator} from "../../utils/validate";
import { Field, Form } from 'react-final-form';
import { useHistory, withRouter } from 'react-router-dom';
import { Button as PANWDSButton, LoadingButton }  from "@panwds/react-ui";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { PANTile, PANTitle, toast } from '../../components';
import {PANWDSBreadcrumbs, PANWDSInput} from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import {useTranslate} from '../../customHooks';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    flexDirection: 'row',
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent',
  },
  description:{
    color: "#333333",
  },
}));

const AccountsCreateToolbar = memo(({ onDownload, formProps, submitting, downloading, ...props }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const translate = useTranslate();
  return (
    <div className={classes.toolbar}>
      <PANWDSButton
        size="md"
        appearance="secondary"
        disabled={submitting || downloading}
        onClick={() => {
          history.goBack();
        }}
        dataMetrics="cloudngfw-accounts-create-cancel"
      >
        Cancel
      </PANWDSButton>
      <LoadingButton
        dataResult={{
          loading: !!downloading,
          success: true
        }}
        size="md"
        appearance="secondary"
        icon={<CloudDownloadIcon fontSize='small' />}
        disabled={submitting || downloading}
        dataMetrics="cloudngfw-accounts-create-download"
        onClickCallback={() => {
          if (!formProps.invalid && formProps.valid) {
            onDownload(formProps.values)
          }
        }}
      >
        {translate(`resources.accounts.fields.Download`)}
      </LoadingButton>
      <LoadingButton
        dataResult={{
          loading: !!submitting,
          success: true
        }}
        size="md"
        appearance="primary"
        type="submit"
        disabled={submitting || downloading}
        dataMetrics="cloudngfw-accounts-create-submit"
      >
        {translate(`resources.accounts.fields.Create`)}
      </LoadingButton>
    </div>
  );
});

const AccountsCreate = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const saveAccount = useCallback(async (values) => {
    try {
      const response = await dataProvider.create('accounts', { data: values });
      if (!response.data) {
        //@ts-ignore
        toast.error(response?.error, { toastId: "accounts-create" });
      }
      return response.data;
    }
    catch (error: any) {
      toast.error(error?.error, { toastId: "accounts-create" })
    }
  }, []);

  const handleDownload = useCallback(async (values) => {
    setDownloading(true);
    try {
      const response = await saveAccount(values);
      if (response && response?.AccountId) {
        const mainURL = new URL(response?.CFTRoleURL);
        const stackURL = new URL(mainURL.hash.replace(/^#/, ''), mainURL);
        const fileURL = stackURL.searchParams.get('templateURL');
        if (fileURL) {
          const fileName = fileURL.split('/').pop() || '';
          const linkElement = document.createElement('a');
          linkElement.setAttribute('href', fileURL);
          linkElement.setAttribute('download', fileName);
          linkElement.click();
          setTimeout(function(){history.goBack();}, 3000);
        }
      }
    }
    catch (error: any) {
      toast.error(error?.error, { toastId: "accounts-save" });
    }
    finally {
      setDownloading(false);
    }
  }, []);

  const handleSave = useCallback(
    async (values, formProps) => {
      setSubmitting(true);
      try {
        const response = await saveAccount(values);
        if (response && response?.AccountId) {
          const win = window.open(response?.CFTRoleURL, '_blank');
          win?.focus();
          history.goBack();
        }
      }
      catch (e: any) {
        toast.error(e?.error, { toastId: "accounts-save" });
      }
      finally {
        setSubmitting(false);
      }
    }, []
  );

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          accounts: translate(`resources.accounts.name`),
          create: translate(`resources.accounts.fields.CreateButton`),
        }}
      />
      <PANTitle title={translate(`resources.accounts.fields.CreateButton`)} divider />

      <Form
        onSubmit={handleSave}
        render={({ handleSubmit, ...formProps }) => (
          <form
            onSubmit={handleSubmit}
            style={{ margin: 16 }}>
            <Grid container style={{ width: 'auto' }}>
              <PANTile size={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} className="noLeftPadding">
                    <FormControl className={classes.formControl}>
                      <Field
                        name="AccountId"
                        // @ts-ignore
                        component={PANWDSInput}
                        title="AWS Account Id"
                        type="name"
                        validate={requiredValidator(translate('validation.required'))}
                        required
                        dataMetrics="cloudngfw-account-create-name"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography variant="body1" gutterBottom className={classes.description}>
                  <h3>{translate('resources.accounts.content.stepsTitle')}</h3>
                  <ol>
                    <li dangerouslySetInnerHTML={{ __html: translate('resources.accounts.content.stepOne') }}></li>
                    <li dangerouslySetInnerHTML={{ __html: translate('resources.accounts.content.stepTwo') }}></li>
                    <li dangerouslySetInnerHTML={{ __html: translate('resources.accounts.content.stepThree') }}></li>
                  </ol>
                </Typography>
              </PANTile>
            </Grid>
            <AccountsCreateToolbar onDownload={handleDownload} formProps={formProps} submitting={submitting} downloading={downloading} />
          </form>
        )}
      />
    </>
  );
};

export default withRouter(AccountsCreate);
