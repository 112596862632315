import { useEffect, useState } from "react";
import { each, find } from "lodash";
import { dataProvider } from "../../../../dataProvider";
import { PANTile, SaveButton, toast } from "../../../../components";
import { PANWDSCheckboxField, PANWDSForm, PANWDSInput, PANWDSRadioField, PANWDSSelectWithSearch } from "../../../../components/PANWDSElements";
import cx from "classnames";
import CircularLoader from "../../../../components/CircularLoader/CircularLoader";
import { FormControl, Grid } from "@material-ui/core";
import { Field, FormSpy } from "react-final-form";
import { Tab, TabList, TabPanel, Button as PANWDSButton } from "@panwds/react-ui";
import { composeValidators } from "../../../../utils/validate";
import { usePermissions, useTranslate } from "../../../../customHooks";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import { useHistory, useLocation, useParams } from "react-router-dom";

const LogSettingForm = ({ record, classes, logSettingsData }: { record: any, classes: any, logSettingsData: any }) => {
    const history = useHistory();
    const translate = useTranslate();
    const location = useLocation();
    const params = useParams<{ firewallname: string }>();
    const search = useLocation().search;
    const accountId = new URLSearchParams(search).get('AccountId');
    const firewallName = params.hasOwnProperty('firewallname') ? params?.firewallname : "";
    const { permissions } = usePermissions();
    const [key, setKey] = useState(0);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [incompleteTabs, setIncompleteTabs] = useState<string[]>([]);
    const cloudMetrics = [{ text: "Dataplane CPU Utilization (%)", value: "Dataplane_CPU_Utilization" },
    { text: "Dataplane Packet Buffer Utilization (%)", value: "Dataplane_Packet_Buffer_Utilization" },
    { text: "Connection Per Second", value: "Connection_Per_Second" },
    { text: "Session Throughput Kbps", value: "Session_Throughput_Kbps" },
    { text: "Session Throughput Pps", value: "Session_Throughput_Pps" },
    { text: "Session Active", value: "Session_Active" },
    { text: "Session Utilization", value: "Session_Utilization" },
    { text: "Bytes In", value: "BytesIn" },
    { text: "Bytes Out", value: "BytesOut" },
    { text: "Packets In", value: "PktsIn" },
    { text: "Packets Out", value: "PktsOut" }];

    const initialTabList = [
        {
            key: 0,
            id: "logTab1",
            label: "TRAFFIC",
            hidden: true
        },
        {
            key: 1,
            id: "logTab2",
            label: "THREAT",
            hidden: true
        },
        {
            key: 2,
            id: "logTab3",
            label: "DECRYPTION",
            hidden: true
        },
    ];


    const [logTabValue, setLogTabValue] = useState<any>({
        value: undefined, tabList: initialTabList
    });
    const logSettingsDataLoaded = logSettingsData !== undefined;

    //const [formData, setFormData] = useState<any>(logSettingsProps?.logSettingsData || {})

    const handleLogTabChange = (newValue: string) => {
        setLogTabValue({ ...logTabValue, value: newValue });
        setIncompleteTabs([]);
    };

    const onLogSettingSubmit = async (formValues: any, form: any) => {
        const values = JSON.parse(JSON.stringify(formValues));
        // PRE-validation of tabs fully filled
        const incomplete: string[] = [];
        for (let i = 0; i < Object.keys(values.LogType).length; ++i) {
            const tab = values.LogType[i].name;
            if (values.LogType[i].checked
                && (!values[tab]?.LogDestination
                    || !values[tab]?.LogDestinationType)) {
                incomplete.push(tab);
            }
        }
        setIncompleteTabs([...incomplete]);
        if (incomplete.length > 0) {
            // has incomplete tabs
            return;
        }
        // valid, continue submition
        //let state = form.getState();
        let payload: any = { LogDestinationConfigs: [] };
        setSubmitInProgress(true);
        each(values.LogType, (val, key) => {
            if (typeof val === "string") {
                if (val) {
                    values[val]['LogType'] = val;
                    payload.LogDestinationConfigs.push(values[val]);
                }
            } else if (val.checked) {
                if (values[val.name]) {
                    values[val.name]['LogType'] = val.name;
                    payload.LogDestinationConfigs.push(values[val.name]);
                }
            }
        });
        payload['FirewallName'] = record?.Firewall?.FirewallName ?? firewallName;
        payload['CloudWatchMetricNamespace'] = values["CloudWatchNamespace"];
        if (values["CloudWatchNamespace"]) {
            payload['CloudWatchMetricsFields'] = values["CloudWatchMetricsFields"];
        }
        if(process.env.REACT_APP_ENABLE_CLOUD_METRICS !== "true"){
            payload['CloudWatchMetricsFields'] = [];
        }
        payload['AccountId'] = record?.Firewall?.AccountId || accountId;
        if(values['AdvancedThreatLog']){
            payload['AdvancedThreatLog'] = values['AdvancedThreatLog'];
        }
        if(process.env.REACT_APP_ENABLE_ADDITIONAL_THREAT_LOG !== "true"){
            payload['AdvancedThreatLog'] = false;
        }

        dataProvider.update("logProfile", payload).then(async (response: any) => {
            if (response.data) {
                toast.success("Successfully updated log profile.");
                //history.goBack();
            } else {
                toast.error(response?.error, { toastId: "logpropfile-update" });
            }
        })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "logpropfile-update" });
            })
            .finally(() => setSubmitInProgress(false));
    };

    const resetValues = () => {
        setKey(key + 1);
        if (logSettingsData?.LogType?.length) {
            logSettingsData.LogType.forEach((name: any) => {
                tabChange({ target: { name, checked: true } }, initialTabList);
            });
        } else {
            setLogTabValue({ tabList: initialTabList, value: undefined }); // set initial tab
        }
    }

    const LogSettingToolbar = (toolbarProps: any) => {
        const { valid } = toolbarProps;
        return (
            <div className={classes.toolbar}>
                <PANWDSButton
                    size="md"
                    appearance="secondary"
                    disabled={submitInProgress}
                    onClick={resetValues}
                    dataMetrics="cloudngfw-firewall-edit-log-setting-cancel"
                >
                    Cancel
                </PANWDSButton>
                <SaveButton
                    appearance="primary"
                    size="md"
                    label="Save"
                    redirect={false}
                    submitOnEnter={true}
                    disabled={submitInProgress || !permissions?.UpdateLogProfile || !valid}
                    loading={submitInProgress}
                    dataMetrics="cloudngfw-firewall-edit-log-setting-save"
                    {...toolbarProps}
                />
            </div>
        );
    };

    const isLogTypeEnabled = (logType: string) => (value: string, formValues: any) => {
        if (find(formValues.LogType, function (o) {
            return o.name === logType;
        })?.checked && !value) {
            return 'This field is required';
        } else {
            return undefined
        }
    };

    const tabChange = (e: any, tabList = logTabValue.tabList) => {
        //const selected = Object.keys(checks).filter(i => checks[i].checked).map(i => checks[i].name);

        let currentTab: any = find(tabList, function (o) {
            return o.label === e.target.name;
        })?.key;
        if (currentTab !== undefined) {
            tabList[currentTab]['hidden'] = !e.target.checked;
            let tabChecked: any = find(tabList, function (o) {
                return o.hidden === false;
            })?.id;
            setLogTabValue({ value: (tabChecked !== undefined) ? tabChecked : undefined, tabList: tabList });
        }
    }

    useEffect(() => {
        if (logSettingsData?.LogType?.length) {
            logSettingsData.LogType.forEach((name: any) => {
                tabChange({ target: { name, checked: true } });
            });
        }
    }, [logSettingsData]);

    return (
        <PANWDSForm
            key={key}
            toolbar={logSettingsDataLoaded && <LogSettingToolbar />}
            onSubmit={onLogSettingSubmit}
            initialValues={logSettingsData}
        >
            <div
                className={cx(classes.pantileContainer, !(logSettingsDataLoaded) ? classes.loadingBlur : "")}>
                <CircularLoader loading={!(logSettingsDataLoaded)} />
                <PANTile title={translate(`resources.firewalls.fields.LogDestination`)} subtitle={translate(`resources.firewalls.fields.LogDestinationSubtitle`)} size={12}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Field
                                name="LogType"
                                // @ts-ignore
                                component={PANWDSCheckboxField}
                                isGroup
                                options={["TRAFFIC", "THREAT", "DECRYPTION"]}
                                label={translate(`resources.firewalls.fields.LogType`)}
                                initValue={logSettingsData?.LogType}
                                // onChange={(checks: any) => {
                                //     const selected = Object.keys(checks).map(i => ({ name: checks[i].name, index: i, checked: checks[i].checked }));
                                //     tabChange(selected);
                                // }}
                                onClick={tabChange}
                                disabled={!permissions?.UpdateLogProfile}
                            />
                        </FormControl>
                    </Grid>

                    {
                        logTabValue.tabList.filter((tab: any) => !tab.hidden).length > 0 &&
                        <>
                            <TabList
                                key={Math.random()} // temp logic fix for dynamic tabs, need to update panwds versions to accommodate this fix.
                                activeTabId={logTabValue.value}
                                onActiveChange={(nextTabId) => handleLogTabChange(nextTabId)}
                            >
                                {
                                    logTabValue.tabList.filter((tab: any) => !tab.hidden).map((tab: any) => (
                                        <Tab id={tab.id} key={tab.id} >
                                            <span className={incompleteTabs.includes(tab.label) ? classes.incompleteTab : ""}>
                                                {tab.label} {incompleteTabs.includes(tab.label) && "*"}
                                            </span>
                                        </Tab>
                                    ))
                                }
                            </TabList>


                            <TabPanel addClassName={classes.tabPanelStyles} activeTabId={logTabValue.value} forTabId={"logTab1"}>
                                <Grid item xs={12} sm={6} style={{ paddingTop: "15px" }}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="TRAFFIC.LogDestinationType"
                                            row
                                            // @ts-ignore
                                            component={PANWDSRadioField}
                                            options={[
                                                { label: "S3", value: "S3" },
                                                { label: "Cloudwatch Log Group", value: "CloudWatchLogs" },
                                                { label: "Kinesis Data Firehose", value: "KinesisDataFirehose" }
                                            ]}
                                            title={translate(`resources.firewalls.fields.LogDestinationType`)}
                                            validate={composeValidators(isLogTypeEnabled("TRAFFIC"))}
                                            disabled={!permissions?.UpdateLogProfile}
                                            dataMetrics={"cloudngfw-firewall-edit-traffic-log-destination-type"}
                                            required
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="TRAFFIC.LogDestination"
                                            // @ts-ignore
                                            component={PANWDSInput}
                                            title={translate(`resources.firewalls.fields.LogDestination`)}
                                            validate={composeValidators(isLogTypeEnabled("TRAFFIC"))}
                                            disabled={!permissions?.UpdateLogProfile}
                                            dataMetrics="cloudngfw-firewall-edit-traffic-log-destination"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </TabPanel>

                            <TabPanel addClassName={classes.tabPanelStyles} activeTabId={logTabValue.value} forTabId={"logTab2"}>
                                <Grid item xs={12} sm={6} style={{ paddingTop: "15px" }}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="THREAT.LogDestinationType"
                                            row
                                            // @ts-ignore
                                            component={PANWDSRadioField}
                                            options={[
                                                { label: "S3", value: "S3" },
                                                { label: "Cloudwatch Log Group", value: "CloudWatchLogs" },
                                                { label: "Kinesis Data Firehose", value: "KinesisDataFirehose" }
                                            ]}
                                            title={translate(`resources.firewalls.fields.LogDestinationType`)}
                                            validate={composeValidators(isLogTypeEnabled("THREAT"))}
                                            disabled={!permissions?.UpdateLogProfile}
                                            dataMetrics={"cloudngfw-firewall-edit-threat-log-destination-type"}
                                            required
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="THREAT.LogDestination"
                                            row
                                            // @ts-ignore
                                            component={PANWDSInput}
                                            title={translate(`resources.firewalls.fields.LogDestination`)}
                                            validate={composeValidators(isLogTypeEnabled("THREAT"))}
                                            disabled={!permissions?.UpdateLogProfile}
                                            dataMetrics="cloudngfw-firewall-edit-threat-log-destination"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </TabPanel>

                            <TabPanel addClassName={classes.tabPanelStyles} activeTabId={logTabValue.value} forTabId={"logTab3"}>
                                <Grid item xs={12} sm={6} style={{ paddingTop: "15px" }}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="DECRYPTION.LogDestinationType"
                                            row
                                            // @ts-ignore
                                            component={PANWDSRadioField}
                                            options={[
                                                { label: "S3", value: "S3" },
                                                { label: "Cloudwatch Log Group", value: "CloudWatchLogs" },
                                                { label: "Kinesis Data Firehose", value: "KinesisDataFirehose" }
                                            ]}
                                            title={translate(`resources.firewalls.fields.LogDestinationType`)}
                                            validate={composeValidators(isLogTypeEnabled("DECRYPTION"))}
                                            disabled={!permissions?.UpdateLogProfile}
                                            dataMetrics="cloudngfw-firewall-edit-decryption-log-destination-type"
                                            required
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Field
                                            name="DECRYPTION.LogDestination"
                                            row
                                            // @ts-ignore
                                            component={PANWDSInput}
                                            title={translate(`resources.firewalls.fields.LogDestination`)}
                                            validate={composeValidators(isLogTypeEnabled("DECRYPTION"))}
                                            disabled={!permissions?.UpdateLogProfile}
                                            dataMetrics="cloudngfw-firewall-edit-decryption-log-destination"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </TabPanel>
                        </>
                    }
                </PANTile>
                {process.env.REACT_APP_ENABLE_ADDITIONAL_THREAT_LOG === "true" &&
                <PANTile size={12} title={translate(`resources.firewalls.fields.LogGeneration`)} subtitle={translate(`resources.firewalls.fields.LogGenerationSubtitle`)} >
                    <Grid item xs={12} sm={6} style={{ paddingTop: "15px" }}>
                        <FormControl fullWidth>
                            <Field
                                name="AdvancedThreatLog"
                                // @ts-ignore
                                component={PANWDSCheckboxField}
                                label={translate(`resources.firewalls.fields.AdditionalThreatLogs`)}
                                dataMetrics="cloudngfw-certificate-create-field-selfsigned"
                                muted={translate(`resources.firewalls.fields.AdditionalThreatLogsHelpText`)}
                                type="checkbox"
                            />
                        </FormControl>
                    </Grid>
                </PANTile>
                }
                <PANTile size={12} title={translate(`resources.firewalls.fields.Metrics`)} >
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Field
                                name="CloudWatchNamespace"
                                row
                                // @ts-ignore
                                component={PANWDSInput}
                                title={translate(`resources.firewalls.fields.CloudWatchNamespace`)}
                                disabled={!permissions?.UpdateLogProfile}
                                dataMetrics="cloudngfw-firewall-edit-cloud-watch-namespace"
                            />
                        </FormControl>
                    </Grid>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.CloudWatchNamespace && process.env.REACT_APP_ENABLE_CLOUD_METRICS === "true") {
                                return (
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <Field
                                                name="CloudWatchMetricsFields"
                                                row
                                                // @ts-ignore
                                                component={PANWDSSelectWithSearch}
                                                title={translate(`resources.firewalls.fields.CloudWatchMetricsFields`)}
                                                items={cloudMetrics}
                                                enableMultiSelect
                                                enableArrayInput
                                                allowSetNone
                                            />
                                        </FormControl>
                                    </Grid>
                                );
                            } else return null;
                        }}
                    </FormSpy>
                </PANTile>
            </div>
        </PANWDSForm>
    );
}

export default LogSettingForm;
