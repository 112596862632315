import React, {useEffect} from "react";
import {dataProvider} from "../dataProvider";
import * as DataTypes from "../api/FwaasDataTypes";
import {toast} from "../components";
import {RouteUri} from "../routeUri";
import {useHistory} from "react-router-dom";
import {ReduxActions} from "../redux";
import {useAppDispatch} from "../app/hooks";
import CircularLoader from "../components/CircularLoader/CircularLoader";

const CspCallback = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const callApi = async () => {
        let payload: any = {
            PubSubMessageStatus: "SupportAccountPending"
        };
        await dataProvider
            .update("support", payload)
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                if (!response.data) {
                    toast.error(response?.error);
                }
            })
            .catch((e: any) => {
                toast.error(e?.error);
            })
            .finally(async () => {
                await dispatch(ReduxActions.setPubSubMessageStatus('SupportAccountPending'));
                history.replace(RouteUri.Dashboard);
            });
    };

    useEffect(() => {
        callApi().catch(console.error);
    }, []);

    return <CircularLoader loading={true}/>;
};

export default CspCallback;
