import { Failed, Pending, Success } from "../images";
let sucessValues = ["SUCCESS","CREATE_COMPLETE","UPDATE_COMPLETE", "ACCEPTED", "RUNNING", "PRECOMMITDONE", "PREVALIDATEDONE"].map((val) => val.toLowerCase());
let failedValues = ["CREATE_FAIL", "UPDATE_FAIL", "DELETE_FAIL", "REJECTED", "FAILED"].map((val) => val.toLowerCase());
let pendingValues = ["CREATING", "UPDATING", "DELETING", "PENDING", "PRECOMMIT", "UNCOMMITTED", "COMMITTING"].map((val) => val.toLowerCase());

//argument passed is a string
export const getStatusIconForString = (status: string) :any => {
    let statusValue = Pending;
    if(sucessValues.includes(status.toLowerCase())) {
        return statusValue =  Success;
    } else if (failedValues.includes(status.toLowerCase())) {
        return statusValue = Failed;
    }
    return statusValue;
}

//argument passed is an array
export const getStatusIconForArray = (statusArray: any) :any => {
    const statusArrayLowerCase = statusArray.map((val: string) => val && val.toLowerCase());
    let failedStatusExist, pendingStatusExist = false;
    let statusExist = 'Success';
    failedStatusExist = statusArrayLowerCase.some((val: string) => failedValues.includes(val));
    if(failedStatusExist) {
        return statusExist = 'Failed';
    } else if(!failedStatusExist) {
        pendingStatusExist = statusArrayLowerCase.some((val: string) => pendingValues.includes(val));
        if(pendingStatusExist) {
            return statusExist = 'Pending';
        } 
    }   
    return statusExist;
}