import {
  Box,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import Emitter from "../../eventEmitter";
import { ApplicationConfigManager, SupportedRegion } from '../../types';
import PANWDSButtonDropdown from '../PANWDSElements/PANWDSButtonDropdown';
import {ReduxActions, ReduxResources} from "../../redux";
import {useAppDispatch} from "../../app/hooks";

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: "5px 16px",
    background: "#FFFFFF",
    borderBottom: "1px solid #DADBDB",
  },
  flexgap:{
    gap: '20px'
  }
}));

// Reset Reducers when region changed for updated data
const resetResources = [
    ReduxResources.FIREWALL,
    ReduxResources.RULESTACK
]

const SelectRegion = memo(({ disabled, onSelect }: { disabled: boolean, onSelect: (region: any) => void }) => {
  const classes = useStyles();
    const dispatch = useAppDispatch();
  const handleMenuItemClick = async (
    event: any,
  ) => {
    const { highlightedIndex } = event;
    const newAppConfig = ApplicationConfigManager.getInstance().getConfig();
    newAppConfig.currentRegion = ApplicationConfigManager.getInstance().getConfig().supportedRegions[highlightedIndex];
    Emitter.emit('regionWillChange', newAppConfig.currentRegion);
    onSelect(newAppConfig.currentRegion);
    //console.log("Setting new region : " + newAppConfig.currentRegion.RegionCode);
    await ApplicationConfigManager.getInstance().setConfig(newAppConfig);
    Promise.all(resetResources.map(resource => dispatch(ReduxActions.resetListDetails({resource})({})))).then(() => {
        Emitter.emit('regionChanged', newAppConfig.currentRegion);
    })
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <PANWDSButtonDropdown
          buttonTitle={(
            <>
              Region: {'\u00A0'}
              <span className="tw-text-blue-600">
                {ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName}
              </span>
            </>
          )}
          items={
            ApplicationConfigManager.getInstance().getSupportedRegions().map((region: SupportedRegion, index: number) => {
              return {
                value: (<span className={`tw-flex ${classes.flexgap}`}>
                  <span className='tw-flex-1'>{region.RegionDisplayName}</span><span>{region.RegionCode}</span>
                </span>),
                key: index,
                disabled: ApplicationConfigManager.getInstance().getConfig().currentRegion === region
              }
            })
          }
          appearance="tertiary"
          dataMetrics="select-region-btn"
          onChange={(e: any, index: any) => {
            handleMenuItemClick(e)
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
});

export const PANSelectRegion = memo(() => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);

  const handleLoadingData = useCallback((isDisabled) => {
    setDisabled(isDisabled);
  }, [setDisabled]);

  const handleOnSelectRegion = useCallback((region: any) => {
    setDisabled(true);
  }, [setDisabled])

  useEffect(() => {
    Emitter.on("regionLoadingData", handleLoadingData);
    return () => {
      Emitter.off("regionLoadingData", handleLoadingData);
    }
  }, []);

  return (
    <Box className={classes.Container}>
      <SelectRegion disabled={disabled} onSelect={handleOnSelectRegion} />
    </Box>
  );
});
