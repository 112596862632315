import { useEffect, useState } from 'react';
import { useTranslate } from '../../customHooks';
import { withRouter } from "react-router-dom";
import { PANTitle } from '../../components';
import { GetFirewallsLengthByAccount } from '../../utils/GetFirewallsLengthByAccount';
import { GetXAccountRoleNames } from '../../utils/GetXAccountRoleNames';
import { getCFTEndpoint, getXAccountRoleNames } from '../../utils/GetUrlLinks';
import { ISplitButtonAction } from '../../types';
import {PANWDSBreadcrumbs} from "../../components/PANWDSElements";

const AccountsTitle = ( { accountId }: any ) => {
  const translate = useTranslate();
  const [firewallsLength, setFirewallsLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [xAccountRoleNamesLength, setXAccountRoleNamesLength] = useState(0);
  const [xAccountRoleNames, setXAccountRoleNames] = useState([]);

  useEffect(() => {
    GetFirewallsLengthByAccount(setFirewallsLength, setLoading);
    GetXAccountRoleNames(setXAccountRoleNamesLength, setXAccountRoleNames, setLoading, accountId);
  }, []);

  const dropDownXAccountsArray: ISplitButtonAction[] = [];
  xAccountRoleNames.forEach((xAccountRoleName) => {
    Object.keys(xAccountRoleName).forEach((accountName) => {
        dropDownXAccountsArray.push({
          menuText: accountName,
          progressText: accountName,
          handleAction: () => getXAccountRoleNames(xAccountRoleName[accountName])
        })
    });
  });

  return (<>
    <PANTitle title={accountId}
      overviewPanel={[{key: translate("resources.accounts.overviewPanel.awsAccountId"), values: [{ text: accountId, isNormalText: true }]},
        {key: translate("resources.accounts.overviewPanel.numberOfNgfws"), values: [{ text: firewallsLength, isNormalText:true }], isLoading: loading},
        {key: translate("resources.accounts.overviewPanel.numberOfCrossAccountRoleNames"), values: [{ text: xAccountRoleNamesLength, isDropdownLink: xAccountRoleNamesLength > 0, isNormalText: xAccountRoleNamesLength > 0, linkOption: 'dropdown', dropDownActionsArray: dropDownXAccountsArray}], isLoading: loading},
        {key: translate("resources.accounts.overviewPanel.cftStatus"), values: [{ text: translate("resources.accounts.overviewPanel.cftStatus"), isLink: true, linkOption: 'external', linkToOpen: getCFTEndpoint()}]}
    ]}
    />
  </>
  );
};

const AccountsEdit = (props: any) => {
    const translate = useTranslate();
    const accountId = props.match.params.id;

    return (
        <>
            <PANTitle divider={false} />
            <PANWDSBreadcrumbs
                mapping={{
                    accounts: translate(`resources.accounts.name`),
                    [accountId]: accountId,
            }}
            />
            <AccountsTitle accountId={accountId} />
        </>
    );
};
export default withRouter(AccountsEdit);
