
import { FilterComponent } from "../../../components/Filters/FilterComponent";
import { DateRangeFilter } from "@panwds/react-filters";

export const getUsageFilters = (dimensionData: any) => {
    const usageFilters = [
        {
            Component: DateRangeFilter,
            noCustom: true,
            name: "Period",
            label: "Period",
            context: {
                items: [{
                value: "1",
                children: "Past 30 Days"
                },
                {
                value: "3",
                children: "Past 3 Months"
                },
                {
                value: "6",
                children: "Past 6 Months"
                } 
            ]
            },
            columnValue: 'Period',
            defaultValues: "6",
         },
        {
            Component: FilterComponent,
            label: "Dimension",
            name: "Dimension",
            required: true,
            items: dimensionData,
            defaultValues: ['NGFWUsageHours', 'TrafficSecured', 'Tp', 'WildFire', 'Dns', 'PanMgmt', 'Atp', 'Aurl', 'Awf', 'Dlp', 'AddOn', 'Overages'],
            columnValue: 'Dimension',
            dataTestId: "Dimension",
            enableStyles: true
        }
    ]
    return usageFilters;
};