import {Redirect, Route} from "react-router-dom";
import {RouteUri} from "../routeUri";
import WithTitle from "./WithTitle";
import WithPermissions from "./WithPermissions";
import * as resources from "../resources";
import React from "react";
import { ApplicationConfigManager } from "../types";

const newAppConfig = ApplicationConfigManager.getInstance().getConfig();

const ProtectedRoutesUri = [
    <Route exact path={RouteUri.Home} render={(routeProps) => {
        return <Redirect to={{
            pathname: RouteUri.Dashboard,
        }} />
    }} />,
    <Route
        exact
        path={RouteUri.NGFirewallList}
        render={(routeProps) => (
            <WithTitle title="Firewalls">
                <WithPermissions
                    component={(ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2") ? resources.firewallsV2.list : resources.firewalls.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.NGFirewallCreate}
        render={(routeProps) => (
            <WithTitle title="Create Firewall">
                <WithPermissions
                    component={resources.firewalls.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.NGFirewallEdit}
        render={(routeProps) => (
            <WithTitle title="Edit Firewall">
                <WithPermissions
                    component={(ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2") ? resources.firewallsV2.edit : resources.firewalls.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.UserList}
        render={(routeProps) => (
            <WithTitle title="Users and Roles">
                <WithPermissions
                    component={resources.users.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.UserCreate}
        render={(routeProps) => (
            <WithTitle title="Create User">
                <WithPermissions
                    component={resources.users.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.UserEdit}
        render={(routeProps) => (
            <WithTitle title="Edit User">
                <WithPermissions
                    component={resources.users.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.Overview}
        render={(routeProps) => (
            <WithTitle title="Overview">
                <WithPermissions
                    component={resources.overview.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.AccountList}
        render={(routeProps) => (
            <WithTitle title="AWS Accounts">
                <WithPermissions
                    component={resources.accounts.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.AccountCreate}
        render={(routeProps) => (
            <WithTitle title="Create Account">
                <WithPermissions
                    component={resources.accounts.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.AccountEdit}
        render={(routeProps) => (
            <WithTitle title="Edit Account">
                <WithPermissions
                    component={resources.accounts.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.RuleStackList}
        render={(routeProps) => (
            <WithTitle title="Rulestacks">
                <WithPermissions
                    component={resources.ruleStacks.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.RuleStackCreate}
        render={(routeProps) => (
            <WithTitle title="Create Rulestack">
                <WithPermissions
                    component={resources.ruleStacks.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.RuleStackEdit}
        render={(routeProps) => (
            <WithPermissions
                component={resources.ruleStacks.edit}
                {...routeProps}
            />
        )}
    />,
    <Route
        exact
        path={RouteUri.RuleList}
        render={(routeProps) => (
            <WithTitle title="Security Rules">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.SecurityServices}
        render={(routeProps) => (
            <WithTitle title="Security Services">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.ObjectList}
        render={(routeProps) => (
            <WithTitle title="Rulestack Objects">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.RuleStackSettings}
        render={(routeProps) => (
            <WithTitle title="Edit Rulestack">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.AssociatedFirewalls}
        render={(routeProps) => (
            <WithTitle title="Associated Firewalls">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.ConfigDiff}
        render={(routeProps) => (
            <WithTitle title="Configuration Diff">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.RuleCreate}
        render={(routeProps) => (
            <WithTitle title="Create Security Rule">
                <WithPermissions
                    component={resources.rules.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.RuleEdit}
        render={(routeProps) => (
            <WithTitle title="Edit Security Rule">
                <WithPermissions
                    component={resources.rules.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.RuleEditUsage}
        render={(routeProps) => (
            <WithTitle title="Security Rule Usage">
                <WithPermissions
                    component={resources.rules.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.CertificateList}
        render={(routeProps) => (
            <WithTitle title="Certificates">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.CertificateCreate}
        render={(routeProps) => (
            <WithTitle title="Create Certificate">
                <WithPermissions
                    component={resources.certificate.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.CertificateEdit}
        render={(routeProps) => (
            <WithTitle title="Edit Certificate">
                <WithPermissions
                    component={resources.certificate.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.PrefixList}
        render={(routeProps) => (
            <WithTitle title="Prefix Lists">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.PrefixListCreate}
        render={(routeProps) => (
            <WithTitle title="Create Prefix List">
                <WithPermissions
                    component={resources.prefix.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.PrefixListEdit}
        render={(routeProps) => (
            <WithTitle title="Edit Prefix List">
                <WithPermissions
                    component={resources.prefix.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.Feed}
        render={(routeProps) => (
            <WithTitle title="Intelligent Feeds">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.FeedCreate}
        render={(routeProps) => (
            <WithTitle title="Create Intelligent Feed">
                <WithPermissions
                    component={resources.feed.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.FeedEdit}
        render={(routeProps) => (
            <WithTitle title="Edit Intelligent Feed">
                <WithPermissions
                    component={resources.feed.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.URLCategories}
        render={(routeProps) => (
            <WithTitle title="Custom URL Categories">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.URLCategoryCreate}
        render={(routeProps) => (
            <WithTitle title="Create URL Category">
                <WithPermissions
                    component={resources.category.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.URLCategoryEdit}
        render={(routeProps) => (
            <WithTitle title="Edit URL Category">
                <WithPermissions
                    component={resources.category.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.FQDNList}
        render={(routeProps) => (
            <WithTitle title="FQDN Lists">
                <WithPermissions
                    component={resources.ruleStacks.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.FQDNCreate}
        render={(routeProps) => (
            <WithTitle title="Create FQDN List">
                <WithPermissions
                    component={resources.fqdn.create}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.FQDNEdit}
        render={(routeProps) => (
            <WithTitle title="Edit FQDN List">
                <WithPermissions
                    component={resources.fqdn.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.FileBlockingProfiles}
        render={(routeProps) => (
            <WithTitle title="File Blocking Profiles">
                <WithPermissions
                    component={resources.fileBlocking.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.URLFilteringProfiles}
        render={(routeProps) => (
            <WithTitle title="URL Filtering Profiles">
                <WithPermissions
                    component={resources.urlFiltering.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.Tenant}
        render={(routeProps) => (
            <WithTitle title="Tenant">
                <WithPermissions
                    component={resources.tokens.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.Integrations}
        render={(routeProps) => (
            <WithTitle title="Integrations">
                <WithPermissions
                    component={process.env.REACT_APP_SHOW_CM === "true" ? resources.integrations.list : resources.integrations.oldList}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.Subscription}
        render={(routeProps) => (
            <WithTitle title="Subscription">
                <WithPermissions
                    component={resources.subscription_mgmt.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.Usage}
        render={(routeProps) => (
            <WithTitle title="Usage Explorer">
                <WithPermissions
                    component={resources.usage.list}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.NGFirewallSettings}
        render={(routeProps) => (
            <WithTitle title="Edit Firewall">
                <WithPermissions
                    component={resources.firewalls.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.NGFirewallLogSettings}
        render={(routeProps) => (
            <WithTitle title="Log Settings">
                <WithPermissions
                    component={resources.firewalls.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.NGFirewallEndpoints}
        render={(routeProps) => (
            <WithTitle title="Firewall Endpoints">
                <WithPermissions
                    component={resources.firewalls.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.NGFirewallRules}
        render={(routeProps) => (
            <WithTitle title="Firewall Security Rules">
                <WithPermissions
                    component={resources.firewalls.edit}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,
    <Route
        exact
        path={RouteUri.NGFirewallRulesUsage}
        render={(routeProps) => (
            <WithTitle title="Firewall Security Rules Usage">
                <WithPermissions
                    component={resources.firewalls.ruleUsage}
                    {...routeProps}
                />
            </WithTitle>
        )}
    />,

    <Route
        exact
        path={RouteUri.InventoryList}
        render={(routeProps) => (
            <WithPermissions
                component={resources.inventory.list}
                {...routeProps}
            />
        )}
    />,

    <Route
        exact
        path={RouteUri.VPCsList}
        render={(routeProps) => (
            <WithPermissions
                component={resources.inventory.list}
                {...routeProps}
            />
        )}
    />,

    <Route
        exact
        path={RouteUri.TagsList}
        render={(routeProps) => (
            <WithPermissions
                component={resources.inventory.list}
                {...routeProps}
            />
        )}
    />,
];

export default ProtectedRoutesUri;
