import {callPANWDBanner, toast} from "../components";
import {Button} from "@panwds/react-ui";
import {ApplicationConfigManager} from "../types";
import {ReduxActions, ReduxResources} from "../redux";
import {store} from "../app/store";
import isEmpty from "lodash/isEmpty";
import {ExternalLinkIcon} from "@panwds/icons";

export const callCSPRedirectLink = ({supportState, translate}: any) => {

    const openLink = ({serialNumber}: any) => {
        const userEmail = ApplicationConfigManager.getInstance().getUserEmail();
        window.open(`${process.env.REACT_APP_SUPPORT_URL}/home/register?tenantId=${supportState.ExternalId}&productSku=CLOUD-NGFW-AWS&productSerial=${serialNumber}&userEmail=${userEmail}&returnURL=${window.location.origin}/csp/callback`, "_self");
    }

    if (supportState.SerialNumber) {
        openLink({serialNumber: supportState.SerialNumber});
    } else {
        store.dispatch(ReduxActions.describe({resource: ReduxResources.SUPPORT})({})).unwrap()
            .then((response) => {
                if (response.data.SupportInformations && !isEmpty(response.data.SupportInformations[0])) {
                    const supportInfo = response.data.SupportInformations[0];
                    if (!supportInfo.SerialNumber) {
                        toast.info(translate("csp.toastForSerialNumber"))
                    } else {
                        openLink({serialNumber: supportInfo.SerialNumber});
                    }
                } else {
                    toast.info(translate("csp.toastForSerialNumber"))
                }
            });
    }

    return;
}

export const checkCSPBanner = ({translate, reduxState}: any) => {
    const externalIcon = <ExternalLinkIcon size="xs" className="tw-pl-2" />;
    if (!reduxState.support.cspEnable) {
        callPANWDBanner({
            label: translate("csp.registerCSPBanner"),
            actions: <>
                <Button appearance="clear" size="sm"
                        onClick={() => window.open("https://docs.paloaltonetworks.com/cdss", "_blank")}>
                    Learn More
                    {externalIcon}
                </Button>
                <Button appearance="primary" size="sm"
                        dataMetrics={"cloudngfw-csp-redirect"}
                        onClick={() => callCSPRedirectLink({supportState: reduxState.support, translate})}>
                    {translate("csp.goToCSP")}
                    {externalIcon}
                </Button>
            </>,
            appearance: "warning",
        })
    }
    if (reduxState.support.PubSubMessageStatus && reduxState.support.PubSubMessageStatus === 'SupportAccountPending') {
        callPANWDBanner({
            label: translate("csp.waitForSupportAccount"),
            appearance: "warning",
        })
    }
    return;
};
