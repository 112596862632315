import _, { get, isEmpty } from 'lodash';

// @ts-ignore
export const onSearchChangesUtils = ({ columns, value, gridData, getGridDataValue, callback }) => {
  const lowerSearchText = value && value.toLowerCase();
  if (value?.trim() === "") {
    callback([]);
    return;
  }
  const newData = gridData?.filter((p: any) => {
    return columns.reduce((acc: any, col: any) => {
      const value = getGridDataValue(col.name, p);
      const v = (value).toLowerCase();
      return acc || v.indexOf(lowerSearchText) !== -1; // make the search case-insensitive
    }, false);
  });
  callback(newData || []);
};


// to be used in .filter HOF of gridData
export const searchGridDataFilter = ({ columns, value, row }: any) => {
  const lowerSearchText = value && value.toLowerCase();
  if (value?.trim() === "") {
    return true;
  }
  
  return columns.some((col: any) => {
    const value = get(row, col.name, '');
    const v = value.toLowerCase();
    return v.indexOf(lowerSearchText) !== -1; // make the search case-insensitive
  });
};