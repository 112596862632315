import { Box, FormControl, Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState, Fragment } from "react";
import { Field, FormSpy } from "react-final-form";
import { useHistory, withRouter } from "react-router-dom";
import { Button as PANWDSButton } from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from "../../components";
import { Row } from "../../components/FormElements";
import { dataProvider } from "../../dataProvider";
import { isAlphaNumeric, isFeedURL, isIpV4, isRequired, maxLength, selectIsRequired, composeValidators } from "../../utils/validate";
import { ApplicationConfigManager } from "../../types";
import {PANWDSBreadcrumbs, PANWDSForm, PANWDSInput, PANWDSSelect} from '../../components/PANWDSElements';
import {useTranslate} from '../../customHooks';

export const styles = {
  price: { width: "7em" },
  width: { width: "7em" },
  height: { width: "7em" },
  stock: { width: "7em" },
  widthFormGroup: { display: "inline-block" },
  heightFormGroup: { display: "inline-block", marginLeft: 32 },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

//interface FormValues {
//  FeedURL?: string;
//}

const hours = [
  "00:00 AM", "01:00 AM", "02:00 AM", "03:00 AM", "04:00 AM", "05:00 AM", "06:00 AM", "07:00 AM",
  "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "13:00 PM", "14:00 PM", "15:00 PM",
  "16:00 PM", "17:00 PM", "18:00 PM", "19:00 PM", "20:00 PM", "21:00 PM", "22:00 PM", "23:00 PM",
];
export const timeOptions = hours.map((hour: string, index: number) => ({ text: hour, value: index }));

const FeedCreate = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const [certificateProfiles, setCertificateProfiles] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  const [record, setRecord] = useState<any>({});

  const FeedCreateToolbar = (toolbarProps: any) => (
    <div className={classes.toolbar}>
      <PANWDSButton
        size="md"
        appearance="secondary"
        disabled={submitting}
        dataTestId="rulestack-create-cancel"
        onClick={() => {
          history.goBack();
        }}
        dataMetrics="cloudngfw-feed-create-cancel-button"
      >
        Cancel
      </PANWDSButton>
      <SaveButton
        appearance="primary"
        size="md"
        label={translate("ra.action.create")}
        redirect={false}
        submitOnEnter={true}
        className={classes.button}
        loading={submitting}
        dataMetrics="cloudngfw-feed-create-save-button"
        {...toolbarProps}
      />
    </div>
  );

  useEffect(() => {
    dataProvider.getList("certificate", {
      pagination: { page: 1, perPage: 5 },
      sort: { field: 'Name', order: 'ASC' },
      filter: {},
      data: { 'RuleStackName': ruleStackName }
    }).then(async (response: any) => {
      if (response.data) {
        setCertificateProfiles(response.data.map((certificate: any) => ({ value: certificate.Name })));
      } else {
        toast.error(response?.error, { toastId: "certificate-getList" });
      }
    })
      .catch((e: any) => {
        toast.error(e?.error, { toastId: "certificate-getList" });
      });
  }, [ruleStackName]);

  const save = useCallback(
    async (values) => {
      if (record) {
        values = { ...values, RuleStackName: ruleStackName }
      }
      if (values.Frequency !== "DAILY") {
        delete values.Time;
      }
      setSubmitting(true);
      try {
          await dataProvider.create("feed", { data: values });
        //toast.success("Success");
        history.goBack();
      } catch (error: any) {
        toast.error(error?.error, { toastId: "feed-create" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  );

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: `${translate(`resources.ruleStacks.name`)} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`feeds`]: translate(`resources.feed.name`),
          create: translate(`resources.feed.fields.CreateButton`),
        }}
      />
      <PANTitle title={translate(`resources.feed.fields.CreateButton`)} divider />
      <Box style={{ position: 'relative' }}>

          <PANWDSForm
              onSubmit={save}
              toolbar={<FeedCreateToolbar />}
              validate={(values: any) => {
                  let errors: any = {};
                  // if (values.Type === "URL_LIST") {
                  //     errors.FeedURL = isFeedURL(values?.FeedURL);
                  // }
                  // if (values.Type === "IP_LIST") {
                  //     errors.FeedURL = isIpV4(values?.FeedURL);
                  // }
                  return errors;
              }}
              style={{ margin: 16 }}
          >
              <Fragment>
                  <Grid container style={{ width: 'auto', gap: 16 }}>
                      <PANTile title={translate(`resources.feed.fields.General`)} subtitle={translate(`resources.feed.fields.CreateSubtitle`)} size={12}>
                          <Row>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="Name"
                                          // @ts-ignore
                                          component={PANWDSInput}
                                          title={translate(`resources.feed.fields.Name`)}
                                          dataMetrics="cloudngfw-feed-create-field-name"
                                          required
                                          validate={composeValidators(isAlphaNumeric, maxLength(63), isRequired)}
                                      />
                                  </FormControl>
                              </Grid>
                          </Row>
                          <Row>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="Description"
                                          // @ts-ignore
                                          component={PANWDSInput}
                                          title={translate(`resources.feed.fields.Description`)}
                                          dataMetrics="cloudngfw-feed-create-field-description"
                                          validate={maxLength(512)}
                                      />
                                  </FormControl>
                              </Grid>
                          </Row>
                      </PANTile>
                      <PANTile title={translate(`resources.feed.fields.FeedSource`)} size={12}>
                          <Row>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="Type"
                                          // @ts-ignore
                                          component={PANWDSSelect}
                                          items={[
                                              { text: "IP List", value: "IP_LIST" },
                                              { text: "URL List", value: "URL_LIST" },
                                          ]}
                                          title={translate(`resources.feed.fields.Type`)}
                                          dataMetrics="cloudngfw-feed-create-field-type"
                                          required
                                          validate={selectIsRequired}
                                      />
                                  </FormControl>
                              </Grid>
                          </Row>
                          <Row>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="FeedURL"
                                          // @ts-ignore
                                          component={PANWDSInput}
                                          title={translate(`resources.feed.fields.FeedURL`)}
                                          dataMetrics="cloudngfw-feed-create-field-feedurl"
                                          required
                                          validate={composeValidators(maxLength(255), isRequired)}
                                      />
                                  </FormControl>
                              </Grid>
                          </Row>
                          <Row>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="Certificate"
                                          // @ts-ignore
                                          component={PANWDSSelect}
                                          items={certificateProfiles}
                                          title={translate(`resources.feed.fields.CertificateProfile`)}
                                          dataMetrics="cloudngfw-feed-create-field-certificate"
                                          allowEmpty
                                      />
                                  </FormControl>
                              </Grid>
                          </Row>
                          <Row>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="Frequency"
                                          // @ts-ignore
                                          component={PANWDSSelect}
                                          items={[
                                              { text: "Hourly", value: "HOURLY" },
                                              { text: "Daily", value: "DAILY" }
                                          ]}
                                          title={translate(`resources.feed.fields.UpdatesFrequency`)}
                                          dataMetrics="cloudngfw-feed-create-field-frequency"
                                          required
                                          validate={selectIsRequired}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                  <FormControl fullWidth>
                                      <FormSpy subscription={{ values: true }}>
                                          {({values}) => (
                                              values.Frequency === "DAILY" && <Field
                                                  title="&nbsp;"
                                                  name="Time"
                                                  // @ts-ignore
                                                  component={PANWDSSelect}
                                                  dataMetrics="cloudngfw-feed-create-field-time"
                                                  items={timeOptions}
                                                  initialValue={3}
                                              />
                                          )}
                                      </FormSpy>
                                  </FormControl>
                              </Grid>
                          </Row>
                      </PANTile>
                  </Grid>
              </Fragment>
          </PANWDSForm>
      </Box>
    </>
  );
};

export default withRouter(FeedCreate);
