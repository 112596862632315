import React from "react";
import {useOktaAuthClient} from "../customHooks";
import {oktaProvider} from "../authProvider";
import {RouteUri} from "../routeUri";
import {useHistory} from "react-router-dom";
import {isEmpty} from "lodash";

class LoginCallbackProps {
    errorComponent?: any;
    loadingElement?: any;
    onAuthResume?: any;
}

const LoginCallback: React.FC<LoginCallbackProps> = ({ errorComponent, loadingElement = null, onAuthResume }) => {
    const [callbackError, setCallbackError] = React.useState(null);
    const history = useHistory();

    const ErrorReporter = errorComponent || null;

    const parseUrl = async () => {
        await oktaProvider.parseFromUrl()
            .then(async (res: any) => {
                const state = res.state; // passed to getWithRedirect(), can be any string
                // manage token or tokens
                const tokens = res.tokens;
                if (!isEmpty(state)) {
                    history.replace(RouteUri.Register, {queryString: state, tokens});
                } else {
                    await oktaProvider.setToken({tokens});
                    history.replace(RouteUri.Dashboard);
                }
            })
            .catch((err: any) => {
                setCallbackError(err);
                // handle OAuthError
            });
    };

    React.useEffect(() => {
        parseUrl().catch(console.error);
    }, [useOktaAuthClient]);

    const displayError = callbackError;
    if (displayError) {
        return <ErrorReporter error={displayError}/>;
    }

    return loadingElement;
};

export default LoginCallback;
