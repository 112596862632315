import {ApplicationConfigManager, OktaProvider} from "./types";
import {useOktaAuthClient} from "./customHooks";

const oktaProvider = (): OktaProvider => ({
    signIn: async (options: any) => {
        return await useOktaAuthClient.signInWithRedirect({originalUri: '/', ...options});
    },
    parseFromUrl: async () => {
        return await useOktaAuthClient.token.parseFromUrl();
    },
    setToken: async ({tokens}: any) => {
        localStorage.setItem('idToken', tokens?.idToken?.idToken);
        await useOktaAuthClient.tokenManager.setTokens(tokens);
        //await useOktaAuthClient.handleLoginRedirect(tokens, '/');
        //await ApplicationConfigManager.getInstance().loadConfig();
        return;
    }
});

export default oktaProvider;
