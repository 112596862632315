const pendoInitialize = ({UserName = ''}) => {

  const pendo = window.pendo;
  pendo.initialize({
    visitor: {
      id: UserName           // Required if user is logged in
      // email:        // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    }
  });
};

interface PendoUpdateValues {
  UserName: any;
  FirstName?: string;
  LastName?: string;
}

const pendoUpdate = ({UserName, FirstName, LastName}: PendoUpdateValues) => {

  const pendo = window.pendo;
  if (UserName) {
    pendo.updateOptions({
      visitor: {
        id: UserName,
        email: UserName,
        full_name: `${FirstName} ${LastName}`
      },
    });
  }
};

export {pendoInitialize, pendoUpdate};
