import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {ApplicationConfigManager, CognitoProvider} from './types';
import { isEmpty } from 'lodash';

const cognitoProvider = (
): CognitoProvider => ({
    signIn: ({ email, password, state }: { email: any, password: any, state: any }) => {
        return new Promise((resolve, reject) => {
            const authenticationData = {
                Username: email,
                Password: password,
            };
            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
            ApplicationConfigManager.cognitoInstance.setCognitoUser(email);
            const cognitoUser: CognitoUser = ApplicationConfigManager.cognitoInstance.getCognitoUser();
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async function (session) {
                    var idToken = session.getIdToken().getJwtToken();
                    //var accessToken = session.getAccessToken().getJwtToken();
                    //console.log("loading session from a valid signin and token");

                    if(isEmpty(state)) {
                        localStorage.setItem('idToken', idToken);
                        ApplicationConfigManager.cognitoInstance.setCognitoUserSession(session);
                    }
                    //await ApplicationConfigManager.getInstance().loadConfig();
                    return resolve(session);
                },
                onFailure: function (err) {
                    return reject(err);
                },

                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    localStorage.setItem('reset', userAttributes?.email);
                    resolve({ redirectTo: '/setPassword', email: userAttributes?.email });
                }
            })
        });
    },
    setPassword: ({ email, oldPassword, password }: { email: string, oldPassword: string, password: string }) => {
        var authenticationData = {
            Username: email,
            Password: oldPassword,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        ApplicationConfigManager.cognitoInstance.setCognitoUser(email);
        var cognitoUser = ApplicationConfigManager.cognitoInstance.getCognitoUser();
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (session) {
                    localStorage.removeItem('reset')
                    return resolve(session);
                },

                onFailure: function (err) {
                    localStorage.removeItem('reset')
                    return reject(err);
                },

                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    cognitoUser.completeNewPasswordChallenge(password, requiredAttributes, {
                        onSuccess: function (session) {
                            return resolve(session);
                        },

                        onFailure: function (err) {
                            return reject(err);
                        }

                    })
                }
            })
        });
    },
    forgotPassword: ({ email }: { email: string }) => {
        //store the cognitoUser as we need to confirm on the same
        ApplicationConfigManager.cognitoInstance.setCognitoUser(email);
        const cognitoUser = ApplicationConfigManager.cognitoInstance.getCognitoUser();
        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: function (data) {
                    //console.log('CodeDeliveryData from forgotPassword: ', data);
                    return Promise.resolve(data);
                },

                onFailure: function (err) {
                    console.log(err.message || JSON.stringify(err));
                    return Promise.reject(err);
                },
                //Optional automatic callback
                inputVerificationCode: function (data) {
                    //console.log('Code sent to: ', data);
                },
            })
        });
    },
    confirmPassword: ({ email, verificationCode, password }: {
        email: string, verificationCode: string,
        password: string
    }) => {
        ApplicationConfigManager.cognitoInstance.setCognitoUser(email);
        const cognitoUser = ApplicationConfigManager.cognitoInstance.getCognitoUser();
        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, password, {
                onSuccess: function (session: any) {
                    //console.log('forgotPassword confirmation successful: ', session);
                    return resolve(session);
                },

                onFailure: function (err: any) {
                    return reject(err);
                }

            })
        });
    },
    changePassword: ({oldPassword, password}: {oldPassword: string, password: string}) => {
        return new Promise((resolve, reject) => {
            ApplicationConfigManager.cognitoInstance.getCognitoUser().changePassword(oldPassword, password, (err: any, result: any) => {
                if (!err && result) {
                    resolve(result);
                } else {
                    reject(err);
                }
            })
        })
    },
    login: (auth) => {
        //console.log("inside  authprovider login ");
        return Promise.resolve(auth);
    },
    refreshToken: async ({updatePermission = false}) => {
        try {
            const cognitoInstance = ApplicationConfigManager.cognitoInstance;
            await cognitoInstance.refreshSession(cognitoInstance.getCognitoUserSession());
            if (updatePermission) {
                await ApplicationConfigManager.getInstance().ensureUserDisplayName(() => {});
            }
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
        // const cognitoUser = ApplicationConfigManager.cognitoInstance.getCognitoUser();
        // //@ts-ignore
        // const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: cognitoUser?.signInUserSession?.refreshToken?.token});
        // return new Promise((resolve, reject) => {
        //     //@ts-ignore
        //     cognitoUser.refreshSession(RefreshToken, async (session, user) => {
        //         const idToken = user?.idToken?.getJwtToken();
        //         localStorage.setItem('idToken', idToken);
        //         await ApplicationConfigManager.getInstance().ensureUserDisplayName(() => {});
        //     })
        // });
    },
});

export default cognitoProvider;
