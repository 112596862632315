import {Tooltip, TooltipProps} from "@panwds/react-ui";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        display: "inline-block!important"
    }
}))

interface PANWDSTooltip extends TooltipProps {
    showTooltip: boolean
}

const Index = (props: PANWDSTooltip) => {
    const {children, showTooltip = false} = props;
    const classes = useStyles();

    if (showTooltip) {
        return <Tooltip addClassName={classes.tooltip} {...props} defaultPlacement={props.defaultPlacement || "top"}>
            {children}
        </Tooltip>
    } else {
        return <>{children}</>;
    }
};

export default Index;
