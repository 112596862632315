import { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import { Skeleton, Link } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Failed, Pending, Success } from '../../images';
import { IValueOptions } from '../../types';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useHistory } from "react-router-dom";
import SplitButton from '../Buttons/SplitButton';
import {useTranslate} from '../../customHooks';

const useStyles = makeStyles((theme) => ({
  commitStatusString: {
    color: '#707070',
    fontSize: '12px',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 'left',
    marginTop: '5px'
  },
  commitStatus: {
    color: '#333333',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  commitStatusImage: {
    margin: '0px 4px'
  },
  keyHeader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#707070',
    paddingTop: '18px',
    paddingBottom: '8px',
    paddingRight: '40px',
    paddingLeft: 0,
  },
  dropdownHeader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#707070',
    paddingTop: '18px',
    paddingRight: '40px',
    paddingLeft: 0,
    paddingBottom: '2px',
  },
  valueHeader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#333333',
    paddingRight: '40px',
  },
  gridStyles: {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'row',
  },
  linkStyles: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#006FCC !important',
    paddingRight: '40px',
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none !important',
    cursor: 'pointer'

  },
  verticalLine: {
    borderLeft: '1px solid #DADBDB',
    width: '4px',
    height: '24px',
    marginLeft: '10px'
  },
  openInNewIcon: {
    width: '12px',
    height: '12px',
    marginTop: '5px',
    marginLeft: '3px'
  },
  dropdownStyles: {
    "&>section": {
      "&>div": {
        "&>button": {
          border: 'none !important',
          padding: '0',
          width: 'auto',
          "&+span": {
            marginTop: '0',
          },
          "&>span": {
            justifyContent: 'flex-start !important',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '12px',
            color: '#006FCC !important',
          }
        }
      }
    }
  }
}));

export const OverviewPanel = ({ panelData }: any) => {
  const {key, values, isLoading, openModal} = panelData;
  const classes = useStyles();
  const history = useHistory();
  const translate = useTranslate();

  const openAssociatedLink = (linkOption: string, linkToOpen: any) => {
    if(linkOption === 'modal') {
      openModal(true);
    } else if(linkOption === 'internal') {
      history.replace(linkToOpen);
    } else if(linkOption === 'external') {
      window.open(linkToOpen, '_blank');
    }
  }

  const getGridValue = ({text, isLink, isStatus, isStatusLink, isDropdownLink, linkOption='internal', linkToOpen, dropDownActionsArray=[], isNormalText} : IValueOptions, index: number) => {
    if(isStatus && !isLoading) {
      return (
        (text !== undefined || text !== '') ?
        <Fragment key={index}>
          <img key={index + '-img'} className={classes.commitStatusImage} src={(text === "Success" || text === "Associated") ? Success : (text === "Failed" || text === "NotAssociated") ? Failed : Pending} alt="Status" />
          <span key={index + '-text'}>{text}</span>
        </Fragment> :
        <div className={classes.valueHeader} key={index}>{translate("resources.overviewPanel.na")}</div>
      )
    }
    else if(isLink && !isLoading) {
        return (<Fragment key={index}>
          <Link
            className={classes.linkStyles}
            key={index}
            onClick={() => {
              openAssociatedLink(linkOption, linkToOpen)
            }}
          >
            <div>{text}</div>
            {linkOption === 'external' && <OpenInNewIcon className={classes.openInNewIcon}/>}
            {index+1 !== values.length &&
              <div className={classes.verticalLine}></div>
            }
          </Link>
          </Fragment>
        )
    } else if(isStatusLink && !isLoading) {
      return (<Fragment key={index}>
        <Link
          className={classes.linkStyles}
          key={index}
          onClick={() => {
            openAssociatedLink(linkOption, linkToOpen)
          }}
        >
         <>
          <img key={index + '-img'} className={classes.commitStatusImage} src={(text[1] === "Success") ? Success : (text[1] === "Failed") ? Failed : Pending} alt="Status" />
          <span key={index + '-text'}>{text[0]}</span>
         </>
        </Link>
        </Fragment>
      )
    } else if(isDropdownLink && !isLoading) {
        return (
        <div className={classes.dropdownStyles} key={index}>
          <SplitButton
                    buttons={dropDownActionsArray}
                    title={text}
                    icon='external'
                  />
        </div>)
    }
    else if(isNormalText && !isLoading) {
      return (
      <div key={index} className={classes.valueHeader}>{text}</div>)
    }
  }

  return (
    <>
      <Grid container spacing={3} direction="column" className={classes.gridStyles}>
        <Grid item xs="auto" className={classes.gridStyles}>
          <div className={values[0]?.isDropdownLink ? classes.dropdownHeader : classes.keyHeader}>{key}</div>
        </Grid>
        {isLoading && <Skeleton variant="text" width="50px" height="30px" />}
        <Grid item xs="auto" className={classes.gridStyles}>
          {(!isLoading && values?.length === 0) ? <div className={classes.valueHeader}>{translate("resources.overviewPanel.na")}</div>
          : values?.map(({text, isLink, isStatus, isStatusLink, isDropdownLink, linkOption, linkToOpen, dropDownActionsArray, isNormalText}: IValueOptions, index: number) =>
              getGridValue({text, isLink, isStatus, isStatusLink, isDropdownLink, linkOption, linkToOpen, dropDownActionsArray, isNormalText}, index))}
          </Grid>
      </Grid>
    </>
  );
}

export default OverviewPanel;
