import {createSlice} from '@reduxjs/toolkit'
import * as initialState from "../utils/initialStates";
import {
    compareList,
    create,
    deleteResource,
    describe,
    fetchList,
    fetchListDetails,
    resetListDetails,
    updateResource
} from "../actions";
import Resources from '../utils/enums';
import {isEmpty, last} from "lodash";



const firewallsSlice = createSlice({
    name: Resources.FIREWALL,
    initialState: initialState.firewallsInitialState,
    reducers: {},
    extraReducers: (builder) => {
        const resource = Resources.FIREWALL;
        builder
            .addCase(fetchListDetails({resource}).pending, (state) => {
                state.listDetails.loading = true;
            })
            .addCase(fetchListDetails({resource}).fulfilled, (state, action) => {
                state.listDetails.loading = false;
                if (action.meta.arg.data?.NextToken) {
                    state.listDetails.data = [...state.listDetails.data, ...action.payload.data];
                    state.listDetails.total += action.payload.total;
                } else {
                    state.listDetails.data = action.payload.data;
                    state.listDetails.total = action.payload.total;
                }
                if (!action.meta.arg?.notSaveNextToken) {
                    state.listDetails.nextToken = action.payload.nextToken;
                }
            })
            .addCase(fetchListDetails({resource}).rejected, (state, action) => {
                state.listDetails.loading = false;
                if (!action.payload?.noToast) {
                    state.listDetails.error = action.payload;
                }
            })
            .addCase(fetchList({resource}).pending, (state, action) => {
                state.list.loading = true;
            })
            .addCase(fetchList({resource}).fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.data = action.payload.data;
            })
            .addCase(fetchList({resource}).rejected, (state, action) => {
                state.list.loading = false;
                state.list.error = action.error.message;
            })
            .addCase(compareList({resource}).pending, (state, action) => {
                state.listDetails.loading = true;
            })
            .addCase(compareList({resource}).fulfilled, (state, action) => {
                state.list.data = action.payload.list;
                state.listDetails.data = action.payload.listDetails;
                state.listDetails.total = action.payload.listDetails.length;
                state.listDetails.loading = false;
            })
            .addCase(resetListDetails({resource}).fulfilled, (state, action) => {
                state.listDetails = initialState.listDetails;
                state.list = initialState.list;
            })
            .addCase(describe({resource}).fulfilled, (state, action) => {
                if (action.payload.error) {
                    // When one api is giving error there is no point to set the whole list to initialState
                    //state.listDetails = initialState.listDetails;
                } else {
                    let firewallPayload = action.payload.data;
                    // @ts-ignore
                    const lastFirewallId = last(state.listDetails.data).id;
                    firewallPayload.id = lastFirewallId + 1;
                    state.listDetails.data = [...state.listDetails.data, firewallPayload];
                    state.listDetails.total += 1;
                    if (!isEmpty(state.list.data) && !isEmpty(state.listDetails.data) && state.list.data.length === state.listDetails.total) {
                        const list = state.list.data;
                        const listDetails = state.listDetails.data;
                        const newList: any[] = [];
                        let index: number = Math.floor(100000 + Math.random() * 900000);
                        list.map((l: any) => {
                            const firewall = listDetails.find((ld: any) => ld.Firewall.FirewallName === l.FirewallName);
                            firewall.id = index++;
                            newList.push(firewall);
                        })
                        state.listDetails.data = newList;
                    }
                }
            })
            .addCase(describe({resource}).rejected, (state, action) => {
                state.listDetails = initialState.listDetails;
            })
            .addCase(deleteResource({resource}).fulfilled, (state, action) => {
                const deletedItems = action.meta.arg?.deletedItems;
                if (deletedItems && deletedItems.length > 0) {
                    const filteredData = state.listDetails.data.filter((l: any) => deletedItems.findIndex((di: any) => di.Firewall.FirewallName === l?.Firewall?.FirewallName) === -1);
                    state.listDetails.data = filteredData;
                    state.listDetails.total = filteredData.length;
                    state.listDetails.loading = false;
                }
            })
            .addCase(updateResource({resource}).fulfilled, (state, action) => {
                let firewallPayload = action.payload.data;
                const firewallName = action.meta.arg.FirewallName;
                const index = state.listDetails.data.findIndex((d: any) => d.Firewall.FirewallName === firewallName);
                if (index !== -1) {
                    state.listDetails.data[index].Firewall = firewallPayload.Firewall;
                    state.listDetails.data[index].Status = firewallPayload.Status;
                }
            })
    }
});

export const {} = firewallsSlice.actions;

export default firewallsSlice.reducer
