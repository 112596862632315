import { withRouter } from "react-router-dom";
import { FormControl, Grid, makeStyles } from "@material-ui/core";
import { kebabCase } from "lodash";
import { usePermissions } from "../../../customHooks";
import { useTranslate } from "../../../customHooks";
import { SaveButton, toast } from "../../../components";
import { dataProvider } from "../../../dataProvider";
import { Button, Sheet, SheetBody, ModalBody } from "@panwds/react-ui";
import { Field } from "react-final-form";
import { CaretLeftIcon } from "@panwds/icons";
import { PANWDSForm, PANWDSInput } from "../../../components/PANWDSElements";
import { Button as PANWDSButton } from "@panwds/react-ui";
import { useCallback, useState } from "react";
import { Row } from "../../../components/FormElements";
import {
    TableProvider,
    ConnectedTable,
    ConnectedTableLayout,
} from "@panwds/react-table";
import {
    composeValidators,
    requiredValidator,
    maxLength,
    isAlphaNumericWithUnderscore,
} from "../../../utils/validate";
import { PANWDSTable } from "../../../components/PANWDSElements/PANWDSTable";
import { PANWDSModal } from "../../../components/PANWDSElements/PANWDSModal";
import _ from "lodash";

const useStyles = makeStyles((theme) => {
    return {
        backButtonHeader: {
            display: "flex",
            marginBottom: "18px",
            alignItems: "center",
            padding: "16px",
        },
        backButtonTitle: {
            marginLeft: "12px",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#000000",
        },
        toolbar: {
            background: "transparent",
            display: "flex",
            gap: theme.spacing(1),
            justifyContent: "space-between",
            padding: "10px 0",
            alignItems: "center",
        },
        iconedParagraph: {
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
            gap: "10px",
            color: "#333333",
        },
        leftPosStyle: {
            margin: "8px 0 0 16px",
        },
        rightPosStyle: {
            display: "flex",
        },
        modalHeader: {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "20px",
            padding: "16px 16px 0",
        },
        deleteListStyles: {
            height: '50px',
            overflowY: 'scroll'
        }
    };
});

const VpcGroupEdit = ({
    isOpenTags,
    setOpenTags,
    getManageVpcGroup,
    rowData,
    callBackVpcList
}: any) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const classes = useStyles();
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [confirmDelete, setConfirmDelete] = useState<any>({ open: false });
    const [vpcDataRaw, setVpcDataRaw] = useState<[]>([]);
    const [vpcGroupData, setVpcGroupData] = useState<never[]>([]);

    setTimeout(() => setLoading(false), 500);

    const goToManageVPCs = () => {
        getManageVpcGroup();
    };

    const loadVPCData = (needRefresh = false) => {
        setLoading(true);
        if (!vpcDataRaw.length || needRefresh) {
            dataProvider
                .getList("vpcs", { data: { refresh: needRefresh } })
                .then((response) => {
                    if (response?.data) {
                        // @ts-ignore
                        setVpcDataRaw(response?.data);
                        triggerVpcGroupCall(response?.data);
                    } else {
                        toast.error(response, { toastId: `vpc-list-error` });
                    }
                })
                .catch((error: any) => {
                    if (error?.noToast) {
                        return;
                    }
                    toast.error(error?.error, { toastId: `vpc-list-error` });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const loadVPCGroups = (needRefresh = false, vpcDataPretty?: any) => {
        // if (!permissions?.ListRuleStacks) {
        //     if (permissions && !permissions?.ListRuleStacks) {
        //         // TODO toast is not showing, but if we add toast container it will show
        //         // many times until permissions is loaded, need to rethink this logic
        //         toast.warning(translate("permissions.cantView"));
        //     }
        //     setLoading(false);
        //     return;
        // }
        if (!vpcGroupData?.length || needRefresh) {
            dataProvider
                .getList("vpcGroups")
                .then((response) => {
                    if (response?.data) {
                        // @ts-ignore
                        let combData = getCombinedVPCGroupData(
                            response?.data,
                            needRefresh,
                            vpcDataPretty
                        );
                        setVpcGroupData(combData);
                    } else {
                        toast.error(response, {
                            toastId: `vpc-group-list-error`,
                        });
                    }
                })
                .catch((error: any) => {
                    if (error?.noToast) {
                        return;
                    }
                    toast.error(error?.error, {
                        toastId: `vpc-group-list-error`,
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
        }
    };

    const getCombinedVPCGroupData = (responseData: any, needRefresh: any, vpcDataPretty?: any) => {
        let combinedVpcGroup: any = [];
        var vpcGroups = _.mapValues(_.groupBy(vpcDataPretty, 'VpcGroup'),
                        clist => clist?.map(group => _.omit(group, 'VpcGroup')));
        Object.keys(vpcGroups).forEach(function (key) {
          if(!_.isEmpty(responseData)) {
              responseData.forEach(function (vpcGroup: any) {
              if (vpcGroup.Name === key) {
                let newAdd: any = {
                  ...vpcGroup,
                  noOfVPCscount: vpcGroups[key]?.length,
                  vpcsList: vpcGroups[key],
                };
                vpcGroup.Name === "Default" ? combinedVpcGroup.unshift(newAdd) : combinedVpcGroup.push(newAdd);
                return;
              }
            })
          } else {
            let newAdd: any = {
              Name: key,
              noOfVPCscount: vpcGroups[key]?.length,
              vpcsList: vpcGroups[key],
            };
            key === "Default" ? combinedVpcGroup.unshift(newAdd) : combinedVpcGroup.push(newAdd);
            return;
          }
        });
        const leftOvergroupsFromApi = responseData.filter((id1: any) => !combinedVpcGroup.some((id2: any) => id2.Name === id1.Name));
        leftOvergroupsFromApi.forEach((vpcGroup: any) => {
          let newAdd: any = {
            ...vpcGroup,
            noOfVPCscount: 0,
            vpcsList: [],
          };
          vpcGroup?.Name === "Default" ? combinedVpcGroup.unshift(newAdd) : combinedVpcGroup.push(newAdd);
        });
        return combinedVpcGroup;
      };

    const triggerVpcGroupCall = (vpcDataFromRefresh: any) => {
        loadVPCGroups(true, vpcDataFromRefresh);
    };

    const save = useCallback(
        async (values: any) => {
            let { VpcGroupEntry } = values;
            let payload: any = {
                Name: values?.Name,
                VpcGroupEntry: VpcGroupEntry,
            };
            setSubmit(true);
            try {
                await dataProvider.update("vpcGroups", payload);
                //toast.success("Success");
                getManageVpcGroup();
                return;
            } catch (error: any) {
                toast.error(error?.error, { toastId: "vpcgroup-update" });
                return;
            } finally {
                setSubmit(false);
            }
        },
        [history, permissions]
    );

    const getDeleteView = (selectedVPCs: any) => {
        setConfirmDelete({ open: true, selectedVPCs });
    };

    const CreateVpcGroupToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        let { pristine, submitting, invalid } = toolbarProps;
        return (
            <div className={classes.toolbar}>
                <div className={classes.leftPosStyle}>
                    <PANWDSButton
                        size="md"
                        appearance="secondary-destructive"
                        disabled={submitting || submit}
                        buttonPosition="left"
                        onClick={() => getDeleteView(rowData)}
                        dataMetrics="cloudngfw-vpcGroupName-create-cancel-button"
                    >
                        Delete
                    </PANWDSButton>
                </div>
                <div className={classes.rightPosStyle}>
                    <PANWDSButton
                        size="md"
                        appearance="secondary"
                        disabled={submitting || submit}
                        onClick={() => {
                            getManageVpcGroup();
                        }}
                        dataMetrics="cloudngfw-vpcGroupName-create-cancel-button"
                    >
                        Cancel
                    </PANWDSButton>
                    <SaveButton
                        appearance="primary"
                        size="md"
                        redirect={false}
                        submitOnEnter={true}
                        loading={submitting}
                        disabled={submitting || invalid || pristine || submit}
                        dataMetrics="cloudngfw-vpcGroupName-create-save-button"
                        {...toolbarProps}
                    />
                </div>
            </div>
        );
    };

    const onCloseCreateSheet = () => {
        setOpenTags(false);
        getManageVpcGroup();
    };

    const getTagsColumns = () => {
        return [
            {
                accessor: "VpcId",
                Header: translate(`resources.inventory.vpc.vpc`),
                columnSize: 6,
                render: ({ row }: any) => {
                    return (
                        <span
                            key={`tags-record-${kebabCase(
                                row?.original?.VpcId
                            )}`}
                            // className={classes.appColumnStyles}
                            data-test-id={`tags-record-${kebabCase(
                                row?.original?.VpcId
                            )}`}
                        >
                            {row?.original?.VpcId}
                        </span>
                    );
                },
            },
            {
                accessor: "AccountId",
                Header: translate(`resources.inventory.vpc.awsaccount`),
                columnSize: 6,
                render: ({ row }: any) => {
                    return (
                        <span
                            key={`vpc-record-${kebabCase(
                                row?.original?.AccountId
                            )}`}
                            //className={classes.appColumnStyles}
                            data-test-id={`vpc-record-${kebabCase(
                                row?.original?.AccountId
                            )}`}
                        >
                            {row?.original?.AccountId}
                        </span>
                    );
                },
            },
        ];
    };

    const getModalColumns = () => [
        {
            accessor: "VpcId",
            Header: translate(`resources.inventory.vpc.vpc`),
            columnSize: 4,
            render: ({ row }: any) => {
                return (
                    <span
                        key={`tags-record-${kebabCase(row?.original?.VpcId)}`}
                        // className={classes.appColumnStyles}
                        data-test-id={`tags-record-${kebabCase(
                            row?.original?.VpcId
                        )}`}
                    >
                        {row?.original?.VpcId}
                    </span>
                );
            },
        },
    ];

    const getModalBody = () => {
        const groupName = confirmDelete?.selectedVPCs?.Name;
        return (
            <>
                <div className={classes.modalHeader}>{translate(`resources.inventory.vpcGroups.deleteGroupMessage`)} "{groupName}", {translate(`resources.inventory.vpcGroups.deleteSubGroupMessage`)} "{groupName}"?</div>
                <ModalBody addClassName="tw-overflow-auto">
                    <TableProvider
                        columns={getModalColumns()}
                        data={confirmDelete?.selectedVPCs?.vpcsList}
                    >
                        <ConnectedTableLayout>
                            <ConnectedTable />
                        </ConnectedTableLayout>
                    </TableProvider>
                </ModalBody>
            </>
        );
    };

    const deleteVPCGroup = (payload: any) => {
        setLoading(true);
        dataProvider
            .delete("vpcGroups", payload)
            .then((response) => {
                if (response) {
                    // @ts-ignore
                    let message = (
                        <>
                            Vpc group deleted succesfully:
                            <ul>{payload?.Name}</ul>
                        </>
                    );
                    // loadVPCData(true);
                    // setTimeout(() =>  {
                    //     callBackVpcList();
                    // }, 5000);
                    // goToManageVPCs();
                    toast.success(message);
                    setConfirmDelete({ open: false });
                    callBackVpcList();
                } else {
                    toast.error(response, { toastId: `vpc-group-list-error` });
                    setConfirmDelete({ open: false });
                }
            })
            .catch((error: any) => {
                if (error?.noToast) {
                    return;
                }
                toast.error(error?.error, { toastId: `vpc-group-list-error` });
                setConfirmDelete({ open: false });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const confirmChangeVpcGroup = (selItems: any, groupChange: any) => {
        if (selItems && Array.isArray(selItems)) {
            setLoading(true);
            const successItems: any = [];
            const failedItems: any = [];
            const commitedSuccess: any = [];
            let pendingCalls = 0;
            selItems.map((item: any) => {
                const vpcPayload: any = {
                    AccountId: item?.AccountId,
                    VpcId: item?.VpcId,
                    VpcGroupEntry: {
                        VpcGroup: groupChange?.toString()?.split("(")?.[0],
                    },
                };
                dataProvider
                    .update("vpcs", vpcPayload)
                    .then(async (response: any) => {
                        successItems.push(item);
                        pendingCalls += 1;
                        if (response.data) {
                            commitedSuccess.push(item);
                        } else {
                            toast.error(response?.error);
                        }
                    })
                    .catch((e: any) => {
                        failedItems.push({ item, e });
                    })
                    .finally(() => {
                        pendingCalls -= 1;
                        DeleteAndNotify(
                            selItems,
                            successItems,
                            commitedSuccess,
                            failedItems,
                            pendingCalls
                        );
                    });
                return item;
            });
        }
    };

    const DeleteAndNotify = async (
        selected: any,
        successItems: any,
        commitedSuccess: any,
        failedItems: any,
        pendingCalls: number
    ) => {
        if (
            failedItems.length + successItems.length === selected.length &&
            pendingCalls === 0
        ) {
            // request finished
            if (commitedSuccess.length > 0) {
                deleteVPCGroup(confirmDelete?.selectedVPCs);
                // loadVPCData(true);
                let message = (
                    <>
                        The following vpcs were updated succesfully to default
                        group and the vpc group was deleted:
                        <ul className={classes.deleteListStyles}>
                            {commitedSuccess.map((item: any) => (
                                <li key={item?.VpcId}> - {item?.VpcId}</li>
                            ))}
                        </ul>
                    </>
                );
                toast.success(message);
                setConfirmDelete({ open: false });
            }
            if (failedItems.length > 0) {
                toast.error(
                    <>
                        Couldn't change the following VPCs to default, please
                        try to delete the vpc group again:
                        {
                            <ul className={classes.deleteListStyles}>
                                {failedItems.map(({ item, e }: any) => (
                                    <li key={item?.VpcId}>
                                        {" "}
                                        - {item?.VpcId} ({e?.error})
                                    </li>
                                ))}
                            </ul>
                        }
                    </>
                );
                setConfirmDelete({ open: false });
            }
        }
    };

    const confirmDeleteGroup = async () => {
        deleteVPCGroup(confirmDelete?.selectedVPCs);
        loadVPCData(true);
        setConfirmDelete({ open: false })
        setTimeout(() =>  {
            goToManageVPCs()
        }, 3000);
    };

    const ConfirmDeleteModal = (
        <PANWDSModal
            title={translate(`panwdsTabTable.notification`)}
            onClose={() => setConfirmDelete({ open: false })}
            isOpen={confirmDelete.open}
            size="md"
            dataTestId="cloudngfw-panwds-set-vpc-group-confirm-modal"
            dataMetrics="cloudngfw-panwds-set-vpc-group-confirm-modal"
            modalBody={getModalBody()}
            modalFooter={{
                footerRequired: true,
                cancelButtonRequired: true,
                featureSpecificButton: (
                    <SaveButton
                        appearance="primary"
                        size="md"
                        label={translate(`panwdsTable.confirm`)}
                        // redirect={false}
                        onClick={() => {
                            confirmDelete?.selectedVPCs?.vpcsList?.length > 0
                                ? confirmChangeVpcGroup(
                                      confirmDelete?.selectedVPCs?.vpcsList,
                                      "Default"
                                  )
                                : confirmDeleteGroup();
                        }}
                        dataMetrics="cloudngfw-panwds-delete-confirm-modal-button"
                    />
                ),
            }}
        />
    );

    return (
        <>
            <Sheet
                onClose={onCloseCreateSheet}
                title={translate(`resources.inventory.vpcGroups.editVpcGroup`)}
                isOpen={isOpenTags}
                showMask={true}
                position="md"
            >
                <SheetBody style={{ padding: 0 }}>
                    <div className={classes.backButtonHeader}>
                        <Button
                            aria-label="back button"
                            icon={<CaretLeftIcon size="sm" />}
                            appearance="secondary"
                            size="sm"
                            onClick={goToManageVPCs}
                        />
                        <span className={classes.backButtonTitle}>
                            {translate(`resources.inventory.tags.back`)}
                        </span>
                    </div>
                    <PANWDSForm
                        toolbar={<CreateVpcGroupToolbar />}
                        onSubmit={save}
                        requireStylingFooter={true}
                        footerStyle={{
                            height: "64px",
                            borderTop: "1px solid #DADBDB",
                            "& button": {
                                marginRight: "16px",
                                padding: "16px",
                            },
                        }}
                        initialValues={rowData}
                        key={"Vpc-group-edit"}
                    >
                        <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="Name"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    required
                                    disabled
                                    validate={composeValidators(
                                        requiredValidator(
                                            translate("validation.required")
                                        ),
                                        maxLength(128),
                                        isAlphaNumericWithUnderscore
                                    )}
                                    title={translate(
                                        `resources.inventory.vpcGroups.vpcGroupName`
                                    )}
                                    dataMetrics="cloudngfw-vpcGroupName-create-field-name"
                                />
                            </FormControl>
                        </Row>
                        <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="VpcGroupEntry.Description"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    title={translate(
                                        `resources.inventory.vpcGroups.description`
                                    )}
                                    validate={maxLength(512)}
                                    dataMetrics="cloudngfw-vpcGroupName-create-field-description"
                                    disabled={submit}
                                />
                            </FormControl>
                        </Row>
                        <PANWDSTable
                            permissions={permissions}
                            columns={getTagsColumns()}
                            gridData={rowData?.vpcsList}
                            // loading={false}
                            title={translate(`resources.inventory.vpcs`)}
                            searchFilterRequired={true}
                            showToolbar={true}
                            idProperty={"VPCGroupEdit"}
                            showTileTitle
                            emptySubtitle={translate(
                                `resources.inventory.tags.noassociatedTags`
                            )}
                            singleSelect={false}
                            multiSelect={false}
                            dataTestId="vpc-group-edit-table"
                            tableInnerContainerClassName="vpc-group-edit-table"
                            infiniteScroll={false}
                            enablePagination={false}
                            rowSize="standard"
                            tableContainerStyle={{
                                display: "flex",
                                flexFlow: "column",
                                padding: "16px 0 16px 0",
                                boxSizing: "border-box",
                                overflowX: "hidden",
                                overflowY: "hidden",
                            }}
                        />
                    </PANWDSForm>
                </SheetBody>
            </Sheet>
            {confirmDelete.open && ConfirmDeleteModal}
        </>
    );
};

export default withRouter(VpcGroupEdit);
