import { Box, Theme, withStyles, Typography } from "@material-ui/core";

export const NoDataContainer = withStyles((theme: Theme) => ({
    root: {
        minHeight: 244,
        color: '#333333',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))(Box);

export const Title = withStyles((theme: Theme) => ({
    root: {
        width: 440,
        fontSize: 14,
        textAlign: 'center',
        margin: '8px 0',
    }
}))(Typography)


export const Message = withStyles((theme: Theme) => ({
    root: {
        width: 547,
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 24,
    }
}))(Typography)

export const Children = withStyles((theme: Theme) => ({
    root: {
        fontSize: 12,
        textAlign: 'center',
        margin: 10,
    }
}))(Typography)