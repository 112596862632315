import { Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { category, certificate, feed, fqdn, prefix } from '../../../resources';
import { RouteUri } from '../../../routeUri';
import PropTypes from 'prop-types';
import { getRSFromUrl } from '../utils';
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
    border: '1px solid #DADBDB',
    width: '100%',
  },
  indicator: {
    backgroundColor: '#006FCC',
    left: 0,
  },
  objectsGrid: {
    gridTemplateColumns: '165px calc(100% - 165px)',
    display: 'grid',
    height: '100%',
  },
  tabs: {
    "& .MuiButtonBase-root.MuiTab-root": {
      color: "#707070",
      opacity: 1,
    },
    "& .Mui-selected": {
      color: "#333333 !important",
      fontWeight: 600,
      opacity: 1,
    }
  }
}));

const SecurityObjects = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

    const ruleStackName = isEmpty(params) ? getRSFromUrl() : params?.rulestackname;

  const [objectsTabValue, setObjectsTabValue] = useState(0);

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{ height: '100%' }}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const ObjectsTabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return <div {...other}>{value === index && (children)}</div>;
  }

  const handleObjectsTabValueChange = (event: any, newValue: number) => {
    //setObjectsTabValue(newValue);
    let newUrl = "";
    switch (newValue) {
      case 0: newUrl = RouteUri.PrefixList.replace(":rulestackname", ruleStackName); break;
      case 1: newUrl = RouteUri.FQDNList.replace(":rulestackname", ruleStackName); break;
      case 2: newUrl = RouteUri.URLCategories.replace(":rulestackname", ruleStackName); break;
      case 3: newUrl = RouteUri.Feed.replace(":rulestackname", ruleStackName); break;
      case 4: newUrl = RouteUri.CertificateList.replace(":rulestackname", ruleStackName); break;
    }
    history.replace(newUrl);
  };

  useEffect(() => {
    if (location.pathname.indexOf("/prefixlist") > 0) {
      setObjectsTabValue(0);
    } else if (location.pathname.indexOf("/fqdn") > 0) {
      setObjectsTabValue(1);
    } else if (location.pathname.indexOf("/categories") > 0) {
      setObjectsTabValue(2);
    } else if (location.pathname.indexOf("/feeds") > 0) {
      setObjectsTabValue(3);
    } else if (location.pathname.indexOf("/certificates") > 0) {
      setObjectsTabValue(4);
    }
  }, [location.pathname, history, ruleStackName]);

  return (
    <Grid container className={classes.objectsGrid}>
      <Grid item>
        <Tabs
          style={{ background: 'none', margin: '16px 0 0 16px', boxSizing: 'border-box' }}
          orientation="vertical"
          classes={{
            indicator: classes.indicator,
          }}
          centered
          value={objectsTabValue}
          onChange={handleObjectsTabValueChange}
          className={classes.tabs}
        >
          <Tab style={{ textTransform: 'none', width: 'max-content', minWidth: 'auto' }} label="Prefix List" />
          <Tab style={{ textTransform: 'none', width: 'max-content', minWidth: 'auto' }} label="FQDN List" />
          <Tab style={{ textTransform: 'none', width: 'max-content', minWidth: 'auto' }} label="Custom URL Category" />
          <Tab style={{ textTransform: 'none', width: 'max-content', minWidth: 'auto' }} label="Intelligent Feed" />
          <Tab style={{ textTransform: 'none', width: 'max-content', minWidth: 'auto' }} label="Certificates" />
        </Tabs>
      </Grid>
      <Grid item>
        <TabPanel value={objectsTabValue} index={0}>
          <prefix.list />
        </TabPanel>
        <TabPanel value={objectsTabValue} index={1}>
          <fqdn.list />
        </TabPanel>
        <TabPanel value={objectsTabValue} index={2}>
          <category.list />
        </TabPanel>
        <TabPanel value={objectsTabValue} index={3}>
          <feed.list />
        </TabPanel>
        <TabPanel value={objectsTabValue} index={4}>
          <certificate.list />
        </TabPanel>
      </Grid>
    </Grid>);
}

export default SecurityObjects;
