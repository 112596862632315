import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';
import CategoryList from './CategoryList';

const category = {
    create: CategoryCreate,
    list: CategoryList,
    edit: CategoryEdit,
    icon: InvoiceIcon,
};

export default category;