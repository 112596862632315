import {useState, useEffect, forwardRef, useCallback} from "react";
import "typeface-lato";
import englishMessages from './i18n/en';
import {ApplicationConfigManager} from './types';
import CSPAccount from "./layout/CSPAccount";
import EventEmitter from "./eventEmitter";
import {IntlProvider} from "react-intl";
import "@panwds/tailwindcss/palo.min.css";
import {GlobalProvider} from "./context/global";
import {GlobalFilterProvider} from "@panwds/react-filters";
import CircularLoader from './components/CircularLoader/CircularLoader';
import Routes from './routes';
import { Modal, ModalProvider, ToastProvider } from '@panwds/react-ui';
import {ToastContainer} from "./components";
import {useHistory, useLocation} from "react-router-dom";
import {RouteUri} from "./routeUri";
import {OktaRegistrationModal, MultiTenantModal} from "./layout/components";

const flattenObject = (ob: any) => {
    const toReturn = {};

    for (const i in ob) {
        if (!ob.hasOwnProperty(i)) {
            continue;
        }

        if ((typeof ob[i]) === 'object') {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) {
                    continue;
                }
                // @ts-ignore
                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            // @ts-ignore
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
};

const ModalWrapper = forwardRef<any, any>((props, ref) => {
    return (
        <CSPAccount setCspWorkflow={props.setCspWorkflow} ref={ref}/>
    );
});

const App = () => {
    const [loading, setLoading] = useState(true);
    const [cspWorkFlow, setCspWorkflow] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        ApplicationConfigManager
            .getInstance()
            .loadConfig()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                // TODO: Check action when the request fails
                // console.error(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        EventEmitter.on("setCspWorkFlow", (setValue = true) => {
            setCspWorkflow(setValue);
        });
        return () => {
            EventEmitter.off("setCspWorkFlow", () => setCspWorkflow(false));
        }
    }, []);

    useEffect(() => {
        EventEmitter.on("logoutUser", handleLogout);
        return () => {
            EventEmitter.off("logoutUser", handleLogout);
        }
    }, []);

    const handleLogout = useCallback(({redirectTo} : any) => {
        ApplicationConfigManager.getInstance().clearSession();
        history.push(RouteUri.Login);
    }, []);

    const handleCspWorkFlow = async () => {
        setCspWorkflow(false);
        EventEmitter.off("setCspWorkFlow", () => setCspWorkflow(false));

        //Set csp workflow to false in config
        let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
        newAppConfig.setCspWorkFlow = false;
        await ApplicationConfigManager.getInstance().setConfig(newAppConfig);
    };

    if (loading) {
        return (
            <CircularLoader loading={loading}/>
        )
    }
    return (
        <GlobalProvider>
            <GlobalFilterProvider>
                <IntlProvider messages={flattenObject(englishMessages)} defaultLocale="en-US" locale="en-US">
                    <ToastProvider>
                        <ModalProvider>
                            <OktaRegistrationModal/>
                            <MultiTenantModal/>
                            <Routes />
                            <Modal isOpen={cspWorkFlow} onClose={handleCspWorkFlow} style={{width: '448px', maxHeight: "100%"}}>
                                <ModalWrapper setCspWorkflow={handleCspWorkFlow}/>
                                {/* setCspWorkflow={setCspWorkflow} */}
                            </Modal>
                            <ToastContainer />
                        </ModalProvider>
                    </ToastProvider>
                </IntlProvider>
            </GlobalFilterProvider>
        </GlobalProvider>
    );
};

export default App;
