import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { kebabCase, find } from "lodash";
import { makeStyles } from "@material-ui/core";
import { toast } from '../../../components';
import { usePermissions } from "../../../customHooks";
import {useTranslate} from '../../../customHooks';
import { dataProvider } from "../../../dataProvider";
import { nameStyleCursor } from "../../../layout/styles";
import { Sheet, SheetBody, Tooltip, Button, ModalBody } from "@panwds/react-ui";
import { PANWDSTable, PANWDSModal } from "../../../components/PANWDSElements";
import { EditIcon, DeleteIcon } from "@panwds/icons";
import {
  TableProvider,
  ConnectedTable,
  ConnectedTableLayout,
} from "@panwds/react-table";
import SaveButton from "../../../components/Buttons/SaveButton";
import _ from "lodash";

const useStyles = makeStyles(() => {
  return {
    appColumnStyles: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100px",
    },
    sheetContainer: {
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100vh",
      display: "flex",
    },
    footer: {
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center",
      height: "64px",
      borderTop: "1px solid #DADBDB",
      "& button": {
        marginRight: "16px",
        padding: "16px",
      },
    },
    modalHeader: {
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      padding: '16px 16px 0',
    },
    deleteListStyles: {
      height: '50px',
      overflowY: 'scroll'
    }
  };
});

const VPCGroupList = ({ isOpenTags, setOpenTags, groupedVPCs, createPanel, getEditView, callBackVpcList, isCreateOpen, isEditOpen }: any) => {
  const { permissions, rawPermissions } = usePermissions();
  const translate = useTranslate();
  const classes = useStyles();
  const nameClass = nameStyleCursor();
  const [loading, setLoading] = useState(true);
  const [vpcGroupData, setVpcGroupData] = useState<never[]>([]);
  const [vpcDataRaw, setVpcDataRaw] = useState<[]>([]);
  const [confirmDelete, setConfirmDelete] = useState<any>({ open: false });
  const [deleteTrigerred, setDeleteTrigerred] = useState(false);
  const isPermitted = rawPermissions && find(rawPermissions, { Policy: "InventoryAdmin" });


  const loadVPCData = (needRefresh = false) => {
    setLoading(true);
    if (!vpcDataRaw.length || needRefresh) {
        dataProvider
          .getList("vpcs", { data: { refresh: needRefresh } })
          .then((response) => {
            if (response?.data) {
              // @ts-ignore
              setVpcDataRaw(response?.data);
              triggerVpcGroupCall(response?.data);
            } else {
              toast.error(response, { toastId: `vpc-list-error` });
            }
          })
          .catch((error: any) => {
            if (error?.noToast) {
              return;
            }
            toast.error(error?.error, { toastId: `vpc-list-error` });
          })
          .finally(() => {
            setLoading(false);
          });
    }
  };

  const triggerVpcGroupCall = (vpcDataFromRefresh: any) => {
      loadVPCGroups(true, vpcDataFromRefresh);
  }


  const loadVPCGroups = (needRefresh = false, vpcDataPretty?: any) => {
    if (!vpcGroupData?.length || needRefresh) {
      dataProvider
        .getList("vpcGroups")
        .then((response) => {
          if (response?.data) {
            // @ts-ignore
            let combData = getCombinedVPCGroupData(response?.data, needRefresh, vpcDataPretty);
            setVpcGroupData(combData);
          } else {
            toast.error(response, { toastId: `vpc-group-list-error` });
          }
        })
        .catch((error: any) => {
          if (error?.noToast) {
            return;
          }
          toast.error(error?.error, { toastId: `vpc-group-list-error` });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
    }
  };

  const getCombinedVPCGroupData = (responseData: any, needRefresh: any, vpcDataPretty?: any) => {
    let combinedVpcGroup: any = [];
    var vpcGroups = _.mapValues(_.groupBy(vpcDataPretty, 'VpcGroup'),
                    clist => clist?.map(group => _.omit(group, 'VpcGroup')));
    let newGroupedVPCs = !needRefresh ? groupedVPCs : vpcGroups
    Object.keys(newGroupedVPCs).forEach(function (key) {
      if(responseData.length !== 0) {
          responseData.forEach(function (vpcGroup: any) {
          if (vpcGroup.Name === key) {
            let newAdd: any = {
              ...vpcGroup,
              noOfVPCscount: newGroupedVPCs[key]?.length,
              vpcsList: newGroupedVPCs[key],
              disabled: vpcGroup.Name === "Default"
            };
            vpcGroup.Name === "Default" ? combinedVpcGroup.unshift(newAdd) : combinedVpcGroup.push(newAdd);
            return;
          }
        })
      } else {
        let newAdd: any = {
          Name: key,
          noOfVPCscount: newGroupedVPCs[key]?.length,
          vpcsList: newGroupedVPCs[key],
          disabled: key === "Default"
        };
        key === "Default" ? combinedVpcGroup.unshift(newAdd) : combinedVpcGroup.push(newAdd);
        return;
      }
    });
    const leftOvergroupsFromApi = responseData.filter((id1: any) => !combinedVpcGroup.some((id2: any) => id2.Name === id1.Name));
    leftOvergroupsFromApi.forEach((vpcGroup: any) => {
      let newAdd: any = {
        ...vpcGroup,
        noOfVPCscount: 0,
        vpcsList: [],
        disabled: vpcGroup?.Name === "Default"
      };
      vpcGroup?.Name === "Default" ? combinedVpcGroup.unshift(newAdd) : combinedVpcGroup.push(newAdd);
    });
    return combinedVpcGroup;
  };

  useEffect(() => {
    loadVPCGroups();
  }, []);

  const confirmDeleteGroup = async () => {
    setConfirmDelete({ open: false });
    await deleteVPCGroup(confirmDelete?.selectedVPCs);
    loadVPCData(true);
  }

  const getModalColumns = () =>  [
    {
      accessor: "VpcId",
      Header: translate(`resources.inventory.vpc.vpc`),
      columnSize: 4,
      render: ({ row }: any) => {
        return (
          <span
            key={`tags-record-${kebabCase(row?.original?.VpcId)}`}
            // className={classes.appColumnStyles}
            data-test-id={`tags-record-${kebabCase(row?.original?.VpcId)}`}
          >
            {row?.original?.VpcId}
          </span>
        );
      },
    },
  ]

  const getModalBody = () => {
    const groupName = confirmDelete?.selectedVPCs?.Name;
    return (
      <>
      <div className={classes.modalHeader}>{translate(`resources.inventory.vpcGroups.deleteGroupMessage`)} "{groupName}", {translate(`resources.inventory.vpcGroups.deleteSubGroupMessage`)} "{groupName}"?</div>
      <ModalBody addClassName="tw-overflow-auto">
        <TableProvider
          columns={getModalColumns()}
          data={confirmDelete?.selectedVPCs?.vpcsList}
        >
          <ConnectedTableLayout>
            <ConnectedTable />
          </ConnectedTableLayout>
        </TableProvider>
    </ModalBody>
    </>
    )
  }

  const ConfirmDeleteModal = (
    <PANWDSModal
      title={translate(`panwdsTabTable.notification`)}
      onClose={() => setConfirmDelete({ open: false })}
      isOpen={confirmDelete.open}
      size="md"
      dataTestId="cloudngfw-panwds-set-vpc-group-confirm-modal"
      dataMetrics="cloudngfw-panwds-set-vpc-group-confirm-modal"
      modalBody={getModalBody()}
      modalFooter={{
        footerRequired: true,
        cancelButtonRequired: true,
        featureSpecificButton: (
          <SaveButton
            appearance="primary"
            size="md"
            label={translate(`panwdsTable.confirm`)}
            // redirect={false}
            onClick={() => {
              confirmDelete?.selectedVPCs?.vpcsList?.length > 0 ? confirmChangeVpcGroup(confirmDelete?.selectedVPCs?.vpcsList, 'Default') : confirmDeleteGroup();
            }}
            dataMetrics="cloudngfw-panwds-delete-confirm-modal-button"
          />
        ),
      }}
    />
  );

  const getDeleteView = (selectedVPCs: any) => {
    setConfirmDelete({open: true,  selectedVPCs});
  }

  const confirmChangeVpcGroup = (selItems: any, groupChange: any) => {
    if (selItems && Array.isArray(selItems)) {
      setConfirmDelete({ open: false });
      setDeleteTrigerred(true);
      setLoading(true);
      const successItems: any = [];
      const failedItems: any = [];
      const commitedSuccess: any = [];
      let pendingCalls = 0;
      selItems.map((item: any) => {
        const vpcPayload: any = {
          AccountId: item?.AccountId,
          VpcId: item?.VpcId,
          VpcGroupEntry: {
            VpcGroup: groupChange?.toString()?.split('(')?.[0]
          }
        };
        dataProvider.update("vpcs", vpcPayload)
          .then(async (response: any) => {
            successItems.push(item);
            pendingCalls += 1;
            if (response.data) {
                    commitedSuccess.push(item);
                  } else {
                      toast.error(response?.error);
                  }
          })
          .catch((e: any) => {
            failedItems.push({ item, e })
          })
          .finally(() => {
            pendingCalls -= 1;
            DeleteAndNotify(selItems, successItems, commitedSuccess, failedItems, pendingCalls)
          });
        return item;
      })
    }
  }

  const DeleteAndNotify = async (selected: any, successItems: any, commitedSuccess: any, failedItems: any, pendingCalls: number) => {
    if (failedItems.length + successItems.length === selected.length && pendingCalls === 0) { // request finished
      loadVPCData(true);
      if (commitedSuccess.length > 0) {
        await deleteVPCGroup(confirmDelete?.selectedVPCs);
        let message = (
          <>
            The following vpcs were updated succesfully to default group and the vpc group was deleted:
            <ul className={classes.deleteListStyles}>
              {commitedSuccess.map((item: any) => <li key={item?.VpcId}> - {item?.VpcId}</li>)}
            </ul>
          </>
        );
        toast.success(message);
      }
      if (failedItems.length > 0) {
        toast.error(<>
          Couldn't change the following VPCs to default, please try to delete the vpc group again:
          {<ul className={classes.deleteListStyles}>
            {failedItems.map(({ item, e }: any) => <li key={item?.VpcId}> - {item?.VpcId} ({e?.error})</li>)}
          </ul>}
        </>);
        setConfirmDelete({ open: false });
      }
    }
  };

  const getVPCGRoupsColumns = () => {
    if(isPermitted !== undefined) {
      return [{
        accessor: "Name",
        Header: translate(`resources.inventory.vpcGroups.vpcGroupName`),
        columnSize: 3,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-ips-record-${kebabCase(
                row?.original?.Name?.toLowerCase()
              )}`}
              className={classes.appColumnStyles}
              data-test-id={`tags-ips-record-${kebabCase(
                row?.original?.Name?.toLowerCase()
              )}`}
            >
              {row?.original?.Name}
            </span>
          );
        },
      },
      {
        accessor: "VpcGroupEntry.Description",
        Header: translate(`resources.inventory.vpcGroups.description`),
        columnSize: 3,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-ips-record-${kebabCase(
                row?.original?.VpcGroupEntry?.Description?.toLowerCase()
              )}`}
              className={classes.appColumnStyles}
              data-test-id={`tags-ips-record-${kebabCase(
                row?.original?.VpcGroupEntry?.Description?.toLowerCase()
              )}`}
            >
              {row?.original?.VpcGroupEntry?.Description}
            </span>
          );
        },
      },
      {
        accessor: "noOfVPCscount",
        Header: translate(`resources.inventory.vpcGroups.noOfVPCscount`),
        columnSize: 3,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-ips-record-${kebabCase(row?.original?.noOfVPCscount)}`}
              className={classes.appColumnStyles}
              data-test-id={`tags-ips-record-${kebabCase(
                row?.original?.noOfVPCscount
              )}`}
            >
              {row?.original?.noOfVPCscount}
            </span>
          );
        },
      },
      {
        accessor: "actions",
        Header: translate(`resources.inventory.vpcGroups.actions`),
        columnSize: 3,
        render: ({ row }: any) => {
          if (row?.original?.Name?.toLowerCase() !== "default") {
            return (
              <>
                <Tooltip label={translate(`resources.inventory.vpcGroups.edit`)}>
                  <button className="tw-mr-2 tw-border-none tw-bg-transparent" onClick={() => getEditView(row?.original)}>
                    <EditIcon
                      className="tw-text-gray-700"
                      size="sm"
                      data-metrics="icons-edit"
                    />
                  </button>
                </Tooltip>
                <Tooltip
                  label={translate(`resources.inventory.vpcGroups.delete`)}
                >
                  <button className="tw-mr-2 tw-border-none tw-bg-transparent" onClick={() => getDeleteView(row?.original)}>
                    <DeleteIcon
                      className="tw-text-gray-700"
                      size="sm"
                      data-metrics="icons-delete"
                    />
                  </button>
                </Tooltip>
              </>
            );
          } else {
            return null;
          }
        },
        sticky: true,
      },
    ]
    } else {
      return [{
        accessor: "Name",
        Header: translate(`resources.inventory.vpcGroups.vpcGroupName`),
        columnSize: 4,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-ips-record-${kebabCase(
                row?.original?.Name?.toLowerCase()
              )}`}
              className={classes.appColumnStyles}
              data-test-id={`tags-ips-record-${kebabCase(
                row?.original?.Name?.toLowerCase()
              )}`}
            >
              {row?.original?.Name}
            </span>
          );
        },
      },
      {
        accessor: "VpcGroupEntry.Description",
        Header: translate(`resources.inventory.vpcGroups.description`),
        columnSize: 4,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-ips-record-${kebabCase(
                row?.original?.VpcGroupEntry?.Description?.toLowerCase()
              )}`}
              className={classes.appColumnStyles}
              data-test-id={`tags-ips-record-${kebabCase(
                row?.original?.VpcGroupEntry?.Description?.toLowerCase()
              )}`}
            >
              {row?.original?.VpcGroupEntry?.Description}
            </span>
          );
        },
      },
      {
        accessor: "noOfVPCscount",
        Header: translate(`resources.inventory.vpcGroups.noOfVPCscount`),
        columnSize: 4,
        render: ({ row }: any) => {
          return (
            <span
              key={`tags-ips-record-${kebabCase(row?.original?.noOfVPCscount)}`}
              className={classes.appColumnStyles}
              data-test-id={`tags-ips-record-${kebabCase(
                row?.original?.noOfVPCscount
              )}`}
            >
              {row?.original?.noOfVPCscount}
            </span>
          );
        },
      },
    ]
    }
  };

  const deleteVPCGroup = (payload: any) => {
    setLoading(true);
    if(!deleteTrigerred) setDeleteTrigerred(true)
    dataProvider.delete("vpcGroups", payload)
    .then((response) => {
      if (response) {
        // @ts-ignore
        let message = (
          <>
            Vpc group deleted succesfully:
            <ul>
            {payload?.Name}
            </ul>
          </>
        );
        toast.success(message, response);
        setConfirmDelete({ open: false });
      } else {
        toast.error(response, { toastId: `vpc-group-list-error` });
        setConfirmDelete({ open: false });
      }
    })
    .catch((error: any) => {
      if (error?.noToast) {
        return;
      }
      toast.error(error?.error?.error, { toastId: `vpc-group-list-error` });
      setConfirmDelete({ open: false });
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const vpcGroupToolbarActionsCategorized: any[] = [];
  /* bulk delete action */
  // vpcGroupToolbarActionsCategorized.push({
  //   title: translate(`resources.inventory.vpcGroups.delete`),
  //   actionsMap: [],
  //   handleButtonAction: () => deleteVPCGroup(), //to-do if required
  //   type: "button",
  //   disabledAddLogic: true,
  // });
  vpcGroupToolbarActionsCategorized.push({
    title: translate(`resources.inventory.vpcGroups.createNewVPCGroup`),
    actionsMap: [],
    handleButtonAction: () => createPanel(),
    type: "button",
    disabled: isPermitted === undefined
    // disabledAddLogic: true,
  });

  const onCloseManageList = () => {
    if(!isCreateOpen && !isEditOpen && isPermitted !== undefined) callBackVpcList();
    setOpenTags(false)
  }

  return (
    <>
      <Sheet
        onClose={onCloseManageList}
        title={translate(`resources.inventory.vpcGroups.manageVpcGroup`)}
        isOpen={isOpenTags}
        showMask={true}
        position="lg"
      >
        <div className={classes.sheetContainer}>
          <SheetBody isLoading={loading}>
            <PANWDSTable
              permissions={permissions}
              columns={getVPCGRoupsColumns()}
              gridData={vpcGroupData}
              loading={loading}
              title={`${translate(
                `resources.inventory.vpcGroups.vpcGroups`
              )} (${vpcGroupData?.length})`}
              searchFilterRequired={true}
              showToolbar={true}
              dropDownActionsArray={vpcGroupToolbarActionsCategorized}
              idProperty={"Tags-IPs"}
              showTileTitle
              emptySubtitle={translate(
                `resources.inventory.vpcGroups.noVpcGroups`
              )}
              singleSelect={false}
              multiSelect={false}
              dataTestId="vpc-groups-table"
              tableInnerContainerClassName="tags-IPs-table"
              infiniteScroll={false}
              enablePagination={true}
              rowSize="standard"
              tableContainerStyle={{
                display: "flex",
                flexFlow: "column",
                padding: "0",
                boxSizing: "border-box",
                overflowX: "hidden",
                overflowY: "hidden",
              }}
            />
          </SheetBody>
          <div className={classes.footer}>
            <Button
              key="tags-IPs-table-button"
              appearance="secondary"
              size="sm"
              buttonPosition="right"
              data-metrics="cngfw-tags-IPs-table-button"
              //@ts-ignore
              onClick={onCloseManageList}
            >
              {translate(`resources.inventory.close`)}
            </Button>
          </div>
        </div>
      </Sheet>
      {confirmDelete.open && ConfirmDeleteModal}
    </>
  );
};

export default withRouter(VPCGroupList);
