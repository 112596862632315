import { FormHelperText, makeStyles, TextareaAutosize } from "@material-ui/core";
import { PANFieldContainer, PANHelpText, PANLabelStyle } from "./styles";

const useStyles = makeStyles((theme) => ({
  TextArea: {
    background: "#FFFFFF",
    border: "1px solid #DADBDB",
    boxSizing: "border-box",
    borderRadius: 4,
    padding: "7px 8px",
    fontSize: 16,
    height: "auto",
    maxWidth: "100%",
    minWidth: "100%",
    resize: "none",
    marginTop: 24,
    fontFamily: "Lato",
  },
  error: {
    border: "1px solid #D13C3C",
    borderRadius: 4,
  },
  errorText: {
    color: "#D13C3C",
  },
}));

export const PANMultilineInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {

  const existerror = !!(touched && error);

  return (
  <PANTextAreaInput {...props} {...inputProps} existerror={existerror} error={error} />
)};

export const PANTextAreaInput = (props: any) => {
  const classes = useStyles();
  let value = props.value;
  if(typeof value !== "string"){
    value = value.join("\n");
  }
  let existerror:boolean = !!props.error;

  const handleBlur = (e: any) => {
    if(! e.target.value.trim()){
      props.onChange(e.target.value.trim());
      return;
    }
    let lines = e.target.value.trim().split('\n');
    lines = lines.map( (l:string) => l.trim());
    props.onChange(lines);
  };

  const handleChange = (e: any) => {
    const lines = e.target.value.split('\n');
    props.onChange({ ...e, target: { ...e.target, value: lines } });
  };

  return (
    <PANFieldContainer>
      <PANLabelStyle
        shrink
        htmlFor={props.label}
        className={props?.existerror ? classes.errorText : ""}
      >
        {props.label}{" "}
        {props.required && <strong className={classes.errorText}>*</strong>}
      </PANLabelStyle>

      <TextareaAutosize
        id={props.label}
        className={classes.TextArea}
        maxRows={4}
        onBlur={handleBlur}
        value={value}
        onChange={handleChange}
      />
      <FormHelperText>Enter one value per line</FormHelperText>
      {existerror && <PANHelpText id={props.label}>{props.error}</PANHelpText>}
    </PANFieldContainer>
  );
};
