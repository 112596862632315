
import { FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { usePermissions, useTranslate } from '../../customHooks';
import { Field } from "react-final-form";
import { useHistory, withRouter } from 'react-router-dom';
import { Button as PANWDSButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from '../../components';
import { Row } from "../../components/FormElements";
import {PANWDSBreadcrumbs, PANWDSForm, PANWDSInput, PANWDSTextarea} from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import { isAlphaNumeric, isFqdnList, isRequired, maxLength, composeValidators } from '../../utils/validate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

const FQDNEdit = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();

  const { permissions } = usePermissions();

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  // get the feed name from route params
  const fqdnListName = props.match.params.fqdnname;

  const [record, setRecord] = useState<any>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const title = fqdnListName;

  useEffect(() => {
    if (permissions?.DescribeFqdnList) {
      dataProvider.describe('fqdn', '', { RuleStackName: ruleStackName, Name: fqdnListName })
        .then((res: any) => {
          if (res.data) {
            setRecord({ ...res.data });
          } else {
            toast.error(res?.error, { toastId: "fqdn-describe" });
          }
        }).catch((e:any) => {
        toast.error(e?.error, { toastId: "fqdn-describe" });
      });
    }
  }, [fqdnListName, ruleStackName, permissions]);

  const saveAction = useCallback(
    async (values) => {
      if (!permissions?.UpdateFqdnList) {
        return;
      }
      let payload = { ...values.FqdnListCandidate, RuleStackName: values.RuleStackName, Name: values.Name };
      setSubmitting(true);
      try {
          await dataProvider.update("fqdn", payload);
        //toast.success("Success");
        history.goBack();
        return;
      } catch (error: any) {
        toast.error(error?.error, { toastId: "fqdn-update" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, permissions]
  );

  const SaveToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          dataTestId="rulestack-create-cancel"
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-fqdn-edit-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          submitOnEnter={true}
          className={classes.button}
          disabled={!permissions?.UpdateFqdnList}
          loading={submitting}
          dataMetrics="cloudngfw-fqdn-edit-save-button"
          {...toolbarProps}
        />
      </div>
    )
  };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: translate(`resources.ruleStacks.name`),
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`fqdn`]: translate(`resources.fqdn.name`),
          [fqdnListName]: fqdnListName
        }}
      />
      <PANTitle title={title} divider />
        <PANWDSForm
            toolbar={<SaveToolbar />}
            onSubmit={saveAction}
            initialValues={record}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: 'auto' }}>
                    <PANTile title={translate(`resources.fqdn.fields.General`)}
                             subtitle={translate(`resources.fqdn.fields.CreateSubtitle`)}
                             size={12}>
                        <Row>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        required
                                        title={translate(`resources.fqdn.fields.Name`)}
                                        dataMetrics="cloudngfw-fqdn-edit-field-name"
                                        validate={composeValidators(isRequired, isAlphaNumeric, maxLength(128))}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="FqdnListCandidate.Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title={translate(`resources.fqdn.fields.Description`)}
                                        dataMetrics="cloudngfw-fqdn-edit-field-description"
                                        validate={maxLength(512)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="FqdnListCandidate.FqdnList"
                                        // @ts-ignore
                                        component={PANWDSTextarea}
                                        enableArrayValue
                                        note="Enter one value per line"
                                        rowsMin={1}
                                        title={translate(`resources.fqdn.fields.FqdnList`)}
                                        dataMetrics="cloudngfw-fqdn-edit-field-list"
                                        validate={composeValidators(isRequired, isFqdnList, maxLength(255))}
                                        initialValue={record && record.PrefixListCandidate ? record.FqdnListCandidate.FqdnList : ""}
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(FQDNEdit);
