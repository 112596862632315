import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import FQDNCreate from './FQDNCreate';
import FQDNEdit from './FQDNEdit';
import FQDNList from './FQDNList';

const fqdn = {
    create: FQDNCreate,
    edit: FQDNEdit,
    list: FQDNList,
    icon: InvoiceIcon,
};

export default fqdn;