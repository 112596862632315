import { dataProvider } from "../dataProvider";
import { toast } from '../components';

export  const GetXAccountRoleNames = async (setXAccountRoleNamesLength: any, setXAccountRoleNames: any, setLoading: any, accountId: string) => {
    let xAccountRolesLength: any = 0;
    let result: any = {};
    let accountRoles = [];
    const payload =
      { AccountId: accountId } as any;

    try {
      result = await dataProvider.describe("xaccountroles", '', payload);
      xAccountRolesLength = (Object.keys(result.data?.Firewall).length) + (Object.keys(result.data?.RuleStack).length) + (result.data?.CloudTrailRole ? 1 : 0);

      for (const xAccountFirewallRole in result.data?.Firewall) {
        accountRoles.push({
          [xAccountFirewallRole]: result.data?.Firewall[xAccountFirewallRole]
        })
      }
      for (const xAccountRuleStackRole in result.data?.RuleStack) {
        accountRoles.push({
          [xAccountRuleStackRole]: result.data?.RuleStack[xAccountRuleStackRole]
        })
      }
      for (const xAccountCloudTrailRole in result.data) {
        if(xAccountCloudTrailRole === 'CloudTrailRole') {
          accountRoles.push({
            [xAccountCloudTrailRole]: result.data?.[xAccountCloudTrailRole]
          })
        }
      }
      setXAccountRoleNames(accountRoles);
      setXAccountRoleNamesLength(xAccountRolesLength);
    } catch(e: any) {
      toast.error(e?.error, { toastId:  "xAccountRoles-list" });
    } finally {
      setLoading(false);
    }
    return;
}
