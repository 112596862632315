import {
    Body,
    Button,
    Link,
    Sheet,
    SheetBody,
    ToggleSwitch,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton, Title, Bold, Checkbox
} from "@panwds/react-ui";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslate} from "../../customHooks";
import {CircleCheckIcon, CaretLeftIcon, ExclamationTriangleIcon, ExclamationCircleIcon} from "@panwds/icons";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";
import {SaveButton, toast} from "../../components";
import {dataProvider} from "../../dataProvider";
import {ApplicationConfigManager} from "../../types";
import {FormattedMessage} from "react-intl";
import CircularLoader from "../../components/CircularLoader/CircularLoader";

const useStyles = makeStyles(() => ({
    iconAndText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        padding: '0.5rem 0',
        position: "relative"
    },
    circle: {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
    },
    enabledColor: {
        color: '#33CCB8',
    },
    disabledColor: {
        color: '#707070',
    },
    pendingColor: {
        color: "var(--light-severity-warning-p-4, var(--orange-40, #F38C16))"
    },
    errorColor: {
        color: "var(--light-icons-error, #E14E4E)"
    },
    sheetBody: {
        flexGrow: 1,
    },
    sheetContent: {
        display: "grid",
        gap: "8px"
    },
    sheetContentBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "4px",
    },
    sheetBottom: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "1rem",
        borderTop: "1px solid #DADBDB"
    },
    backButton: {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingBottom: "1rem"
    },
    requisite: {
        lineHeight: '22px',
        margin: 0,
        whiteSpace: "break-spaces"
    }
}));

const DefaultSecurityServices = [
    {
        label: "IPS Vulnerability",
        value: "Enable"
    },
    {
        label: "Anti-Spyware",
        value: "Enable",
    },
    {
        label: "Antivirus",
        value: "Enable",
    },
    {
        label: "File Blocking",
        value: "Enable",
    },
    {
        label: "URL Categories and Filtering",
        value: "Enable",
    },
    {
        label: "WildFire",
        value: "Enable",
    },
    {
        label: "Decryption",
        value: "Enable",
    }
];

const AddOnSecurityServices = {
    label: 'Data Loss Prevention (DLP)'
};

const DlpStatus = {
    pending: "pending",
    disable: "disable",
    complete: "complete",
    requisite: "requisite",
}
type DlpStatusType = "disable" | "pending" | "enable" | "complete" | "requisite";
type DlpDataType = {
    status: string,
    DlpTenantId?: Array<string>,
    PanDlpTenantId?: string,
    EulaAccepted?: boolean
};
type ModalState = {
    isOpen: boolean,
    isChecked?: boolean,
    isLoading?: boolean,
};

const SecurityServices = ({onClose, showBackButton = false, onCallback = undefined, panorama}: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [isLoading, setIsLoading] = useState(true);
    const dlpDataInitialState = {
        status: DlpStatus.disable,
    }
    const [dlpData, setDlpData] = useState<DlpDataType>(dlpDataInitialState);
    const modalInitialState = {
        isOpen: false,
        isChecked: false,
        isLoading: false,
    };
    const [modalState, setModalState] = useState<ModalState>(modalInitialState);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const showDLP = process.env.REACT_APP_SHOW_DLP === 'true';

    const appConfig = ApplicationConfigManager.getInstance().getConfig();

    useEffect(() => {
        if (showDLP) {
            getDlpData();
        } else {
            setIsLoading(false);
        }
    }, []);

    const getDlpData = () => {
        if (!isEmpty(panorama) && !isEmpty(panorama?.Panorama)) {
            callApi({
                linkId: panorama.LinkId,
                payload: {
                    options: {
                        method: 'PUT',
                    }
                },
            }).then((res: any) => {
                const link = res;
                if (!isEmpty(link)) {
                    setDlpData({...link, status: link.Status});
                }
                setIsLoading(false);
            }).catch(e => {
                toast.error(e.error.error)
            });
        } else {
            onClose();
            toast.error("Panorama not selected");
        }
    };

    const callApi = (payload: { Action?: string, linkId: string, payload?: object }) => {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            dataProvider.update("dlpLinks", payload)
                .then((res: any) => {
                    resolve(res.data);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    };

    const handleDlpStatus = () => {
        setModalState({...modalState, isLoading: true});
        setIsSubmitLoading(true);
        callApi({
            linkId: panorama.LinkId,
            payload: {
                options: {
                    method: 'PUT',
                    body: JSON.stringify({
                        Action: 'enable',
                        EulaAccepted: true
                    })
                }
            },
        }).then((res: any) => {
            setIsSubmitLoading(false);
            setDlpData({status: res.Status});
            setModalState({...modalInitialState});
        }).catch(e => {
            setIsSubmitLoading(false);
            setModalState({...modalInitialState});
            toast.error(e.error.error);
        })
    };

    type SupportProps = { label: string, href: string, dataMetrics: string }

    const createSupportLink = ({label, href, dataMetrics}: SupportProps) => (
        <Link size={"sm"}
              external
              dataMetrics={dataMetrics}
              href={href}>
            {label}
        </Link>
    );

    const callFormattedMessage = (id: string) => (
        <>
            {/* @ts-expect-error Server Component */}
            <FormattedMessage
                id={id}
                values={{
                    compatibilityList: <ol style={{listStyleType: "decimal", padding: "5px 12px"}}>
                        <li>{translate("resources.integrations.securityService.compatibilityListValue1")}</li>
                        <li>{translate("resources.integrations.securityService.compatibilityListValue2")}</li>
                        <li>{translate("resources.integrations.securityService.compatibilityListValue3")}</li>
                        <li>{translate("resources.integrations.securityService.compatibilityListValue4")}</li>
                    </ol>,
                    compatibilityMatrix: createSupportLink({
                        label: translate("resources.integrations.securityService.compatibilityMatrix"),
                        href: "https://docs.paloaltonetworks.com/compatibility-matrix/panorama/plugins",
                        dataMetrics: "cloudngfw-integration-security-service-requisite-compatibility-matrix"
                    })
            }}/>
        </>

    );

    const handleToggle = () => {
        if (!isEmpty(dlpData?.DlpTenantId) || !isEmpty(dlpData?.PanDlpTenantId) || dlpData.EulaAccepted) {
            handleDlpStatus();
        } else {
            setModalState({isOpen: true})
        }
    }

    return (
        <Sheet
            onClose={onClose}
            title={translate("resources.integrations.securityService.title")}
            isOpen
            position="sm"
        >
            <SheetBody isLoading={isLoading} addClassName={classes.sheetBody}>
                {showBackButton && <div className={classes.backButton}>
                    <Button aria-label="Icon only button for back" icon={<CaretLeftIcon size={"xs"}/>}
                            appearance={"secondary"} size={"sm"} onClick={() => onCallback()}/>
                    <Body size={"md"}>{translate("common.back")}</Body>
                </div>}

                <div className={classes.sheetContent}>
                    {DefaultSecurityServices.map(({label, value}) => (
                        <div key={`key-${label}`} className={classes.sheetContentBody}>
                            <Body size={"sm"} appearance={"secondary"}>{label}</Body>
                            <div className={classes.iconAndText}>
                                <CircleCheckIcon size="sm"
                                                 className={value === "Enable" ? classes.enabledColor : classes.disabledColor}/>
                                <Body size={"sm"}>
                                    {value === "Enable"
                                        ? translate('resources.integrations.values.enabled')
                                        : translate('resources.integrations.values.disabled')}
                                </Body>
                            </div>
                        </div>
                    ))}

                    {showDLP && <div className={classes.sheetContentBody}>
                        <Body size={"sm"} appearance={"secondary"}>{AddOnSecurityServices.label}</Body>
                        <div className={classes.iconAndText}>

                            {
                                dlpData.status === DlpStatus.disable ?
                                    (isSubmitLoading ? <CircularLoader loading={isSubmitLoading}/> :
                                    <ToggleSwitch
                                        label="Disabled"
                                        labelPlacement="right"
                                        onClick={() => handleToggle()}
                                        checked={false}/>)
                                    :
                                    <>
                                        {dlpData.status === DlpStatus.complete &&
                                            <CircleCheckIcon size="sm" className={classes.enabledColor}/>}
                                        {dlpData.status === DlpStatus.pending && <ExclamationTriangleIcon size="sm"
                                                                                                          className={classes.pendingColor}/>}
                                        {dlpData.status === DlpStatus.requisite && <ExclamationCircleIcon size="sm"
                                                                                                          className={classes.errorColor}/>}

                                        <Body size={"sm"}>
                                            {dlpData.status === DlpStatus.complete && translate('resources.integrations.values.enabled')}
                                            {dlpData.status === DlpStatus.pending && translate('resources.integrations.values.pending')}
                                            {dlpData.status === DlpStatus.requisite && translate('resources.integrations.values.actionRequired')}
                                        </Body>
                                    </>
                            }
                        </div>
                        <Body as={"div"} size={"sm"} appearance={"secondary"}>
                            {dlpData.status === DlpStatus.requisite && <p className={classes.requisite}>
                                {callFormattedMessage("resources.integrations.securityService.dlpLicenceRequisite")}
                            </p>}
                        </Body>
                    </div>}
                </div>
            </SheetBody>

            <div className={classes.sheetBottom}>
                <Button appearance={"secondary"} disabled={isLoading} onClick={onClose}>{translate("resources.integrations.close")}</Button>
            </div>

            <Modal style={{minHeight: '256px'}}
                   confirmClose={false} size="md"
                   onClose={() => setModalState({...modalState, isOpen: false})}
                   isOpen={modalState.isOpen}
            >
                <ModalHeader title={
                    <Title level={1} addClassName="tw-flex tw-items-center tw-gap-1" size={"xxs"}>
                        <ExclamationTriangleIcon size="md" className={classes.pendingColor}/>
                        {translate("resources.integrations.modal.warning")}
                    </Title>
                } enableClose={!modalState.isLoading}/>
                <ModalBody>
                    <Body size={"md"}>
                        <Bold appearance={"semibold"}>
                            {translate("resources.integrations.securityService.modal.dlp")}
                        </Bold>
                    </Body>
                    <Body size={"md"} addClassName="tw-pb-3 tw-pt-3">
                        {translate("resources.integrations.securityService.modal.enableDlp")}
                    </Body>
                    <div className="tw-pt-4 tw-pb-2">
                        <Checkbox
                            checked={modalState.isChecked || false}
                            onChange={() => setModalState({...modalState, isChecked: !modalState.isChecked})}
                        >
                            {translate("resources.integrations.securityService.modal.tAndC")} <Link size={"sm"}
                                  target='_blank' rel='noreferrer'
                                  dataMetrics="cloudngfw-integration-security-service-modal-end-user-agreement"
                                  href={"https://www.paloaltonetworks.com/content/dam/pan/en_US/assets/pdf/legal/palo-alto-networks-end-user-license-agreement-eula.pdf"}>
                            {translate("resources.integrations.securityService.modal.endUserAgreement")}
                            </Link>
                        </Checkbox>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ModalCloseButton disabled={modalState.isLoading}>{translate("common.cancel")}</ModalCloseButton>
                    <SaveButton
                        appearance="primary"
                        size="md"
                        label={translate("resources.integrations.enable")}
                        onClick={() => handleDlpStatus()}
                        submitOnEnter={true}
                        loading={modalState.isLoading}
                        disabled={modalState.isLoading || !modalState.isChecked}
                    />
                </ModalFooter>
            </Modal>
        </Sheet>
    );
};

export default SecurityServices;
