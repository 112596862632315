import {FormControl, Grid} from "@material-ui/core";
import { Field } from "react-final-form";
import { PANWDSInput } from "../../../../components/PANWDSElements";
import { validateAwsAccounts } from "../../../../utils/validate";
import {Row} from "../../../../components/FormElements";
import {useTranslate} from "../../../../customHooks";

const Index = () => {
    const translate = useTranslate();

    return (
        <>
            <Row>
                <Grid item xs={12} sm={12} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="AllowListAccounts"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.firewallsV2.endpointManagement.allowListedAccounts`)}
                            validate={validateAwsAccounts}
                            dataMetrics="cloudngfw-firewall-create-name"
                            placeholder="Enter one or more AWS accounts and use comma to separate them (e.g account1, account2 )"
                            note={translate(`resources.firewallsV2.allowListedAccountsMessage`)}
                        />
                    </FormControl>
                </Grid>
            </Row>
        </>
    );
};

export default Index;
