import { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import { PANWDSTable } from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';
import { nameStyleCursor } from '../../layout/styles';
import { RouteUri } from '../../routeUri';
import { toast } from '../../components';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from '@panwds/react-ui';
import get from 'lodash/get';
import { usePermissions, useTranslate } from '../../customHooks';

const CategoryList = (props: any) => {
  const nameClass = nameStyleCursor();

  const translate = useTranslate();
  const history = useHistory();

  const title = translate(`resources.category.name`)
  const [gridData, setGridData] = useState([]); const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken]: any = useState();
  const { permissions } = usePermissions();

  // @ts-ignore
  const [gridRef, setGridRef] = useState({
    current: {
      columns: []
    },
  });

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  const deleteAction = (selected: any) => {
    if (!permissions?.DeleteCustomURLCategory) {
      toast.warning(translate("permissions.cantExecute"));
      return;
    }
    if (selected && Array.isArray(selected)) {
      let calls: any = [];
      setLoading(true);
      selected.map((item: any) => {
        if (item.Name) {
          calls.push(dataProvider.delete("category", { id: item.Name, previousData: {...item, RuleStackName: ruleStackName} }));
        }
        return item;
      });
      Promise.all(calls)
        .then((responses) => toast.success(translate(`resources.category.delete.success`)))
        .catch((e) => toast.error(e?.error))
        .finally(() => {
          setLoading(false);
          loadGridData(true);
        });
    }
  }

  const getcolumns = () => [
    {
      accessor: 'Name',
      Header: translate(`resources.category.fields.Name`),
      columnSize: 2,
      render: ({ row }: any) => <span
        className={nameClass.blueColor}
        onClick={(evt) => onRowClick(row, evt)}>
          {row?.original?.Name}
      </span>,
    },
    {
      accessor: 'URLCategoryCandidate.URLTargets',
      Header: translate(`resources.category.fields.URLTargets`),
      columnSize: 5,
      noTooltip: true,
      render: ({ row }: any) => {
        if (row?.original?.URLCategoryCandidate?.URLTargets) {
          const list = row?.original?.URLCategoryCandidate?.URLTargets.map((entry: any) => {
            return entry
          }).join(", ");
          return <Tooltip label={list}>
            <span>{list}</span>
          </Tooltip>
        }
        return null;
      },
    },
    {
      accessor: 'URLCategoryCandidate.Description',
      Header: translate(`resources.category.fields.Description`),
      columnSize: 5,
      render: ({ row }: any) => {
        if (row?.original?.URLCategoryCandidate) {
          return <span>{row?.original?.URLCategoryCandidate?.Description}</span>
        }
        return null;
      },
    }
  ];

  const loadGridData = (needRefresh=false) => {
    if (!permissions?.ListURLCategories) {
      if (permissions && !permissions?.ListURLCategories) {
        // TODO toast is not showing, but if we add toast container it will show
        // many times until permissions is loaded, need to rethink this logic
        toast.warning(translate("permissions.cantView"));
      }
      setLoading(false);
      return;
    }
    if ((gridData.length && nextToken) || !gridData.length || needRefresh) {
      if (!gridData.length || needRefresh) {
        setLoading(true);
      }
      dataProvider.getList("category", {
        data: { RuleStackName: ruleStackName, NextToken: (nextToken) ? nextToken : undefined }
      }).then(async (response: any) => {
        if (response.data) {
          //@ts-ignore
          let responseObj = response.data;
          if (needRefresh){
            setGridData(responseObj);
          } else {
            //@ts-ignore
            setGridData([...gridData, ...responseObj]);
          }
          setNextToken(response?.nextToken);
        } else {
          toast.error(response?.error, { toastId: "category-getList" });
        }
      })
      .catch((e:any) => {
        if (e?.noToast) {
          return;
        }
        toast.error(e?.error, { toastId: "category-getList" });
      })
      .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    return () => {
      dataProvider.abort('category');
    }
  }, []);

  const onRowClick = useCallback((rowProps, event) => {
    if (!permissions?.DescribeURLCategory) {
      return;
    }
    history.push(
      RouteUri.URLCategoryEdit.replace(":rulestackname", ruleStackName)
        .replace(":categoryname", rowProps.original.Name));
  }, [history, ruleStackName, permissions]);

  const getGridDataValue = (name: string, data: any) => {
    let value = '';
    if (name === 'URLCategoryCandidate.URLTargets') {
      const lists = get(data, name, []);
      value = lists ? JSON.stringify(lists) : ''
    } else {
      value = get(data, name);
    }
    if (value) return value + '';
    return '';
  }

  const toolbarActions: any[] = [];
  if (permissions?.DeleteCustomURLCategory){
    toolbarActions.push({
      title: translate(`common.actions`),
      dataMetrics: "cloudngfw-category-actions-btn",
      actionsMap: [{ menuText: translate(`common.delete`), handleAction: deleteAction, dataMetrics: "cloudngfw-category-delete-btn" },]
    });
  }
  if (permissions?.CreateCustomURLCategory){
    toolbarActions.push({
      title: translate(`resources.category.fields.CreateButton`),
      actionsMap: [],
      dataMetrics: "cloudngfw-category-create-btn",
      handleButtonAction: () => history.push(RouteUri.URLCategoryCreate.replace(":rulestackname", ruleStackName)),
      type: 'button',
      appearance: 'primary'
    });
  }

  return (
    <>
      <PANWDSTable
        setHandle={setGridRef}
        loadGridData={loadGridData}
        infiniteScroll={false}
        overflowTable={true}
        permissions={permissions}
        columns={getcolumns()}
        gridData={gridData}
        showSelectGroupBy={false}
        title={title}
        subtitle={translate(`resources.category.fields.ListSubtitle`)}
        idProperty={"RuleStackName"}
        showToolbar={true}
        dropDownActionsArray={toolbarActions}
        showTileTitle={true}
        emptyTitle={translate(`resources.category.fields.EmptyTitle`)}
        emptySubtitle={translate(`resources.category.fields.EmptySubtitle`)}
        loading={loading}
        isBackgroundFetching={nextToken}
        singleSelect={false}
        multiSelect={true}
        useOldToolbarButtons={true}
        offsetTableHeight={305}
        tableMinHeight={550}
        searchFilterRequired={true}
        dataMetrics="cloudngfw-category-table"
        dataTestId="cloudngfw-category-table"
      />
    </>
  );
};

export default withRouter(CategoryList);
