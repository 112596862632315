
import { Box, FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useCallback, useState, Fragment} from "react";
import { Field, FormSpy } from "react-final-form";
import { useHistory, withRouter } from "react-router-dom";
import { Button as PANWDSButton }  from "@panwds/react-ui";
import { PANTile, PANTitle, SaveButton, toast } from '../../components';
import { Row } from "../../components/FormElements";
import { isAlphaNumeric, isRequired, maxLength, composeValidators } from '../../utils/validate';
import {PANWDSBreadcrumbs, PANWDSCheckboxField, PANWDSForm, PANWDSInput} from '../../components/PANWDSElements';
import {ApplicationConfigManager} from "../../types";
import {useTranslate} from '../../customHooks';
import {dataProvider} from "../../dataProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
  button: {
    textTransform: "capitalize",
  },
}));

const CertificateCreate = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  // get the rulestack name from route params
  const ruleStackName = props.match.params.rulestackname;

  const save = useCallback(
    async (values) => {
      values = { ...values, RuleStackName: ruleStackName }
      if (values['CertificateSelfSigned']) {
        delete values['CertificateSignerArn']
      }
      setSubmitting(true);
      try {
          await dataProvider.create("certificate", { data: values });
        //toast.success("Success");
        history.goBack();
        return;
      } catch (error: any) {
        toast.error(error?.error, { toastId: "certificate-create" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]
  );

  const CertificateCreateToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          dataTestId="rulestack-create-cancel"
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-certificate-create-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          submitOnEnter={true}
          className={classes.button}
          loading={submitting}
          dataMetrics="cloudngfw-certificate-create-save-button"
          {...toolbarProps}
        />
      </div>
    )
  };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          [`rulestacks`]: `${translate(`resources.ruleStacks.name`)} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
          [ruleStackName]: ruleStackName,
          [`objects`]: translate(`resources.objects.name`),
          [`certificates`]: translate(`resources.certificate.name`),
          create: translate(`resources.certificate.fields.CreateButton`)
        }}
      />
      <PANTitle title={translate(`resources.certificate.fields.CreateButton`)} divider />
      <Box style={{ position: 'relative' }}>

          <PANWDSForm
              onSubmit={save}
              toolbar={<CertificateCreateToolbar />}
              style={{ margin: 16 }}
          >
              <Fragment>
                  <Grid container style={{ width: 'auto' }}>
                      <PANTile title={translate(`resources.certificate.fields.General`)} subtitle={translate(`resources.certificate.fields.CreateSubtitle`)} size={12}>
                          <Row>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="Name"
                                          // @ts-ignore
                                          component={PANWDSInput}
                                          title={translate(`resources.certificate.fields.Name`)}
                                          dataMetrics="cloudngfw-certificate-create-field-name"
                                          required
                                          validate={composeValidators(isRequired, maxLength(63), isAlphaNumeric)}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                  <FormControl fullWidth>
                                      <Field
                                          name="Description"
                                          // @ts-ignore
                                          component={PANWDSInput}
                                          title={translate(`resources.certificate.fields.Description`)}
                                          dataMetrics="cloudngfw-certificate-create-field-description"
                                          validate={maxLength(512)}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={12} className="noLeftPadding">

                                  <FormSpy subscription={{ values: true }}>
                                      {({values, ...rest }) => {
                                          let  certificateSelfSigned = values.CertificateSelfSigned;
                                          //@ts-ignore
                                          return (<FormControl fullWidth>
                                              <Field
                                                  name="CertificateSignerArn"
                                                  // @ts-ignore
                                                  component={PANWDSInput}
                                                  title={translate(`resources.certificate.fields.CertificateSignerArn`)}
                                                  dataMetrics="cloudngfw-certificate-create-field-signerarn"
                                                  disabled={certificateSelfSigned}
                                              />
                                          </FormControl>)
                                      }}
                                  </FormSpy>
                              </Grid>
                              <Grid item xs={6} className="noLeftPadding">
                                  <FormControl fullWidth>
                                      <Field
                                          name="CertificateSelfSigned"
                                          // @ts-ignore
                                          component={PANWDSCheckboxField}
                                          label={translate(`resources.certificate.fields.SelfSignedCertificate`)}
                                          dataMetrics="cloudngfw-certificate-create-field-selfsigned"
                                          muted={translate(`resources.certificate.fields.SelfSignedCertificateSubtitle`)}
                                          type="checkbox"
                                      />
                                  </FormControl>
                              </Grid>
                          </Row>
                      </PANTile>
                  </Grid>
              </Fragment>
          </PANWDSForm>
      </Box>
    </>
  );
};

export default withRouter(CertificateCreate);
