import moment from 'moment';

export const getMonthsInCSTFormat = (date: any, months: any) => {
    date.setMonth(date.getMonth() + months);
    const year = date.getFullYear();
    let month = date.getMonth() + 1; // Months start at 0!
    let day = date.getDate();
    let endDate = `${year}-${month}-${day}`;
    return endDate;
}

export const getDateFormat = (dateString: string) => {
    return moment(dateString).format('YYYY-MM-DD')
}

// Function to get the previous day's date in YYYY-MM-DD format
export const getPreviousDay = (date: any) => {
    date.setDate(date.getDate() - 1); // Subtract one day from the current date
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
};
