import React from "react";
import {authProvider} from "../../authProvider";
import {ApplicationConfigManager} from "../../types";
import {RouteUri} from "../../routeUri";
import {toast} from "../../components";
import { useHistory } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';
import TimeoutModal from './timeoutModal';

const makeEventMessage = (message: string) => {
  return {
    message,
    time: new Date().getTime() * 1000,
  }
}

const CustomSidebar = (props: any) => {
  const [showModal, setShowModal] = React.useState(false);
  const history = useHistory();

  const broadCastChannel = React.useMemo(() => {
    return new BroadcastChannel(process.env.REACT_APP_IDLE_CHANNEL || 'fwaas-idle-timer');
  }, []);

  const handleOnIdle = React.useCallback((event: any) => {
    const remainingTime = getRemainingTime();
    if (remainingTime <= 0) {
      setShowModal(true);
    }
  }, []);

  const handleOnStayLoggedIn = React.useCallback(async () => {
    setShowModal(false);
    try {
      await authProvider.checkAuth(null);
      broadCastChannel.postMessage(makeEventMessage(process.env.REACT_APP_STAY_MESSAGE || ''));
    } catch (error: any) {
      broadCastChannel.postMessage(makeEventMessage(process.env.REACT_APP_LOGOUT_MESSAGE || ''));
      logoutUser();
    }
  }, []);

  const handleOnTimeExpire = React.useCallback(() => {
    setShowModal(false);
    logoutUser();
  }, []);

  const handleOnStayAnotherTab = React.useCallback(() => {
    setShowModal(false);
    reset();
  }, []);

  const logoutUser = React.useCallback(() => {
    ApplicationConfigManager.getInstance().clearSession();
    authProvider.logout(null).finally(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      history.replace(RouteUri.Home);
    });
  }, [history]);

  const messageListenerCallback = React.useCallback((event: MessageEvent<{message: string }>) => {
    if (typeof event?.data?.message === 'string') {
      switch (event?.data?.message) {
        case process.env.REACT_APP_LOGOUT_MESSAGE:
          logoutUser();
          break;
        case process.env.REACT_APP_STAY_MESSAGE:
          handleOnStayAnotherTab();
          break;
        default:
          break;
      }
    }

  }, [logoutUser, handleOnStayAnotherTab]);

  const togglePopup = () => {
    setShowModal(!showModal);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getRemainingTime, reset } = useIdleTimer({
    timeout: parseInt(process.env.REACT_APP_IDLE_TIMEOUT || '900000', 10),
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true
    },
  });

  const modalDelayToClose = parseInt(process.env.REACT_APP_MODAL_TIMER || '10', 10);

  React.useEffect(() => {
    broadCastChannel.addEventListener('message', messageListenerCallback);
    return () => {
      broadCastChannel.removeEventListener('message', messageListenerCallback);
      broadCastChannel.close();
    }
  }, []);

  return (
    <>
      {/*<Sidebar classes={classes} {...props} size={200}/>*/}
      {props.children}
      <TimeoutModal
        showModal={showModal}
        delay={modalDelayToClose}
        onStayLoggedIn={handleOnStayLoggedIn}
        togglePopup={togglePopup}
        onTimeExpire={handleOnTimeExpire}
      />
    </>
  );
};

export default CustomSidebar;
