import { Line } from "react-chartjs-2";
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { useEffect, useState } from "react";
import { CircularProgress } from '@material-ui/core';
import { useTranslate } from "../../customHooks";
import { makeStyles } from '@material-ui/core/styles';
import { PANTitle, SplitButton, toast } from "../../components";
import { withRouter } from "react-router-dom";
import { ISplitButtonAction } from "../../types";
import { dataProvider } from "../../dataProvider";
import * as DataTypes from "../../api/FwaasDataTypes";
import zoomPlugin from 'chartjs-plugin-zoom';
import {PANWDSBreadcrumbs} from "../../components/PANWDSElements";

  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
  );

const useStyles = makeStyles((theme) => ({
    lineChartContainer: {
        margin: '16px',
    },
    dropdownContainer: {
        margin: '16px',
        display: 'flex',
        "&>section": {
            "&>div": {
                "&>div": {
                    "&>ul": {
                        "&>li": {
                            backgroundColor: '#FFFFFF !important'
                        },
                        "&>li:hover": {
                            backgroundColor: '#f1f1f1 !important'
                        }
                    }
                }
            }
        }
    },
    disabledBackground: {
        width: "100%",
        height: "100%",
        position: "fixed",
        background: "#ccc",
        opacity: 0.5,
        zIndex: 1
    },
    loaderStyles: {
        height: "400px",
        textAlign: "center",
        "&>div": {
            marginTop: "200px",
        }
    }
}));

export const SubscriptionCreditUsage = (props: any) => {
    const d = new Date();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const [ startDate, setStartDate ] = useState<any>(`${month}-${year-1}`);
    const [ endDate, setEndDate ] = useState<any>(`${month}-${year}`);
    const [creditUsageInfo, setCreditUsageInfo ] = useState<any>([]);
    const [ xAxisLabels, setXAxisLabels ] = useState([startDate, endDate]);
    const [ usedCredits, setUsedCredits ] = useState<any>([]);
    const [ dimension, setDimension ] = useState('all');
    const [ purchasedCredits, setPurchasedCredits ] = useState<any>();
    const [ selectedCreditType, setSelectedCreditType ] = useState('all');
    const [ loadingCreditUsage, setLoadingCreditUsage ] = useState<boolean>(true);
    const [ filteredCreditUsage, setFilteredCreditUsage ] = useState<any>([]);

    let timer: NodeJS.Timeout;
    const translate = useTranslate();
    const classes = useStyles();

    const dropDownDimensionArray: ISplitButtonAction[] = [
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.fwUsageHours"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.fwUsageHours"), handleAction: () => handleDimensionDropdownOption('FWUsageHours'), enabled: dimension !== 'FWUsageHours' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.tpTrafficSecured"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.tpTrafficSecured"), handleAction: () => handleDimensionDropdownOption('TPTrafficSecured'), enabled: dimension !== 'TPTrafficSecured' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.tpUsageHours"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.tpUsageHours"), handleAction: () => handleDimensionDropdownOption('TPUsageHours'), enabled: dimension !== 'TPUsageHours' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.urlFUsageHours"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.urlFUsageHours"), handleAction: () => handleDimensionDropdownOption('URLFUsageHours'), enabled: dimension !== 'URLFUsageHours' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.enhancedSupport"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.enhancedSupport"), handleAction: () => handleDimensionDropdownOption('EnhancedSupport'), enabled: dimension !== 'EnhancedSupport' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.overagesPayG"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.overagesPayG"), handleAction: () => handleDimensionDropdownOption('OveragesPAYG'), enabled: dimension !== 'OveragesPAYG' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.all"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.all"), handleAction: () => handleDimensionDropdownOption('all'), enabled: dimension !== 'all' },
    ];

    const dropDownCreditTypeArray: ISplitButtonAction[] = [
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.contract"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.contract"), handleAction: () => handleCreditTypeDropdownOption('Contract'), enabled: selectedCreditType !== 'Contract' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.payG"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.payG"), handleAction: () => handleCreditTypeDropdownOption('PAYG'), enabled: selectedCreditType !== 'PAYG' },
        { menuText: translate("resources.support.subscription.subscriptionCreditUsage.all"), progressText: translate("resources.support.subscription.subscriptionCreditUsage.all"), handleAction: () => handleCreditTypeDropdownOption('all'), enabled: selectedCreditType !== 'all' },
    ];

    const handleDimensionDropdownOption = (dimensionClicked: string) => {
        setLoadingCreditUsage(true);
        setSelectedCreditType('all');
        // need to set dates back to previous 12 months after zoom is enabled.
        const d = new Date();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        setStartDate(`${month}-${year-1}`);
        setEndDate((`${month}-${year}`));
        setDimension(dimensionClicked);
    }

    const handleCreditTypeDropdownOption = async (creditTypeClicked: string) => {
        setSelectedCreditType(creditTypeClicked);
        if (creditTypeClicked !== 'all') {
            let filteredCreditUsageInfo = creditUsageInfo.filter((item: { Type: string; }) => item["Type"] === creditTypeClicked);
            await setFilteredCreditUsage(filteredCreditUsageInfo);
            await setXAxisLabels(filteredCreditUsageInfo.map((item: { [x: string]: any; }) => item["Date"]));
            await setUsedCredits(filteredCreditUsageInfo.map((item: { [x: string]: any; }): any => Number(item["UsedCredit"] )));
            await setPurchasedCredits(filteredCreditUsageInfo.map((item: { [x: string]: any; }) => Number(item["TotalCredit"] )));
        } else {
            await setFilteredCreditUsage(creditUsageInfo);
            await setXAxisLabels(creditUsageInfo.map((item: { [x: string]: any; }) => item["Date"]));
            await setUsedCredits(creditUsageInfo.map((item: { [x: string]: any; }): any => Number(item["UsedCredit"] )));
            await setPurchasedCredits(creditUsageInfo.map((item: { [x: string]: any; }) => Number(item["TotalCredit"] )));
        }
    }

    const getAverageCredits = (usedCredits: any[]) => {
        var sumCredits = 0;
        usedCredits?.forEach(function (usedCredit: number) {
            sumCredits += usedCredit;
        });
        const averageCreds =  sumCredits/usedCredits.length;
        let avgCredsArray: any = [];
        for(let i = 0; i < usedCredits.length; i++) {
            avgCredsArray.push(averageCreds);
        }
        return avgCredsArray;
    }

    useEffect(() => {
        dataProvider.describe("settings", '', { startDate: startDate, endDate: endDate, dimension: dimension === 'all' ? '' : dimension })
        .then(async (response: DataTypes.IFwaasApiResponse) => {
            if (response.data){
                const creditUsageInfoResponse = await response.data?.CreditUsageInfo;
                if(creditUsageInfoResponse !== undefined) {
                    setCreditUsageInfo(creditUsageInfoResponse);
                    setFilteredCreditUsage(creditUsageInfoResponse);
                    setXAxisLabels(creditUsageInfoResponse.map((item: { [x: string]: any; }) => item["Date"]));
                    setUsedCredits(creditUsageInfoResponse.map((item: { [x: string]: any; }): any => Number(item["UsedCredit"] )));
                    setPurchasedCredits(creditUsageInfoResponse.map((item: { [x: string]: any; }) => Number(item["TotalCredit"] )));
                } else {
                    setCreditUsageInfo([]);
                    setUsedCredits([]);
                    setPurchasedCredits([]);
                }
            } else {
                setCreditUsageInfo([]);
                setUsedCredits([]);
                setPurchasedCredits([]);
                toast.error(response?.error?.error);
            }
        })
        .catch((e: any) => {
          toast.error(e?.error);
        }).finally(() => {
          setLoadingCreditUsage(false);
        });
       return;
    }, [dimension, startDate, endDate]);

    const getCreditsUsageOnNewDate = ({ chart }: any) => {
        const ticks = chart.scales.x.ticks;

        if(ticks.length === 1) {
            chart.resetZoom();
        }

        const d = new Date();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        const startDate = ticks[0].label;
        const endDate = ticks.length > 1 ? ticks[ticks.length - 1].label : `&end=${month}-${year}`;
        setStartDate(startDate);
        setEndDate(endDate);
        clearTimeout(timer);
            timer = setTimeout(() => {
                chart.stop(); // make sure animations are not running
                chart.update('none');
            }, 500);
        return;
    }

    const zoomOptions = {
        zoom: {
        drag: {
            enabled: creditUsageInfo?.length >= 1 && filteredCreditUsage?.length >= 1,
        },
        mode: 'x' as const,
        onZoomComplete: getCreditsUsageOnNewDate,
        }
    };

    const scales = {
        x: {
            min: startDate,
            max: endDate,
            title: {
                display: true,
                align: 'center' as const,
                text: translate(`resources.support.subscription.subscriptionCreditUsage.dates`),
                font: {
                    size: 14,
                    weight: '400',
                    family: 'Roboto',
                },
            }
        },
        y: {
            min: 0,
            max: 500,
            title: {
                display: true,
                align: 'center' as const,
                text: translate(`resources.support.subscription.subscriptionCreditUsage.creditUsageSentenceCase`),
                font: {
                    size: 14,
                    weight: '400',
                    family: 'Roboto',
                },
            }
        },
    };

    const scalesTitle = {
        x: {
            offset: (creditUsageInfo === undefined || creditUsageInfo?.length <= 2) && (filteredCreditUsage === undefined || filteredCreditUsage?.length <= 2),
            title: {
                display: true,
                align: 'center' as const,
                text: translate(`resources.support.subscription.subscriptionCreditUsage.dates`),
                font: {
                    size: 14,
                    weight: '400',
                    family: 'Roboto',
                },
            }
        },
        y: {
            title: {
                display: true,
                align: 'center' as const,
                text: translate(`resources.support.subscription.subscriptionCreditUsage.creditUsageSentenceCase`),
                font: {
                    size: 14,
                    weight: '400',
                    family: 'Roboto',
                },
            }
        },
    }

    const options = {
        responsive: true,
        aspectRatio: 2.5,
        scales: creditUsageInfo?.length >= 1 && filteredCreditUsage?.length >= 1 ? scalesTitle : scales,
        plugins: {
            zoom: zoomOptions,
            legend: {
                display: creditUsageInfo?.length >= 1 && filteredCreditUsage?.length >= 1,
                position: 'top' as const,
                align: 'end' as const,
                color: '#000000',
                labels: {
                    // This is for more specific font property, overrides the global property
                    font: {
                        size: 10,
                        weight: '400',
                        family: 'Roboto',
                        lineHeight: 12,
                    },
                    usePointStyle: true,
                    padding: 29,
                    boxWidth: 10,
                    boxHeight: 8,
                    boxPadding: 15,
                    textAlign: 'center' as const
                },
            },
        },
        cubicInterpolationMode: "monotone",
        transitions: {
            zoom: {
              animation: {
                duration: 100
              }
            }
          }
    };

    const labels =  xAxisLabels;

    const data = {
        labels,
        datasets: [
            {
                label: translate(`resources.support.subscription.subscriptionCreditUsage.usedCredits`),
                data: usedCredits,
                borderColor: '#006FCC',
                backgroundColor: '#006FCC',
            },
            {
                label: translate(`resources.support.subscription.subscriptionCreditUsage.averageCredits`),
                data: getAverageCredits(usedCredits),
                borderColor: '#0A644E',
                backgroundColor: '#0A644E',
            },
            {
                label: translate(`resources.support.subscription.subscriptionCreditUsage.purchasedCredits`),
                data: purchasedCredits,
                borderColor: '#CA386A',
                backgroundColor: '#CA386A',
            },
        ],
    };

    const plugin = {
        id: 'emptyChart',
        beforeDraw(chart: any) {
            const { datasets } = chart.data;
            let hasCreditUsageData = false;

            for (let dataset of datasets) {
                if (dataset.data.length > 0 && dataset.data.some((item: number) => item !== 0)) {
                    hasCreditUsageData = true;
                    break;
                }
            }

            if (!hasCreditUsageData) {
                const { chartArea: { left, top, right, bottom }, ctx } = chart;
                const centerX = (left + right) / 2;
                const centerY = (top + bottom) / 2;

                chart.clear();
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(translate(`resources.support.subscription.subscriptionCreditUsage.noUsageByTheTenant`), centerX, centerY);
                ctx.restore();
            }
        }
    };

  return (
    <>
        <PANTitle divider={false} />
        <PANWDSBreadcrumbs
            mapping={{
                subscription: translate(`resources.support.subscription.subscriptionCreditUsage.subscriptionManagement`),
                [`creditUsage`]: translate(`resources.support.subscription.subscriptionCreditUsage.creditUsage`),
             }}
        />
        <PANTitle title={translate(`resources.support.subscription.subscriptionCreditUsage.creditUsage`)} divider={true}/>
        <div>
            <div className={classes.dropdownContainer}>
                <SplitButton
                    key='CreditTypeArray'
                    buttons={dropDownCreditTypeArray}
                    title={selectedCreditType !== 'all' ? selectedCreditType : translate("resources.support.subscription.subscriptionCreditUsage.creditType")}
                    disabled={loadingCreditUsage}
                />
                <SplitButton
                    key='DimensionArray'
                    buttons={dropDownDimensionArray}
                    title={dimension !== 'all' ? dimension : translate("resources.support.subscription.subscriptionCreditUsage.dimensions")}
                    disabled={loadingCreditUsage}
                />
            </div>
            <div className={classes.lineChartContainer}>
            { loadingCreditUsage ?
                <div className={classes.loaderStyles}> <CircularProgress color="inherit" size={25} thickness={2} /></div>
                :<Line options={options} data={data} plugins={[plugin]} />
            }
            </div>
        </div>
    </>
  );
}

export default withRouter(SubscriptionCreditUsage);
